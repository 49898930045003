import CryptoJS from "crypto-js";
import { setCookie, parseCookies, destroyCookie } from "nookies";

export function useSecureCookieStorage() {
  const setItem = (name: string, data: any) => {
    const key = `${process.env.REACT_APP_STORAGE_VERSION}-${name}`;

    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY ?? ""
    ).toString();

    setCookie(undefined, key, encrypted, {
      maxAge: 60 * 60 * 24,
      path: "/",
      domain: process.env.REACT_APP_COOKIE_DOMAIN ?? ".eventx.com.br",
      httpOnly: false,
    });
  };

  const getItem = (name: string) => {
    if (typeof window === "undefined") {
      return;
    }

    const key = `${process.env.REACT_APP_STORAGE_VERSION}-${name}`;

    const { [key]: encrypted } = parseCookies();

    if (!encrypted) {
      return;
    }

    const decrypted = CryptoJS.AES.decrypt(
      encrypted,
      process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY ?? ""
    ).toString(CryptoJS.enc.Utf8);

    if (typeof decrypted !== "string") {
      return "";
    }

    return JSON.parse(decrypted);
  };

  const removeItem = (name: string) => {
    destroyCookie(
      undefined,
      `${process.env.REACT_APP_STORAGE_VERSION}-${name}`,
      {
        domain: process.env.REACT_APP_COOKIE_DOMAIN ?? ".eventx.com.br",
        path: "/",
      }
    );
  };

  return { setItem, getItem, removeItem };
}
