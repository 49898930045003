import { useEffect, useState } from "react";

import { RiCloseCircleLine } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import { RiBankLine } from "react-icons/ri";

import { Colors } from "constants/colors";

import {
  ButtonSearch,
  ContainerInputSearch,
  ContainerModal,
  ContainerSelect,
  ModalStyled,
  NormalCardBorder,
} from "./styles";

import Datas from "constants/Datas";

interface ModalSelectBankProps {
  handleCancel(): void;
  handleOk(): void;
  isModalVisible: boolean;
  handleSelectBank(bankId: string): void;
}

interface Bank {
  value: string;
  label: string;
}

export function ModalSelectBank({
  isModalVisible,
  handleCancel,
  handleOk,
  handleSelectBank,
}: ModalSelectBankProps) {
  const [banks, setBanks] = useState<Bank[] | []>();

  const [textSearch, setTextSearch] = useState("");

  useEffect(() => {
    setBanks(Datas.Banks);
  }, []);

  useEffect(() => {
    let filterTimeout: NodeJS.Timeout;

    const doFilter = () => {
      clearTimeout(filterTimeout);
      if (!textSearch) return setBanks(Datas.Banks ?? []);

      filterTimeout = setTimeout(async () => {
        if (Datas.Banks) {
          setBanks(
            Datas.Banks?.filter((bank: Bank) =>
              bank.label.toLowerCase().includes(textSearch.toLowerCase())
            )
          );
        }
      }, 500);
    };

    if (textSearch) {
      doFilter();
    }
  }, [textSearch]);

  return (
    <ModalStyled
      width={857}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <h1>Filtrar por Banco</h1>

        <h2>Selecione o banco para a visualização dos resultados.</h2>

        <ContainerInputSearch>
          <input
            type="text"
            placeholder="Insira o nome do banco..."
            onChange={(e) => setTextSearch(e.target.value)}
          />

          <ButtonSearch onClick={() => {}}>
            <FiSearch size={18} color={Colors.primary100} />
          </ButtonSearch>
        </ContainerInputSearch>

        <ContainerSelect>
          {banks?.map((bank) => (
            <NormalCardBorder onClick={() => handleSelectBank(bank.value)}>
              <div className="circle-icon">
                <RiBankLine size={16} color={Colors.secondary100} />
              </div>

              <h3 className="normal">{bank.label}</h3>
            </NormalCardBorder>
          ))}
        </ContainerSelect>
      </ContainerModal>
    </ModalStyled>
  );
}
