import styled from "styled-components";
import { Colors } from "constants/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  > h1 {
    color: ${Colors.secondary100};
    font-family: "Poppins";
    font-size: 1.625rem;
    line-height: 2.6rem;
    font-weight: 600;
    margin: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  padding-bottom: 270px;
`;
