import api from '../../api'

import useSWR from 'swr'

import {
  transformerDateToBr,
  transformerDateToSend
} from 'utils/transformerDate'
import { authClient } from '../../authClient'

import { ptBR } from 'date-fns/locale'
import { format, parseISO, isAfter, addDays, addMinutes } from 'date-fns'

import { Purchase, TicketType, UserType } from 'types/index'
import { Organizer } from 'contexts/AuthContext'
import moment from 'moment'
import { authPayment } from 'services/authPayment'

export async function VerifyCode (code: string) {
  try {
    const url: string = `/verify-code-password/${code}`

    const response = await api.get(url)

    return response.data
  } catch (err) {
    throw new Error()
  }
}

interface ResetPasswordProps {
  credentials: {
    password: string
    code: string
  }
}

export async function ResetPassword (credentials: ResetPasswordProps) {
  try {
    const url: string = '/reset-password'

    const response = await api.put(url, credentials)

    return response.data
  } catch (err) {
    throw new Error()
  }
}

export function useUser<Data = any, Error = any> (
  id: number | string | undefined
) {
  const url: string = `/user`

  const { data, error, mutate } = useSWR<Data, Error>(url, async url => {
    const response = await authClient.get(url)

    const newResponse = {
      ...response.data,
      formattedBirthdate: response?.data.birthdate
        ? transformerDateToBr(response?.data.birthdate)
        : response?.data.birthdate
    }

    return newResponse
  })

  return { data, error, mutate }
}

export function useUserPurchase<Data = any, Error = any> (
  page: number | string
) {
  const partialUrl: string = '/user-purchase'

  const { data, error, mutate, isValidating } = useSWR<Data, Error>(
    [partialUrl, page],
    async () => {
      const url: string = `${partialUrl}?page=${page}&limit=5`

      const response = await authClient.get(url)

      const newMapData = response.data.results.map((purchase: Purchase) => {
        if (purchase === null) {
          return {
            purchase
          }
        }

        const createdAt = moment(purchase.created_at)

        const createdAtComponent = createdAt.utc().format('DD/MM/YYYY')
        const createdAtTimeComponent = createdAt.utc().format('HH:mm')

        const purchaseDate = parseISO(purchase.created_at)
        const moreThan15minutes = addMinutes(purchaseDate, 15)

        return {
          ...purchase,
          formattedDate: format(
            parseISO(purchase.created_at),
            "eee', 'dd' 'MMMM' 'yyyy'",
            { locale: ptBR }
          ),
          formattedDay: createdAtComponent,
          formattedHour: createdAtTimeComponent
        }
      })

      const newResponse = { ...response.data, results: newMapData }

      return newResponse
    }
  )

  return { data, error, mutate, isValidating, partialUrl }
}

interface useUserPurchaseByIdProps {
  purchaseId: string
}

export function useUserPurchaseById<Data = any, Error = any> ({
  purchaseId
}: useUserPurchaseByIdProps) {
  const url: string = `/user-purchase/${purchaseId}`

  const { data, error, mutate } = useSWR<Data, Error>(
    url,
    async url => {
      const { data } = await authClient.get(url)

      const response = data[0]

      const newResponse = {
        ...response
      }

      return newResponse
    },
    {
      revalidateOnFocus: false,
      revalidateIfStale: false
    }
  )

  return { data, error, mutate }
}

export interface Event {
  id: string
  name: string
  photo: string
  address_name: string
  address_street: string
  begin_date: string
  begin_hour: string
  formattedDate: string
  formattedHour: string
}

export function useUserTicket<Data = any, Error = any> (page: number | string) {
  const partialUrl: string = '/user-ticket'

  const { data, error, mutate } = useSWR<Data, Error>(
    [partialUrl, page ?? 1],
    async () => {
      const url: string = `${partialUrl}?page=${page}`

      const response = await authClient.get(url)

      const newMapData = response.data.results.map((ticket: TicketType) => {
        if (ticket.event === null) {
          return {
            ...ticket,
            event: null,
            formattedDate: ticket?.created_at
              ? format(addDays(parseISO(ticket?.created_at), 1), 'dd/MM/yy', {
                  locale: ptBR
                })
              : null,
            isOpen: false
          }
        }

        return {
          ...ticket,
          formattedDate: ticket?.created_at
            ? format(parseISO(ticket?.created_at), 'dd/MM/yy', {
                locale: ptBR
              })
            : null,
          event: {
            ...ticket?.event,
            begin_date: ticket?.event?.begin_date
              ? format(parseISO(ticket?.event?.begin_date), "dd' de 'MMMM'", {
                  locale: ptBR
                })
              : '',
            begin_hour: ticket?.event?.begin_hour
              ? `${ticket?.event?.begin_hour.split(' ')[1].substring(0, 5)} h`
              : '',
            end_date: ticket?.event?.end_date
              ? format(parseISO(ticket?.event?.end_date), "dd' de 'MMMM'", {
                  locale: ptBR
                })
              : '',
            end_hour: ticket?.event?.end_hour
              ? `${ticket?.event?.end_hour.split(' ')[1].substring(0, 5)} h`
              : ''
          },
          isOpen: false
        }
      })

      const newResponse = { ...response.data, results: newMapData }

      return newResponse
    },
    {
      revalidateOnFocus: false
    }
  )

  return { data, error, mutate, partialUrl }
}

export function useUserTicketById<Data = any, Error = any> (id: string) {
  const partialUrl: string = '/user-ticket'

  const { data, error, mutate } = useSWR<Data, Error>(partialUrl, async () => {
    const url: string = `${partialUrl}/find/${id}`

    const response = await authClient.get(url)

    const newResponse = {
      ...response.data,
      event: {
        ...response.data?.event,
        formattedDate: format(
          parseISO(response?.data?.event?.begin_date),
          'dd/MM/yy',
          {
            locale: ptBR
          }
        ),
        formattedHour: format(
          parseISO(response?.data?.event?.begin_date),
          'HH:mm',
          {
            locale: ptBR
          }
        )
      },
      formattedDate: format(parseISO(response?.data?.created_at), 'dd/MM/yy', {
        locale: ptBR
      })
    }

    return newResponse
  })

  return { data, error, mutate, partialUrl }
}

interface RegisterProps {
  name: string
  email: string
  cell_phone: string
  password: string
}

export async function Register ({
  name,
  email,
  cell_phone,
  password
}: RegisterProps) {
  const url: string = '/register'

  const response = await api.post(url, {
    email,
    name,
    cell_phone,
    password
  })

  return response.data
}

interface UpdateUserProps {
  id: string | number | undefined
  name: string
  birthdate: string
  cell_phone: string
}

export async function UserUpdate ({
  name,
  birthdate,
  cell_phone
}: UpdateUserProps) {
  const url: string = '/user'

  const response = await authClient.patch(url, {
    name,
    birthdate: birthdate ? transformerDateToSend(birthdate) : '',
    cell_phone
  })

  return response.data
}

interface UpdatePasswordProps {
  password_old: string
  password_new: string
}

export async function UserUpdatePassword ({
  password_old,
  password_new
}: UpdatePasswordProps) {
  const url: string = '/users/update-password'

  const response = await authClient.put(url, {
    password_old,
    password_new
  })

  return response.data
}

export async function GetUserById () {
  const url = `/user`

  const response = await authClient.get<UserType>(url)

  return response
}

export async function GetOrganizers () {
  const url = `/organizer`

  const response = await authClient.get<{
    results: Organizer[]
    count: number
  }>(url)

  return response
}
interface RefundPurchaseProps {
  purchaseId: string
  credentials: any
}

export async function RefundPurchase ({
  purchaseId,
  credentials
}: RefundPurchaseProps) {
  const url: string = `/checkout/transaction/cancel/${purchaseId}`

  const response = await authPayment.post(url, credentials)

  return response.data
}
