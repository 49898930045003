import styled from "styled-components";
import { Colors } from "constants/colors";

export const Container = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContainerButtonsEdit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ContainerButtonsSave = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 800px) {
    gap: 30px;
  }

  @media (max-width: 640px) {
    flex-direction: column;
  }
`;

export const ButtonEdit = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  display: flex;
  width: 12.28rem;
  height: 2.81rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 0.65rem;
  background: ${Colors.primary10};

  @media (max-width: 1140px) {
    width: 100%;
    height: 38px;
    border-radius: 8px;
    margin-bottom: 20px;

    svg {
      display: none;
    }
  }

  span {
    color: ${Colors.primary100};
    font-family: Cairo;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 700;
    margin-left: 10px;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 22px;
    }
  }
`;

export const ButtonCancel = styled.button`
  cursor: pointer;
  border: 0;
  width: 12.28rem;
  height: 2.81rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.primary10};
  border-radius: 14px;

  span {
    font-weight: bold;
    font-size: 0.93rem;
    text-align: center;
    color: ${Colors.primary100};

    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 22px;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 38px;
    border-radius: 8px;

    svg {
      display: none;
    }
  }
`;

export const ButtonSave = styled.button`
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.28rem;
  height: 2.81rem;
  background: ${Colors.primary100};
  border-radius: 0.65rem;

  span {
    font-weight: bold;
    font-size: 0.93rem;
    color: ${Colors.white};
    margin-left: 10px;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 22px;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 38px;
    border-radius: 8px;

    svg {
      display: none;
    }
  }

  @media (max-width: 640px) {
    margin-bottom: 20px;
  }
`;

export const Divider = styled.div`
  margin-top: 1.87rem;
  padding-bottom: 1.87rem;
  height: 1px;
  border-top: 1px solid ${Colors.secondary10};
`;
