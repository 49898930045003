import { useFetchAuth, useFetchBasic } from "services/useFetch";

import {
  Event,
  EventOrganizer,
  EventTypeOrganizer,
  FeaturedEvent,
  Meta,
  TicketEvent,
} from "types";
import eoLocale from "date-fns/locale/pt-BR";
import { format, parseISO, addDays } from "date-fns";
import { ptBR } from "date-fns/locale";
import useSWR from "swr";
import { authClient } from "services/authClient";
import moment from "moment";
import { checkFormat } from "utils/checkFormat";

type GetEventNicknameAvailability = {
  is_avaliable: boolean;
};

export async function getEventNicknameAvailability(
  nickname: string
): Promise<boolean> {
  const { data } = await authClient.get<GetEventNicknameAvailability>(
    `/event/nickname?nickname=${nickname}`
  );

  return data.is_avaliable;
}

export function useEventWithDiscount<Data = any, Error = any>() {
  const url: string = "/no-auth/with-discount?page=1&limit=20";

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  return { data, error, mutate };
}

interface ResponseFeaturedEvents {
  data: FeaturedEvent[];
}

export function useFeaturedEvent<Data = any, Error = any>() {
  const url: string = "/no-auth/next-events?page=1&limit=10";

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  const dataResponse = data as unknown;

  const newData = dataResponse as ResponseFeaturedEvents;

  const newResponse = newData?.data.map((featured: FeaturedEvent) => {
    return {
      ...featured,
      formattedDate: format(
        parseISO(featured.begin_date),
        "eeeeee', 'dd' 'MMM'",
        {
          locale: eoLocale,
        }
      ),
      formattedDay: `${featured.begin_hour.split(":")[0]}:${
        featured.begin_hour.split(":")[1]
      }H`,
    };
  });

  return { data: newResponse, error, mutate };
}

export function useEventByNickName<Data = any, Error = any>(id: string) {
  const url: string = `/no-auth/event-by-nickname/${id}`;

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  return { data, error, mutate };
}

interface useEventSearchProps {
  page: string;
  paramsString?: string;
}

interface ResponseCategoryEventsFilters {
  data: FeaturedEvent[];
  meta: Meta;
}

export function useEventSearch<Data = any, Error = any>({
  page,
  paramsString,
}: useEventSearchProps) {
  const partialUrl = "/no-auth/search-event";

  const url: string = `${partialUrl}?${paramsString}`;

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    key: [`${partialUrl}?${paramsString}`, page],
    method: "get",
    url,
  });

  const dataResponse = data as unknown;

  const newData = dataResponse as ResponseCategoryEventsFilters;

  const newMapData = newData?.data.map((featured: FeaturedEvent) => {
    return {
      ...featured,
      formattedDate: format(
        parseISO(featured.begin_date),
        "eeeeee', 'dd' 'MMM'",
        {
          locale: eoLocale,
        }
      ),
      formattedDay: `${featured.begin_hour.split(":")[0]}:${
        featured.begin_hour.split(":")[1]
      }H`,
    };
  });

  const newResponse = { ...newData, data: newMapData };

  return { data: newResponse, error, mutate, partialUrl };
}

export function useEventSlider<Data = any, Error = any>() {
  const url: string = "/no-auth/last-insert?page=1&limit=12";

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  return { data, error, mutate };
}

export function useAuthMyEvent<Data = any, Error = any>(
  id: number | string | undefined
) {
  const url: string = `/event/single/${id}`;

  const { data, error, mutate } = useSWR<Data, Error>(
    url,
    async (url) => {
      const { data } = await authClient.get(url);

      const date = moment(data?.begin_date);
      const dateComponent = date.utc().format("DD/MM/YYYY");

      const endDate = moment(data?.end_date);
      const dateEndComponent = endDate.utc().format("DD/MM/YYYY");

      const formattedHour = checkFormat(data?.begin_hour ?? "");

      const newResponse = {
        ...data,
        formattedDate: dateComponent,
        formattedHour: formattedHour ?? "",
        formattedBeginDate: dateComponent,
        formattedEndDate: dateEndComponent,
        formattedBeginHour: data?.begin_hour
          ? data?.begin_hour.split(" ")[1]
          : "",
        formattedEndHour: data?.end_hour ? data?.end_hour.split(" ")[1] : "",
      };

      return newResponse;
    },
    {
      isPaused: () => {
        return !id;
      },
    }
  );

  return { data, error, mutate };
}

export function useMyEventDetail<Data = any, Error = any>(
  nickname: string | undefined
) {
  const url: string = `/event/details/${nickname}`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const { data } = await authClient.get(url);

    // const response = data.event;

    const responseData = data[0];

    const response = responseData.event;

    const date = moment(response?.begin_date);
    const dateComponent = date.utc().format("DD/MM/YYYY");

    const endDate = moment(response?.end_date);
    const dateEndComponent = endDate.utc().format("DD/MM/YYYY");

    const newMap = {
      ...response,
      formattedDate: response?.begin_date
        ? format(addDays(parseISO(response?.begin_date), 1), "dd' de 'MMMM'", {
            locale: ptBR,
          })
        : "",
      formattedHour: response?.begin_hour
        ? `${response?.begin_hour.split(":")[0]}:${
            response.begin_hour.split(":")[1]
          }h`
        : "",
      formattedBeginDate: dateComponent,
      formattedEndDate: dateEndComponent,
      // tickets: mapTickets,
    };

    return newMap;

    // const newResponse = {
    //   ...response.data,
    //   formattedBirthdate: response?.data.birthdate
    //     ? transformerDateToBr(response?.data.birthdate)
    //     : response?.data.birthdate,
    // };

    // return newResponse;
  });

  return { data, error, mutate };
}

// export function useMyEventDetail<Data = any, Error = any>(nickname: string) {
//   const url: string = `/event/details/${nickname}`;

//   const { data, error, mutate } = useFetchBasic<Data, Error>({
//     method: "get",
//     url,
//   });

//   const dataResponse = data as { event: unknown };

//   const dataHere = dataResponse.event as unknown;

//   const newData = dataHere as Event;

//   const date = moment(newData?.begin_date);
//   const dateComponent = date.utc().format("DD/MM/YYYY");

//   const endDate = moment(newData?.end_date);
//   const dateEndComponent = endDate.utc().format("DD/MM/YYYY");

//   const mapTickets = newData?.tickets?.map((ticket: TicketEvent) => ({
//     ...ticket,
//     isOpen: false,
//     formattedDate: format(addDays(parseISO(ticket.created_at), 1), "dd/MM/yy", {
//       locale: ptBR,
//     }),
//   }));

//   const newMap = {
//     ...newData,
//     formattedDate: newData?.begin_date
//       ? format(addDays(parseISO(newData?.begin_date), 1), "dd' de 'MMMM'", {
//           locale: ptBR,
//         })
//       : "",
//     formattedHour: newData?.begin_hour
//       ? `${newData?.begin_hour.split(":")[0]}:${
//           newData.begin_hour.split(":")[1]
//         }h`
//       : "",
//     formattedBeginDate: dateComponent,
//     formattedEndDate: dateEndComponent,
//     tickets: mapTickets,
//   };

//   return { data: newMap, error, mutate };
// }

export function useMyEvent<Data = any, Error = any>(
  page: string | number,
  name?: string
) {
  const partialUrl: string = "/event";

  const { data, error, mutate } = useSWR<Data, Error>(
    [partialUrl, page, name],
    async () => {
      const url: string = `${partialUrl}?limit=40&page=${page}&name=${name}`;

      const response = await authClient.get(url);

      const newMapData = response.data.results.map((event: EventOrganizer) => {
        return {
          ...event,
          isOpen: false,
          formattedDate: format(parseISO(event.begin_date), "dd'/'MM'/'y'", {
            locale: ptBR,
          }),
          // formattedHour: `${event.begin_hour.split(":")[0]}:${
          //   event.begin_hour.split(":")[1]
          // }h`,
          formattedHour: "",
        };
      });

      const newResponse = { ...response.data, results: newMapData };

      return newResponse;
    }
  );

  return { data, error, mutate, partialUrl };
}

export function useVerifyPurchase<Data = any, Error = any>(
  purchase_id: string
) {
  const url: string = `/events/verify-purchase/${purchase_id}`;

  const { data, error, mutate } = useFetchAuth<Data, Error>({
    method: "get",
    url,
  });

  return { data, error, mutate };
}

interface EditEventProps {
  id: string;
  dataEvent: Event;
}

export async function EditEventService({ id, dataEvent }: EditEventProps) {
  const url = `/event/${id}`;

  const {
    name,
    description,
    begin_date,
    end_date,
    begin_hour,
    end_hour,
    privacity,
    type_event,
    address_name,
    categories,
    address_zipcode,
    organizer,
    address_street,
    address_country,
    address_district,
    address_city,
    address_state,
    address_number,
    age,
    // nickname,
    streaming_id,
    streaming_name,
    streaming_url,
    support_email,
    support_whatsapp,
    nickname,
  } = dataEvent;

  const categoriesEdit = categories.map((category) => category._id);

  const credentials = {
    name,
    description,
    begin_date,
    end_date,
    begin_hour,
    end_hour,
    privacity: Number(privacity),
    type_event,
    address_name,
    address_zipcode,
    address_street,
    address_country,
    address_district,
    address_city,
    address_state,
    address_number,
    age,
    support_email,
    support_whatsapp,
    categories: categoriesEdit,
    nickname,
    ...((String(type_event) === "2" || String(type_event) === "3") && {
      streaming_id: String(streaming_id),
      streaming_name,
      streaming_url,
    }),
  };

  await authClient.patch(url, credentials);
}

interface AddEventProps {
  credentials: any;
}

export async function AddEvent({ credentials }: AddEventProps) {
  const url: string = "/event";

  const response = await authClient.post(url, credentials);

  return response.data;
}

interface EditEventPhotoProps {
  eventId: string;
  file: File;
}

export async function EditEventPhotoService({
  eventId,
  file,
}: EditEventPhotoProps) {
  const url = `/event/upload/${eventId}`;

  const formData = new FormData();
  formData.append("image", file);

  await authClient.post(url, formData, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });
}
