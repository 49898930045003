import { Helmet } from "react-helmet";

interface MetaTags {
  title?: string;
  description?: string;
  keywords?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  ogType?: string;
  ogUrl?: string;
  noIndex?: boolean;
}

function MetaTagsWrapper({ metaTags }: { metaTags?: MetaTags }) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      {metaTags?.title && <title>{metaTags.title}</title>}
      {metaTags?.description && (
        <meta
          name="description"
          content={metaTags.description}
          data-rh="true"
        />
      )}
      {metaTags?.keywords && (
        <meta name="keywords" content={metaTags.keywords} data-rh="true" />
      )}
      {metaTags?.ogTitle && (
        <meta property="og:title" content={metaTags.ogTitle} />
      )}
      {metaTags?.ogDescription && (
        <meta property="og:description" content={metaTags.ogDescription} />
      )}
      {metaTags?.ogImage && (
        <meta property="og:image" itemProp="image" content={metaTags.ogImage} />
      )}
      {metaTags?.ogType && (
        <meta property="og:type" content={metaTags.ogType} />
      )}
      {metaTags?.ogUrl && <meta property="og:url" content={metaTags.ogUrl} />}
      {metaTags?.noIndex && <meta name="robots" content="noindex,nofollow" />}
    </Helmet>
  );
}

export default MetaTagsWrapper;
