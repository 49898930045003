import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { IoCloseCircleSharp } from "react-icons/io5";

import { useFiltersContext } from "contexts/FiltersContext";

import { Colors } from "constants/colors";

import { KeySort } from "constants/filters";

import {
  BorderSelect,
  Container,
  DivIsSelected,
  SelectStyledWithoutDrop,
} from "./styles";
import moment from "moment";

interface SelectFilterModalProps {
  title: string;
  sortKey: KeySort;
}

const dataFilter = [
  { id: 1, name: "Hoje" },
  { id: 2, name: "Últimos 7 dias" },
  { id: 3, name: "Últimos 15 dias" },
  { id: 4, name: "Últimos 30 dias" },
  { id: 5, name: "Data personalizada" },
];

export function SelectDataFilter({ title, sortKey }: SelectFilterModalProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { setIsOpenFilterModalData } = useFiltersContext();

  const data_gte = searchParams.get("data_gte") ?? "";

  const data_lte = searchParams.get("data_lte") ?? "";

  const data_type = searchParams.get("data_type") ?? "";

  const clearId = useCallback(() => {
    searchParams.delete("data_gte");

    searchParams.delete("data_lte");

    searchParams.delete("data_type");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      data_type: "choose",
    });
  }, [searchParams, setSearchParams]);

  const handleOpenModal = () => {
    setIsOpenFilterModalData(true);
  };

  const dataFromUrl = dataFilter.find(
    (dataF) => dataF.id === Number(data_type)
  );

  const dataGte = data_gte !== "" ? moment(data_gte, "YYYY-MM-DD") : "";
  const formattedDataGte = dataGte !== "" ? dataGte.format("D-M-Y") : "";

  const dataLte = data_gte !== "" ? moment(data_lte, "YYYY-MM-DD") : "";
  const formattedDataLte = dataLte !== "" ? dataLte.format("D-M-Y") : "";

  if (data_type === "choose" && data_gte === "" && data_lte === "") {
    return (
      <Container>
        <BorderSelect>
          <SelectStyledWithoutDrop
            bordered={false}
            labelInValue
            size="large"
            defaultValue={{ value: 0, label: title }}
            open={false}
            onClick={handleOpenModal}
          />
        </BorderSelect>
      </Container>
    );
  }

  if (data_type !== "choose" && data_gte === "" && data_lte === "") {
    return (
      <Container>
        <DivIsSelected>
          <h3 className="selected">{dataFromUrl?.name}</h3>

          <IoCloseCircleSharp
            onClick={clearId}
            style={{ cursor: "pointer" }}
            size={18}
            color={Colors.white}
          />
        </DivIsSelected>
      </Container>
    );
  }

  if (data_type === "" && data_gte !== "" && data_lte !== "") {
    return (
      <Container>
        <DivIsSelected>
          <h3 className="selected">{`${formattedDataGte} até ${formattedDataLte}`}</h3>

          <IoCloseCircleSharp
            onClick={clearId}
            style={{ cursor: "pointer" }}
            size={18}
            color={Colors.white}
          />
        </DivIsSelected>
      </Container>
    );
  }

  return null;
}
