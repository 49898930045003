import { RiArrowRightSLine } from "react-icons/ri";
import { Container, Content, Icon, Item, Text } from "./styles";
import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";

interface Option {
  title: string;
  icon: ReactNode;
  path: string;
}

interface SidebarOptionsProps {
  options: Option[];
}

export function SidebarOptions({ options }: SidebarOptionsProps) {
  const location = useLocation();

  const { pathname } = location;

  return (
    <Container>
      {options.map((option) => (
        <Link to={option.path} key={option.path}>
          <Item active={pathname === option.path}>
            <Content>
              <Icon active={pathname === option.path}>{option.icon}</Icon>

              <Text active={pathname === option.path}>{option.title}</Text>
            </Content>

            <RiArrowRightSLine className="icon" size={25} />
          </Item>
        </Link>
      ))}
    </Container>
  );
}
