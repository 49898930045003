import { useCallback, useEffect } from 'react'
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'

import { Spin } from 'antd'

import { LoadingOutlined } from '@ant-design/icons'
import { GoChevronRight } from 'react-icons/go'
import { BiChevronRight } from 'react-icons/bi'
import { RiQuestionLine } from 'react-icons/ri'

import { useTabsTicketsAndPurchases } from 'contexts/TabsTicketsAndPurchasesContext'

import { useDisclosure } from 'hooks/useDisclosure'

import { formatPrice } from 'utils/formatPrice'
import { returnTypePaymentPurchase } from 'utils/purchase'
import { returnStatusPurchase, purchaseSubtitleFactor } from 'utils/purchase'
import { buildMicroRoute } from 'utils/urlBuilder'

import { Purchase } from 'types'

import { Colors } from 'constants/colors'

import {
  BadgeMobile,
  BorderSmallPrice,
  ButtonCancelPurchaseMobile,
  ButtonClose,
  ButtonConfirm,
  CardShopping,
  Center,
  ContainerCard,
  ContainerDetailsShoping,
  ContainerModal,
  ContainerModalConfirm,
  ContainerModalSubtotal,
  ContainerModalTypePayment,
  ContainerPurchaseDetails,
  ContainerShoppingsMobile,
  ContainertModalTotal,
  ModalStyled,
  PurchaseDetails
} from './styles'

import cancel_confirm from 'assets/cancel-confirm.svg'
import headphone_icon from 'assets/headphone-icon.svg'
import headphone_gray from 'assets/headphone-gray.svg'
import { useSearchPurchase } from 'hooks/useSearchPurchase'
import { ModalPayment } from 'components/ModalPayment'
import { divideBy100 } from 'utils/divideBy100'
import LoadMoreItems from 'components/LoadMoreItems'

export function PurchaseTabPane () {
  const { handleSelectedPurchaseMobile, selectedPurchaseMobile } =
    useTabsTicketsAndPurchases()

  const { pathname } = useLocation()

  const [searchParams, setSearchParams] = useSearchParams()

  const navigate = useNavigate()

  const checkoutId = searchParams.get('checkout_id') ?? ''

  const { handleCancel, handleOk, isModalVisible, showModal } = useDisclosure()

  const {
    showModal: showModalPayment,
    handleOk: handleOkPayment,
    handleCancel: handleCancelPayment,
    isModalVisible: isModalPaymentVisible
  } = useDisclosure()

  const {
    data,
    hasMoreItems,
    handleLoadMorePurchasingsMobile,
    loadingMoreItems
  } = useSearchPurchase()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  )

  const handleOpenCheckout = useCallback(
    checkoutId => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        checkout_id: String(checkoutId)
      })
    },
    [searchParams, setSearchParams]
  )

  const handleCloseModalPayment = () => {
    searchParams.delete('checkout_id')

    setSearchParams({
      ...Object.fromEntries([...searchParams])
    })

    handleCancelPayment()
  }

  useEffect(() => {
    if (checkoutId) {
      showModalPayment()

      return
    }

    handleOkPayment()
  }, [checkoutId, handleOkPayment, showModalPayment])

  return (
    <>
      {!data ? (
        <Center>
          <Spin spinning indicator={antIcon} />
        </Center>
      ) : null}

      {!selectedPurchaseMobile ? (
        <ContainerShoppingsMobile>
          <h2 className='history'>HISTÓRICO</h2>

          {data?.results.map((purchase: Purchase) => {
            return (
              <ContainerCard key={purchase._id}>
                <h3 className='date'>{purchase.formattedDate}</h3>
                <CardShopping>
                  <div
                    className='content'
                    onClick={() => handleSelectedPurchaseMobile(purchase)}
                  >
                    <div className='container-texts'>
                      <div className='container-image'>
                        {purchase?.event_id !== null ? (
                          <img
                            className='ticket'
                            src={
                              purchase?.event_id?.thumb ??
                              '/assets/banner-2.png'
                            }
                            alt='Logo'
                            width='33px'
                            height='33px'
                          />
                        ) : null}
                      </div>

                      <div className='texts'>
                        {purchase?.event_id !== null ? (
                          <h2>{purchase?.event_id?.name}</h2>
                        ) : null}
                        <p>{purchaseSubtitleFactor(purchase)}</p>
                      </div>
                    </div>

                    <GoChevronRight size={22} color={Colors.secondary50} />
                  </div>

                  {/* {purchase.purchases.map((purchaseItem, index) => (
                    <div className="ticket-quantity" key={index}>
                      <div className="badge">
                        <span>{purchaseItem.quantity}</span>
                      </div>

                      <h2>{purchaseItem.name}</h2>
                    </div>
                  ))} */}

                  <div className='buttons'>
                    <Link className='item-buttom' to={`/faq`}>
                      <button>Ajuda</button>
                    </Link>

                    {purchase?.payment_status === 'PENDING' ? (
                      <button onClick={() => handleOpenCheckout(purchase?._id)}>
                        Link do Checkout
                      </button>
                    ) : null}
                  </div>
                </CardShopping>
              </ContainerCard>
            )
          })}

          <LoadMoreItems
            hasMoreItems={hasMoreItems}
            handleLoadMore={handleLoadMorePurchasingsMobile}
            fetching={loadingMoreItems}
            length={data?.results?.length}
            total={data?.count}
          />
        </ContainerShoppingsMobile>
      ) : null}

      {selectedPurchaseMobile ? (
        <ContainerModal>
          <div className='title'>
            <h2 className='title'>Detalhes da compra</h2>
          </div>
          <div className='container-web'>
            <ContainerDetailsShoping>
              <div className='header-details'>
                <div className='content'>
                  {/* {selectedPurchaseMobile?.event &&
                  selectedPurchaseMobile?.event?.photo ? (
                    <img
                      className="ticket"
                      src={selectedPurchaseMobile?.event.photo}
                      alt="Logo"
                      width="128px"
                      height="128px"
                    />
                  ) : null} */}

                  {/* <div className="column">
                    <h2>{selectedPurchaseMobile?.event.name}</h2>

                    <span>
                      Realizada ás {selectedPurchaseMobile?.formattedHour} .{" "}
                      {selectedPurchaseMobile?.formattedDay}
                    </span>

                    <Link
                      style={{ textDecoration: "none" }}
                      to={buildMicroRoute(
                        `/${selectedPurchaseMobile?.event?.nickname}`
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Ver evento
                    </Link>
                  </div> */}
                </div>

                <div className='badge-content'>
                  <h3>ID da compra: {selectedPurchaseMobile?._id}</h3>{' '}
                </div>
              </div>

              {/* {selectedPurchaseMobile?.purchases?.length
                ? selectedPurchaseMobile?.purchases.map((purchase) => (
                    <ContainerBorderNormalShipping key={purchase.id}>
                      <div className="content-border">
                        <h2 className="title-border">{purchase.name}</h2>

                        <div className="row">
                          <strong>R$ {purchase.total}</strong>
                        </div>
                      </div>

                      <div className="badge-quantity">
                        <h4>{purchase.quantity}x</h4>
                      </div>
                    </ContainerBorderNormalShipping>
                  ))
                : null} */}

              <ContainerModalTypePayment>
                <h3 className='type'>Pagamento</h3>

                <div className='t-payment'>
                  <span className='price'>
                    {returnTypePaymentPurchase(
                      selectedPurchaseMobile?.payment_type ?? ''
                    )}
                  </span>
                </div>
              </ContainerModalTypePayment>

              <ContainerModalSubtotal>
                <div className='row-sub'>
                  <h3>Subtotal</h3>

                  <span>
                    {selectedPurchaseMobile?.total_wet !== null
                      ? selectedPurchaseMobile?.total_wet === 0
                        ? 'R$ 0,00'
                        : formatPrice(
                            divideBy100(selectedPurchaseMobile?.total_wet ?? 0)
                          )
                      : '-'}
                  </span>
                </div>

                <div className='row-sub'>
                  <h3>Taxa</h3>

                  <span>
                    {selectedPurchaseMobile?.total_fee !== null
                      ? selectedPurchaseMobile?.total_fee === 0
                        ? 'R$ 0,00'
                        : formatPrice(
                            divideBy100(selectedPurchaseMobile?.total_fee ?? 0)
                          )
                      : '-'}
                  </span>
                </div>
              </ContainerModalSubtotal>

              <ContainertModalTotal>
                <h3>Valor total:</h3>

                <span>
                  {selectedPurchaseMobile?.total !== null
                    ? selectedPurchaseMobile?.total === 0
                      ? 'R$ 0,00'
                      : formatPrice(
                          divideBy100(selectedPurchaseMobile?.total ?? 0)
                        )
                    : '-'}
                </span>
              </ContainertModalTotal>
            </ContainerDetailsShoping>

            {selectedPurchaseMobile?.payment_status === 'PAID' &&
            selectedPurchaseMobile?.payment_type !== 'courtesy' ? (
              <div className='cancel-purchasing'>
                <button onClick={showModal}>
                  <span>Cancelar compra</span>
                </button>
              </div>
            ) : null}

            <footer className='help-you'>
              <h1>PRECISA DE AJUDA?</h1>

              <Link to='/faq'>
                <div className='drop'>
                  <div className='first'>
                    <div className='badge'>
                      <img
                        src={headphone_icon}
                        width={25}
                        height={26}
                        alt='headphone-icon'
                      />
                    </div>
                    <h2>Suporte</h2>
                  </div>

                  <BiChevronRight color={Colors.primary100} size={34} />
                </div>
              </Link>
            </footer>
          </div>

          <div className='container-mobile'>
            <ContainerPurchaseDetails>
              <div className='header'>
                <h1>Detalhes da compra</h1>
                <a
                  target={'_blank'}
                  href={buildMicroRoute('/faq')}
                  rel='noreferrer'
                >
                  <RiQuestionLine size={24} color={Colors.gray30} />
                </a>
              </div>

              <PurchaseDetails>
                <div className='shopping-event'>
                  <div className='container'>
                    {/* <div className="container-image">
                      {selectedPurchaseMobile?.event &&
                      selectedPurchaseMobile?.event?.photo ? (
                        <img
                          className="ticket"
                          src={selectedPurchaseMobile?.event.photo}
                          alt="Logo"
                          width="44px"
                          height="44px"
                        />
                      ) : null}
                    </div> */}

                    <div className='content'>
                      {/* <h3>{selectedPurchaseMobile?.event.name}</h3> */}

                      <span>
                        Realizada ás {selectedPurchaseMobile?.formattedHour} .{' '}
                        {selectedPurchaseMobile?.formattedDay}
                      </span>
                    </div>
                  </div>

                  {/* <div className="view-event">
                    <a
                      style={{ textDecoration: "none" }}
                      href={buildMicroRoute(
                        `/${selectedPurchaseMobile?.event?.nickname}`
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>Ver evento</button>
                    </a>
                  </div> */}
                </div>

                <BadgeMobile>
                  <h4>CÓDIGO da compra: {selectedPurchaseMobile?._id}</h4>
                </BadgeMobile>

                <div className='borders'>
                  {/* {selectedPurchaseMobile?.purchases.length
                    ? selectedPurchaseMobile?.purchases.map((purchase) => (
                        <BorderNormalPrice key={purchase.id}>
                          <div className="column">
                            <h3>{purchase.name}</h3>

                            <strong>{formatPrice(purchase.total * 100)}</strong>
                          </div>

                          <div className="quantity">
                            <h4>x{purchase.quantity}</h4>
                          </div>
                        </BorderNormalPrice>
                      ))
                    : null} */}

                  <BorderSmallPrice>
                    <h2 className='title-card'>Pagamento</h2>

                    <div className='card'>
                      <div className='t-payment'>
                        <span className='price'>
                          {returnTypePaymentPurchase(
                            selectedPurchaseMobile?.payment_type ?? ''
                          )}
                        </span>
                      </div>
                    </div>
                  </BorderSmallPrice>

                  <BorderSmallPrice>
                    <h2 className='title-card'>STATUS</h2>

                    <div className='card'>
                      <div className='t-payment'>
                        <span className='price'>
                          {returnStatusPurchase(
                            selectedPurchaseMobile?.payment_status || ''
                          )}
                        </span>
                      </div>
                    </div>
                  </BorderSmallPrice>
                </div>

                <div className='prices'>
                  <div className='row-price'>
                    <h3>Subtotal</h3>

                    <span>
                      {selectedPurchaseMobile?.total_wet
                        ? formatPrice(
                            divideBy100(selectedPurchaseMobile.total_wet)
                          )
                        : '-'}
                    </span>
                  </div>
                  <div className='row-price'>
                    <h3>Tax</h3>
                    <span>
                      {selectedPurchaseMobile?.total_fee
                        ? formatPrice(
                            divideBy100(selectedPurchaseMobile.total_fee)
                          )
                        : '-'}
                    </span>
                  </div>
                  {/* <div className="row-price">
                  <h3>Descontos</h3>

                  <span>- R$9,00</span>
                </div> */}
                </div>

                <div className='total-price'>
                  <h5>Valor total</h5>
                  <h3>
                    {selectedPurchaseMobile?.total !== null
                      ? selectedPurchaseMobile?.total === 0
                        ? 'R$ 0,00'
                        : formatPrice(
                            divideBy100(selectedPurchaseMobile?.total ?? 0)
                          )
                      : '-'}
                  </h3>
                </div>

                {selectedPurchaseMobile?.payment_status === 'PAID' &&
                selectedPurchaseMobile.payment_type !== 'courtesy' ? (
                  <ButtonCancelPurchaseMobile
                    onClick={() => {
                      handleOk()
                      showModal()
                    }}
                  >
                    <span>Cancelar compra</span>
                  </ButtonCancelPurchaseMobile>
                ) : null}
              </PurchaseDetails>
            </ContainerPurchaseDetails>

            <footer className='help-you'>
              <h1>PRECISA DE AJUDA?</h1>

              <Link to='/faq'>
                <div className='drop'>
                  <div className='first'>
                    <div className='badge'>
                      {/* <Image
                    className="ticket"
                    src="/assets/headphone-gray.svg"
                    alt="badge"
                    width={15}
                    height={15}
                  /> */}

                      <img
                        src={headphone_gray}
                        alt='headphone'
                        width={15}
                        height={15}
                      />
                    </div>
                    <h2>Suporte</h2>
                  </div>

                  <BiChevronRight color={Colors.secondary40} size={18} />
                </div>
              </Link>
            </footer>
          </div>
        </ContainerModal>
      ) : null}

      <ModalStyled
        width={642}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        // closeIcon
      >
        <ContainerModalConfirm>
          <img
            src={cancel_confirm}
            width={189}
            height={189}
            alt='confirm-cancel'
          />

          <div className='texts-confirm'>
            <h3>Deseja cancelar a compra?</h3>

            <p>
              Talvez falar com o vendendor possa resolver seu problema, tente
              fazer isso antes de cancelar a compra de vez!
            </p>
          </div>

          <div className='buttons'>
            <ButtonClose onClick={handleCancel}>
              <span>Voltar</span>
            </ButtonClose>

            <ButtonConfirm
              onClick={() => {
                handleOk()
                navigate(`/profile/terms-cancel/${selectedPurchaseMobile?._id}`)
              }}
            >
              <span>Sim</span>
            </ButtonConfirm>
          </div>
        </ContainerModalConfirm>
      </ModalStyled>

      <ModalPayment
        checkoutId={checkoutId}
        isModalVisible={isModalPaymentVisible}
        handleOk={handleCloseModalPayment}
        handleCancel={handleCloseModalPayment}
      />
    </>
  )
}
