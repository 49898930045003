import { Container } from "./styles";

import survey_search from "assets/survey-search.svg";

export function EmptySearchComponent() {
  return (
    <Container>
      <h3 className="title">Nenhum resultado encontrado :(</h3>

      <p>
        Não encontramos itens relacionados à sua pesquisa. Tente buscar
        novamente por outras palavras-chave.
      </p>

      <img src={survey_search} alt="survey_search" />
    </Container>
  );
}
