import {
  RiCloseCircleLine,
  RiCommunityLine,
  RiFacebookFill,
  RiInformationLine,
  RiInstagramFill,
  RiLink,
  RiMapPinAddLine,
  RiMicrosoftFill,
  RiPencilFill,
  RiTwitterFill,
  RiVideoAddLine,
  RiVideoChatFill,
  RiVimeoFill,
  RiWhatsappFill,
  RiYoutubeFill,
  RiTwitterLine,
} from "react-icons/ri";

import { BiCameraMovie } from "react-icons/bi";
import { SiFoodpanda } from "react-icons/si";

import { Colors } from "./colors";

export const platforms = [
  {
    id: 1,
    name: "Pandastreaming",
    icon: <SiFoodpanda color={Colors.secondary100} width={13} height={13} />,
  },
  {
    id: 2,
    name: "Youtube",
    icon: <RiYoutubeFill color={Colors.secondary100} width={13} height={13} />,
  },
  {
    id: 3,
    name: "Facebook",
    icon: <RiFacebookFill color={Colors.secondary100} width={13} height={13} />,
  },
  {
    id: 4,
    name: "Twitter",
    icon: <RiTwitterLine color={Colors.secondary100} width={13} height={13} />,
  },
  {
    id: 5,
    name: "Instagram",
    icon: (
      <RiInstagramFill color={Colors.secondary100} width={13} height={13} />
    ),
  },
  {
    id: 6,
    name: "Vimeo",
    icon: <RiVimeoFill color={Colors.secondary100} width={13} height={13} />,
  },
  {
    id: 7,
    name: "Microsoft Teams",
    icon: (
      <RiMicrosoftFill color={Colors.secondary100} width={13} height={13} />
    ),
  },
  {
    id: 8,
    name: "Zoom",
    icon: <BiCameraMovie color={Colors.secondary100} width={13} height={13} />,
  },
  {
    id: 9,
    name: "Whatsapp",
    icon: <RiWhatsappFill color={Colors.secondary100} width={13} height={13} />,
  },
  {
    id: 10,
    name: "Outra",
    icon: <RiPencilFill color={Colors.secondary100} width={13} height={13} />,
  },
];
