import styled from "styled-components";
import { Select } from "antd";
import { Colors } from "constants/colors";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 50px 136px 50px 136px;
  border: 1px solid ${Colors.black10};
  border-radius: 0.93rem;
  margin-top: 2.34rem;

  @media (max-width: 1024px) {
    padding: 0px 0px;
  }
`;

export const Title = styled.h2`
  color: ${Colors.secondary100};
  font-size: 1.21rem;
  font-family: Poppins;
  font-weight: 600;
  line-height: 160%;

  @media (max-width: 670px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 700;
    line-height: 25px;
  }
`;

export const SubTitle = styled.h3`
  color: ${Colors.black70};
  font-size: 1rem;
  font-family: Open Sans;
  line-height: 160%;
  margin-top: 0.93rem;

  @media (max-width: 670px) {
    font-size: 12px;
    font-family: Open Sans;
    margin-top: 8px;
  }
`;

export const LabelTitle = styled.h4`
  color: ${Colors.secondary100};
  font-size: 1.03rem;
  font-family: Poppins;
  font-weight: 500;
  line-height: 160%;

  @media (max-width: 670px) {
    color: ${Colors.black100};
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
  }
`;

export const SubLabelTitle = styled.h4`
  color: ${Colors.black60};
  font-size: 0.75rem;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 160%;

  strong {
    font-size: 0.75rem;
    font-family: Open Sans;
    font-style: italic;
    color: ${Colors.black70};

    @media (max-width: 670px) {
      font-size: 14px;
      font-family: Cairo;
      font-weight: 600;
    }
  }

  @media (max-width: 670px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
  }
`;

export const Divider = styled.div`
  margin-top: 1.87rem;
  padding-bottom: 1.87rem;
  height: 1px;
  border-top: 1px solid ${Colors.secondary10};
`;

export const ContainerImage = styled.div`
  border-radius: 0.65rem;
  background: ${Colors.purple5};
  width: 24.98rem;
  height: 9.98rem;
  flex-shrink: 0;
  margin-top: 0.93rem;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 4.12rem;
    height: 4.12rem;

    @media (max-width: 670px) {
      width: 100%;
    }
  }

  @media (max-width: 670px) {
    width: 100%;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.68rem;
`;

export const ButtonPrev = styled.button`
  border: 0;
  width: 18.23rem;
  height: 3.84rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 14px;

  span {
    color: ${Colors.black10};
    text-align: center;
    font-size: 1.21rem;
    font-family: Cairo;
    font-weight: 700;
    line-height: 160%;
  }
`;

export const ButtonNext = styled.button`
  border: 0;
  display: flex;
  width: 18.23rem;
  height: 3.84rem;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.primary100};
  flex-shrink: 0;
  border-radius: 14px;

  span {
    color: ${Colors.white};
    text-align: center;
    font-size: 1.21rem;
    font-family: Cairo;
    font-weight: 700;
    line-height: 160%;
    margin-left: 0.46rem;
  }
`;

export const ContainerEventLocal = styled.div`
  width: 70%;
  height: 3.37rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0px;
  background: ${Colors.white};
  margin-top: 1.21rem;

  @media (max-width: 720px) {
    border-radius: 0px;
    border: 0;
    padding: 0;
    padding-left: 10px;
    margin-top: 16px;
    padding-bottom: 30px;
    border-bottom: 1px solid ${Colors.black10};

    .radio {
      margin-bottom: 16px;
    }

    .group {
      flex-direction: column;
      align-items: space-between;
      justify-content: flex-start;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: red;
    border-color: transparent;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: ${Colors.primary100};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${Colors.primary100} !important;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${Colors.primary100};
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${Colors.primary100};
  }

  .ant-radio-wrapper {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 0.93rem;
    color: ${Colors.secondary70};

    @media (max-width: 720px) {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;

      color: ${Colors.secondary70};
    }
  }
`;

export const GridInputs = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  @media (max-width: 670px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
`;

export const ContainerSwitch = styled.div`
  display: flex;
  flex-direction: row;

  .ant-switch {
    margin-right: 0.8rem;
  }

  .ant-switch-checked {
    background-color: ${Colors.primary100};
  }

  @media (max-width: 670px) {
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

interface CardPlataformProps {
  active: boolean;
}

export const CardPlataform = styled.div<CardPlataformProps>`
  cursor: pointer;
  width: 100%;
  margin-top: 1.68rem;
  min-height: 8.29rem;
  border-radius: 0.93rem;
  border: ${(props) =>
    props.active
      ? `2px solid ${Colors.primary100}`
      : `1px solid ${Colors.black10}`};
  background: ${Colors.white};
  padding: 0.93rem 0.89rem;

  display: flex;

  div.content {
    width: 100%;
    margin-left: 0.93rem;

    div.header {
      width: 100%;
      border-bottom: 1px solid #e9e7ee;

      h4 {
        color: ${(props) =>
          props.active ? Colors.primary100 : Colors.secondary40};
        font-size: 0.93rem;
        font-family: Poppins;
        font-weight: ${(props) => (props.active ? 600 : 400)};
        margin-bottom: 0px;
      }

      h5 {
        color: ${(props) =>
          props.active ? Colors.primary100 : Colors.secondary40};
        font-size: 0.75rem;
        font-family: Poppins;
      }
    }

    div.footer {
      margin-top: 0.65rem;

      h2 {
        color: ${(props) =>
          props.active ? Colors.primary100 : Colors.secondary40};
        font-size: 0.56rem;
        font-family: Poppins;
        font-weight: 700;
      }

      h3 {
        color: ${(props) =>
          props.active ? Colors.primary100 : Colors.secondary40};
        font-size: 0.75rem;
        font-family: Poppins;
      }
    }
  }
`;

export const ContainerSelect = styled.div`
  margin-bottom: 1.87rem;
  width: 100%;
`;

export const BorderSelect = styled.div`
  border: 2px solid ${Colors.secondary20};
  width: 100%;
  height: 2.81rem;
  border-radius: 0.37rem;
  margin-bottom: 0.45rem;
  margin-right: 0.51rem;
  display: flex;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 0.45rem;

    height: 32px;
    border-radius: 6px;
  }
`;

export const LabelSelect = styled.div`
  display: flex;

  h4 {
    color: ${Colors.secondary40};
    font-size: 0.93rem;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
  }
`;

export const StyledSelect = styled(Select)``;
