import { useSearchParams } from "react-router-dom";
import { useDisclosure } from "./useDisclosure";
import { ReportPurchase } from "services/Hook/Financial";

export function useGenerateFinancialReport() {
  const [searchParams] = useSearchParams();

  const { handleOk, handleCancel, showModal, isModalVisible } = useDisclosure();

  const organizers = searchParams.get("organizer") ?? "";
  // const date_type = searchParams.get("date_type") ?? "";
  // can be date_lte and date_gte
  const events = searchParams.get("events") ?? "";
  const tickets = searchParams.get("tickets") ?? "";
  const data_gte = searchParams.get("data_gte") ?? "";
  const data_lte = searchParams.get("data_lte") ?? "";
  const data_type = searchParams.get("data_type") ?? "";

  async function handleGenerateReport() {
    try {
      await ReportPurchase({
        paramsObject: {
          type_report: 1,
          ...(organizers && { organizers: [organizers] }),
          ...(events && { events: events.split("%") }),
          ...(tickets && { event_tickets: tickets.split("%") }),
          ...(data_gte && { created_gte: data_gte }),
          ...(data_lte && { created_lte: data_lte }),
          ...(data_type && { data_type }),
        },
      });

      showModal();
    } catch (err) {}
  }

  return {
    handleGenerateReport,
    handleOk,
    handleCancel,
    showModal,
    isModalVisible,
  };
}
