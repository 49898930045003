import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { IoCloseCircleSharp } from "react-icons/io5";

import { Colors } from "constants/colors";

import {
  BorderSelect,
  Container,
  DivIsSelected,
  SelectStyledWithoutDrop,
} from "./styles";
import { KeySort } from "constants/filters";
import { useFiltersContext } from "contexts/FiltersContext";
import { dataStatus } from "components/ModalFilterByStatus";

interface SelectFilterModalProps {
  title: string;
  sortKey: KeySort;
}

export function SelectPaymentStatusFilter({ title }: SelectFilterModalProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { setIsOpenFilterModalStatus } = useFiltersContext();

  const paymentStatus = searchParams.get("payment_status") ?? "";

  const clearId = useCallback(() => {
    searchParams.delete("payment_status");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [searchParams, setSearchParams]);

  const handleOpenModal = () => {
    setIsOpenFilterModalStatus(true);
  };

  const findPaymentStatus = dataStatus.find(
    (item) => item.key === paymentStatus
  );

  return (
    <Container>
      {paymentStatus === "" ? (
        <BorderSelect>
          <SelectStyledWithoutDrop
            bordered={false}
            labelInValue
            size="large"
            defaultValue={{ value: 0, label: title }}
            open={false}
            onClick={handleOpenModal}
          />
        </BorderSelect>
      ) : (
        <DivIsSelected>
          <h3 className="selected">{findPaymentStatus?.name}</h3>

          <IoCloseCircleSharp
            onClick={clearId}
            style={{ cursor: "pointer" }}
            size={18}
            color={Colors.white}
          />
        </DivIsSelected>
      )}
    </Container>
  );
}
