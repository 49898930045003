import * as yup from "yup";

export const createLocalEventFormSchema = yup.object().shape({
  type_event: yup.number().required("Tipo do evento obrigatório"),
  streaming_id: yup
    .number()
    .notRequired()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .when("type_event", {
      is: (type_event: any) => type_event === 2 || type_event === 3,
      then: yup.number().required("Plataforma obrigatória"),
    }),
  streaming_url: yup
    .string()
    .notRequired()
    .nullable()
    .when("type_event", {
      is: (type_event: any) => type_event === 2 || type_event === 3,
      then: yup.string().required("Link obrigatório"),
    }),
  streaming_name: yup
    .string()
    .notRequired()
    .nullable()
    .when("type_event", {
      is: (type_event: any) => type_event === 2 || type_event === 3,
      then: yup.string().required("Nome da plataforma obrigatório"),
    }),
  address_name: yup
    .string()
    .notRequired()
    .when("type_event", {
      is: (type_event: any) => type_event === 1 || type_event === 3,
      then: yup.string().required("Nome obrigatória"),
    }),
  address_zipcode: yup
    .string()
    .notRequired()
    .when("type_event", {
      is: (type_event: any) => type_event === 1 || type_event === 3,
      then: yup.string().required("Cep obrigatório"),
    }),
  address_street: yup
    .string()
    .notRequired()
    .when("type_event", {
      is: (type_event: any) => type_event === 1 || type_event === 3,
      then: yup.string().required("Rua obrigatória"),
    }),
  address_complement: yup
    .string()
    .notRequired()
    .when("type_event", {
      is: (type_event: any) => type_event === 1 || type_event === 3,
      then: yup.string().required("Complemento obrigatório"),
    }),
  address_country: yup.string().notRequired(),
  address_district: yup
    .string()
    .notRequired()
    .when("type_event", {
      is: (type_event: any) => type_event === 1 || type_event === 3,
      then: yup.string().required("Bairro obrigatório"),
    }),
  address_city: yup
    .string()
    .notRequired()
    .when("type_event", {
      is: (type_event: any) => type_event === 1 || type_event === 3,
      then: yup.string().required("Cidade obrigatória"),
    }),
  address_state: yup
    .string()
    .notRequired()
    .when("type_event", {
      is: (type_event: any) => type_event === 1 || type_event === 3,
      then: yup.string().required("Estado obrigatório"),
    }),
  address_number: yup
    .string()
    .notRequired()
    .when("type_event", {
      is: (type_event: any) => type_event === 1 || type_event === 3,
      then: yup.string().required("Número obrigatória"),
    }),
});
