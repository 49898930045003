import styled from "styled-components";

import { Colors } from "constants/colors";
import { Modal, Slider } from "antd";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 44px 109px 64px 109px;

  @media (max-width: 800px) {
    padding: 24px 10px 24px 10px;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.87rem;

    color: ${Colors.secondary100};
    font-family: "Poppins";
    margin-bottom: 0px;
    @media (max-width: 800px) {
      font-size: 20px;
    }
  }

  h2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.03rem;

    margin-top: 0.93rem;

    color: ${Colors.black70};
  }
`;

export const ContainerCustomData = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 2.48rem;
`;

export const GridInputs = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  margin-top: 1.96rem;

  @media (max-width: 670px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
  isFull?: boolean;
}

export const ContainerInputFull = styled.div<ContainerInputProps>`
  width: ${(props) => (props.isFull ? "100%" : "49%")};
  display: flex;
  flex-direction: column;

  margin-bottom: 25px;

  input {
    width: 100%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: ${true ? "14px" : "20px"};

    padding: 0px 0px 4px 23px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-left: 13px;
    margin-top: 10px;
    color: ${Colors.secondary40};
    font-weight: normal;

    font-size: 1.21rem;
  }
`;

export const ContainerButtonsModal = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.75rem;
`;

export const ButtonClear = styled.button`
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  width: 10.4rem;
  height: 2.81rem;
  padding: 0.65rem 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background-color: ${Colors.primary10};

  span {
    color: ${Colors.primary100};
    text-align: center;
    font-family: Cairo;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 700;
  }
`;

export const ButtonOk = styled.button`
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  width: 10.4rem;
  height: 2.81rem;
  padding: 0.65rem 2.84rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px 0px rgba(212, 0, 80, 0.15);

  span {
    color: ${Colors.white};
    text-align: center;
    font-family: Cairo;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
  }
`;

export const SliderStyled = styled(Slider)`
  color: ${Colors.primary100};

  .ant-slider-track {
    position: absolute;
    height: 4px;
    background-color: ${Colors.primary100};
    border-radius: 2px;
    transition: background-color 0.3s;
  }

  .ant-slider-track:hover {
    position: absolute;
    height: 4px;
    background-color: ${Colors.primary100};
    border-radius: 2px;
    transition: background-color 0.3s;
  }

  .ant-slider-handle {
    position: absolute;
    width: 1.12rem;
    height: 1.12rem;
    margin-top: -7px;
    background-color: ${Colors.primary100};
    border: solid 2px ${Colors.primary100};
    border-radius: 50%;
    box-shadow: 0;
    cursor: pointer;
    transition: border-color 0.3s, box-shadow 0.6s,
      transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    box-shadow: -2px 1px 4px rgba(0, 0, 0, 0.35);
  }

  .ant-slider:hover .ant-slider-rail {
    background-color: ${Colors.primary100};
  }
  .ant-slider:hover .ant-slider-track {
    background-color: ${Colors.primary100};
  }
  .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: ${Colors.primary100};
  }

  .ant-slider-handle:focus {
    border-color: ${Colors.primary100};
    outline: none;
    box-shadow: -2px 1px 4px rgba(0, 0, 0, 0.35);
  }
`;
