import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.34rem 6.37rem 2.34rem 6.37rem;
  border: 1px solid ${Colors.black10};
  border-radius: 0.93rem;
  margin-top: 2.34rem;

  @media (max-width: 1024px) {
    padding: 0px 0px;
    border: 0;
  }

  div.container-card {
    @media (max-width: 800px) {
      margin-top: 10px;
    }

    div.border {
      margin-top: 3.21rem;

      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      div.line {
        padding-top: 2.81rem;
        border-top: 1px solid #e9e7ee;
        width: 80%;

        @media (max-width: 600px) {
          width: 100%;
        }
      }

      button {
        cursor: pointer;
        border: 0;
        outline: 0;
        max-width: 22.17rem;
        width: 100%;
        height: 2.81rem;

        background: ${Colors.primary20};
        border-radius: 0.65rem;

        display: flex;
        align-items: center;
        justify-content: center;

        span {
          font-family: "Cairo";
          font-style: normal;
          font-weight: 700;
          font-size: 0.93rem;
          margin-left: 0.45rem;
          margin-bottom: 0px;

          color: ${Colors.primary100};
        }
      }
    }
  }
`;

export const Title = styled.h2`
  color: ${Colors.secondary100};
  font-size: 1.21rem;
  font-family: Poppins;
  font-weight: 600;
  line-height: 160%;

  @media (max-width: 670px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 700;
    line-height: 25px;
  }
`;

export const SubTitle = styled.h3`
  color: ${Colors.black70};
  font-size: 1rem;
  font-family: Open Sans;
  line-height: 160%;
  margin-top: 0.93rem;

  @media (max-width: 670px) {
    font-size: 12px;
    font-family: Open Sans;
    margin-top: 8px;
  }
`;

export const LabelTitle = styled.h4`
  color: ${Colors.secondary100};
  font-size: 1.03rem;
  font-family: Poppins;
  font-weight: 500;
  line-height: 160%;

  @media (max-width: 670px) {
    color: ${Colors.black100};
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
  }
`;

export const Divider = styled.div`
  margin-top: 1.87rem;
  padding-bottom: 1.87rem;
  height: 1px;
  border-top: 1px solid ${Colors.secondary10};
`;

export const ButtonAddTicket = styled.button`
  cursor: pointer;
  border: 0;
  display: flex;
  width: 24.98rem;
  height: 2.81rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background: ${Colors.primary10};
  margin-top: 1.68rem;

  @media (max-width: 1024px) {
    display: none;
  }


  span {
    color: ${Colors.primary100};
    font-size: 0.93rem;
    font-family: Cairo;
    font-weight: 700;
    line-height: 160%;
    margin-left: 0.46rem;

    @media (max-width: 670px) {
      font-size: 12px;
      font-weight: 600;
      margin-left: 7px;
    }
  }

  @media (max-width: 670px) {
    width: 100%;
  }
`;


export const ButtonAddTicketResponsive = styled.button`
  cursor: pointer;
  border: 0;
  display: flex;
  width: 24.98rem;
  height: 2.81rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background: ${Colors.primary10};
  margin-top: 1.68rem;

  @media (min-width: 1024px) {
    display: none;
  }


  span {
    color: ${Colors.primary100};
    font-size: 0.93rem;
    font-family: Cairo;
    font-weight: 700;
    line-height: 160%;
    margin-left: 0.46rem;

    @media (max-width: 670px) {
      font-size: 12px;
      font-weight: 600;
      margin-left: 7px;
    }
  }

  @media (max-width: 670px) {
    width: 100%;
  }
`;

export const TitleTicket = styled.h3`
  color: ${Colors.black30};
  font-family: Open Sans;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;

  @media (max-width: 800px) {
    margin-top: 12px;
  }
`;



export const ButtonPrint = styled.button`
  width: 12.28rem;
  height: 2.81rem;

  cursor: pointer;
  border: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 14px;

  background: ${Colors.white20};

  @media (max-width: 800px) {
    width: 25.73px;
    height: 25.73px;
    border-radius: 50%;

    div.text {
      display: none;
    }

    svg {
      width: 13px;
      height: 13px;
    }
  }

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 0.93rem;
    margin-left: 10px;
    margin-bottom: 0px;
    margin-left: 10px;
    color: ${Colors.red10};
  }
`;
