import { useCallback } from "react";
import { CreateAddAndEditTicket } from "components/CreateAndEditTicket";
import { ModalComponent } from "components/ModalComponent";
import { useCreateAndEditTicket } from "contexts/CreateAndEditTicketContext";

interface ModalCreateAndEditTicketProps {
  event_id: string;
}

export function ModalCreateAndEditTicket({
  event_id,
}: ModalCreateAndEditTicketProps) {
  const { isOpenModalTicket, setIsOpenModalTicket, ticket, setSelectedTicket } =
    useCreateAndEditTicket();

  const handleClose = useCallback(() => {
    setIsOpenModalTicket(false);
    setSelectedTicket(null);
  }, [setIsOpenModalTicket, setSelectedTicket]);

  return (
    <ModalComponent
      handleOk={handleClose}
      handleCancel={handleClose}
      isModalVisible={isOpenModalTicket}
      title={ticket ? "Editar Ingresso" : "Criar ingresso"}
    >
      <CreateAddAndEditTicket event_id={event_id} />
    </ModalComponent>
  );
}
