import { useState } from "react";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";

import { RiArrowRightSLine } from "react-icons/ri";
import { MdEdit } from "react-icons/md";

import { authClient } from "services/authClient";

import { Colors } from "constants/colors";
import { useAuth } from "contexts/AuthContext";

import {
  Avatar,
  Card,
  Container,
  ContainerItems,
  ProfileCard,
  Content,
  ButtonExit,
} from "./styles";

import { successNotification } from "components/Notification";

import user_avatar from "assets/user-avatar.svg";
import ticket_profile from "assets/ticket-profile.svg";
import minhas_compras from "assets/minhas-compras.svg";
import personal_data from "assets/personal-data.svg";
import data_access from "assets/data-access.svg";
import address_profile from "assets/address-profile.svg";

export function SidebarProfile() {
  const { user, signOut, hydratationUser } = useAuth();

  const [loading, setLoading] = useState(false)

  const onSelectFile = async (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setLoading(true)

    try {
      let formData1 = new FormData();
      if (e.target.files[0]) {
        formData1.append("image", e.target.files[0]);
      }

      await authClient.post(`/user/avatar/upload`, formData1, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setLoading(false)

      hydratationUser();

      successNotification(
        "Usuario atualizado",
        `Seu avatar foi atualizado com sucesso!`,
        1
      );
    } catch (err) {
      setLoading(false)
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 12, color: Colors.primary100 }} spin />
  );

  return (
    <Container>
      <ContainerItems>
        <h2>Meu perfil</h2>

        <ProfileCard>
          <Avatar>
            <img src={user?.avatar ?? user_avatar} alt={user?.name} />

            <input
              id="file"
              type="file"
              style={{ display: "none" }}
              accept="image/png, image/gif, image/jpeg"
              onChange={onSelectFile}
            />

            <label className="badge" htmlFor="file">
              {loading ? <Spin spinning indicator={antIcon} /> : <MdEdit size={11} />}
            </label>
          </Avatar>

          <Content>
            <h2>{`${user?.name ?? ""} ${user?.last_name ?? ""} `}</h2>
            <span>{user?.email}</span>
            <span>{user?.cell_phone}</span>
          </Content>
        </ProfileCard>

        <Link to="/profile/my-ticket">
          <Card>
            <div>
              <img
                className="ticket"
                src={ticket_profile}
                alt="Logo"
                width={15}
                height={13}
              />
              <h2>Meus ingressos</h2>
            </div>
            <RiArrowRightSLine size={25} color={Colors.secondary40} />
          </Card>
        </Link>

        <Link to="/profile/my-shopping">
          <Card>
            <div>
              <img
                className="ticket"
                src={minhas_compras}
                alt="Logo"
                width={15}
                height={13}
              />
              <h2>Minhas compras</h2>
            </div>
            <RiArrowRightSLine size={25} color={Colors.secondary40} />
          </Card>
        </Link>

        <Link to="/profile/personal-data">
          <Card>
            <div>
              <img
                className="ticket"
                src={personal_data}
                alt="Logo"
                width={15}
                height={13}
              />
              <h2>Dados pessoais</h2>
            </div>

            <RiArrowRightSLine size={25} color={Colors.secondary40} />
          </Card>
        </Link>

        <Link to="/profile/access-data">
          <Card>
            <div>
              <img
                className="ticket"
                src={data_access}
                alt="Logo"
                width={15}
                height={13}
              />
              <h2>Dados de acesso</h2>
            </div>

            <RiArrowRightSLine size={25} color={Colors.secondary40} />
          </Card>
        </Link>

        <Link to="/profile/address">
          <Card>
            <div>
              <img
                className="active"
                src={address_profile}
                alt="Logo"
                width={15}
                height={13}
              />
              <h2>Meu endereço</h2>
            </div>

            <RiArrowRightSLine size={25} color={Colors.secondary40} />
          </Card>
        </Link>

        <ButtonExit onClick={signOut}>
          <span>Sair</span>
        </ButtonExit>
      </ContainerItems>
    </Container>
  );
}
