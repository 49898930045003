import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { paramsToString } from "utils/paramsToString";

import { Transaction } from "types";
import { useSWRConfig } from "swr";
import { authClient } from "services/authClient";
import {
  UserTicketBalance,
  useTicketBalance,
} from "services/Hook/TicketOffice";
import { useGetDateAgo } from "./useGetDateAgo";

interface TransactionType {
  count: number;
  results: UserTicketBalance[];
}

interface UseSearchFinancialGeneralParams {
  statusFinancial?: string;
}

export function useSearchUserLedsBalance({
  statusFinancial,
}: UseSearchFinancialGeneralParams) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { daysFilter } = useGetDateAgo();

  const { mutate } = useSWRConfig();

  const pageCurrent = searchParams.get("page");
  const [page, setPage] = useState(
    Number(pageCurrent) === 0 ? 1 : Number(pageCurrent)
  );

  const [pageMobile, setPageMobile] = useState(1);

  const [loadingMoreItems, setLoadingMoreItems] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const organizers = searchParams.get("organizer") ?? "";
  const date_type = searchParams.get("date_type") ?? ""; // can be date_lte and date_gte
  const events = searchParams.get("events") ?? "";
  const tickets = searchParams.get("tickets") ?? "";
  const data_gte = searchParams.get("data_gte") ?? "";
  const data_lte = searchParams.get("data_lte") ?? "";
  const data_type = searchParams.get("data_type") ?? "";
  const filterId = searchParams.get("filter_id") ?? "";
  const filterText = searchParams.get("filter_text") ?? "";

  const [paramsString, setParamsString] = useState<URLSearchParams>();

  const { data, partialUrl } = useTicketBalance<TransactionType>({
    page: String(page),
    paramsString: String(paramsString),
    paramsObject: {
      ...(organizers && { organizers: [organizers] }),
      ...(events && { events: events.split("%") }),
      ...(tickets && { event_tickets: tickets.split("%") }),
      ...(statusFinancial && { status: statusFinancial }),
      ...(data_lte && { date_end: data_lte }),
      ...(data_gte && { date_start: data_gte }),
      ...(data_type &&
        data_type !== "choose" && {
          date_end:
            daysFilter.find((day) => String(day.id) === data_type)?.date_end ??
            daysFilter[0].date_end,
          date_start:
            daysFilter.find((day) => String(day.id) === data_type)
              ?.date_start ?? daysFilter[0].date_start,
        }),
        ...(filterId && filterText && { [filterId]: filterText }),
    },
  });

  const handleLoadMorePurchasingsMobile = () => {
    setPageMobile(pageMobile + 1);

    mutate(
      `${partialUrl}-${1}-${paramsString ?? ""}`,
      async () => {
        setLoadingMoreItems(true);

        const response = await authClient.post(
          `${partialUrl}?page=${pageMobile + 1}&limit=10`
        );

        const count = response.data.count;

        const last_page = Math.ceil(count / 10);

        if (pageMobile + 1 === last_page) {
          setHasMoreItems(false);
        }

        setLoadingMoreItems(false);

        const mapEvents = response?.data?.results?.map(
          (purchase: Transaction) => ({
            ...purchase,
            isOpen: false,
          })
        );

        let newMapEvents = data?.results;

        const mobile = data?.results;

        if (mobile) {
          newMapEvents = [...mobile, ...mapEvents];
        }

        return {
          count: response?.data?.count,
          results: newMapEvents,
        };
      },
      false
    );
  };

  useEffect(() => {
    const paramsString = new URLSearchParams(
      paramsToString({
        organizers,
        date_type,
        events,
        data_gte,
        data_lte,
        tickets,
        status: statusFinancial ?? "",
        filter_id: filterId,
        filter_text: filterText,
      })
    );

    setParamsString(paramsString);
  }, [data_gte, data_lte, date_type, events, filterId, filterText, organizers, statusFinancial, tickets]);

  const onChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: String(currentPage),
      });

      window.scrollTo({ top: 660, behavior: "smooth" });
    },
    [searchParams, setSearchParams]
  );

  // const handleOpenDrop = (id: string) => {
  //   mutate(
  //     `${partialUrl}-${page}-${paramsString ?? ""}`,
  //     () => {
  //       const newMapPurchases = data?.results?.map((purchase) =>
  //         purchase._id === id
  //           ? { ...purchase, isOpen: !purchase?.isOpen }
  //           : purchase
  //       );

  //       return {
  //         results: newMapPurchases,
  //         count: data?.count,
  //       };
  //     },
  //     false
  //   );
  // };

  return {
    data,
    page,
    loadingMoreItems,
    // isValidating,
    // handleOpenDrop,
    hasMoreItems,
    onChange,
    handleLoadMorePurchasingsMobile,
  };
}
