import { useEffect, useState } from "react";

import { Colors } from "constants/colors";

import {
  Container,
  ContainerItems,
  CardProfile,
  TitleCard,
  ContainerHeader,
  P,
  Subtitle,
  ContainerDate,
  Divider,
  LabelTitle,
  ContainerLike,
  Like,
  ContainerP,
  ButtonBack,
  DividerMargin,
  ContainerButtons,
  ButtonBackModal,
  ContainerButtonBack,
  ContainerModalAddDocument,
} from "./styles";

import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";

import { GridSidebar } from "styles/pages/Sidebar";
import { SidebarOrganizerProfile } from "components/SidebarOrganizerProfile";
import { EmptyTicketComponent } from "components/EmptyTicketComponent";
import {
  RiArrowLeftLine,
  RiEyeFill,
  RiQuestionLine,
  RiThumbDownFill,
  RiThumbUpFill,
  RiTimeLine,
} from "react-icons/ri";

import { useParams } from "react-router-dom";
import { HeaderTitleOrganizerProfile } from "components/HeaderTitleOrganizerProfile";
import {
  ButtonShare,
  ContainerLink,
  RowShared,
  SubTitle,
} from "pages/OrganizerCadaster/Steps/Identification/styles";
import { useAuthOrganizerById } from "services/Hook/Organizer";

import { Document, OrganizerResponse } from "types";
import { useDisclosure } from "hooks/useDisclosure";
import { Modal } from "components/Modal";

export default function OrganizerDocument() {
  const metaTags = {
    title: `Dados de acesso - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
    description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
    noIndex: true,
  };

  const [selectedDocument, setSelectedDocument] = useState<Document | null>(
    null
  );

  const { handleOk, showModal, isModalVisible } = useDisclosure();

  const [typeRender, setTypeRender] = useState("not_empty");

  const { id } = useParams();

  const { data } = useAuthOrganizerById<OrganizerResponse>(String(id));

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [typeRender]);

  const handleSelectDocument = (document: Document) => {
    setSelectedDocument(document);

    showModal();
  };

  return (
    <>
      <Header isProfile metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <div className="breadcrumb">
            <ListBreadcrumb
              data={[
                { name: "Home", link: "/" },
                { name: "Perfil", link: "/" },
              ]}
            />
          </div>

          <HeaderTitleOrganizerProfile />

          <GridSidebar>
            <SidebarOrganizerProfile />

            <CardProfile
            // autoComplete="off"
            // onSubmit={handleSubmit(handleResetPassword)}
            >
              <ContainerHeader>
                <TitleCard>Documentos</TitleCard>

                <RiQuestionLine
                  size={37}
                  color={Colors.gray30}
                  onClick={() => setTypeRender("help")}
                />
              </ContainerHeader>

              {typeRender === "empty" ? (
                <EmptyTicketComponent
                  title="Você não cadastrou seus dados ainda :("
                  subtitle="Seus dados financeiros não foram cadastrados em nosso sistema até o momento, aproveite para cadastrar agora!"
                />
              ) : null}

              {typeRender === "not_empty" ? (
                <>
                  {data?.documents && data?.documents?.length > 0 ? (
                    <>
                      <SubTitle>
                        Especificações: arquivo do tipo PNG ou JPEG, com tamanho
                        máximo recomendado de 4MB.
                      </SubTitle>

                      {data?.documents.map((document) => (
                        <ContainerLink>
                          <h2>Documento de identidade (frente)</h2>

                          <RowShared>
                            <input
                              type="text"
                              className="upload"
                              defaultValue={document?.patch}
                              disabled
                              readOnly
                            />

                            <label className="badge" htmlFor="identify-front">
                              <ButtonShare
                                onClick={() => handleSelectDocument(document)}
                              >
                                <RiEyeFill color={Colors.white} size={14} />
                              </ButtonShare>
                            </label>
                          </RowShared>
                        </ContainerLink>
                      ))}
                    </>
                  ) : (
                    <EmptyTicketComponent title="Seu organizador não cadastrou documentos ainda :(" />
                  )}
                </>
              ) : null}

              {typeRender === "help" ? (
                <>
                  <Subtitle>
                    Como receberei meu repasse referente às vendas de meu
                    evento?
                  </Subtitle>

                  <ContainerDate>
                    <RiTimeLine color={Colors.black50} width={26} height={26} />

                    <span className="date">12/03/21 - Atualizado</span>
                  </ContainerDate>

                  <ContainerP>
                    <P>
                      Entre em contato com a eventx, para mais informações de
                      como receber os seus repasses !!!
                    </P>
                  </ContainerP>

                  <Divider />

                  <LabelTitle>Este artigo foi útil?</LabelTitle>

                  <ContainerLike>
                    <Like>
                      <RiThumbUpFill size={18} color={Colors.secondary40} />
                    </Like>

                    <Like>
                      <RiThumbDownFill size={18} color={Colors.secondary40} />
                    </Like>
                  </ContainerLike>

                  <DividerMargin />

                  <ContainerButtons>
                    <ButtonBack onClick={() => setTypeRender("not_empty")}>
                      <RiArrowLeftLine size={18} color={Colors.primary100} />

                      <span>Voltar</span>
                    </ButtonBack>
                  </ContainerButtons>
                </>
              ) : null}
            </CardProfile>
          </GridSidebar>
        </ContainerItems>

        <Modal
          isModalVisible={isModalVisible}
          handleCancel={handleOk}
          handleOk={handleOk}
        >
          <ContainerModalAddDocument>
            <h2 className="title">Visualizar documento</h2>

            <p className="title">{selectedDocument?.patch}</p>

            <img src={selectedDocument?.patch} alt="" className="document" />

            <ContainerButtonBack>
              <ButtonBackModal onClick={handleOk}>Voltar</ButtonBackModal>
            </ContainerButtonBack>
          </ContainerModalAddDocument>
        </Modal>
      </Container>

      <Footer />
    </>
  );
}
