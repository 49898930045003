import { Colors } from "constants/colors";
import styled from "styled-components";

import { Select } from "antd";

export const ContainerForm = styled.form`
  width: 100%;
  height: 100%;
`;

export const ContainerDrawer = styled.div`
  height: 60px;

  div.data-drawer {
    display: flex;
    flex-direction: column;
    height: 143px;
    margin-top: 27px;
    border-bottom: 1px solid ${Colors.secondary10};

    div.data {
      display: flex;
      flex-direction: column;

      padding-left: 14px;
      padding-right: 14px;

      h1 {
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;

        color: ${Colors.black100};
      }
    }
  }

  div.next-drawer {
    display: flex;
    flex-direction: column;
    height: 134px;
    margin-top: 27px;
    margin-bottom: 25px;
    border-bottom: 1px solid ${Colors.secondary10};

    div.next {
      display: flex;
      flex-direction: column;

      padding-left: 14px;
      padding-right: 14px;

      h1 {
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;

        color: ${Colors.black100};
      }

      div {
        display: flex;
        flex-direction: column;

        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: red;
          border-color: transparent;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;

          color: ${Colors.secondary70};
        }
      }
    }
  }

  div.select-drawer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 84px;
    margin-top: 27px;

    div.select {
      display: flex;
      flex-direction: column;

      padding-left: 14px;
      padding-right: 14px;

      h1 {
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;

        color: ${Colors.black100};
      }

      div {
        width: 100%;
        margin-top: 4px;
      }
    }
  }

  div.buttons-drawer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-top: 37px;

    padding-left: 14px;
    padding-right: 14px;

    button.first {
      border: 0;
      width: 97px;
      height: 31px;
      left: 96px;
      top: 470px;

      background: ${Colors.primary10};
      border-radius: 6px;

      font-weight: 600;
      font-size: 12px;
      line-height: 22px;

      color: ${Colors.primary100};
    }

    button.second {
      border: 0;
      width: 97px;
      height: 31px;

      background: ${Colors.primary100};

      box-shadow: 0px 3px 20px rgba(212, 0, 80, 0.15);
      border-radius: 6px;

      font-weight: bold;
      font-size: 12px;
      line-height: 22px;

      color: ${Colors.white};
    }
  }
`;

export const ContainerFilterDrawer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 27px;
  padding-bottom: 25px;

  border-bottom: 1px solid ${Colors.secondary10};
`;

export const ContentDrawer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-left: 14px;
  padding-right: 14px;
`;

export const ColumnDrawer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  row-gap: 1.06rem;
`;

export const LabelFilter = styled.h5`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 0.87rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 2px solid ${Colors.secondary20};

  input {
    border: 0;
    outline: 0;
    margin-left: 24px;
    margin-bottom: 5px;
    width: 100%;

    color: ${Colors.secondary40};
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;

export const BorderSelect = styled.div`
  border: 2px solid ${Colors.secondary20};
  width: 100%;
  height: 2.81rem;
  border-radius: 0.37rem;
  margin-right: 0.51rem;
  display: flex;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 0.45rem;

    height: 32px;
    border-radius: 6px;
  }
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .ant-select-selection-item {
    color: ${Colors.secondary40};
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const BadgeWithClose = styled.div`
  width: 100%;
  height: 27px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-radius: 4px;
  background-color: ${Colors.gray35};
  margin-top: 20px;
  padding: 0px 10px;

  div.content {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      color: ${Colors.secondary70};
      font-family: Cairo;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin-left: 6px;
    }
  }
`;
