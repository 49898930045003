import { useCreateOrganizer } from "contexts/CreateOrganizerContext";

import { Container, ContainerItems, Title } from "./styles";

import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { Footer } from "components/Footer";
import { StepsOrganizerComponent } from "components/StepsOrganizer";

import { Introduction } from "./Steps/Introduction";
import { Identification } from "./Steps/Identification";
import { Document } from "./Steps/Document";
import { BankData } from "./Steps/BankData";

export default function OrganizerCadaster() {
  const { organizer } = useCreateOrganizer();

  const steps: { [key: string]: any } = {
    0: <Introduction />,
    1: <Identification />,
    2: <Document />,
    3: <BankData />,
  };

  return (
    <>
      <Header />
      <Container>
        <ContainerItems>
          <>
            <div className="bread">
              <ListBreadcrumb
                data={[
                  { name: "Home", link: "/" },
                  { name: "Perfil", link: "/" },
                  { name: "Cadastro", link: "/" },
                ]}
              />
            </div>

            <Title>Cadastre seus dados de organizador</Title>
          </>

          <StepsOrganizerComponent />

          {steps[organizer?.step ?? 0]}
        </ContainerItems>
      </Container>

      <Footer />
    </>
  );
}
