import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { IoCloseCircleSharp } from "react-icons/io5";

import { Colors } from "constants/colors";

import {
  BorderSelect,
  Container,
  DivIsSelected,
  SelectStyledWithoutDrop,
} from "./styles";
import { KeySort } from "constants/filters";
import { useFiltersContext } from "contexts/FiltersContext";

interface SelectFilterModalProps {
  title: string;
  sortKey: KeySort;
}

// pegar evento selecionado por parametro no caso -> event_name
export function SelectEventFilter({ title, sortKey }: SelectFilterModalProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { setIsOpenFilterModalEvent } = useFiltersContext();

  const events = searchParams.get("events") ?? "";

  const clearId = useCallback(() => {
    searchParams.delete("events");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [searchParams, setSearchParams]);

  const handleOpenModal = () => {
    setIsOpenFilterModalEvent(true);
  };

  const eventsLength = events?.split(",").length;

  return (
    <Container>
      {events === "" ? (
        <BorderSelect>
          <SelectStyledWithoutDrop
            bordered={false}
            labelInValue
            size="large"
            defaultValue={{ value: 0, label: title }}
            open={false}
            onClick={handleOpenModal}
          />
        </BorderSelect>
      ) : (
        <DivIsSelected>
          <h3 className="selected">{eventsLength} Eventos selecionados</h3>

          <IoCloseCircleSharp
            onClick={clearId}
            style={{ cursor: "pointer" }}
            size={18}
            color={Colors.white}
          />
        </DivIsSelected>
      )}
    </Container>
  );
}
