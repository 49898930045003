import { authClient } from "services/authClient";

interface CreateDocumentOrganizerProps {
  document: File;
  organizerId: string;
  type: number;
}

export async function CreateDocumentOrganizer({
  document,
  organizerId,
  type,
}: CreateDocumentOrganizerProps) {
  const url: string = "/organizer/document";

  let formData = new FormData();

  formData.append("document", document);
  formData.append("organizer_id", organizerId);
  formData.append("type", String(type));

  const response = await authClient.post(url, formData, {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
}
