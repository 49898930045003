import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Card } from "components/ModalGeneralBalanceAvailable/styles";
import { Divider } from "antd";
import { RiLock2Line } from "react-icons/ri";
import { Colors } from "constants/colors";
import { InputForm } from "components/Form/InputForm";
import {
  CancelButton,
  ConfirmButton,
  Footer,
} from "../OrganizerWithdraw/styles";
import { Container, Wrapper } from "./styles";
import { useForm } from "react-hook-form";

interface ConfirmPasswordProps {
  fetching: boolean;
  onConfirmPassword: (password: string) => void;
  onClose: () => void;
}

interface FormSchema {
  password: string;
}

const formSchema = yup.object().shape({
  password: yup.string().required("Informe a senha"),
});

function ConfirmPassword({
  fetching,
  onConfirmPassword,
  onClose,
}: ConfirmPasswordProps) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormSchema>({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (values: FormSchema) => {
    onConfirmPassword(values.password);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Container>
          <h1>Digite sua senha de acesso</h1>

          <Divider />

          <Wrapper>
            <InputForm
              {...register("password")}
              isFull
              error={errors.password}
              type="password"
              icon={<RiLock2Line size={17} color={Colors.secondary40} />}
              label="Senha"
            />
          </Wrapper>
        </Container>
      </Card>

      <Footer>
        <CancelButton type="button" onClick={onClose}>
          Cancelar
        </CancelButton>
        <ConfirmButton disabled={fetching} type="submit">
          Prosseguir
        </ConfirmButton>
      </Footer>
    </form>
  );
}

export default ConfirmPassword;
