import { useEffect, useState } from "react";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import { Container, ContainerItems, CardProfile, TitleCard } from "./styles";

import Popup from "containers/Popup";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";

import { GridSidebar } from "styles/pages/Sidebar";
import { SidebarOrganizerProfile } from "components/SidebarOrganizerProfile";
import { TitleWithMargin } from "pages/OrganizerCadaster/Steps/Identification/styles";

import { HeaderTitleOrganizerProfile } from "components/HeaderTitleOrganizerProfile";
import { Form } from "./Form";

const metaTags = {
  title: `Dados de acesso - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

export default function OrganizerData() {
  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Header isProfile={true} metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <div className="breadcrumb">
            <ListBreadcrumb
              data={[
                { name: "Home", link: "/" },
                { name: "Perfil", link: "/" },
              ]}
            />
          </div>

          <HeaderTitleOrganizerProfile />

          <GridSidebar>
            <SidebarOrganizerProfile />

            <CardProfile>
              <TitleCard>Dados do organizador</TitleCard>

              <Form />
            </CardProfile>
          </GridSidebar>
        </ContainerItems>
      </Container>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
