import { Modal } from "antd";
import styled from "styled-components";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 0.93rem;
    padding: 0px;
  }

  padding: 0px;

  .ant-modal,
  .ant-modal-content {
    height: 100%;
    width: 100%;
    margin: 0;
    top: 0;
    padding: 0px;
  }

  .ant-modal-body {
    height: 100%;
    padding: 0px;
  }
`;

export const StyledIframe = styled.iframe`
  outline: none;
  height: 700px;
  width: 100%;
  border-radius: 12px;
`;
