import { buildMicroRoute } from "utils/urlBuilder";

import left_icon_mobile from "assets/left-icon-mobile.svg";
import exit_icon_mobile from "assets/exit-icon-mobile.svg";
import logo_mobile from "assets/logo-mobile.svg";
import search_icon_mobile from "assets/search-icon-mobile.svg";

interface MobileLinksContainerProps {
  isProfile?: boolean;
  visible?: boolean;
  onOpenDrawer: () => void;
  onCloseDrawer: () => void;
  customBack?(): void;
  setActiveDrop: (value: boolean) => void;
  activeDrop: boolean;
}

function MobileLinksContainer({
  isProfile,
  visible,
  onOpenDrawer,
  onCloseDrawer,
  customBack,
  setActiveDrop,
  activeDrop,
}: MobileLinksContainerProps) {
  return (
    <div className="mobile">
      {visible ? (
        <img
          src={exit_icon_mobile}
          onClick={onCloseDrawer}
          width="23px"
          height="31px"
          alt=""
        />
      ) : (
        <img
          src={left_icon_mobile}
          onClick={onOpenDrawer}
          alt="left-icon"
          width="20px"
          height="31px"
        />
      )}

      <a href={buildMicroRoute("/")}>
        <img src={logo_mobile} alt="logo-mobile" width="34px" height="34px" />
      </a>

      {activeDrop ? (
        <img
          src={exit_icon_mobile}
          onClick={() => setActiveDrop(!activeDrop)}
          width="23px"
          height="31px"
          alt=""
        />
      ) : (
        <img
          onClick={() => setActiveDrop(!activeDrop)}
          src={search_icon_mobile}
          width="23px"
          height="31px"
          alt="search-icon-mobile"
        />
      )}
    </div>
  );
}

export default MobileLinksContainer;
