/* eslint-disable camelcase */
export const parentalRating = [
  { age: null, color: '#fff' },
  { age: 'L', color: '#00EE6D' },
  { age: 10, color: '#08A6FF' },
  { age: 12, color: '#DFC900' },
  { age: 14, color: '#EA8D02' },
  { age: 16, color: '#BC1C1C' },
  { age: 18, color: '#000000' },
]

export const switchEventType = (type_event: number) => {
  switch (type_event) {
    case 1:
      return 'Presencial'
    case 2:
      return 'Online'
    case 3:
      return 'Híbrido (presencial + online)'
  }
}

export function formatData(data: string) {
  const date = data.split('T')[0]

  const month = date.split('-')[1]
  const day = date.split('-')[2]
  const year = date.split('-')[0]
  return `${day}/${month}/${year}`
}

export const Streamings = [
  { value: '1', label: 'Pandastreaming' },
  { value: '2', label: 'Youtube' },
  { value: '3', label: 'Facebook' },
  { value: '4', label: 'Twitter' },
  { value: '5', label: 'Instagram' },
  { value: '6', label: 'Vímeo' },
  { value: '7', label: 'Microsoft Teams' },
  { value: '8', label: 'Zoom' },
  { value: '9', label: 'Whatsapp' },
  { value: '10', label: 'Outra plataforma' },
]
