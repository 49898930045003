import { useCallback } from "react";
import { RiCloseCircleLine } from "react-icons/ri";
import { Colors } from "constants/colors";
import { useGeneralContext } from "contexts/GeneralContext";
import { ModalStyled } from "./styles";
import { ModalGeneralBalanceAvailableProps } from "./types";
import AvailableBalance from "./AvailableBalance";

export function ModalGeneralBalanceAvailable({
  balance,
  onWithdraw,
}: ModalGeneralBalanceAvailableProps) {
  const {
    isOpenModalGeneralBalanceAvailable,
    setIsOpenModalGeneralBalanceAvailable,
  } = useGeneralContext();

  const closeModal = useCallback(() => {
    setIsOpenModalGeneralBalanceAvailable(false);
  }, [setIsOpenModalGeneralBalanceAvailable]);

  return (
    <ModalStyled
      width={1200}
      visible={isOpenModalGeneralBalanceAvailable}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <AvailableBalance
        balance={balance}
        onWithdraw={onWithdraw}
        closeModal={closeModal}
      />
    </ModalStyled>
  );
}
