import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
  height: 100%;
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.34rem 6.37rem 2.34rem 6.37rem;
  border: 1px solid ${Colors.black10};
  border-radius: 0.93rem;
  margin-top: 2.34rem;

  @media (max-width: 1024px) {
    padding: 0px 0px;
    border: 0;
  }

  div.link-event {
    margin-top: 22px;
    margin-bottom: 8px;

    h4.link {
      font-weight: normal;
      font-size: 10px;
      line-height: 24px;

      display: flex;
      align-items: center;

      color: ${Colors.secondary40};
    }

    nav {
      width: 100%;
      height: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 36px;

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-right: 10px;

      border-radius: 6px;
      border: 1px solid ${Colors.border};

      div.searchInput {
        width: 100%;
        height: 100%;

        input {
          height: 100%;
          width: 90%;
          border: none;
          outline: none;
          background-color: transparent;
          padding: 0px 2px 0px 28px;
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;

          color: ${Colors.secondary70};
        }
      }

      div.search {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18%;
        height: 100%;

        div.copy {
          width: 42px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          background: ${Colors.primary100};

          box-shadow: 0px 3px 20px rgba(212, 0, 80, 0.15);
          border-radius: 3px;
        }
      }
    }
  }
`;

export const Title = styled.h2`
  color: ${Colors.secondary100};
  font-size: 1.21rem;
  font-family: Poppins;
  font-weight: 600;
  line-height: 160%;

  @media (max-width: 670px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 700;
    line-height: 25px;
  }
`;

export const SubTitle = styled.h3`
  color: ${Colors.black70};
  font-size: 1rem;
  font-family: Open Sans;
  line-height: 160%;
  margin-top: 0.93rem;

  @media (max-width: 670px) {
    font-size: 12px;
    font-family: Open Sans;
    margin-top: 8px;
  }
`;

export const LabelTitle = styled.h4`
  color: ${Colors.secondary100};
  font-size: 1.03rem;
  font-family: Poppins;
  font-weight: 500;
  line-height: 160%;

  @media (max-width: 670px) {
    color: ${Colors.black100};
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
  }
`;

export const SubTitleShare = styled.h5`
  color: ${Colors.black70};
  font-family: Open Sans;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  margin-top: 0.65rem;
`;

export const Divider = styled.div`
  margin-top: 1.87rem;
  padding-bottom: 1.87rem;
  height: 1px;
  border-top: 1px solid ${Colors.secondary10};
`;

export const ContainerLink = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.68rem;

  h2 {
    color: ${Colors.secondary40};
    font-family: Poppins;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0px;

    @media (max-width: 670px) {
      font-size: 11px;
    }
  }
`;

export const RowShared = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 4px;
  gap: 0.93rem;

  input {
    border: none;
    outline: 0;
    width: 100%;
    height: 2.81rem;
    border-radius: 0.37rem;
    border: 2px solid ${Colors.secondary20};
    background: #fff;
    padding-left: 0.93rem;

    @media (max-width: 670px) {
      border: transparent;
    }
  }

  @media (max-width: 670px) {
    border-radius: 3px;
    padding-right: 12px;
    border-radius: 5px;
    border: 1.5px solid ${Colors.border20};
    background: ${Colors.white};
  }
`;

export const ButtonShare = styled.button`
  cursor: pointer;
  border: 0;
  outline: 0;
  display: flex;
  width: 4.68rem;
  height: 2.81rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px 0px rgba(212, 0, 80, 0.15);

  img {
    width: 1.12rem;
    height: 1.12rem;

    @media (max-width: 670px) {
      width: 14px;
      height: 14px;
    }
  }

  @media (max-width: 670px) {
    border-radius: 3px;
    width: 48px;
    height: 28px;
  }
`;
