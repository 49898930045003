import { Radio, RadioChangeEvent } from "antd";

import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { useCreateEvent } from "contexts/CreateEventContext";

import { CreateLocalEventFormData } from "types";
import { createLocalEventFormSchema } from "lib/validations/createEvent";

import {
  Card,
  Container,
  ContainerEventLocal,
  Divider,
  LabelTitle,
  RowTypeEvent,
  SubTitle,
  Title,
} from "./styles";

import { ButtonNext, ButtonPrev, ContainerButtons } from "../../styles";

import { Form } from "./Form";

export function Local() {
  const methods = useForm<CreateLocalEventFormData>({
    resolver: yupResolver(createLocalEventFormSchema),
  });

  const { control } = methods;

  const { event } = useCreateEvent();

  const handleAddLocal: SubmitHandler<CreateLocalEventFormData> = async ({
    type_event,
    streaming_id,
    streaming_name,
    streaming_url,
    address_name,
    address_zipcode,
    address_street,
    address_complement,
    address_country,
    address_district,
    address_city,
    address_state,
    address_number,
  }) => {
    event.createLocal({
      type_event,
      address_map: true,
      streaming_id: streaming_id,
      streaming_name: streaming_name ?? null,
      streaming_url: streaming_url ?? null,
      address_complement,
      address_name,
      address_zipcode,
      address_street,
      address_country,
      address_district,
      address_city,
      address_state,
      address_number,
    });

    event.goToNextStep();
  };

  return (
    <FormProvider {...methods}>
      <Container onSubmit={methods.handleSubmit(handleAddLocal)}>
        <Card>
          <Title>3. Local do evento</Title>
          <SubTitle>Defina o local onde seu evento vai acontecer.</SubTitle>

          <Divider />

          <LabelTitle>Qual o tipo do seu evento?</LabelTitle>

          <ContainerEventLocal>
            <Controller
              control={control}
              name="type_event"
              defaultValue={1}
              render={({ field: { onChange, value } }) => (
                <Radio.Group
                  onChange={(e: RadioChangeEvent) => {
                    onChange(e.target.value);
                  }}
                  value={value}
                >
                  <RowTypeEvent>
                    <Radio className="radio" value={1}>
                      Evento presencial
                    </Radio>
                    <Radio className="radio" value={2}>
                      Evento online
                    </Radio>
                    <Radio className="radio" value={3}>
                      Evento híbrido (presencial + online)
                    </Radio>
                  </RowTypeEvent>
                </Radio.Group>
              )}
            />
          </ContainerEventLocal>

          <Divider />

          <Form />
        </Card>

        <ContainerButtons>
          <ButtonPrev active type="button" onClick={event.goToBackStep}>
            <span>Voltar</span>
          </ButtonPrev>

          <ButtonNext type="submit">
            <p>Prosseguir</p>
          </ButtonNext>
        </ContainerButtons>
      </Container>
    </FormProvider>
  );
}
