import { Dropdown } from "antd";
import { Badge } from "components/Badge";
import { ButtonsContainer, DropdownButton } from "components/Header/styles";
import { RiShoppingCart2Fill } from "react-icons/ri";

interface CartDropdownProps {
  isDropdownVisible: boolean;
  setIsDropdownVisible: (value: boolean) => void;
  quantityTotal: number;
}

function CartDropdown({
  isDropdownVisible,
  setIsDropdownVisible,
  quantityTotal,
}: CartDropdownProps) {
  return (
    <ButtonsContainer>
      <Dropdown
        visible={isDropdownVisible}
        // overlay={menu}
        overlay={<div></div>}
        placement="bottomRight"
      >
        <Badge count={quantityTotal} offset={[0, 12]} overflowCount={9}>
          <DropdownButton
            onClick={() => setIsDropdownVisible(!isDropdownVisible)}
          >
            <RiShoppingCart2Fill />
          </DropdownButton>
        </Badge>
      </Dropdown>
    </ButtonsContainer>
  );
}

export default CartDropdown;
