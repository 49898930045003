import { FRONTLINE_URL } from "constants/environmentVariables";
import { useEffect } from "react"

function NotFound() {
  useEffect(() => {
    const { pathname, search } = window.location;

    if (!FRONTLINE_URL) return;

    window.location.href = FRONTLINE_URL + pathname + search;
  }, []);

  return null;
}

export default NotFound;
