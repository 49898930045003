import { IdentificationResponseOrganizer } from "types";

import { ActionTypes } from "./actions";

export interface EventState {
  step: number;
  identification: IdentificationResponseOrganizer | null;
  frontIdentify: string;
  backIdentify: string;
  proofOfRegistrationStatus: string;
  proofOfAddress: string;
  selfieHoldingDocument: string;
}

export function organizerReducer(state: EventState, action: any) {
  switch (action.type) {
    case ActionTypes.GO_TO_BACK_STEP:
      if (state.step === 0) {
        return { ...state };
      }

      return {
        ...state,
        step: state.step - 1,
      };

    case ActionTypes.GO_TO_NEXT_STEP:
      if (state.step >= 3) {
        return { ...state };
      }

      return {
        ...state,
        step: state.step + 1,
      };

    case ActionTypes.ADD_IDENTIFICATION:
      return {
        ...state,
        identification: action.payload.identification,
      };

    case ActionTypes.ADD_FRONT_IDENTIFY:
      return {
        ...state,
        frontIdentify: action.payload.document,
      };

    case ActionTypes.ADD_BACK_IDENTIFY:
      return {
        ...state,
        backIdentify: action.payload.document,
      };

    case ActionTypes.PROOF_OF_REGISTRATION_STATUS:
      return {
        ...state,
        proofOfRegistrationStatus: action.payload.document,
      };

    case ActionTypes.ADD_PROOF_OF_ADDRESS:
      return {
        ...state,
        proofOfAddress: action.payload.document,
      };

    case ActionTypes.ADD_SELFIE_HOLDING_DOCUMENT:
      return {
        ...state,
        selfieHoldingDocument: action.payload.document,
      };

    case ActionTypes.RESET_STORE:
      return {
        ...state,
        step: 0,
        introduction: null,
        frontIdentify: "",
        backIdentify: "",
        proofOfRegistrationStatus: "",
        proofOfAddress: "",
        selfieHoldingDocument: "",
      };

    default:
      return state;
  }
}
