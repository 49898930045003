import { Link } from "react-router-dom";

import { BsThreeDots } from "react-icons/bs";
import { IoMdCloseCircle } from "react-icons/io";
import { MdCheckCircle } from "react-icons/md";

import { Colors } from "constants/colors";
import { EventOrganizer, EventTypeOrganizer } from "types";

import {
  BadgeMy,
  CategoryMy,
  Container,
  InativeBadgeMy,
  Box,
  DropDownEvent,
  StatusWrapper,
  StatusContainer,
  StatusComponent,
} from "./styles";

import eyes_event_icon from "assets/eyes-event-icon.svg";
import ri_pencil_line from "assets/ri_pencil-line.svg";
import integrations_event_icon from "assets/integrations-event-icon.svg";
import share_event_icon from "assets/share-event-icon.svg";
import { FRONTLINE_URL } from "constants/environmentVariables";
import EventThumb from "./EventThumb";
import { ADMIN_STATUS } from "constants/adminStatus";

interface ListTableProps {
  events: EventOrganizer[] | undefined;
  handleOpenDropCardEvent(event: EventOrganizer | null): void;
  eventSelected: EventOrganizer | null;
  showModalShare(): void;
}

export function ListTable({
  events,
  handleOpenDropCardEvent,
  eventSelected,
  showModalShare,
}: ListTableProps) {
  return (
    <Container>
      <table>
        <thead>
          <tr>
            <th>EVENTO E AUTOR</th>
            <th>DATA E HORÁRIO</th>
            <th>LOCAL</th>
            <th>ORGANIZADOR</th>
            <th>CATEGORIA</th>
            <th>STATUS</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {events?.map((event) => {
            return (
              <tr style={{ borderRadius: 20 }} key={event?._id}>
                <td>
                  <div className="row">
                    <Link to={`/my-event/${event?._id}`}>
                      <EventThumb img={event?.thumb} />
                    </Link>

                    <div className="column">
                      <Link to={`/my-event/${event?._id}`}>
                        <h5>{event?.name}</h5>
                      </Link>

                      {/* <h6>{event?.creator?.name}</h6> */}
                    </div>
                  </div>
                </td>

                <td>
                  <div className="date">
                    <h5>{event?.formattedDate}</h5>

                    <h6>{event?.formattedHour}</h6>
                  </div>
                </td>

                <td>
                  <h3>
                    {event?.address_city}, {event?.address_state}
                  </h3>
                </td>

                <td>
                  <h3>{event?.organizer?.name}</h3>
                </td>

                <td>
                  {event.categories.map((category, index) => (
                    <CategoryMy hasUp={index > 0}>
                      <span>{category.name}</span>
                    </CategoryMy>
                  ))}
                </td>

                <td>
                  <StatusWrapper>
                    <StatusContainer>
                      {event?.status ? (
                        <BadgeMy>
                          <MdCheckCircle color={Colors.white} />

                          <span>ATIVO</span>
                        </BadgeMy>
                      ) : (
                        <InativeBadgeMy>
                          <IoMdCloseCircle color={Colors.black50} />

                          <span>INATIVO</span>
                        </InativeBadgeMy>
                      )}

                      {!event?.status ? (
                        <InativeBadgeMy>
                          <IoMdCloseCircle color={Colors.black50} />

                          <span>INATIVO</span>
                        </InativeBadgeMy>
                      ) : null}
                    </StatusContainer>
                    {event?.admin_status ? (
                      <StatusContainer>
                        <StatusComponent status={event.admin_status}>
                          <span>
                            {ADMIN_STATUS.find(
                              (a) =>
                                a.value === event.admin_status?.toLowerCase()
                            )?.label?.toUpperCase()}
                          </span>
                        </StatusComponent>
                      </StatusContainer>
                    ) : null}
                  </StatusWrapper>
                </td>

                <td>
                  <div className="div-icon-three">
                    <Box
                      active={event?.isOpen}
                      onClick={() => handleOpenDropCardEvent(event)}
                    >
                      <BsThreeDots
                        style={{ cursor: "pointer" }}
                        size={22}
                        color={Colors.black100}
                      />
                    </Box>

                    {eventSelected?._id === event._id ? (
                      <DropDownEvent>
                        <ul>
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/my-event/${event._id}`}
                          >
                            <li>
                              <div className="circle-icon">
                                <img src={eyes_event_icon} alt="eyes" />
                              </div>
                              <span>Visualizar evento</span>
                            </li>
                          </Link>

                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/my-event/${event?._id}/edit`}
                          >
                            <li>
                              <div className="circle-icon">
                                <img src={ri_pencil_line} alt="eyes" />
                              </div>
                              <span>Editar evento</span>
                            </li>
                          </Link>

                          <a
                            style={{ textDecoration: "none" }}
                            href={`${FRONTLINE_URL}/${event.nickname}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <li>
                              <div className="circle-icon">
                                <img src={eyes_event_icon} alt="eyes" />
                              </div>
                              <span>Visualizar Link do Checkout</span>
                            </li>
                          </a>

                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/my-event/${event._id}/integration`}
                          >
                            <li>
                              <div className="circle-icon">
                                <img src={integrations_event_icon} alt="edit" />
                              </div>
                              <span>Gerenciar integrações</span>
                            </li>
                          </Link>

                          <li className="last" onClick={showModalShare}>
                            <div className="circle-icon">
                              <img src={share_event_icon} alt="duplicate" />
                            </div>
                            <span>Compartilhar evento</span>
                          </li>
                        </ul>
                      </DropDownEvent>
                    ) : null}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
}
