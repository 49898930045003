import { Row } from "antd";
import {
  ColumnGap,
  ContainerData,
  ContainerInformations,
  Informations,
  SubTitleInformation,
  TitleSmall,
} from "pages/CheckInOut/styles";
import { formatCpf } from "utils/formatCpf";
import { formatDate } from "utils/formatDate";

type FirstStepProps = {
  holderName: string;
  holderDocument?: string;
  ticketId: string;
  ticketName: string;
  eventName: string;
  checkInAt?: Date | null;
  checkoutAt?: Date | null;
};

function FirstStep({
  holderName,
  holderDocument,
  ticketId,
  ticketName,
  eventName,
  checkInAt,
  checkoutAt,
}: FirstStepProps) {
  return (
    <>
      <Informations>
        <ContainerInformations>
          <SubTitleInformation>{holderName}</SubTitleInformation>

          <TitleSmall>
            Documento: {holderDocument ? formatCpf(holderDocument) : "-"}
          </TitleSmall>

          <TitleSmall>ID do ingresso: {ticketId}</TitleSmall>

          <TitleSmall>Evento: {eventName}</TitleSmall>

          <TitleSmall>Ingresso: {ticketName}</TitleSmall>

          <ContainerData>
            <ColumnGap>
              <Row className="checkInContainer">
                <strong>Check-in:&nbsp;</strong>
                <p className="checkInOutHour">
                  {checkInAt ? formatDate(checkInAt) : "Não"}
                </p>
              </Row>

              <Row className="checkInContainer">
                <strong>Check-out:&nbsp;</strong>
                <p className="checkInOutHour">
                  {checkoutAt ? formatDate(checkoutAt) : "Não"}
                </p>
              </Row>
            </ColumnGap>
          </ContainerData>
        </ContainerInformations>
      </Informations>
    </>
  );
}

export default FirstStep;
