import { RiCloseCircleLine } from "react-icons/ri";

import { Colors } from "constants/colors";

import { ContainerModal, ModalStyled } from "./styles";

interface ModalCategoryProps {
  title: string;
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
  children: JSX.Element;
}

export function ModalComponent({
  title,
  isModalVisible,
  handleOk,
  handleCancel,
  children,
}: ModalCategoryProps) {
  return (
    <ModalStyled
      width={1200}
      visible={isModalVisible}
      onOk={handleOk}
      style={{ borderRadius: "20px" }}
      onCancel={handleCancel}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <h1>{title}</h1>

        {children}
      </ContainerModal>
    </ModalStyled>
  );
}
