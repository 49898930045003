import { useCallback, useEffect, useState } from "react";

import axios from "axios";

import { Controller, useFormContext } from "react-hook-form";

import { BiVideoPlus } from "react-icons/bi";
import { AiOutlineLink } from "react-icons/ai";

import { useCreateEvent } from "contexts/CreateEventContext";

import { Divider, Switch, Select } from "antd";

import {
  RiCommunityLine,
  RiInformationLine,
  RiMapPinAddLine,
} from "react-icons/ri";

import { Colors } from "constants/colors";

import { Platform } from "types";

import { useDisclosure } from "hooks/useDisclosure";

import {
  Container,
  ContainerSwitch,
  LabelTitle,
  GridInputs,
  ContainerImage,
  CardPlataform,
  BorderSelect,
  ContainerSelect,
  LabelSelect,
  StyledSelect,
} from "./styles";

import { InputForm } from "components/Form/InputForm";
import { ModalPlatform } from "components/ModalPlatform";

import { ufs } from "constants/ufs";

import ri_video_chat_fill from "assets/ri_video-chat-fill.svg";
import ri_video_chat_active from "assets/ri_video-chat-fill-active.svg";

const { Option } = Select;

export function Form() {
  const [checked, setChecked] = useState(false);

  const { handleOk, handleCancel, isModalVisible, showModal } = useDisclosure();

  const { event } = useCreateEvent();

  const {
    register,
    reset,
    setError,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useFormContext();

  useEffect(() => {
    reset({
      type_event: event.local?.type_event ?? 1,
      streaming_id: !!event.local?.streaming_id
        ? Number(event.local?.streaming_id)
        : null,
      streaming_url: event.local?.streaming_url,
      streaming_name: event.local?.streaming_name,
      address_name: event.local?.address_name,
      address_zipcode: event.local?.address_zipcode,
      address_street: event.local?.address_street,
      address_complement: event.local?.address_complement,
      address_country: event.local?.address_country,
      address_district: event.local?.address_district,
      address_city: event.local?.address_city,
      address_state: event.local?.address_state,
      address_number: event.local?.address_number,
    });
  }, [event.local, reset]);

  const typeEvent = watch("type_event");
  const streamingId = watch("streaming_id");
  const streamingName = watch("streaming_name");
  const addressZipCode = watch("address_zipcode");

  const handleSearchAddress = useCallback(async () => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        try {
          const response = await axios.get(
            `https://viacep.com.br/ws/${addressZipCode}/json/`
          );

          const { logradouro, bairro, localidade, uf } = response.data;

          if (bairro === undefined) {
            setError("address_zipcode", {
              type: "manual",
              message: "Não foi possivel encontrar o Cep",
            });
            return;
          }
          setValue("address_street", logradouro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_district", bairro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_city", localidade, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_state", uf, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } catch (err) {
          setError("address_zipcode", {
            type: "required",
            message: "Não foi possivel encontrar o Cep",
          });
        }
      }
    }
  }, [addressZipCode, setError, setValue]);

  useEffect(() => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        handleSearchAddress();
      }
    }
  }, [addressZipCode, handleSearchAddress]);

  const handleSelectedPlatform = useCallback(
    (platform: Platform) => {
      setValue("streaming_id", platform.id, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue("streaming_name", platform.name, {
        shouldValidate: true,
        shouldDirty: true,
      });

      handleOk();
    },
    [handleOk, setValue]
  );

  return (
    <Container>
      {typeEvent === 1 || typeEvent === 3 ? (
        <LabelTitle>Informe o local onde o evento acontecerá</LabelTitle>
      ) : null}

      {typeEvent === 1 || typeEvent === 3 ? (
        <>
          <InputForm
            isFull
            {...register("address_name")}
            placeholder="Insira o nome do local..."
            error={errors.address_name}
            icon={<RiInformationLine size={17} color={Colors.secondary40} />}
            label="Título do endereço"
          />

          <GridInputs>
            <InputForm
              isFull
              {...register("address_zipcode")}
              placeholder="Insira o CEP do local..."
              mask="cep"
              error={errors.address_zipcode}
              icon={<RiMapPinAddLine size={17} color={Colors.secondary40} />}
              label="CEP"
            />

            <InputForm
              isFull
              {...register("address_street")}
              placeholder="Insira o endereço do local..."
              error={errors.address_street}
              icon={<RiCommunityLine size={17} color={Colors.secondary40} />}
              label="Endereço"
            />
          </GridInputs>

          <GridInputs>
            <InputForm
              isFull
              {...register("address_number")}
              placeholder="Insira o número do local..."
              error={errors.address_number}
              icon={<RiCommunityLine size={17} color={Colors.secondary40} />}
              label="Número"
            />

            <InputForm
              isFull
              {...register("address_complement")}
              placeholder="Insira o complemento do endereço..."
              error={errors.address_complement}
              icon={<RiCommunityLine size={17} color={Colors.secondary40} />}
              label="Complemento"
            />
          </GridInputs>

          <GridInputs>
            <InputForm
              isFull
              {...register("address_district")}
              placeholder="Insira o bairro do local..."
              error={errors.address_district}
              icon={<RiCommunityLine size={17} color={Colors.secondary40} />}
              label="Bairro"
            />

            <InputForm
              isFull
              {...register("address_city")}
              placeholder="Insira a cidade do endereço..."
              error={errors.address_city}
              icon={<RiCommunityLine size={17} color={Colors.secondary40} />}
              label="Cidade"
            />
          </GridInputs>

          <ContainerSelect>
            <LabelSelect>
              <h4>Estado</h4>
            </LabelSelect>

            <Controller
              control={control}
              name="address_state"
              rules={{ required: "Salutation is required" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <BorderSelect>
                  <StyledSelect
                    size="large"
                    labelInValue
                    bordered={false}
                    placeholder="Escolha o estado"
                    value={value}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onChange={(e: any) => {
                      onChange(e.value);
                    }}
                  >
                    {ufs.map((uf) => {
                      return (
                        <Option value={uf.sigla} key={uf.id}>
                          {uf.nome}
                        </Option>
                      );
                    })}
                  </StyledSelect>
                </BorderSelect>
              )}
            />
          </ContainerSelect>

          <Divider />

          <ContainerSwitch>
            <Switch
              checked={checked}
              onChange={(e) => {
                setChecked(e);
              }}
            />

            <LabelTitle>Mostrar endereço no Google Maps</LabelTitle>
          </ContainerSwitch>

          <ContainerImage>
            <iframe
              width="100%"
              height="152px"
              id="gmap_canvas"
              src={`https://maps.google.com/maps?q=${addressZipCode}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
              style={{
                border: 0,
                borderRadius: "10px",
                filter: "drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.08))",
                overflow: "hidden",
              }}
              // loading="lazy"
              allowFullScreen={true}
              title="Localização do evento"
            ></iframe>
          </ContainerImage>

          <Divider />
        </>
      ) : null}

      {typeEvent === 2 || typeEvent === 3 ? (
        <>
          <LabelTitle>Selecione o formato de transmissão do evento</LabelTitle>

          <CardPlataform onClick={showModal} active={!!streamingId}>
            <div className="container-image">
              {!!streamingId ? (
                <img src={ri_video_chat_active} alt="" />
              ) : (
                <img src={ri_video_chat_fill} alt="" />
              )}
            </div>

            <div className="content">
              <div className="header">
                <h4>Plataforma</h4>

                <h5>
                  Selecione outra plataforma de sua escolha para realizar seu
                  evento online.
                </h5>
              </div>

              <div className="footer">
                <h2>PLATAFORMA SELECIONADA</h2>

                {!streamingId ? <h3>Nenhuma plataforma selecionada</h3> : null}

                {streamingName ? <h3>{streamingName}</h3> : null}
              </div>
            </div>
          </CardPlataform>

          {streamingId ? <Divider /> : null}

          {streamingId ? (
            <>
              {streamingId === 10 ? (
                <LabelTitle>
                  Insira o nome da plataforma e o link da sua transmissão
                </LabelTitle>
              ) : null}

              {streamingId !== 10 ? (
                <LabelTitle>Insira o link da sua transmissão</LabelTitle>
              ) : null}
            </>
          ) : null}

          {streamingId !== 10 ? (
            <>
              <InputForm
                isFull
                {...register("streaming_url")}
                error={errors.streaming_url}
                placeholder="Insira a URL da transmissão..."
                icon={<AiOutlineLink size={17} color={Colors.secondary40} />}
                label="URL da transmissão"
              />
            </>
          ) : null}

          {streamingId === 10 ? (
            <>
              <InputForm
                isFull
                {...register("streaming_name")}
                error={errors.streaming_name}
                placeholder="Insira o nome da plataforma..."
                icon={<BiVideoPlus size={17} color={Colors.secondary40} />}
                label="Nome da plataforma"
              />

              <InputForm
                isFull
                {...register("streaming_url")}
                placeholder="Insira a URL da transmissão..."
                icon={<AiOutlineLink size={17} color={Colors.secondary40} />}
                label="URL da transmissão"
              />
            </>
          ) : null}

          <ModalPlatform
            isModalVisible={isModalVisible}
            handleCancel={handleCancel}
            handleOk={handleOk}
            handleSelectedPlatform={handleSelectedPlatform}
          />
        </>
      ) : null}
    </Container>
  );
}
