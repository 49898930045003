import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-top: 1.87rem;
`;

export const Steps = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ResponsiveSteps = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;

  @media (min-width: 1024px) {
    display: none;
  }
`;

interface StepProps {
  active?: boolean;
  isFirst?: boolean;
}

export const Step = styled.li<StepProps>`
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  padding-left: 0px;
  margin: 0;

  h2 {
    color: ${Colors.black100};
    text-align: ${(props) => (props.isFirst ? "left" : "center")};
    font-size: 0.84rem;
    font-family: Poppins;
    font-weight: ${(props) => (props.active ? "600" : "none")};
    line-height: 160%;
    margin-top: 0.93rem;

    @media (max-width: 1024px) {
      font-size: 12px;
    }
  }
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface CircleProps {
  current?: boolean;
  completed?: boolean;
}

export const Circle = styled.div<CircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2.25rem;
  min-height: 2.25rem;
  border-radius: 50%;
  background-color: ${(props) =>
    props.completed ? Colors.primary100 : Colors.white};
  border: 4px solid
    ${(props) =>
      props.current || props.completed ? Colors.primary100 : Colors.black10};
`;

interface TagProps {
  completed?: boolean;
}

export const Tag = styled.div<TagProps>`
  background-color: ${(props) =>
    props.completed ? Colors.primary100 : Colors.black10};
  width: 100%;
  height: 4px;
`;

export const TagTransparent = styled.div`
  background-color: transparent;
  width: 45%;
  height: 4px;
`;

export const TagTransparentFirst = styled.div`
  background-color: transparent;
  width: 5%;
  height: 4px;
`;
