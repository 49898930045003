import {
  RiAccountBoxLine,
  RiBarChartBoxLine,
  RiCalendarCheckFill,
  RiCamera2Line,
  RiCommunityLine,
  RiCoupon2Line,
  RiLoginBoxLine,
  RiSettings4Line,
  RiSurveyLine,
} from "react-icons/ri";

export const USER_PROFILE_NAV = [
  {
    title: "Meus ingressos",
    icon: <RiCoupon2Line width={22} height={22} />,
    path: "/profile/my-ticket",
  },
  {
    title: "Minhas compras",
    icon: <RiSurveyLine width={22} height={22} />,
    path: "/profile/my-shopping",
  },
  {
    title: "Dados pessoais",
    icon: <RiAccountBoxLine width={22} height={22} />,
    path: "/profile/personal-data",
  },
  {
    title: "Dados de acesso",
    icon: <RiLoginBoxLine width={22} height={22} />,
    path: "/profile/access-data",
  },
  {
    title: "Meus endereços",
    icon: <RiCommunityLine width={22} height={22} />,
    path: "/profile/address",
  },
];

interface ORGANIZER_PROFILE_NAV_PROPS {
  id?: string;
}

export const ORGANIZER_PROFILE_NAV = ({
  id = "23",
}: ORGANIZER_PROFILE_NAV_PROPS) => [
  {
    title: "Dados do organizador",
    icon: <RiAccountBoxLine />,
    path: `/organizer/profile/organizer-data/${id}`,
  },
  {
    title: "Documentos do organizador",
    icon: <RiSettings4Line />,
    path: `/organizer/profile/document/${id}`,
  },
  {
    title: "Dados bancários",
    icon: <RiBarChartBoxLine />,
    path: `/organizer/profile/financial/${id}`,
  },
  {
    title: "Eventos",
    icon: <RiCalendarCheckFill />,
    path: `/my-event`,
  },
  {
    title: "Check-out",
    icon: <RiCamera2Line />,
    path: `/organizer/ticket-check`,
  },
];
