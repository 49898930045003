export type KeySort =
  | "organizer"
  | "data"
  | "events"
  | "price"
  | "payment"
  | "tickets"
  | "payment_status";

export type SortFilterItem = {
  title: string;
  sortKey: KeySort;
};

export const sortingFilterFinancialGeneral: SortFilterItem[] = [
  { title: "Organizador", sortKey: "organizer" },
  { title: "Evento", sortKey: "events" },
  { title: "Ingresso", sortKey: "tickets" },
  { title: "Data", sortKey: "data" },
  { title: "Forma de pagamento", sortKey: "payment" },
];

export const sortingFilterTicketOffice: SortFilterItem[] = [
  { title: "Data", sortKey: "data" },
  { title: "Evento", sortKey: "events" },
  { title: "Ingresso", sortKey: "tickets" },
];

export const sortingFilterFinancialTransactions: SortFilterItem[] = [
  { title: "Organizador", sortKey: "organizer" },
  { title: "Evento", sortKey: "events" },
  { title: "Ingresso", sortKey: "tickets" },
  { title: "Data", sortKey: "data" },
  { title: "Forma de pagamento", sortKey: "payment" },
  { title: "Status do pagamento", sortKey: "payment_status" },
];
