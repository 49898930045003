import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
  height: 100%;
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.34rem 6.37rem 2.34rem 6.37rem;
  border: 1px solid ${Colors.black10};
  border-radius: 0.93rem;
  margin-top: 2.34rem;

  @media (max-width: 1024px) {
    padding: 20px;
    border-radius: 14px;
    border: 1px solid ${Colors.border20};
    background: ${Colors.white};
    box-shadow: 0px 18px 33px 0px rgba(108, 93, 211, 0.1);
    margin-top: 39px;
  }
`;

export const Title = styled.h2`
  color: ${Colors.black100};
  font-family: Poppins;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;

  @media (max-width: 1024px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 700;
    line-height: 25px;
  }
`;

export const ListBenefits = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 2.1rem;

  gap: 1.35rem;

  @media (max-width: 1024px) {
    margin-top: 20px;
    gap: 18px;
  }
`;

export const Retangle = styled.div`
  width: 2.1rem;
  height: 2.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.56rem;
  background: ${Colors.primary10};

  @media (max-width: 1024px) {
    min-width: 2.1rem;
    min-height: 2.1rem;
    border-radius: 50%;
  }

  img {
    width: 1.4rem;
    height: 1.4rem;

    @media (max-width: 1024px) {
      width: 16px;
      height: 16px;
    }
  }
`;

export const RowBenefit = styled.li`
  list-style: none;
  width: 100%;

  display: flex;

  div.content {
    margin-left: 0.56rem;

    h3 {
      color: ${Colors.secondary100};
      font-family: Poppins;
      font-size: 1.03rem;
      font-style: normal;
      font-weight: 700;

      @media (max-width: 1024px) {
        color: ${Colors.secondary100};
        font-family: Poppins;
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    h4 {
      color: ${Colors.black100};
      text-align: justify;
      font-family: Poppins;
      font-size: 0.93rem;
      font-style: normal;
      font-weight: 400;
      margin-top: 0.46rem;

      @media (max-width: 1024px) {
        color: ${Colors.gray10};
        font-family: Poppins;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`;
