import {
  Container,
  ContainerItems,
  CardProfile,
  ContainerLink,
  TitleLink,
  ButtonLink,
} from "./styles";

import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { SidebarProfile } from "components/SidebarProfile";
import { Title } from "components/Title";
import { FRONTLINE_URL } from "constants/environmentVariables";
import { useSecureCookieStorage } from "hooks/useSecureCookieStorage";

import { GridSidebar } from "styles/pages/Sidebar";

const metaTags = {
  title: `Dados de acesso - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

export default function AccessData() {
  const secureCookieStorage = useSecureCookieStorage();

  const redirectToReset = () => {
    secureCookieStorage.removeItem("@ventX-user_token");
    secureCookieStorage.removeItem("userId");
    secureCookieStorage.removeItem("hasOrganizer");

    window.location.href = `${FRONTLINE_URL}/forgot-password`;
  };

  return (
    <>
      <Header isProfile metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <ListBreadcrumb
            data={[
              { name: "Home", link: "/" },
              { name: "Perfil", link: "/" },
            ]}
          />

          <Title />

          <GridSidebar>
            <SidebarProfile />

            <CardProfile autoComplete="off">
              <div className="content-profile">
                <h2 className="title">Dados de acesso</h2>

                <ContainerLink>
                  <TitleLink>
                    Clique aqui para solicitar um link com codigo para troca de
                    senha.
                  </TitleLink>

                  <ButtonLink onClick={redirectToReset}>
                    <span>Enviar link</span>
                  </ButtonLink>
                </ContainerLink>
              </div>
            </CardProfile>
          </GridSidebar>
        </ContainerItems>
      </Container>

      <Footer />
    </>
  );
}
