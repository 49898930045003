import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { FaChevronRight } from "react-icons/fa";

import { Colors } from "constants/colors";

import { useAuthMyEvent, useMyEventDetail } from "services/Hook/Event";

import {
  CardOptionEdit,
  Container,
  ContainerItems,
  ContentContainer,
  EditingBoard,
  EditingBoardResponsive,
  ListOptionsEditEvent,
} from "./styles";

import { PlaceInfo } from "./Tabs/PlaceInfo";
import { GeneralDefinitions } from "./Tabs/GeneralDefinitions";
import { AboutOrganizer } from "./Tabs/AboutOrganizer";
import { Tickets } from "./Tabs/Tickets";
import { EventDateTime } from "./Tabs/EventDateTime";
import { BasicInfo } from "./Tabs/BasicInfo";

import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { Footer } from "components/Footer";
import Sidebar from "components/Sidebar";

const steps = [
  "Informações básicas",
  "Data do evento",
  "Local do evento",
  "Descrição e definições gerais",
  "Sobre o organizador",
  "Ingressos",
];

function Edit() {
  const { nickname } = useParams();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const activeStep = searchParams.get("activeStep");

  const { data } = useAuthMyEvent(String(nickname) ?? "");

  const options = [
    { id: 1, title: "1. Informações básicas" },
    { id: 2, title: "2. Data do evento" },
    { id: 3, title: "3. Local do evento" },
    { id: 4, title: "4. Descrição e definições gerais" },
    { id: 5, title: "5. Sobre o organizador" },
    { id: 6, title: "6. Ingressos" },
  ];

  const handleNavigateForward = (id: number) => {
    navigate({ search: `?activeStep=${id}` });
  };

  const handleBackToOptionsEdit = () => {
    searchParams.delete("activeStep");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  };

  function pickStep(activeStep: string | null) {
    switch (activeStep) {
      case "2":
        return <EventDateTime activeStep={parseInt(activeStep)} data={data} />;
      case "3":
        return <PlaceInfo activeStep={parseInt(activeStep)} data={data} />;
      case "4":
        return (
          <GeneralDefinitions activeStep={parseInt(activeStep)} data={data} />
        );
      case "5":
        return <AboutOrganizer activeStep={parseInt(activeStep)} data={data} />;
      case "6":
        return <Tickets activeStep={parseInt(activeStep)} data={data} />;
      default:
        return (
          <BasicInfo
            activeStep={activeStep ? parseInt(activeStep) : 1}
            data={data}
          />
        );
    }
  }

  return (
    <>
      <Header
        isProfile
        customBack={!!activeStep ? () => handleBackToOptionsEdit() : () => {}}
      />
      <Container>
        <ContainerItems>
          <>
            <div className="bread">
              <ListBreadcrumb
                data={[
                  { name: "Home", link: "/" },

                  { name: "Meus eventos", link: "/my-event" },

                  { name: "Editar evento", link: "/" },
                ]}
              />
            </div>

            {!activeStep ? (
              <div className="main-header">
                <h1>Editar evento</h1>
              </div>
            ) : (
              <div className="main-header">
                <h1 className="responsive">Editar evento</h1>
              </div>
            )}

            <ContentContainer>
              <Sidebar
                steps={steps}
                activeStep={activeStep ? parseInt(activeStep) : 1}
                key="sidebar-edit-event"
              />

              {!activeStep ? (
                <>
                  <ListOptionsEditEvent>
                    <h3 className="title">categorias</h3>

                    {options.map((option) => (
                      <CardOptionEdit
                        onClick={() => handleNavigateForward(option.id)}
                      >
                        <span>{option.title}</span>

                        <FaChevronRight color={Colors.secondary40} size={10} />
                      </CardOptionEdit>
                    ))}
                  </ListOptionsEditEvent>

                  <EditingBoardResponsive>
                    {pickStep(activeStep)}
                  </EditingBoardResponsive>
                </>
              ) : null}

              {activeStep ? (
                <EditingBoard>{pickStep(activeStep)}</EditingBoard>
              ) : null}
            </ContentContainer>
          </>
        </ContainerItems>
      </Container>

      <Footer />
    </>
  );
}

export default Edit;
