import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 3.75rem;

  @media (max-width: 670px) {
    margin-top: 1.5rem;
  }
`;

export const Title = styled.h3`
  color: ${Colors.black30};
  font-family: Open Sans;
  font-size: 1.21rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;

  @media (max-width: 670px) {
    color: ${Colors.gray20};
    font-family: Open Sans;
    font-size: 0.62rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
`;

export const Card = styled.div`
  width: 100%;
  height: 5.62rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem;

  margin-top: 1.4rem;
  border-radius: 0.93rem;
  border: 1px solid ${Colors.black10};
  background: ${Colors.white};
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);

  @media (max-width: 670px) {
    height: 2.37rem;
    margin-top: 0.62rem;
    border-radius: 0.5rem;
    padding: 1.12rem 0.62rem;

    svg {
      width: 0.98rem;
      height: 0.98rem;
    }
  }

  div.content {
    display: flex;
    align-items: center;

    h5 {
      color: ${Colors.secondary40};
      font-family: Poppins;
      font-size: 1.21rem;
      font-style: normal;
      font-weight: 400;
      margin-left: 0.93rem;
      margin-bottom: 0px;

      @media (max-width: 670px) {
        font-family: Poppins;
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        margin-left: 0.75rem;
      }
    }
  }
`;

export const CircleIcon = styled.div`
  width: 2.81rem;
  height: 2.81rem;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.93rem;
  background: ${Colors.secondary10};

  @media (max-width: 670px) {
    background: ${Colors.white};
    width: 1.12rem;
    height: 1.12rem;

    img {
      width: 1.12rem;
      height: 1.12rem;
    }
  }
`;
