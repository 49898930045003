import { IdentificationResponseOrganizer } from "types";

export enum ActionTypes {
  GO_TO_NEXT_STEP = "GO_TO_NEXT_STEP",
  GO_TO_BACK_STEP = "GO_TO_BACK_STEP",
  ADD_IDENTIFICATION = "ADD_IDENTIFICATION",
  ADD_FRONT_IDENTIFY = "ADD_FRONT_IDENTIFY",
  ADD_BACK_IDENTIFY = "ADD_BACK_IDENTIFY",
  PROOF_OF_REGISTRATION_STATUS = "PROOF_OF_REGISTRATION_STATUS",
  ADD_PROOF_OF_ADDRESS = "ADD_PROOF_OF_ADDRESS",
  ADD_SELFIE_HOLDING_DOCUMENT = "ADD_SELFIE_HOLDING_DOCUMENT",
  RESET_STORE = "RESET_STORE",
}

export function addIdentification(
  identification: IdentificationResponseOrganizer
) {
  return {
    type: ActionTypes.ADD_IDENTIFICATION,
    payload: {
      identification,
    },
  };
}

export function addFrontIdentify(document: string) {
  return {
    type: ActionTypes.ADD_FRONT_IDENTIFY,
    payload: {
      document,
    },
  };
}

export function addBackIdentify(document: string) {
  return {
    type: ActionTypes.ADD_BACK_IDENTIFY,
    payload: {
      document,
    },
  };
}

export function addProofOfAddress(document: string) {
  return {
    type: ActionTypes.ADD_PROOF_OF_ADDRESS,
    payload: {
      document,
    },
  };
}

export function addSelfieHoldingDocument(document: string) {
  return {
    type: ActionTypes.ADD_SELFIE_HOLDING_DOCUMENT,
    payload: {
      document,
    },
  };
}

export function toBack() {
  return {
    type: ActionTypes.GO_TO_BACK_STEP,
  };
}

export function toNext() {
  return {
    type: ActionTypes.GO_TO_NEXT_STEP,
  };
}

export function resetStore() {
  return {
    type: ActionTypes.RESET_STORE,
  };
}
