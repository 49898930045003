import styled from "styled-components";
import { Modal } from "antd";
import { Colors } from "constants/colors";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const ContainerModal = styled.div`
  margin-top: 53px;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;

  div.texts-confirm {
    margin-top: 29px;

    h3 {
      font-family: "Cairo";
      font-style: normal;
      font-weight: 700;

      font-size: 1.5rem;
      margin-bottom: 0px;
      text-align: center;

      color: ${Colors.secondary100};
    }

    p {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 0.93rem;

      text-align: center;
      color: ${Colors.black90};
      margin-top: 0.65rem;
    }
  }

  div.buttons {
    margin-top: 17.5px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

export const ButtonConfirm = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;

  width: 12.32rem;
  height: 2.81rem;

  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  border-radius: 14px;

  span {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 700;

    font-size: 0.93rem;
    text-align: center;

    color: ${Colors.white};
  }
`;
