import { createPortal } from "react-dom";

import { useCallback, useEffect, useRef, useState } from "react";

import {
  ActionsContainer,
  CheckinButton,
  CheckoutButton,
  Container,
  ContainerCamera,
  ContainerItems,
  ContainerSection,
  FlexColumn,
  FooterSection,
  HeaderCheck,
  RowInformation,
  ScanButton,
  TitleCheck,
  TitleInformation,
} from "./styles";

import QrScanner from "qr-scanner";

import QrFrame from "assets/qr-frame.svg";
import { getUserTicket, makeCheckin, makeCheckout } from "./services";
import FirstStep from "./components/FirstStep";
import { UserTicketResponse } from "./services/types";
import { RiArrowLeftLine, RiQrCodeLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import {
  errorNotification,
  successNotification,
} from "components/Notification";
import axios from "axios";
import { TicketForPrint } from "components/TicketForPrint";
import { useReactToPrint } from "react-to-print";
import { sleep } from "utils/sleep";
import { useAuth } from "contexts/AuthContext";

function CheckInOut() {
  const { organizerId } = useAuth();
  const scanner = useRef<QrScanner>();
  const videoEl = useRef<HTMLVideoElement>(null);
  const qrBoxEl = useRef<HTMLDivElement>(null);
  const [qrOn, setQrOn] = useState<boolean>(true);

  const [scannedResult, setScannedResult] = useState<string | undefined>("");
  const [userTicket, setUserTicket] = useState<
    UserTicketResponse | undefined
  >();
  const [fetchingAction, setFetchingAction] = useState(false);

  const componentRef = useRef(null);

  const onScanSuccess = (result: QrScanner.ScanResult) => {
    const data = result?.data;

    if (!data || data.length !== 16) {
      return;
    }

    setScannedResult(data);
    // scanner.current?.stop();
  };

  const handleScanAgain = useCallback(() => {
    setScannedResult(undefined);
    setUserTicket(undefined);

    if (!videoEl.current) {
      return;
    }

    scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
      onDecodeError: () => {},
      preferredCamera: "environment",
      highlightScanRegion: true,
      highlightCodeOutline: true,
      overlay: qrBoxEl?.current || undefined,
    });

    scanner?.current
      ?.start()
      .then(() => setQrOn(true))
      .catch((err) => {
        if (err) setQrOn(false);
      });
  }, []);

  const getTicketCode = useCallback(async () => {
    try {
      if (!scannedResult) return;

      const response = await getUserTicket(scannedResult);

      setUserTicket(response);
    } catch (error) {
      let description = undefined;

      if (axios.isAxiosError(error)) {
        description =
          error?.response?.data?.message ?? "Tente novamente mais tarde";
      }

      errorNotification("Ocorreu um erro ao realizar o check-in.", description);

      handleScanAgain();
    }
  }, [scannedResult, handleScanAgain]);

  const handleMakeCheckin = useCallback(async () => {
    if (!userTicket?._id) return;

    setFetchingAction(true);

    try {
      await makeCheckin(userTicket?._id);

      successNotification("Check-in concluído com sucesso.");

      getTicketCode();
    } catch (error) {
      let description = undefined;

      if (axios.isAxiosError(error)) {
        description =
          error?.response?.data?.message ?? "Tente novamente mais tarde";
      }

      errorNotification("Ocorreu um erro ao realizar o check-in.", description);

      handleScanAgain();
    } finally {
      setFetchingAction(false);
      setFetchingAction(false);
    }
  }, [userTicket, getTicketCode, handleScanAgain]);

  const handleMakeCheckout = useCallback(async () => {
    if (!userTicket?._id) return;
    if (!userTicket?._id) return;

    setFetchingAction(true);
    setFetchingAction(true);

    try {
      await makeCheckout(userTicket?._id);
      await makeCheckout(userTicket?._id);

      successNotification("Check-out concluído com sucesso.");

      getTicketCode();
    } catch (error) {
      let description = undefined;

      if (axios.isAxiosError(error)) {
        description =
          error?.response?.data?.message ?? "Tente novamente mais tarde";
      }

      errorNotification(
        "Ocorreu um erro ao realizar o check-out.",
        description
      );

      handleScanAgain();
    } finally {
      setFetchingAction(false);
      setFetchingAction(false);
    }
  }, [userTicket, getTicketCode, handleScanAgain]);

  useEffect(() => {
    getTicketCode();
  }, [getTicketCode]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintOutTicket = useCallback(async () => {
    await sleep(1000);

    handlePrint();
  }, [handlePrint]);

  useEffect(() => {
    if (videoEl?.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: () => {},
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl?.current || undefined,
      });

      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn) alert("Habilite a câmera do seu navegador.");
  }, [qrOn]);

  // TODO: Tentar escanear novamente sem precisar recarregar a página
  const handleRefreshPage = () => {
    window.location.reload();
  };

  return (
    <Container>
      <ContainerItems>
        <HeaderCheck>
          <Link
            style={{ position: "absolute", left: 33, top: 12 }}
            to={`/organizer/profile/main/${organizerId}`}
          >
            <RiArrowLeftLine color="#FFF" size={18} />
          </Link>

          <TitleCheck style={{ marginLeft: "auto", marginRight: "auto" }}>
            Ticket Check-in
          </TitleCheck>
        </HeaderCheck>

        <FlexColumn>
          {!scannedResult ? (
            <ContainerCamera>
              <div className="qr-reader">
                {/* QR */}
                <video ref={videoEl}></video>
                <div ref={qrBoxEl} className="qr-box">
                  <img
                    style={{ visibility: "hidden" }}
                    src={QrFrame}
                    alt="Qr Frame"
                    width={256}
                    height={256}
                    className="qr-frame"
                  />
                </div>
              </div>
            </ContainerCamera>
          ) : null}

          <FooterSection>
            {userTicket ? (
              <ContainerSection>
                <FirstStep
                  holderName={userTicket?.holder_name}
                  holderDocument={userTicket?.holder_cpf ?? "-"}
                  ticketId={userTicket?._id}
                  ticketName={userTicket?.event_ticket_id?.name}
                  eventName={userTicket?.event?.name}
                  checkInAt={
                    userTicket?.checkin_at
                      ? new Date(userTicket.checkin_at)
                      : undefined
                  }
                  checkoutAt={
                    userTicket?.checkout_at
                      ? new Date(userTicket.checkout_at)
                      : undefined
                  }
                />

                <ActionsContainer>
                  {!userTicket?.checkin ? (
                    <CheckinButton
                      onClick={handleMakeCheckin}
                      disabled={fetchingAction}
                    >
                      Realizar check-in
                    </CheckinButton>
                  ) : null}
                  {!userTicket?.checkout ? (
                    <CheckinButton
                      onClick={handleMakeCheckout}
                      disabled={fetchingAction}
                    >
                      Realizar check-out
                    </CheckinButton>
                  ) : null}

                  {userTicket ? (
                    <CheckoutButton onClick={handlePrintOutTicket}>
                      Imprimir Ingresso
                    </CheckoutButton>
                  ) : null}

                  <ScanButton onClick={handleRefreshPage}>
                    <RiQrCodeLine color="#D40050" size={13} />
                    Escanear novamente
                  </ScanButton>
                </ActionsContainer>
              </ContainerSection>
            ) : (
              <RowInformation>
                <TitleInformation
                  style={{ textAlign: "center", width: "100%" }}
                >
                  Aponte para o QR Code
                </TitleInformation>
              </RowInformation>
            )}
          </FooterSection>
        </FlexColumn>
      </ContainerItems>

      {createPortal(
        <>
          {userTicket ? (
            <TicketForPrint
              ref={componentRef}
              selectedTicket={{
                event: userTicket?.event,
                event_ticket_id: userTicket?.event_ticket_id,
                holder_name: userTicket?.holder_name,
                purchase_id: userTicket?.purchase_id,
              }}
            />
          ) : null}
        </>,
        document.body
      )}
    </Container>
  );
}

export default CheckInOut;
