import { Button } from "antd";
import styled from "styled-components";
import { Colors } from "constants/colors";
import { sizeConversor } from "utils/conversors";

export const PrimaryButton = styled(Button)`
  width: 150px;
  height: 38px;
  border-radius: 8px;
  background-color: ${Colors.primary100};
  box-shadow: 0px 15px 30px rgba(212, 0, 80, 0.15);
  color: #fff;
  display: flex;
  align-items: center;
  font-size: ${sizeConversor(20)};
  justify-content: center;
  padding: 0 20px;
  border: 0;
  :hover,
  :active,
  :focus {
    background-color: ${Colors.primary100};
    color: #fff;
    border-color: ${Colors.tertiary100};
  }
`;
export const SecondaryButton = styled(Button)`
  width: 150px;
  height: 38px;
  border-radius: 8px;
  background-color: #fbe5ed;
  color: ${Colors.primary100};
  display: flex;
  font-size: ${sizeConversor(20)};
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  border: 0;
  :hover,
  :active,
  :focus {
    background-color: #fbe5ed;
    color: ${Colors.primary100};
    border-color: ${Colors.tertiary100};
  }
  :disabled {
    background-color: #e7e7ea;
    color: #b8b9c0;
  }
`;

export const GrayButton = styled(Button)`
  width: ${sizeConversor(153)};
  height: 38px;
  border-radius: 8px;
  background-color: ${Colors.white20};
  color: ${Colors.red10};
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin-top: 20px;
  font-size: ${sizeConversor(20)};
  border: 0;
  :hover,
  :active,
  :focus {
    background-color: #fbe5ed;
    color: ${Colors.red10};
    border-color: ${Colors.red0};
  }
  :disabled {
    background-color: #e7e7ea;
    color: #b8b9c0;
  }
`;
