import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
  height: 100%;
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.34rem 6.37rem 2.34rem 6.37rem;
  border: 1px solid ${Colors.black10};
  border-radius: 0.93rem;
  margin-top: 2.34rem;

  @media (max-width: 1024px) {
    padding: 0px 0px;
    border: 0;
  }
`;

export const Title = styled.h2`
  color: ${Colors.secondary100};
  font-size: 1.21rem;
  font-family: Poppins;
  font-weight: 600;
  line-height: 160%;

  @media (max-width: 670px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 700;
    line-height: 25px;
  }
`;

export const SubTitle = styled.h3`
  color: ${Colors.black70};
  font-size: 1rem;
  font-family: Open Sans;
  line-height: 160%;
  margin-top: 0.93rem;

  @media (max-width: 670px) {
    font-size: 12px;
    font-family: Open Sans;
    margin-top: 8px;
  }
`;

export const LabelTitle = styled.h4`
  color: ${Colors.secondary100};
  font-size: 1.03rem;
  font-family: Poppins;
  font-weight: 500;
  line-height: 160%;

  @media (max-width: 670px) {
    color: ${Colors.black100};
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
  }
`;

export const SubLabelTitle = styled.h4`
  color: ${Colors.black60};
  font-size: 0.75rem;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 160%;

  strong {
    font-size: 0.75rem;
    font-family: Open Sans;
    font-style: italic;
    color: ${Colors.black70};

    @media (max-width: 670px) {
      font-size: 14px;
      font-family: Cairo;
      font-weight: 600;
    }
  }

  @media (max-width: 670px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
  }
`;

export const Divider = styled.div`
  margin-top: 1.87rem;
  padding-bottom: 1.87rem;
  height: 1px;
  border-top: 1px solid ${Colors.secondary10};
`;

export const ButtonAddLink = styled.button`
  border: 0;
  display: flex;
  width: 24.98rem;
  height: 2.81rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background: ${Colors.primary10};
  margin-top: 1.68rem;

  span {
    color: ${Colors.primary100};
    font-size: 0.93rem;
    font-family: Cairo;
    font-weight: 700;
    line-height: 160%;
    margin-left: 0.46rem;

    @media (max-width: 670px) {
      font-size: 12px;
      font-weight: 600;
      margin-left: 7px;
    }
  }

  @media (max-width: 670px) {
    width: 100%;
  }
`;

export const ContainerCategories = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 14px;
  margin-top: 1.68rem;
  margin-bottom: 16px;
`;

interface CategoryPillProps {
  active?: boolean;
  haveCursorPointer?: boolean;
}

export const CategoryPill = styled.div<CategoryPillProps>`
  cursor: ${(props) => (props.haveCursorPointer ? "pointer" : "normal")};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  gap: 10px;
  min-width: 109px;
  height: 29px;
  margin-right: 18px;

  background: ${(props) => (props.active ? Colors.primary100 : Colors.white10)};
  border-radius: 100px;

  font-family: "Open Sans";

  h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 0px;
    color: ${(props) => (props.active ? Colors.white : Colors.secondary70)};
  }
`;

export const CardContentModal = styled.div`
  width: 100%;
  height: 19.4rem;
  flex-shrink: 0;
  margin-top: 2.62rem;
  border-radius: 0.93rem;
  border: 1px solid ${Colors.black10};
  background: ${Colors.white};
  padding: 2.34rem;
`;

export const ContainerAge = styled.div`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: red;
    border-color: transparent;
    font-weight: normal;
    font-size: 12px;
    color: ${Colors.primary100};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${Colors.primary100} !important;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${Colors.primary100};
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${Colors.primary100};
  }

  .ant-radio-wrapper {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 0.93rem;
    color: ${Colors.secondary70};

    @media (max-width: 720px) {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: ${Colors.secondary70};
    }
  }
`;

export const ContainerUseTerms = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.87rem;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: red;
    border-color: transparent;
    font-weight: normal;
    font-size: 12px;
    color: ${Colors.primary100};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${Colors.primary100} !important;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${Colors.primary100};
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${Colors.primary100};
  }

  .ant-radio-wrapper {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 0.93rem;
    color: ${Colors.secondary70};

    @media (max-width: 720px) {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;

      color: ${Colors.secondary70};
    }
  }
`;

export const TitleModal = styled.h3`
  color: ${Colors.secondary100};
  font-size: 1.21rem;
  font-family: Poppins;
  font-weight: 600;
`;

export const ContainerButtonsModal = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 3.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonClose = styled.button`
  cursor: pointer;
  display: flex;
  border: 0;
  width: 18rem;
  height: 3.84rem;
  padding: 0.93rem 0px;
  justify-content: center;
  align-items: center;

  border-radius: 0.65rem;
  background: ${Colors.primary10};

  span {
    color: ${Colors.primary100};
    text-align: center;

    font-size: 1.21rem;
    font-family: Cairo;
    font-weight: 700;
    line-height: 160%;
  }
`;

export const ButtonOk = styled.button`
  cursor: pointer;
  display: flex;
  border: 0;
  width: 18rem;
  height: 3.84rem;
  padding: 0.93rem 0px;
  justify-content: center;
  align-items: center;

  border-radius: 0.65rem;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px 0px rgba(212, 0, 80, 0.15);

  span {
    color: ${Colors.white};
    text-align: center;

    font-size: 1.21rem;
    font-family: Cairo;
    font-weight: 700;
    line-height: 160%;
  }
`;
