import styled from "styled-components";

import { Colors } from "constants/colors";
import { Modal, Checkbox } from "antd";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 44px 109px 64px 109px;

  @media (max-width: 800px) {
    padding: 24px 10px 24px 10px;
  }

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 1.87rem;

    color: ${Colors.secondary100};
    font-family: "Poppins";
    margin-bottom: 0px;
    @media (max-width: 800px) {
      font-size: 20px;
    }
  }

  h2 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 1.03rem;

    margin-top: 0.93rem;

    color: ${Colors.black70};
  }
`;

export const NormalCardBorder = styled.div`
  cursor: pointer;
  width: 100%;
  height: 3.46rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  div.circle-icon {
    width: 1.59rem;
    height: 1.59rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: ${Colors.secondary10};
  }

  @media (max-width: 800px) {
    height: 47px;
  }

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  border-bottom: 1px solid ${Colors.border};

  div.check {
    display: flex;
    align-items: center;
    border-right: 1px solid ${Colors.border20};
    padding-right: 13px;
    margin-right: 13px;
  }

  h3.normal {
    font-family: "Open Sans";
    font-weight: 900;
    font-size: 0.93rem;
    color: ${Colors.secondary100};
    margin-left: 0.56rem;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }
  }
`;

export const ContainerSelect = styled.div`
  height: 296px;
  margin-top: 2.15rem;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 10px;
    margin-top: -10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${Colors.black10};
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${Colors.primary100};
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }

  > * {
    &:first-child {
      border-top: 1px solid ${Colors.border};
    }
  }
`;

export const ContainerButtonsModal = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.75rem;
`;

export const ButtonClear = styled.button`
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  width: 10.4rem;
  height: 2.81rem;
  padding: 0.65rem 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background-color: ${Colors.primary10};

  span {
    color: ${Colors.primary100};
    text-align: center;
    font-family: Cairo;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 700;
  }
`;

export const ButtonOk = styled.button`
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  width: 10.4rem;
  height: 2.81rem;
  padding: 0.65rem 2.84rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px 0px rgba(212, 0, 80, 0.15);

  span {
    color: ${Colors.white};
    text-align: center;
    font-family: Cairo;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${Colors.primary100};
    border: 1px solid ${Colors.primary100};
    border-radius: 3px;
  }
  .ant-checkbox.ant-checkbox-checked::after {
    border: 1px solid ${Colors.primary100};
    border-radius: 3px;
  }
`;
