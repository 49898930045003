import moment from "moment";
import { authClient } from "services/authClient";
import { authPayment } from "services/authPayment";
import { useFetchBasic } from "services/useFetch";
import useSWR from "swr";
import { Organizer } from "types";

export function useOrganizerByNickName<Data = any, Error = any>(id: string) {
  const url: string = `/organizers/organizer-by-nickname/${id}`;

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  return { data, error, mutate };
}

export function useAuthOrganizerById<Data = any, Error = any>(id: string) {
  const url: string = `/organizer/single/${id}`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const { data } = response;

    const dateBirthdate = moment(
      data?.organizer?.agent_birthdate,
      "YYYY-MM-DD"
    );

    const formmatedAgentBirthdate = dateBirthdate.format("DD/MM/YYYY");

    const newMapData = { ...data?.organizer, formmatedAgentBirthdate };

    const newResponse = { ...data, organizer: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}

export function useOrganizerById<Data = any, Error = any>(id: string) {
  const url: string = `/organizer/filter?id=${id}`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const { data } = response;

    const dateBirthdate = moment(
      data?.organizer?.agent_birthdate,
      "YYYY-MM-DD"
    );

    const formmatedAgentBirthdate = dateBirthdate.format("DD/MM/YYYY");

    const newMapData = { ...data?.organizer, formmatedAgentBirthdate };

    const newResponse = { ...data, organizer: newMapData };

    return newResponse;
  });

  return { data, error, mutate };
}

export interface OrganizerListResponse {
  results: Array<Organizer>;
  count: number;
}

export function useAuthOrganizer<Data = any, Error = any>() {
  const url: string = "/organizer";

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const { data } = response;

    return data.results;
  });

  return { data, error, mutate };
}

interface CreateOrganizerProps {
  credentials: any;
}

export async function CreateOrganizer({ credentials }: CreateOrganizerProps) {
  const url: string = "/organizer";

  const response = await authPayment.post(url, credentials);

  return response.data;
}

interface EditOrganizerProps {
  id: string;
  credentials: any;
}

export async function EditOrganizer({ id, credentials }: EditOrganizerProps) {
  const url: string = `/organizer/${id}`;

  const response = await authClient.patch(url, credentials);

  return response.data;
}
