import { Colors } from "constants/colors";
import styled, { css } from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  background-color: ${Colors.white10};
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .ant-spin-dot-item {
    background-color: ${Colors.primary100};
  }
`;

export const HeaderCheck = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  background-color: ${Colors.secondary100};
  /* padding-left: 33px; */
`;

export const TitleCheck = styled.h2`
  font-family: Cairo;
  font-size: 18px;
  font-weight: 700;
  text-align: left;
  color: ${Colors.white};
  margin-bottom: 0px;
`;

export const FlexColumn = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ContainerCamera = styled.div`
  /* height: 500px; */

  background-color: #f0f0f0;

  video {
    height: auto;
    width: 100%;
  }

  .scan-region-highlight {
    border: 1.5px solid #ffffff !important;
  }
`;

export const FooterSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 12px 28px 34px 28px;
`;

export const LastReadText = styled.p`
  font-family: Open Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 13.62px;
  text-align: center;
  color: #888996;
  margin-bottom: 0;
`;

export const Informations = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const RowInformation = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TitleInformation = styled.h3`
  font-family: Cairo;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: ${Colors.secondary100};
  margin-bottom: 0px;
`;

export const ContainerInformations = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 14px 0px;
  border: 1px solid #e9e7ee;

  padding: 10px 18px;
  border-radius: 5px;
`;

export const SubTitleInformation = styled.p`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: ${Colors.black100};
`;

export const TitleSmall = styled.span`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #888996;
`;

export const ContainerData = styled.div`
  border-top: 1px solid #e9e7ee;
  margin-top: 10px;
`;

export const ColumnGap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-top: 14px;

  .checkInContainer {
    display: flex;
    align-items: center;

    strong {
      font-size: 12px;
      line-height: 15px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .checkInOutHour {
    color: #888996;
    font-size: 10px;
    line-height: 15px;
    font-family: Poppins;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  font-family: Poppins;
  font-size: 10px;
  font-weight: 400;
  text-align: left;
  color: #888996;

  strong {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    text-align: left;

    color: ${Colors.secondary100};
  }
`;

export const ContainerSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  width: 100%;
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const CheckinButton = styled.button`
  box-shadow: 0px 5px 30px 0px #d4005040;
  background: #d40050;
  padding: 9px;
  border: none;
  border-radius: 8px;

  color: #fff;
  font-family: Cairo;
  font-size: 12px;
  line-height: 22px;
  font-weight: 700;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const CheckoutButton = styled.button`
  background: transparent;
  padding: 9px;
  border: none;
  border-radius: 8px;

  border: 1px solid #d40050;

  color: #d40050;
  font-family: Cairo;
  font-size: 12px;
  line-height: 22px;
  font-weight: 700;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ScanButton = styled.button`
  background: #fbe5ed;
  padding: 9px;
  border: none;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;

  color: #d40050;
  font-family: Cairo;
  font-size: 12px;
  line-height: 22px;
  font-weight: 700;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:hover {
    cursor: pointer;
  }
`;
