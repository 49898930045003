import { useEffect, useState } from "react";

import { Colors } from "constants/colors";

import {
  Container,
  ContainerItems,
  CardProfile,
  TitleCard,
  ContainerHeader,
  P,
  Subtitle,
  ContainerDate,
  Divider,
  LabelTitle,
  ContainerLike,
  Like,
  ContainerP,
  ButtonBack,
  DividerMargin,
  ContainerButtons,
  ContainerAccounts,
  Account,
  AccountHeader,
  TitleAccountHeader,
  ContainerColumn,
  TextAccount,
  ContainerTexts,
  TextAccountStrong,
  ButtonNew,
} from "./styles";

import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";

import Datas from "constants/Datas";

import { GridSidebar } from "styles/pages/Sidebar";
import { SidebarOrganizerProfile } from "components/SidebarOrganizerProfile";
import { EmptyTicketComponent } from "components/EmptyTicketComponent";
import {
  RiArrowLeftLine,
  RiQuestionLine,
  RiThumbDownFill,
  RiThumbUpFill,
  RiTimeLine,
} from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { HeaderTitleOrganizerProfile } from "components/HeaderTitleOrganizerProfile";
import { OrganizerResponse } from "types";
import { useAuthOrganizerById } from "services/Hook/Organizer";

const metaTags = {
  title: `Dados bancários - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

export default function OrganizerFinancial() {
  const [typeRender, setTypeRender] = useState("not_empty");

  const { id } = useParams();

  const { data } = useAuthOrganizerById<OrganizerResponse>(String(id));

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [typeRender]);

  const getBankById = (bankId: string) => {
    const findBank = Datas.Banks.find((bank) => bank.value === bankId);

    if (!findBank) {
      return "Não encontrado";
    }

    return findBank?.label;
  };

  return (
    <>
      <Header isProfile metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <div className="breadcrumb">
            <ListBreadcrumb
              data={[
                { name: "Home", link: "/" },
                { name: "Perfil", link: "/" },
              ]}
            />
          </div>

          <HeaderTitleOrganizerProfile />

          <GridSidebar>
            <SidebarOrganizerProfile />

            <CardProfile>
              <ContainerHeader>
                <TitleCard>Dados bancários</TitleCard>

                <RiQuestionLine
                  size={37}
                  color={Colors.gray30}
                  onClick={() => setTypeRender("help")}
                />
              </ContainerHeader>

              {typeRender === "empty" ? (
                <EmptyTicketComponent
                  title="Você não cadastrou seus dados ainda :("
                  subtitle="Seus dados financeiros não foram cadastrados em nosso sistema até o momento, aproveite para cadastrar agora!"
                />
              ) : null}

              {typeRender === "not_empty" ? (
                <>
                  {data?.bankAccounts && data?.bankAccounts?.length > 0 ? (
                    <>
                      <ContainerAccounts>
                        {data?.bankAccounts?.map((bank) => (
                          <Account>
                            <AccountHeader>
                              <TitleAccountHeader>
                                Conta padrão
                              </TitleAccountHeader>

                              {/* <ContainerButtonsHeader>
                                <Link to="/organizer/profile/financial/23/edit/12">
                                  <ButtonHeaderAccount>
                                    <MdEdit size={18} color={Colors.success} />

                                    <span className="green">Editar </span>
                                  </ButtonHeaderAccount>
                                </Link>

                                <ButtonHeaderAccount>
                                  <FaTrash size={14} color={Colors.red10} />

                                  <span className="red">Excluir</span>
                                </ButtonHeaderAccount>
                              </ContainerButtonsHeader> */}
                            </AccountHeader>

                            <ContainerColumn>
                              <TextAccount>
                                {getBankById(bank?.bank_id)}
                              </TextAccount>

                              <ContainerTexts>
                                <TextAccountStrong>AG: </TextAccountStrong>

                                <TextAccount>{bank?.agency}</TextAccount>
                              </ContainerTexts>

                              <ContainerTexts>
                                <TextAccountStrong>CC: </TextAccountStrong>

                                <TextAccount>{bank?.account}</TextAccount>
                              </ContainerTexts>

                              <ContainerTexts>
                                <TextAccountStrong>
                                  CPF/CNPJ:{" "}
                                </TextAccountStrong>

                                <TextAccount>
                                  {data?.organizer?.document}
                                </TextAccount>
                              </ContainerTexts>
                            </ContainerColumn>
                          </Account>
                        ))}
                      </ContainerAccounts>

                      <DividerMargin />

                      <ContainerButtons>
                        <Link to={`/organizer/profile/financial/${id}/create`}>
                          <ButtonNew>
                            <AiOutlinePlus
                              size={24}
                              color={Colors.primary100}
                            />

                            <span>Adicionar nova</span>
                          </ButtonNew>
                        </Link>
                      </ContainerButtons>
                    </>
                  ) : (
                    <>
                     <EmptyTicketComponent title="Seu organizador não cadastrou dados bancarios ainda :(" />

                     <DividerMargin />

                      <ContainerButtons>
                       <Link to={`/organizer/profile/financial/${id}/create`}>
                        <ButtonNew>
                          <AiOutlinePlus
                           size={24}
                           color={Colors.primary100}
                          />
                           <span>Adicionar nova</span>
                        </ButtonNew>
                      </Link>
                    </ContainerButtons>
                    </>
                  )}
                </>
              ) : null}

              {typeRender === "help" ? (
                <>
                  <Subtitle>
                    Como receberei meu repasse referente às vendas de meu
                    evento?
                  </Subtitle>

                  <ContainerDate>
                    <RiTimeLine color={Colors.black50} width={26} height={26} />

                    <span className="date">12/03/21 - Atualizado</span>
                  </ContainerDate>

                  <ContainerP>
                    <P>Entre em contato com a Eventx !!!</P>
                  </ContainerP>

                  <Divider />

                  <LabelTitle>Este artigo foi útil?</LabelTitle>

                  <ContainerLike>
                    <Like>
                      <RiThumbUpFill size={18} color={Colors.secondary40} />
                    </Like>

                    <Like>
                      <RiThumbDownFill size={18} color={Colors.secondary40} />
                    </Like>
                  </ContainerLike>

                  <DividerMargin />

                  <ContainerButtons>
                    <ButtonBack onClick={() => setTypeRender("not_empty")}>
                      <RiArrowLeftLine size={18} color={Colors.primary100} />

                      <span>Voltar</span>
                    </ButtonBack>
                  </ContainerButtons>
                </>
              ) : null}
            </CardProfile>
          </GridSidebar>
        </ContainerItems>
      </Container>

      <Footer />
    </>
  );
}
