import styled from "styled-components";

import { Colors } from "constants/colors";

import { Drawer } from "antd";

export const DrawerStyled = styled(Drawer)`
  .ant-drawer-title {
    color: ${Colors.white};

    font-family: Cairo;
    font-size: 1.12rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
  }

  .ant-drawer-close {
    position: absolute;
    top: 20px;
    right: 0;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;

  h1 {
    width: 100%;
    color: ${Colors.white};
    font-family: Cairo;
    font-size: 1.12rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0px;
  }
`;
