import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: 38px;

  @media (min-width: 800px) {
    display: none;
  }

  h3 {
    font-weight: 600;
    font-size: 11px;
    line-height: 21px;
    color: ${Colors.black50};
  }
`;

export const ButtonMoreItems = styled.button`
  width: 100%;
  height: 38px;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 34px;

  :disabled {
    opacity: 0.6;
  }

  border: 0;
  background: ${Colors.primary100};
  border-radius: 8px;

  span {
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    color: ${Colors.white};
  }
`;
