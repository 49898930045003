import {
  DataCreateEvent,
  DescriptionCreateEvent,
  EventResponse,
  InformationCreateEvent,
  LocalCreateEvent,
  OrganizerCreateEvent,
  TicketCreateEvent,
} from "types";

export enum ActionTypes {
  GO_TO_NEXT_STEP = "GO_TO_NEXT_STEP",
  GO_TO_BACK_STEP = "GO_TO_BACK_STEP",
  ADD_INFORMATION = "ADD_INFORMATION",
  ADD_DATA = "ADD_DATA",
  ADD_LOCAL = "ADD_LOCAL",
  ADD_DESCRIPTION = "ADD_DESCRIPTION",
  ADD_ORGANIZER = "ADD_ORGANIZER",
  ADD_EVENT_RESPONSE = "ADD_EVENT_RESPONSE",
  ADD_TICKET = "ADD_TICKET",
  RESET_STORE = "RESET_STORE"
}

export function toBack() {
  return {
    type: ActionTypes.GO_TO_BACK_STEP,
  };
}

export function toNext() {
  return {
    type: ActionTypes.GO_TO_NEXT_STEP,
  };
}

export function addInformation(information: InformationCreateEvent) {
  return {
    type: ActionTypes.ADD_INFORMATION,
    payload: {
      information,
    },
  };
}

export function addData(data: DataCreateEvent) {
  return {
    type: ActionTypes.ADD_DATA,
    payload: {
      data,
    },
  };
}

export function addLocal(local: LocalCreateEvent) {
  return {
    type: ActionTypes.ADD_LOCAL,
    payload: {
      local,
    },
  };
}

export function addDescription(description: DescriptionCreateEvent) {
  return {
    type: ActionTypes.ADD_DESCRIPTION,
    payload: {
      description,
    },
  };
}

export function addOrganizer(organizer: OrganizerCreateEvent) {
  return {
    type: ActionTypes.ADD_ORGANIZER,
    payload: {
      organizer,
    },
  };
}

export function addEventResponse(event_response: EventResponse) {
  return {
    type: ActionTypes.ADD_EVENT_RESPONSE,
    payload: {
      event_response,
    },
  };
}

export function addTicket(ticket: TicketCreateEvent) {
  return {
    type: ActionTypes.ADD_TICKET,
    payload: {
      ticket,
    },
  };
}


export function resetStore(){
  return {
    type: ActionTypes.RESET_STORE
  }
}
