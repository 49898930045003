import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PillFilter, RowSelectedFilters } from "./styles";
import { IoCloseCircleSharp } from "react-icons/io5";
import { Colors } from "constants/colors";
import { AiFillCalendar } from "react-icons/ai";
import { useAuthOrganizer } from "services/Hook/Organizer";
import moment from "moment";
import { dataPayment } from "components/ModalFilterByFormPayment";
import { dataStatus } from "components/ModalFilterByStatus";

export function FiltersByTag() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [nameOrganizer, setNameOrganizer] = useState("");
  const [payment, setPayment] = useState("");
  const [status, setStatus] = useState("");

  const organizer = searchParams.get("organizer") ?? "";

  const data_gte = searchParams.get("data_gte") ?? "";
  const data_lte = searchParams.get("data_lte") ?? "";
  const events = searchParams.get("events") ?? "";
  const tickets = searchParams.get("tickets") ?? "";
  const payment_type = searchParams.get("payment_type") ?? "";
  const payment_status = searchParams.get("payment_status") ?? "";

  const { data } = useAuthOrganizer();

  useEffect(() => {
    if (organizer) {
      if (data) {
        const organizerSearch = data?.find(
          (organizerFind: any) => String(organizerFind._id) === organizer
        );

        if (organizerSearch) {
          setNameOrganizer(organizerSearch?.name);
        }
      }
    }

    if (payment_type) {
      const findPayment = dataPayment.find(
        (paymentType) => paymentType.key === payment_type
      );

      if (findPayment) {
        setPayment(findPayment.name);
      }
    }

    if (payment_status) {
      const findStatus = dataStatus.find(
        (paymentStatus) => paymentStatus.key === payment_status
      );

      if (findStatus) {
        setStatus(findStatus.name);
      }
    }
  }, [data, organizer, payment_status, payment_type]);

  const clearId = useCallback(
    (key: string) => {
      if (key === "data") {
        searchParams.delete("data_gte");

        searchParams.delete("data_lte");

        setSearchParams({
          ...Object.fromEntries([...searchParams]),
        });

        return;
      }

      searchParams.delete(key);

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
      });
    },
    [searchParams, setSearchParams]
  );

  const eventsLength = events?.split(",").length;
  const ticketsLength = tickets?.split("%").length;

  const dataGte = data_gte !== "" ? moment(data_gte, "YYYY-MM-DD") : "";
  const formattedDataGte = dataGte !== "" ? dataGte.format("D-M-Y") : "";

  const dataLte = data_gte !== "" ? moment(data_lte, "YYYY-MM-DD") : "";
  const formattedDataLte = dataLte !== "" ? dataLte.format("D-M-Y") : "";

  return (
    <RowSelectedFilters>
      {organizer ? (
        <PillFilter>
          <span>{nameOrganizer}</span>

          <IoCloseCircleSharp
            onClick={() => clearId("organizer")}
            color={Colors.white}
            size={8}
          />
        </PillFilter>
      ) : null}

      {data_gte && data_lte ? (
        <PillFilter>
          <AiFillCalendar color={Colors.white} size={8} />

          <span>{`${formattedDataGte} - ${formattedDataLte}`}</span>

          <IoCloseCircleSharp
            onClick={() => clearId("data")}
            color={Colors.white}
            size={8}
          />
        </PillFilter>
      ) : null}

      {events ? (
        <PillFilter>
          <span>{eventsLength} Eventos selecionados</span>

          <IoCloseCircleSharp
            onClick={() => clearId("events")}
            color={Colors.white}
            size={8}
          />
        </PillFilter>
      ) : null}

      {tickets ? (
        <PillFilter>
          <span>{ticketsLength} Ingressos selecionados</span>

          <IoCloseCircleSharp
            onClick={() => clearId("tickets")}
            color={Colors.white}
            size={8}
          />
        </PillFilter>
      ) : null}

      {payment_type ? (
        <PillFilter>
          <span>{payment}</span>

          <IoCloseCircleSharp
            onClick={() => clearId("payment_type")}
            color={Colors.white}
            size={8}
          />
        </PillFilter>
      ) : null}

      {payment_status ? (
        <PillFilter>
          <span>{status}</span>

          <IoCloseCircleSharp
            onClick={() => clearId("payment_status")}
            color={Colors.white}
            size={8}
          />
        </PillFilter>
      ) : null}
    </RowSelectedFilters>
  );
}
