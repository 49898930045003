import { parentalRating } from "utils/event";

import {
  CardParentalRating,
  ParentalRatingContainer,
  ParentalRatingIcon,
  TitleAsideSmall,
} from "./styles";

interface RatingProps {
  age: number | undefined;
}

export function Rating({ age }: RatingProps) {
  const findParentalRating = parentalRating.find(
    (parental) => parental?.age === age
  );

  return (
    <CardParentalRating>
      <TitleAsideSmall>Classificação indicativa</TitleAsideSmall>

      <ParentalRatingContainer>
        <ParentalRatingIcon rating={findParentalRating ?? parentalRating[1]}>
          <p>{findParentalRating?.age ?? parentalRating[1].age}</p>
        </ParentalRatingIcon>

        {age && age !== 1 ? (
          <p>
            O evento é restrito para maiores de {findParentalRating?.age} anos
            de idade.
          </p>
        ) : null}

        {age && age === 1 ? <p>L - Livre para todas as idades.</p> : null}
      </ParentalRatingContainer>
    </CardParentalRating>
  );
}
