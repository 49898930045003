

import { FiChevronDown, FiSearch } from "react-icons/fi";
import { RiFilterLine } from "react-icons/ri";

import { Select } from "antd";

import { cnpj, cpf } from "utils/masks";

import {
  ContainerInputSearch,
  ButtonSearch,
  SelectFilter
} from "./styles"
import { useSearchParams } from "react-router-dom";
import { useCallback, useEffect, useRef, useState, type ChangeEvent } from "react";
import { Colors } from "constants/colors";




const { Option } = Select;




export function DropDownSearch(){
  const [searchParams, setSearchParams] = useSearchParams();

  const selectRef = useRef<any>(null);

  const [text, setText] = useState("");

  const filterId = searchParams.get("filter_id") ?? "";
  const filterValue = searchParams.get("filter_value") ?? "ID";
  const filterText = searchParams.get("filter_text") ?? "";

  useEffect(() => {
    if (filterText) {
      setText(filterText);
    }
  }, [filterText]);

  const handleSelectedIdFilter = useCallback(
    (value: any) => {
      setText("");

      searchParams.delete("filter_text");

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        filter_id: value.key,
        filter_value: value.value,
      });
    },
    [searchParams, setSearchParams]
  );

  const handleChangeTextFilter = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setText(e.target.value);

      setSearchParams(
        {
          ...Object.fromEntries([...searchParams]),
          filter_text: e.target.value,
        },
        {
          replace: true,
          state: {
            scroll: false,
          },
        }
      );

      if (selectRef.current) {
        selectRef.current.focus();
      }
    },
    [searchParams, setSearchParams]
  );

  const handleKeyUpCpf = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    cpf(e);
  }, []);

  const handleKeyUpCnpj = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      cnpj(e);
    },
    []
  );




  return (
    <ContainerInputSearch search={filterValue !== ""}>
        <SelectFilter search={filterValue !== ""}>
          <div className="prefix-icon-wrapper">
            <RiFilterLine
              className="icon"
              color={Colors.primary100}
              size={24}
            />
          </div>

          <Select
            className="select-drop"
            labelInValue
            bordered={false}
            defaultValue="Selecione uma opção"
            suffixIcon={<FiChevronDown size={16} />}
            style={{ width: "100%" }}
            onChange={handleSelectedIdFilter}
            value={filterValue}
            virtual={false}
          >
            <Option key="_id" value="ID da compra">
              ID da compra
            </Option>
            <Option key="user_name" value="Nome do comprador">
              Nome do comprador
            </Option>
            <Option key="user_document" value="CPF do comprador">
              CPF do comprador
            </Option>
            <Option key="user_email" value="Email do comprador">
              Email do comprador
            </Option>
          </Select>
        </SelectFilter>

        {filterValue !== "" ? (
          <div className="search">
            <input
              type="text"
              value={text}
              onChange={handleChangeTextFilter}
              placeholder={`Pesquisar por ${filterValue ?? ""}...`}
              onKeyUp={
                filterId === "user_document"
                  ? text.length <= 14
                    ? handleKeyUpCpf
                    : handleKeyUpCnpj
                  : () => {}
              }
            />

            <ButtonSearch onClick={() => {}}>
              <FiSearch size={18} color={Colors.primary100} />
            </ButtonSearch>
          </div>
        ) : null}
      </ContainerInputSearch>
  )
}
