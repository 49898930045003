import { RiArrowLeftLine, RiCloseCircleLine } from "react-icons/ri";
import { FiChevronRight } from "react-icons/fi";

import { Colors } from "constants/colors";

import {
  ButtonLeft,
  Card,
  ContainerHeader,
  ContainerModal,
  ContainerTable,
  Divider,
  GridItems,
  Item,
  ModalStyled,
  SubTitle,
  TextTotal,
  Title,
  TitleLast,
  TitleTotal,
} from "./styles";
import { Pagination } from "components/Pagination";

import { useState } from "react";
import { useGeneralContext } from "contexts/GeneralContext";
import { useSearchFinancialTransaction } from "hooks/useSearchFinancialTransaction";
import { formatPrice } from "utils/formatPrice";
import { BadgePurchaseByStatus } from "components/BadgePurchaseByStatus";
import { Transaction } from "types";
import { Link } from "react-router-dom";

interface ModalTotalPurchasesProps {
  total: number;
  fee: number;
}

export function ModalTotalPurchases({ total, fee }: ModalTotalPurchasesProps) {
  const [selectedPurchase, setSelectedPurchase] = useState(false);

  const { data, page, onChange } = useSearchFinancialTransaction({});

  const { isOpenModalTotalPurchases, setIsOpenModalTotalPurchases } =
    useGeneralContext();

  const closeSelectedPurchase = () => {
    setSelectedPurchase(false);
  };

  const closeModal = () => {
    setIsOpenModalTotalPurchases(false);
  };

  return (
    <ModalStyled
      width={1200}
      visible={isOpenModalTotalPurchases}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <ContainerHeader>
          {selectedPurchase ? (
            <ButtonLeft onClick={closeSelectedPurchase}>
              <RiArrowLeftLine color={Colors.primary100} size={22} />
            </ButtonLeft>
          ) : null}

          <h1>Vendas totais</h1>
        </ContainerHeader>

        {!selectedPurchase ? (
          <Card>
            <Title>Vendas totais do evento</Title>

            <GridItems>
              <Item>
                <div className="column-content">
                  <TitleTotal>VENDAS TOTAIS</TitleTotal>

                  <TextTotal>{formatPrice(total ?? 0)}</TextTotal>

                  <SubTitle>
                    Taxa administrativa: {formatPrice(fee ?? 0)}
                  </SubTitle>
                </div>

                <FiChevronRight color={Colors.gray45} size={24} />
              </Item>
            </GridItems>

            <Divider />

            <TitleLast>Últimas vendas</TitleLast>

            <ContainerTable>
              <table>
                <thead>
                  <tr>
                    <th>EVENTO</th>
                    <th>STATUS</th>
                    <th>VALOR</th>
                    <th>DATA</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  {data?.results?.map((purchase: Transaction) => (
                    <Link
                      style={{ width: "100%" }}
                      to={`/financial/${purchase?._id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="table-link"
                    >
                      <tr style={{ cursor: "pointer" }}>
                        <td>
                          <h6>{purchase?.event_id?.name}</h6>
                        </td>

                        <td>
                          <BadgePurchaseByStatus
                            status={purchase?.payment_status}
                          />
                        </td>

                        <td>
                          <h6>
                            {formatPrice(
                              purchase?.total ? purchase?.total / 100 : 0
                            )}
                          </h6>
                        </td>

                        <td>
                          <h6>{purchase?.formattedDate}</h6>
                        </td>

                        <td>
                          <FiChevronRight color={Colors.gray45} size={24} />
                        </td>
                      </tr>
                    </Link>
                  ))}
                </tbody>
              </table>
            </ContainerTable>

            <div className="pagination">
              <Pagination
                title="registros"
                totalCountOfRegisters={data?.count}
                currentPage={Number(page)}
                onPageChange={onChange}
                registersPerPage={10}
              />
            </div>
          </Card>
        ) : null}
      </ContainerModal>
    </ModalStyled>
  );
}
