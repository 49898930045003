import { useState } from "react";

import { Spin, Tooltip } from "antd";

import CopyToClipboard from "react-copy-to-clipboard";

import { useCreateEvent } from "contexts/CreateEventContext";

import { Event } from "types";

import { useAuthMyEvent } from "services/Hook/Event";

import {
  ButtonShare,
  Card,
  Container,
  ContainerLink,
  Divider,
  LabelTitle,
  RowShared,
  SubTitle,
  SubTitleShare,
  Title,
} from "./styles";

import { ButtonNext, ButtonPrev, ContainerButtons } from "../../styles";

import copy_icon from "assets/copy-icon.svg";
import { useDisclosure } from "hooks/useDisclosure";
import { ModalSuccess } from "components/ModalSuccess";
import { useNavigate } from "react-router";
import { LoadingOutlined } from "@ant-design/icons";
import { Colors } from "constants/colors";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
);

export function ShareEvent() {
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const { event } = useCreateEvent();

  const { data } = useAuthMyEvent<Event>(event?.event_response?.id ?? "");

  const navigate = useNavigate();

  const { handleCancel, handleOk, isModalVisible, showModal } = useDisclosure();

  const handleAddInformation = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmiting(true);

    showModal();
  };

  const handleResetCreateEventForm = () => {
    localStorage.removeItem("@EventX-create_event");

    handleOk();

    event.resetTheStore();

    navigate("/my-event");
  };

  return (
    <Container onSubmit={handleAddInformation}>
      <Card>
        <Title>7. Compartilhe seu evento</Title>
        <SubTitle>
          Divulgue o link do seu evento para atrair seu público.
        </SubTitle>

        <Divider />

        <LabelTitle>Divulgue seu evento</LabelTitle>

        <SubTitleShare>
          Como o seu evento é privado, as pessoas só consiguirão acessá-lo pelo
          código ou link abaixo. Copie e divulgue como preferir.
        </SubTitleShare>

        <ContainerLink>
          <h2>Link do evento</h2>

          <RowShared>
            <input
              type="text"
              value={`${process.env.REACT_APP_EVENTX_MICROFRONT}/${data?.nickname}`}
              disabled
              readOnly
            />

            <CopyToClipboard
              text={`${process.env.REACT_APP_EVENTX_MICROFRONT}/${data?.nickname}`}
              onCopy={() => {
                setIsLinkCopied(true);
                setTimeout(() => {
                  setIsLinkCopied(false);
                }, 5000);
              }}
            >
              <Tooltip
                placement="topLeft"
                title={
                  isLinkCopied
                    ? "Link copiado para a área de transferência!"
                    : "Copiar link"
                }
              >
                <ButtonShare type="button">
                  <img src={copy_icon} alt="" width={10} height={10} />
                </ButtonShare>
              </Tooltip>
            </CopyToClipboard>
          </RowShared>
        </ContainerLink>

        {data?.private_code ? (
          <ContainerLink>
            <h2>Código do evento</h2>

            <RowShared>
              <input
                type="text"
                value={`${process.env.REACT_APP_EVENTX_MICROFRONT}/${data?.nickname}`}
                disabled
                readOnly
              />

              <CopyToClipboard
                text={`${process.env.REACT_APP_EVENTX_MICROFRONT}/${data?.nickname}`}
                onCopy={() => {
                  setIsLinkCopied(true);
                  setTimeout(() => {
                    setIsLinkCopied(false);
                  }, 5000);
                }}
              >
                <Tooltip
                  placement="topLeft"
                  title={
                    isLinkCopied
                      ? "Link copiado para a área de transferência!"
                      : "Copiar link"
                  }
                >
                  <ButtonShare type="button">
                    <img src={copy_icon} alt="" width={10} height={10} />
                  </ButtonShare>
                </Tooltip>
              </CopyToClipboard>
            </RowShared>
          </ContainerLink>
        ) : null}
      </Card>

      <ContainerButtons>
        <ButtonPrev
          style={{ cursor: "pointer" }}
          type="button"
          onClick={event.goToBackStep}
        >
          <span>Voltar</span>
        </ButtonPrev>

        <ButtonNext type="submit">
          {!isSubmiting ? <p>Finalizar</p> : null}

          {isSubmiting ? <Spin spinning indicator={antIcon} /> : null}
        </ButtonNext>
      </ContainerButtons>

      <ModalSuccess
        handleCancel={handleCancel}
        handleOk={handleResetCreateEventForm}
        isModalVisible={isModalVisible}
        title="Cadastro de evento finalizado!"
        subtitle="Clique em OK para prosseguir."
      />
    </Container>
  );
}
