import { IoChevronDownOutline } from 'react-icons/io5'
import { RiBankLine, RiContactsLine, RiUserLine } from 'react-icons/ri'

import { Radio, Select, Spin, type RadioChangeEvent } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import { SubmitHandler, useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import Datas from 'constants/Datas'
import { Colors } from 'constants/colors'
import { useDisclosure } from 'hooks/useDisclosure'

import { InputFormAccount } from 'components/Form/InputFormAccount'
import { ModalSelectBank } from 'components/ModalSelectBank'

import {
  ButtonNext,
  ContainerBorderAccountForm,
  FormAccount,
  StyledSelect
} from '../../ModalConfirmReverse/styles'

import { RowTypeEvent } from 'pages/MyEvent/New/Steps/Local/styles'

import {
  BorderSelect,
  ButtonSelectBank,
  ContainerSelect,
  LabelSelect
} from 'pages/OrganizerCadaster/Steps/BankData/styles'

import { RefundPurchase } from 'services/Hook/User'

const { Option } = Select

interface AccountFormData {
  refund_type: number
  holder_name: string
  bank_code: string
  routing_number: string
  account_number: string
  document: string
  type_document: number
  type_account: number
  digit: string
}

const documentFormSchema = yup.object().shape({
  refund_type: yup.number().notRequired(),
  holder_name: yup.string().required('Insira o nome do titular conta'),
  bank_code: yup.string().required(),
  routing_number: yup.string().required(),
  account_number: yup.string().required(),
  document: yup.string().required('Insira o número do documento'),
  type_document: yup.number().notRequired(),
  type_account: yup.number().required(),
  digit: yup.string().required()
})

interface ReverseBoletoProps {
  purchaseId: string
  showModalConfirm(): void
  showModalError(): void
  closeShowReverse?(): void
}

export function ReverseBoleto ({
  purchaseId,
  showModalConfirm,
  showModalError,
  closeShowReverse
}: ReverseBoletoProps) {
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<AccountFormData>({
    resolver: yupResolver(documentFormSchema)
  })

  const bankWatch = watch('bank_code')
  const typeDocument = watch('type_document')

  const {
    handleCancel: handleCancelBank,
    isModalVisible: isModalVisibleBank,
    showModal: showModalBank,
    handleOk: handleOkBank
  } = useDisclosure()

  const handleCancelPurchase: SubmitHandler<AccountFormData> = async ({
    ...data
  }) => {
    const {
      bank_code,
      holder_name,
      account_number,
      digit,
      routing_number,
      type_account
    } = data

    try {
      const credentials = {
        account: account_number,
        agency: routing_number,
        bank_id: bank_code,
        digit,
        holder_name,
        type_account
      }

      await RefundPurchase({ purchaseId, credentials })

      showModalConfirm()
    } catch (err) {
      showModalError()
    }
  }

  const handleSelectBank = (bankId: string) => {
    setValue('bank_code', bankId)

    handleOkBank()
  }

  const findBankById = bankWatch
    ? Datas.Banks.find(bank => bank.value === bankWatch)
    : { value: '', label: '' }

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  )

  return (
    <div>
      <FormAccount onSubmit={handleSubmit(handleCancelPurchase)}>
        <div className='header-texts'>
          <h3>Estorno da compra</h3>

          <p>
            Informa os dados bancários da conta na qual você deseja receber o
            seu estorno.
          </p>
        </div>
        <ContainerBorderAccountForm>
          <div className='header-title'>
            <h5 className='title'>Informações da conta</h5>
          </div>

          <div className='informations'>
            <InputFormAccount
              isFull
              {...register('holder_name')}
              icon={<RiUserLine size={18} color={Colors.secondary40} />}
              type='text'
              label='Nome do titular'
              placeholder='Insira o nome do titular da conta...'
              error={errors.holder_name}
            />
          </div>

          <div className='radios'>
            <span className='label'>Tipo de documento</span>

            <div className='radio'>
              <Controller
                control={control}
                name='type_document'
                defaultValue={1}
                render={({ field: { onChange, value } }) => (
                  <Radio.Group
                    onChange={(e: RadioChangeEvent) => {
                      onChange(e.target.value)
                    }}
                    value={value}
                  >
                    <RowTypeEvent>
                      <Radio className='radio' value={1}>
                        CPF
                      </Radio>
                      <Radio className='radio' value={2}>
                        CNPJ
                      </Radio>
                    </RowTypeEvent>
                  </Radio.Group>
                )}
              />
            </div>
          </div>

          <div className='informations'>
            <InputFormAccount
              isFull
              {...register('document')}
              icon={<RiContactsLine size={18} color={Colors.secondary40} />}
              type='text'
              label={typeDocument === 1 ? 'CPF' : 'CNPJ'}
              placeholder={
                typeDocument === 1
                  ? 'Insira o seu número de CPF...'
                  : 'Insira o seu número do CNPJ...'
              }
              mask={typeDocument === 1 ? 'cpf' : 'cnpj'}
              error={errors.document}
            />
          </div>

          <ContainerSelect>
            <LabelSelect>Banco</LabelSelect>

            <ButtonSelectBank type='button' onClick={showModalBank}>
              {bankWatch ? (
                <span>{findBankById?.label}</span>
              ) : (
                <span>Selecione uma opção</span>
              )}

              <IoChevronDownOutline color={Colors.secondary30} />
            </ButtonSelectBank>

            {!!errors.bank_code ? (
              <span className='error'>{errors?.bank_code?.message}</span>
            ) : null}
          </ContainerSelect>

          <div className='informations'>
            <InputFormAccount
              isFull
              {...register('routing_number')}
              icon={<RiBankLine size={18} color={Colors.secondary40} />}
              type='text'
              label='Agência (sem dígito)'
              placeholder='Insira o número da agência...'
              error={errors.routing_number}
            />
          </div>

          <div className='informations'>
            <InputFormAccount
              {...register('account_number')}
              icon={<RiBankLine size={18} color={Colors.secondary40} />}
              type='text'
              label='Conta'
              placeholder='Insira o número da conta...'
              error={errors.account_number}
            />

            <InputFormAccount
              {...register('digit')}
              icon={<RiBankLine size={18} color={Colors.secondary40} />}
              type='text'
              label='Dígito'
              placeholder='Insira o dígito da conta...'
            />
          </div>

          <ContainerSelect>
            <LabelSelect>Tipo de conta</LabelSelect>

            <Controller
              control={control}
              name='type_account'
              rules={{ required: 'Salutation is required' }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState
              }) => (
                <BorderSelect>
                  <StyledSelect
                    size='large'
                    labelInValue
                    bordered={false}
                    placeholder='Selecione uma opção'
                    value={value}
                    style={{
                      width: '100%'
                    }}
                    onChange={(e: any) => {
                      onChange(e.value)
                    }}
                  >
                    <Option value={'1'} key={'1'}>
                      Corrente
                    </Option>

                    <Option value={'2'} key={'2'}>
                      Poupança
                    </Option>
                  </StyledSelect>
                </BorderSelect>
              )}
            />

            {!!errors.type_account ? (
              <span className='error'>{errors?.type_account?.message}</span>
            ) : null}
          </ContainerSelect>
        </ContainerBorderAccountForm>

        <div className='container-buttons'>
          <ButtonNext disabled={isSubmitting} active type='submit'>
            <Spin spinning={isSubmitting} indicator={antIcon} />

            <span>Finalizar</span>
          </ButtonNext>
        </div>
      </FormAccount>

      <ModalSelectBank
        isModalVisible={isModalVisibleBank}
        handleCancel={handleCancelBank}
        handleOk={handleOkBank}
        handleSelectBank={handleSelectBank}
      />
    </div>
  )
}
