import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerItems = styled.div`
  margin-top: 7.96rem;
  max-width: 1280px;
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;

  @media (max-width: 1024px) {
    padding-left: 28px;
    padding-right: 28px;
    margin-top: 88px;
  }

  display: flex;
  flex-direction: column;

  .breadcrumb {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const TitleCard = styled.h1`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 1.87rem;
`;

export const CardProfile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  @media (max-width: 1024px) {
    display: none;
  }

  div.full-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  div.full-button-save {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  border: 1px solid ${Colors.black10};
  padding: 19px 41px 25px 42px;

  box-shadow: 0px 4px 10px rgba(18, 25, 34, 0.05);
  border-radius: 20px;

  @media (max-width: 800px) {
    margin-left: 0px;
    box-shadow: none;
    padding: 0;
    border: 0;
    height: 100%;
  }

  div.full-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`;
