import { authClient } from "services/authClient";
import { UserTicketResponse } from "./types";

export async function getUserTicket(ticket_code: string) {
  const endpoint = `/user-ticket/query?code=${ticket_code}`;

  const response = await authClient.get<UserTicketResponse>(endpoint, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return response.data;
}

export async function makeCheckin(id: string) {
  const endpoint = `/user-ticket/checkin/${id}`;

  return authClient.patch(endpoint);
}

export async function makeCheckout(id: string) {
  const endpoint = `/user-ticket/checkout/${id}`;

  return authClient.patch(endpoint);
}
