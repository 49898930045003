import { Colors } from "constants/colors";
import styled from "styled-components";

import { Select } from "antd";

export const Container = styled.form`
  width: 100%;
  height: 100%;
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.34rem 6.37rem 2.34rem 6.37rem;
  border: 1px solid ${Colors.black10};
  border-radius: 0.93rem;
  margin-top: 2.34rem;

  @media (max-width: 1024px) {
    padding: 0px 0px;
    border: 0;
    margin-top: 39px;
  }
`;

export const Title = styled.h2`
  color: ${Colors.secondary100};
  font-family: Poppins;
  font-size: 1.03rem;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0px;

  @media (max-width: 1024px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 700;
    line-height: 25px;
  }
`;

export const SubTitle = styled.p`
  color: ${Colors.black70};
  font-family: Open Sans;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  margin-top: 0.65rem;

  span {
    color: ${Colors.black70};
    font-family: Open Sans;
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
  }
`;

export const ListBenefits = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 2.1rem;

  gap: 1.35rem;

  @media (max-width: 1024px) {
    margin-top: 20px;
    gap: 18px;
  }
`;

export const Divider = styled.div`
  margin-top: 1.87rem;
  padding-bottom: 1.87rem;
  height: 1px;
  border-top: 1px solid ${Colors.secondary10};
`;

export const GridInputs = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  @media (max-width: 670px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
`;

export const NavHeader = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${Colors.primary10};
  margin-bottom: 1.87rem;
  padding: 20px 43px 20px 13px;
  border-radius: 10px;

  @media (max-width: 1024px) {
    padding: 8px 10px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

export const Hr = styled.div`
  min-height: 100%;
  width: 4px;
  border-radius: 2.34rem;
  background-color: ${Colors.primary100};
`;

export const ColumnContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 0.89rem;
`;

export const TitleNav = styled.h5`
  color: ${Colors.black100};
  font-family: Poppins;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
  margin-left: 0.46rem;

  @media (max-width: 1024px) {
    color: ${Colors.black100};
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 4px;
  }
`;

export const DividerNav = styled.div`
  width: 100%;
  margin-top: 0.46rem;
  height: 1px;
  border-top: 1px solid ${Colors.primary20};
`;

export const SubtitleNav = styled.p`
  color: ${Colors.black90};
  font-family: Poppins;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  margin-top: 0.84rem;
  margin-bottom: 0px;

  @media (max-width: 1024px) {
    color: ${Colors.black90};
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  strong {
    color: ${Colors.black90};
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;

    @media (max-width: 1024px) {
      font-family: Poppins;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;

export const RowtTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  font-family: "Poppins";
  font-size: 0.93rem;

  .ant-select-selection-item {
    font-family: "Poppins";
    font-size: 0.93rem;
    color: ${Colors.secondary40};
  }
`;

export const BorderSelect = styled.div`
  border: 2px solid ${Colors.secondary20};
  width: 100%;
  height: 2.81rem;
  border-radius: 0.37rem;
  margin-bottom: 0.45rem;
  margin-right: 0.51rem;
  display: flex;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 0px;

    height: 32px;
    border-radius: 6px;
  }
`;

export const LabelSelect = styled.h5`
  color: ${Colors.secondary40};
  font-family: Poppins;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 400;
`;

export const ContainerSelect = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.87rem;

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const ButtonSelectBank = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 0;
  outline: 0;
  height: 2.81rem;
  width: 100%;
  padding: 14px 20px;
  margin-top: 4px;
  border-radius: 8px;
  background: transparent;

  border: 2px solid ${Colors.secondary20};

  span {
    font-family: Poppins;
    font-size: 0.93rem;
    font-weight: 400;
    color: ${Colors.secondary30};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis " [..]";
  }
`;
