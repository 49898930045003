import { RiCloseCircleLine } from "react-icons/ri";

import { Colors } from "constants/colors";

import {
  ContainerModal,
  ContainerSelect,
  ModalStyled,
  NormalCardBorder,
} from "./styles";
import { platforms } from "constants/platforms";

interface ModalCategoryProps {
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
  handleSelectedPlatform(platform: any): void;
}

export function ModalPlatform({
  isModalVisible,
  handleOk,
  handleCancel,
  handleSelectedPlatform,
}: ModalCategoryProps) {
  return (
    <ModalStyled
      width={857}
      visible={isModalVisible}
      onOk={handleOk}
      style={{ borderRadius: "20px" }}
      onCancel={handleCancel}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <h1>Plataformas</h1>

        <h2>Selecione uma plataforma de transmissão.</h2>

        <ContainerSelect>
          {platforms?.map((platform) => (
            <NormalCardBorder onClick={() => handleSelectedPlatform(platform)}>
              <div className="circle-icon">
                {/* <img
                  className="svg-icon"
                  width={13}
                  height={13}
                  src={platform.icon}
                  alt=""
                /> */}

                {platform.icon}
              </div>

              <h3 className="normal">{platform.name}</h3>
            </NormalCardBorder>
          ))}
        </ContainerSelect>
      </ContainerModal>
    </ModalStyled>
  );
}
