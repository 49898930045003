import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const ContainerItems = styled.div`
  margin-top: 7.96rem;
  max-width: 1280px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  padding-left: 28px;
  padding-right: 28px;

  @media (max-width: 1024px) {
    padding-left: 28px;
    padding-right: 28px;
    margin-top: 88px;
  }

  .breadcrumb {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const TitleCard = styled.h1`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
`;

export const CardProfile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  /* height: 100%; */
  align-self: flex-start;
  border: 1px solid ${Colors.black10};
  padding: 1.87rem 2.81rem;

  box-shadow: 0px 4px 10px rgba(18, 25, 34, 0.05);
  border-radius: 0.93rem;

  @media (max-width: 800px) {
    margin-left: 0px;
    box-shadow: none;
    padding: 0;
    border: 0;
    height: 100%;
    margin-bottom: 2rem;
  }
`;

export const ContainerHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.87rem;
`;

export const Subtitle = styled.h3`
  color: ${Colors.secondary100};
  font-family: Poppins;
  font-size: 1.03rem;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0px;
`;

export const ContainerDate = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-bottom: 10px;
  border-bottom: 1px solid #e9e7ee;
  margin-top: 1.68rem;

  span.date {
    color: ${Colors.black50};
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0px;
    margin-left: 10px;
  }
`;

export const ContainerP = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.87rem;
  margin-top: 1.87rem;
`;

export const P = styled.p`
  color: ${Colors.black70};
  font-family: "Open Sans";
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
  text-align: justify;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid ${Colors.secondary10};
  margin-top: 1.87rem;
  margin-bottom: 1.87rem;
`;

export const DividerMargin = styled.div`
  width: 100%;
  height: 1px;
  border: 1px solid ${Colors.secondary10};
  margin-top: 2.81rem;
  margin-bottom: 2.81rem;
`;

export const LabelTitle = styled.h5`
  color: ${Colors.secondary100};
  font-family: Poppins;
  font-size: 1.03rem;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0px;
`;

export const ContainerLike = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 1.87rem;
`;

export const Like = styled.div`
  width: 3.75rem;
  height: 3.75rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 0.65rem;
  border: 1px solid ${Colors.black10};
  background: ${Colors.white};
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 0px;
`;

export const ButtonBack = styled.button`
  width: 12.28rem;
  height: 2.81rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  cursor: pointer;
  outline: 0;
  border: 0;
  border-radius: 0.65rem;
  background: ${Colors.primary10};

  span {
    color: ${Colors.primary100};
    font-family: Cairo;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 700;
    margin-left: 10px;
    margin-bottom: 0px;
  }
`;

export const ButtonEdit = styled.button`
  display: flex;
  cursor: pointer;
  border: 0;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  width: 262px;
  height: 60px;
  background-color: ${Colors.primary10};
  color: ${Colors.primary100};
  font-weight: bold;
  font-size: 20px;
  line-height: 160%;

  span {
    margin-left: 10px;
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 38px;
    margin-top: 53px;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    border-radius: 8px;

    color: ${Colors.primary100};

    @media (max-width: 800px) {
      height: 38px;
      border-radius: 8px;

      svg {
        display: none;
      }
    }
  }
`;

export const ButtonCancel = styled.button`
  cursor: pointer;
  border: 0;
  width: 262px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.primary10};
  border-radius: 14px;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    text-align: center;
    color: ${Colors.primary100};

    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 22px;
    }
  }

  @media (max-width: 1140px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    height: 38px;
    border-radius: 8px;

    svg {
      display: none;
    }
  }
`;

export const ButtonSave = styled.button`
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 262px;
  height: 60px;
  background: ${Colors.primary100};
  border-radius: 14px;

  span {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;

    color: ${Colors.white};
    margin-left: 10px;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 22px;
    }
  }

  @media (max-width: 1140px) {
    width: 100%;

    margin-top: 30px;
  }

  @media (max-width: 800px) {
    height: 38px;
    border-radius: 8px;

    svg {
      display: none;
    }
  }
`;

export const ContainerAccounts = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.84rem;
  list-style: none;
`;

export const Account = styled.li`
  width: 100%;
  height: 13.78rem;
  flex-shrink: 0;
  padding: 24px 40px;
  border-radius: 0.65rem;
  border: 1px solid ${Colors.border20};
  background: ${Colors.white};
  box-shadow: 0px 4px 10px 0px rgba(18, 25, 34, 0.05);
`;

export const AccountHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
`;

export const TitleAccountHeader = styled.h3`
  color: ${Colors.secondary100};
  font-family: Cairo;
  font-size: 1.03rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
`;

export const ContainerButtonsHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const ButtonHeaderAccount = styled.button`
  cursor: pointer;
  border: 0;
  outline: 0;
  display: flex;
  width: 7.17rem;
  height: 2.81rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background: ${Colors.white20};

  span {
    font-family: Cairo;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 700;
    margin-left: 10px;
    margin-bottom: 0px;
  }

  span.green {
    color: ${Colors.success};
  }

  span.red {
    color: ${Colors.red10};
  }
`;

export const ContainerColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ContainerTexts = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3px;
`;

export const TextAccount = styled.h3`
  color: ${Colors.black100};
  font-family: Poppins;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
`;

export const TextAccountStrong = styled.h3`
  color: ${Colors.black100};
  font-family: Poppins;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 0px;
`;

export const ContainerModalAddDocument = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* justify-content: flex-start; */

  padding: 0px 109px 24px 109px;

  @media (max-width: 700px) {
    padding: 0px 20px 24px 0px;
  }

  img.document {
    width: 100%;
    max-height: 394px;

    @media (max-width: 640px) {
      width: 90%;
    }
  }

  h2.title {
    font-weight: bold;
    font-size: 32px;
    /* line-height: 160%; */
    text-align: center;
    color: ${Colors.secondary100};

    @media (max-width: 700px) {
      font-size: 22px;
    }
  }

  p.title {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: ${Colors.secondary100};
    margin-top: 40px;
  }
`;

export const ContainerButtonBack = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonBackModal = styled.button`
  border: 0;
  outline: 0;
  cursor: pointer;

  padding: 9px 28px;

  width: 135px;
  height: 50px;
  background: ${Colors.white};
  border: 1px solid ${Colors.primary100};
  border-radius: 14px;
  margin-top: 54px;

  font-weight: 700;
  font-size: 20px;
  line-height: 160%;
  text-align: center;

  color: ${Colors.primary100};
`;
