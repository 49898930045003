import { Colors } from "constants/colors";
import styled from "styled-components";

interface ContainerInputSearchProps {
  search?: boolean;
}

export const ContainerInputSearch = styled.div<ContainerInputSearchProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${(props) => (props.search ? "37.73rem" : "14.79rem")};

  width: 100%;
  height: 2.81rem;
  border-radius: 0.65rem;
  border: 1px solid ${Colors.border};
  background: ${Colors.white};
  margin-top: 2.15rem;

  @media (max-width: 670px) {
    border: none;
    height: auto;
    width: 100%;
    min-width: 100%;
    flex-direction: column;
    justify-content: normal;
    margin-top: 0.56rem;
    margin-bottom: 1.87rem;
  }

  div.search {
    width: ${(props) => (props.search ? "100%" : "10px")};
    height: 100%;
    display: ${(props) => (props.search ? "flex" : "none")};
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 670px) {
      height: 38px;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border: 1px solid ${Colors.border};
      margin-top: 1.25rem;
    }
  }

  input {
    border: 0;
    outline: 0;
    background-color: ${Colors.white};
    padding-left: 1.31rem;
    width: 100%;
    height: 100%;
    color: ${Colors.gray};
    font-family: Open Sans;
    font-size: 0.84rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const ButtonSearch = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 3.75rem;
  max-width: 3.75rem;
  height: 100%;
  border-left: 1px solid ${Colors.border};
`;

export const SelectFilter = styled.div<ContainerInputSearchProps>`
  width: 14.79rem;
  max-width: 14.79rem;
  height: 100%;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: ${(props) =>
    props.search ? `1px solid ${Colors.border}` : "none"};
  gap: 0.79rem;
  position: relative;

  && .ant-select .ant-select-selector {
    padding-left: calc(3rem - 8px);
  }

  .ant-select-selection-item {
    color: ${Colors.primary100};
    font-family: Cairo;
    font-size: 0.84rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0px;
    white-space: nowrap;

    @media (max-width: 670px) {
      color: ${Colors.secondary70};
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .select-drop {
    @media (max-width: 640px) {
      width: 100%;
    }
  }

  div.prefix-icon-wrapper {
    position: absolute;
    z-index: 1;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    color: ${Colors.primary100};
  }

  @media (max-width: 670px) {
    width: 100%;
    min-width: 100%;
    height: 38px;
    border-radius: 8px;
    border: 1px solid ${Colors.border};

    svg {
      color: ${Colors.secondary70};
    }

    svg.icon {
      display: none;
    }
  }

  h5 {
    color: ${Colors.primary100};
    font-family: Cairo;
    font-size: 0.84rem;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0px;
    white-space: nowrap;

    @media (max-width: 670px) {
      color: ${Colors.secondary70};
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
