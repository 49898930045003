import { Colors } from "constants/colors";
import styled from "styled-components";

export const RowSelectedFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

export const PillFilter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 15px;
  padding: 0px 4px;
  align-items: flex-start;

  border-radius: 4px;
  background: ${Colors.primary100};

  span {
    color: ${Colors.white};
    font-family: Cairo;
    font-size: 8px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 0px;
    margin-left: 3px;
  }

  svg {
    margin-left: 4px;
    margin-top: 2px;
  }
`;
