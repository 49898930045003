import { Colors } from "constants/colors";
import {
  ContainerModalTicketDetail,
  BackgroundTicket,
  ContainerContentModalTicket,
} from "pages/Profile/MyTicket/styles";
import { FiMapPin } from "react-icons/fi";
import { RiCalendar2Line } from "react-icons/ri";
import QRCode from "react-qr-code";
import { useUserTicketById } from "services/Hook/User";
import { TicketType, TicketUserByIdResponse } from "types";

interface DetailTicketProps {
  ticket: TicketType;
  showModal(): void;
}

export function DetailTicket({ ticket, showModal }: DetailTicketProps) {
  const { data } = useUserTicketById<TicketUserByIdResponse>(ticket?._id ?? "");

  return (
    <ContainerModalTicketDetail>
      <BackgroundTicket>
        <img src={data?.event?.photo ?? "/assets/banner.jpg"} alt="Logo" />

        <div className="content">
          <h3>{data?.event?.name}</h3>

          <div className="text">
            <FiMapPin size={22} color={Colors.white} />
            <h4>
              {data?.event?.address_name}{" "}
              {data?.event?.address_street
                ? `| ${data?.event?.address_street}`
                : ""}
            </h4>
          </div>

          <div className="text">
            <RiCalendar2Line size={22} color={Colors.white} />
            <p>
              {data?.event?.formattedDate}, {data?.event?.formattedHour}
            </p>
          </div>
        </div>
      </BackgroundTicket>

      <ContainerContentModalTicket>
        <div className="data">
          <h3 className="data">Dados do ingresso</h3>

          <div className="table">
            <h5>Nome do comprador</h5>
            <p>{data?.holder_name}</p>
          </div>

          <div className="table">
            <h5>Ingresso</h5>
            <p>{ticket?.event_ticket_id?.name}</p>
          </div>

          <div className="table">
            <h5>Código da compra</h5>
            <p>{data?.purchase_id}</p>
          </div>
        </div>

        <div className="qr-mobile">
          <QRCode size={84} value={data?._id || ""} />

          <button onClick={showModal}>Ver QR Code</button>
        </div>
      </ContainerContentModalTicket>
    </ContainerModalTicketDetail>
  );
}
