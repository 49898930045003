import {
  FormEvent,
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
  useCallback,
} from "react";

import { FieldError } from "react-hook-form";

import {
  cep,
  cnpj,
  cpf,
  date,
  hour,
  phone,
  currency,
  especial,
} from "utils/masks";
import { ContainerInputFull, FormLabel } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  hasPrefix?: boolean;
  prefix?: string;
  isEditing?: boolean;
  mask?:
    | "phone"
    | "birthdate"
    | "cpf"
    | "cnpj"
    | "cep"
    | "price"
    | "hour"
    | "especial";
  label?: string;
  error?: FieldError;
  isFull?: boolean;
  icon?: JSX.Element;
}

export const InputBase: ForwardRefRenderFunction<
  HTMLInputElement,
  InputProps
> = (
  {
    name,
    isEditing = true,
    label,
    error = null,
    icon,
    mask,
    isFull,
    hasPrefix,
    prefix = "R$",
    ...rest
  },
  ref
) => {
  const handleKeyUp = useCallback(
    (e: FormEvent<HTMLInputElement>) => {
      switch (mask) {
        case "phone":
          phone(e);
          break;
        case "birthdate":
          date(e);
          break;
        case "cpf":
          cpf(e);
          break;
        case "cnpj":
          cnpj(e);
          break;
        case "cep":
          cep(e);
          break;
        case "hour":
          hour(e);
          break;
        case "price":
          currency(e);
          break;
        case "especial":
          especial(e);
          break;
        default:
          break;
      }
    },
    [mask]
  );

  return (
    <ContainerInputFull isFull={isFull} error={!!error} hasPrefix={hasPrefix}>
      <FormLabel>
        {true && !!icon ? icon : icon}
        <h2>{label}</h2>
      </FormLabel>

      <div className="container">
        {hasPrefix ? <h3 className="prefix">{prefix}</h3> : null}

        <input
          name={name}
          {...rest}
          ref={ref}
          disabled={!isEditing}
          onKeyUp={handleKeyUp}
        />
      </div>

      {!!error ? <span className="error">{error.message}</span> : null}
    </ContainerInputFull>
  );
};

export const InputForm = forwardRef(InputBase);
