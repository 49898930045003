import styled from "styled-components";
import { Button, Select } from "antd";

import { Colors } from "constants/colors";

export const Container = styled.form`
  width: 100%;
  height: 100%;
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.34rem 6.37rem 2.34rem 6.37rem;
  border: 1px solid ${Colors.black10};
  border-radius: 0.93rem;
  margin-top: 2.34rem;

  @media (max-width: 1024px) {
    padding: 0px 0px;
    border: 0;
  }
`;

export const Title = styled.h2`
  color: ${Colors.secondary100};
  font-size: 1.21rem;
  font-family: Poppins;
  font-weight: 600;
  line-height: 160%;

  @media (max-width: 670px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 700;
    line-height: 25px;
  }
`;

export const SubTitle = styled.h3`
  color: ${Colors.black70};
  font-size: 1rem;
  font-family: Open Sans;
  line-height: 160%;
  margin-top: 0.93rem;

  @media (max-width: 670px) {
    font-size: 12px;
    font-family: Open Sans;
    margin-top: 8px;
  }
`;

export const LabelTitle = styled.h4`
  color: ${Colors.secondary100};
  font-size: 1.03rem;
  font-family: Poppins;
  font-weight: 500;
  line-height: 160%;

  @media (max-width: 670px) {
    color: ${Colors.black100};
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
  }
`;

export const SubLabelTitle = styled.h4`
  color: ${Colors.black60};
  font-size: 0.75rem;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 160%;

  strong {
    font-size: 0.75rem;
    font-family: Open Sans;
    font-style: italic;
    color: ${Colors.black70};

    @media (max-width: 670px) {
      font-size: 14px;
      font-family: Cairo;
      font-weight: 600;
    }
  }

  @media (max-width: 670px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
  }
`;

export const Divider = styled.div`
  margin-top: 1.87rem;
  padding-bottom: 1.87rem;
  height: 1px;
  border-top: 1px solid ${Colors.secondary10};
`;

export const ButtonAddImage = styled.button`
  border: 0;
  display: flex;
  width: 24.98rem;
  height: 2.81rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background: ${Colors.primary10};
  margin-top: 1.68rem;

  span {
    color: ${Colors.primary100};
    font-size: 0.93rem;
    font-family: Cairo;
    font-weight: 700;
    line-height: 160%;
    margin-left: 0.46rem;

    @media (max-width: 670px) {
      font-size: 12px;
      font-weight: 600;
      margin-left: 7px;
    }
  }

  @media (max-width: 670px) {
    width: 100%;
  }
`;

export const LabelSelect = styled.h4`
  color: ${Colors.secondary40};
  font-size: 0.93rem;
  font-family: Poppins;
`;

export const ContainerSelect = styled.div`
  margin-top: 1.68rem;
  margin-bottom: 1.87rem;
  width: 100%;

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .ant-select-selection-item {
    font-family: "Poppins";
    font-size: 0.93rem;
    color: ${Colors.secondary40};
  }
`;

export const BorderSelect = styled.div`
  border: 2px solid ${Colors.secondary20};
  width: 100%;
  height: 2.81rem;
  border-radius: 0.37rem;
  margin-bottom: 0.45rem;
  margin-right: 0.51rem;
  display: flex;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 0px;

    height: 32px;
    border-radius: 6px;
  }
`;

export const GridInputs = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  @media (max-width: 670px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
`;

export const ContainerCheckConditions = styled.div`
  display: flex;
  margin-top: 1.68rem;

  gap: 0.98rem;

  .ant-checkbox .ant-checkbox-inner {
    background-color: ${Colors.white};
    border: 1px solid ${Colors.secondary50};
    border-radius: 3px;
  }
  .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${Colors.primary100};
    border: 1px solid ${Colors.primary100};
    border-radius: 3px;
  }
  .ant-checkbox.ant-checkbox-checked::after {
    border: 1px solid ${Colors.primary100};
    border-radius: 3px;
  }
`;

export const TextTerms = styled.h5`
  color: ${Colors.secondary70};
  font-size: 0.93rem;
  font-family: Poppins;
  margin-top: 0px;

  a {
    text-decoration: underline;
    color: ${Colors.primary100};
  }
`;

export const ContainerCheck = styled.div`
  margin-top: 1.68rem;
  margin-bottom: 2.06rem;

  .ant-checkbox .ant-checkbox-inner {
    background-color: ${Colors.white};
    border: 1px solid ${Colors.secondary50};
    border-radius: 3px;
  }
  .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${Colors.primary100};
    border: 1px solid ${Colors.primary100};
    border-radius: 3px;
  }
  .ant-checkbox.ant-checkbox-checked::after {
    border: 1px solid ${Colors.primary100};
  }
`;

export const ButtonNext = styled(Button)`
  cursor: pointer;
  outline: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18.23rem;
  height: 3.84rem;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 14px;
  background: ${Colors.primary100};

  @media (max-width: 670px) {
    width: 124px;
    height: 38px;
    border-radius: 8px;
  }

  &&& {
    background-color: ${Colors.primary100};
    border-color: ${Colors.primary100};
  }

  ${(props) => props.disabled && { opacity: 0.7 }}

  span {
    color: ${Colors.white};
    text-align: center;
    font-family: Cairo;
    font-size: 1.21rem;
    font-style: normal;
    font-weight: 700;

    @media (max-width: 670px) {
      font-size: 12px;
    }
  }
`;
