import {
  SubmitHandler,
  UseFormRegister,
  UseFormHandleSubmit,
} from "react-hook-form";
import { BiSearch } from "react-icons/bi";
import { Colors } from "constants/colors";
import { LocationSelector } from "./LocationSelector";

interface SearchFormData {
  title: string;
}

interface SearchFormProps {
  register: UseFormRegister<SearchFormData>;
  handleSubmit: UseFormHandleSubmit<SearchFormData>;
  searchOrRouter: SubmitHandler<SearchFormData>;
  showModalSearch: () => void;
}

function SearchForm({
  register,
  handleSubmit,
  searchOrRouter,
  showModalSearch,
}: SearchFormProps) {
  return (
    <form onSubmit={handleSubmit(searchOrRouter)}>
      <LocationSelector showModalSearch={showModalSearch} />
      <div className="searchInput">
        <input
          placeholder="Pesquise eventos, locais, serviços..... web"
          {...register("title")}
        />
      </div>
      <button className="search" type="submit">
        <BiSearch size={18} color={Colors.primary100} />
      </button>
    </form>
  );
}

export default SearchForm;
