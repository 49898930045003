import { createContext, useContext, useState } from "react";
import { TicketEvent } from "types";

type CreateAndEditTicketData = {
  isOpenModalTicket: boolean;
  setIsOpenModalTicket(value: boolean): void;
  isOpenDrawerTicket: boolean;
  setIsOpenDrawerTicket(value: boolean): void;
  ticket: TicketEvent | null;
  setSelectedTicket(value: TicketEvent | null): void;
};

const CreateAndEditTicket = createContext<CreateAndEditTicketData>(
  {} as CreateAndEditTicketData
);

export const CreateAndEditTicketProvider: React.FC = ({ children }) => {
  const [ticket, setSelectedTicket] = useState<TicketEvent | null>(null);

  const [isOpenModalTicket, setIsOpenModalTicket] = useState(false);
  const [isOpenDrawerTicket, setIsOpenDrawerTicket] = useState(false);

  return (
    <CreateAndEditTicket.Provider
      value={{
        isOpenModalTicket,
        setIsOpenModalTicket,
        isOpenDrawerTicket,
        setIsOpenDrawerTicket,
        ticket,
        setSelectedTicket,
      }}
    >
      {children}
    </CreateAndEditTicket.Provider>
  );
};

export function useCreateAndEditTicket(): CreateAndEditTicketData {
  const context = useContext(CreateAndEditTicket);

  if (!context) {
    throw new Error(
      `useCreateAndEditTicket it depends CreateAndEditTicketProvider`
    );
  }

  return context;
}
