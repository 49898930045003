import { MdEventNote } from "react-icons/md";
import { Container } from "./styles";

type GenerateReportButtonProps = {
  onHandleGenerateButton: () => void;
}

function GenerateReportButton({ onHandleGenerateButton }: GenerateReportButtonProps) {
  return (
    <Container onClick={onHandleGenerateButton}>
      <MdEventNote />
      <span>Gerar relatório</span>
    </Container>
  );
}

export default GenerateReportButton;
