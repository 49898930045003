import styled from "styled-components";

export const GridInputs = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  @media (max-width: 670px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
`;
