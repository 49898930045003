import { Container, MobileContainer, MobileTabContainer, TabStyled } from "./styles";

import { useState } from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

type TabsType = {
  link: string;
  name: string;
};

interface TabsProps {
  data: TabsType[];
  handleView: (view: any) => void;
}

export function Tabs({ data, handleView }: TabsProps) {
  const [currentTab, setCurrentTab] = useState(data[0].name);
  const { lg: isDesktop } = useBreakpoint();

  const setTab = (newTab: string) => {
    setCurrentTab(newTab);
    handleView(newTab);
  };

  if (!isDesktop) {
    return (
      <MobileContainer>
        {data.map((tab) => (
          <MobileTabContainer
            onClick={() => setTab(tab.name)}
            className={currentTab === tab.name ? 'activeTab' : ''}
            key={tab.name}
          >
            <span>{tab.name}</span>
          </MobileTabContainer>
        ))}
      </MobileContainer>
    );
  }

  return (
    <Container>
      <div className="tabs-content">
        {data.map((tab, index) => {
          return (
            <div
              className="all-index"
              onClick={() => {
                handleView(tab.name);
              }}
              key={index}
            >
              {tab.name === currentTab ? (
                <TabStyled
                  active
                  onClick={() => {
                    setTab(tab.name);
                  }}
                >
                  {tab.name}
                </TabStyled>
              ) : null}

              {tab.name !== currentTab ? (
                <TabStyled
                  active={false}
                  onClick={() => {
                    setTab(tab.name);
                  }}
                >
                  {tab.name}
                </TabStyled>
              ) : null}
            </div>
          );
        })}
      </div>
      <hr />
    </Container>
  );
}
