import { useCallback, useRef, useState } from "react";

import { ResendTicketTable } from "components/ResendTicketTable";
import { Pagination } from "components/Pagination";

import { HeaderFilter } from "components/HeaderFilter";
import { sortingFilterTicketOffice } from "constants/filters";
import { ModalFilterByData } from "components/ModalFilterByData";
import { ModalFilterByEvent } from "components/ModalFilterByEvent";
import { ModalFilterByTicket } from "components/ModalFilterByTicket";
import { LeadsCardContainer, LeadsContainer, HiddenResponsive } from "./styles";

import LeadCard from "./components/LeadCard";
import { useSearchUserLedsBalance } from "hooks/useSearchUserLedsBalance";
import { useReactToPrint } from "react-to-print";

import { TicketForPrint, TicketPrintType } from "components/TicketForPrint";

import html2Canvas from "html2canvas";
import jsPDF from "jspdf";
import { createPortal } from "react-dom";

import { ResendUserTicket } from "services/Hook/TicketOffice";
import {
  errorNotification,
  successNotification,
} from "components/Notification";
import useDefaultFilterByToday from "hooks/useDefaultFilterByToday";
import { FilterContainer } from "pages/TicketOffice/components/TicketOfficeView/styles";
import { FiltersByDataType } from "components/FiltersByDataType";
import { Loading } from "components/Loading";
import { EmptySearchComponent } from "components/EmptySearchComponent";
import LoadMoreItems from "components/LoadMoreItems";
import { TitleTransactions, TitleTransactionsResponsive } from "pages/Financial/Tabs/Transaction/styles";
import { DropDownSearch } from "components/DropDownSearch";

function ResendTicket() {
  useDefaultFilterByToday();
  const {
    data,
    page,
    onChange,
    hasMoreItems,
    handleLoadMorePurchasingsMobile,
    loadingMoreItems,
  } = useSearchUserLedsBalance({});

  const [selectedTicket, setSelectedTicket] = useState<TicketPrintType | null>(
    null
  );

  const componentRef = useRef(null);

  function sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const [fetchingResendTicket, setFetchingResendTicket] = useState(false);
  const handleResendTicket = async (userTicketId: string) => {
    try {
      setFetchingResendTicket(true);
      await ResendUserTicket({ userTicketId });
      successNotification("Ingresso reenviado com sucesso.");
    } catch {
      errorNotification("Ocorreu um erro ao reenviar o ingresso.");
    } finally {
      setFetchingResendTicket(false);
    }
  };

  const downloadPDF = async (ticket: TicketPrintType) => {
    setSelectedTicket(ticket);

    await sleep(1000);

    const input = componentRef.current;

    if (input) {
      html2Canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg");
        const pdf = new jsPDF("p", "mm", "a4", true);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
        const imgX = (pdfWidth - imgWidth * ratio) / 2;
        const imgY = 30;
        pdf.addImage(
          imgData,
          "JPEG",
          imgX,
          imgY,
          imgWidth * ratio,
          imgHeight * ratio
        );

        pdf.save(selectedTicket?.event_ticket_id?.name ?? "Ingresso");
      });
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleSelectedTicket = useCallback(
    async (ticket: TicketPrintType) => {
      setSelectedTicket(ticket);

      await sleep(1000);

      handlePrint();
    },
    [handlePrint]
  );

  return (
    <>
      <HiddenResponsive>
        <HeaderFilter filters={sortingFilterTicketOffice} />
      </HiddenResponsive>

      <TitleTransactions>Pesquisar por:</TitleTransactions>

      <TitleTransactionsResponsive>pesquisar por</TitleTransactionsResponsive>

      <DropDownSearch />

      <LeadsContainer>
        <HiddenResponsive>
          {data && data?.results?.length > 0 ? (
            <ResendTicketTable leads={data?.results} />
          ) : null}

          {!data ? <Loading /> : null}

          {data && data?.results?.length > 0 ? (
            <Pagination
              title="registros"
              totalCountOfRegisters={data?.count}
              currentPage={Number(page)}
              onPageChange={onChange}
              registersPerPage={10}
            />
          ) : null}
        </HiddenResponsive>

        <FilterContainer>
          <hr />

          <FiltersByDataType />
        </FilterContainer>

        <LeadsCardContainer>
          {data?.results?.map((lead) => (
            <LeadCard
              key={lead._id}
              name={lead?.holder_name}
              email={lead?.holder_email}
              handleResendTicket={() => handleResendTicket(lead._id)}
              handlePrintOutTicket={() =>
                handleSelectedTicket({
                  event: lead?.event,
                  event_ticket_id: lead?.event_ticket_id,
                  holder_name: lead?.holder_name,
                  purchase_id: lead?.purchase_id,
                })
              }
              handleDownloadTicket={() => {
                downloadPDF({
                  event: lead?.event,
                  event_ticket_id: lead?.event_ticket_id,
                  holder_name: lead?.holder_name,
                  purchase_id: lead?.purchase_id,
                });
              }}
              eventName={lead?.event.name}
              eventTicketName={lead?.event_ticket_id.name}
              disabledResendTicket={fetchingResendTicket}
            />
          ))}
        </LeadsCardContainer>

        {!data ? <Loading /> : null}

        {data && data?.results?.length === 0 ? <EmptySearchComponent /> : null}

        <LoadMoreItems
          handleLoadMore={handleLoadMorePurchasingsMobile}
          fetching={loadingMoreItems}
          hasMoreItems={hasMoreItems}
          length={data?.results?.length}
          total={data?.count}
        />
      </LeadsContainer>

      <ModalFilterByData />
      <ModalFilterByEvent />
      <ModalFilterByTicket />

      {createPortal(
        <>
          {selectedTicket ? (
            <TicketForPrint
              ref={componentRef}
              selectedTicket={selectedTicket}
            />
          ) : null}
        </>,
        document.body
      )}
    </>
  );
}

export default ResendTicket;
