import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Select, Drawer, DatePicker } from "antd";

import {
  RiCalendar2Line,
  RiCloseCircleLine,
  RiContactsLine,
  RiMoneyDollarCircleLine,
} from "react-icons/ri";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { useFiltersContext } from "contexts/FiltersContext";

import { Colors } from "constants/colors";

import {
  BorderSelect,
  ColumnDrawer,
  ContainerDrawer,
  ContainerFilterDrawer,
  ContainerForm,
  ContentDrawer,
  InputContainer,
  LabelFilter,
  SelectStyled,
} from "./styles";
import moment from "moment";

import { useSearchMyEvent } from "hooks/useSearchMyEvent";
import { BadgeWithClose } from "components/DrawerFilterGeneral/styles";
import { AiFillCloseCircle } from "react-icons/ai";
import { useAuthOrganizer } from "services/Hook/Organizer";
import { useAuthTicketsByEventsId } from "services/Hook/Event/Ticket";
import { TicketEvent } from "types";
import { dataPayment } from "components/ModalFilterByFormPayment";
import { dataStatus } from "components/ModalFilterByStatus";

const { Option } = Select;

type CreateDataEventFormData = {
  organizer: string;
  events: string;
  tickets: string;
  payment_type: string;
  price_min: number;
  price_max: number;
  data_gte: string;
  data_lte: string;
  payment_status: string;
};

const createDataEventFormSchema = yup.object().shape({
  price_min: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, "A Quantidade mínima é 1")
    .max(999999, "A Quantidade máxima é 999999")
    .notRequired(),
  price_max: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, "A Quantidade mínima é 1")
    .max(999999, "A Quantidade máxima é 999999")
    .notRequired(),
  begin_date: yup.string().nullable().notRequired(),
  end_date: yup.string().nullable().notRequired(),
  events: yup.string().notRequired(),
  tickets: yup.string().notRequired(),
  payment_type: yup.string().notRequired(),
  payment_status: yup.string().notRequired(),
});

const dateFormat = "DD/MM/YYYY";

export function DrawerFilterTransaction() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [nameOrganizer, setNameOrganizer] = useState("");

  const { data: dataOrganizers } = useAuthOrganizer();

  const organizer = searchParams.get("organizer") ?? "";
  const data_gte = searchParams.get("data_gte") ?? "";
  const data_lte = searchParams.get("data_lte") ?? "";

  const { data: dataEvents } = useSearchMyEvent();

  const { isOpenFilterDrawerTransactions, setIsOpenFilterDrawerTransactions } =
    useFiltersContext();

  const { control, register, handleSubmit, watch, reset } =
    useForm<CreateDataEventFormData>({
      resolver: yupResolver(createDataEventFormSchema),
    });

  const eventWatch = watch("events");

  const events = searchParams.get("events") ?? "";

  const { data: dataTickets } = useAuthTicketsByEventsId<TicketEvent[]>([
    String(eventWatch ?? ""),
  ]);

  useEffect(() => {
    if (organizer) {
      if (dataOrganizers) {
        const organizerSearch = dataOrganizers?.find(
          (organizerFind: any) => String(organizerFind.id) === organizer
        );

        if (organizerSearch) {
          setNameOrganizer(organizerSearch?.name);
        }
      }
    }
  }, [dataOrganizers, organizer]);

  const handleCloseDrawer = () => {
    setIsOpenFilterDrawerTransactions(false);
  };

  const handleAddFilterTransaction: SubmitHandler<
    CreateDataEventFormData
  > = async ({
    price_min,
    price_max,
    organizer,
    events,
    payment_type,
    tickets,
    data_gte,
    data_lte,
    payment_status,
  }) => {
    if (data_gte && data_lte) {
      searchParams.delete("data_type");
    }

    if (data_gte === "" && data_lte === "") {
      searchParams.delete("data_gte");
      searchParams.delete("data_lte");
    }

    if (!organizer) {
      searchParams.delete("data_type");
    }

    if (!events) {
      searchParams.delete("events");
    }

    if (!tickets) {
      searchParams.delete("tickets");
    }

    if (!payment_status) {
      searchParams.delete("payment_status");
    }

    const dateGte = moment(data_gte, "DD/MM/YYYY");
    const formatDateGte = dateGte.format("YYYY-MM-DD");

    const dateLte = moment(data_lte, "DD/MM/YYYY");
    const formatDateLte = dateLte.format("YYYY-MM-DD");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      ...(!!data_gte &&
        !!data_lte && {
          data_gte: formatDateGte,
          data_lte: formatDateLte,
        }),
      ...(!!price_min &&
        price_max && {
          price_min: String(price_min),
          price_max: String(price_max),
        }),
      ...(!!organizer && { organizer: String(organizer) }),
      ...(!!events && { events: String(events) }),
      ...(!!payment_type && { payment_type: String(payment_type) }),
      ...(!!tickets && { tickets: String(tickets) }),

      ...(!!payment_status && { payment_status: String(payment_status) }),
    });

    setIsOpenFilterDrawerTransactions(false);
  };

  const clear = () => {
    reset({
      data_gte: "",
      data_lte: "",
      events: "",
      organizer: "",
      payment_type: "",
      price_max: undefined,
      price_min: undefined,
      tickets: "",
    });
  };

  const closeOrganizer = () => {
    searchParams.delete("organizer");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });

    reset({
      organizer: "",
    });
  };

  const dataGte = data_gte !== "" ? moment(data_gte, "YYYY-MM-DD") : "";
  const formattedDataGte = dataGte !== "" ? dataGte.format("DD/MM/YYYY") : "";

  const dataLte = data_gte !== "" ? moment(data_lte, "YYYY-MM-DD") : "";
  const formattedDataLte = dataLte !== "" ? dataLte.format("DD/MM/YYYY") : "";

  return (
    <Drawer
      title="Filtros"
      className="custom-drawer"
      width={278}
      drawerStyle={{ color: Colors.secondary100 }}
      headerStyle={{
        paddingLeft: "14px",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
        color: Colors.secondary100,
        fontWeight: "bold",
      }}
      closable={false}
      bodyStyle={{ padding: 0 }}
      placement="right"
      onClose={handleCloseDrawer}
      visible={isOpenFilterDrawerTransactions}
      extra={
        <RiCloseCircleLine
          size={24}
          color={Colors.secondary100}
          onClick={handleCloseDrawer}
        />
      }
    >
      <ContainerForm onSubmit={handleSubmit(handleAddFilterTransaction)}>
        <ContainerFilterDrawer>
          <ContentDrawer>
            <ColumnDrawer>
              <LabelFilter>Organizador</LabelFilter>

              <Controller
                control={control}
                name="organizer"
                rules={{ required: "Salutation is required" }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <BorderSelect>
                    <SelectStyled
                      size="middle"
                      labelInValue
                      showSearch
                      allowClear
                      bordered={false}
                      placeholder="Insira o nome ou selecione..."
                      value={value}
                      ref={ref}
                      style={{
                        width: "100%",
                      }}
                      onChange={(e: any) => {
                        onChange(e.key);
                      }}
                    >
                      {dataOrganizers?.map((organizer: any) => (
                        <Option value={organizer?._id} key={organizer?._id}>
                          {organizer?.name}
                        </Option>
                      ))}
                    </SelectStyled>
                  </BorderSelect>
                )}
              />
            </ColumnDrawer>

            {organizer ? (
              <BadgeWithClose>
                <div className="content">
                  <RiContactsLine size={12} color={Colors.secondary70} />

                  <span>{nameOrganizer}</span>
                </div>

                <AiFillCloseCircle
                  onClick={closeOrganizer}
                  size={14}
                  color={Colors.secondary70}
                />
              </BadgeWithClose>
            ) : null}
          </ContentDrawer>
        </ContainerFilterDrawer>

        <ContainerFilterDrawer>
          <ContentDrawer>
            <ColumnDrawer>
              <LabelFilter>Data</LabelFilter>

              <InputContainer>
                <div>
                  <RiCalendar2Line size={18} color={Colors.secondary40} />
                </div>

                <Controller
                  control={control}
                  name="data_gte"
                  defaultValue={formattedDataGte}
                  rules={{ required: "Salutation is required" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <DatePicker
                      format={dateFormat}
                      placeholder="Selecione a data inicial"
                      suffixIcon={false}
                      style={{
                        height: "auto",
                        width: "100%",
                        border: "none",
                        borderRadius: "0px",
                        cursor: "pointer",
                        fontSize: "17px",
                        margin: "0px",
                        marginLeft: "10px",
                        padding: "0px",
                      }}
                      ref={ref}
                      value={value ? moment(value, dateFormat) : null}
                      onChange={(date: unknown, dateString: string) => {
                        if (!dateString) {
                          onChange(null);
                          return;
                        }

                        onChange(dateString);
                      }}
                    />
                  )}
                />
              </InputContainer>

              <InputContainer>
                <div>
                  <RiCalendar2Line size={18} color={Colors.secondary40} />
                </div>

                <Controller
                  control={control}
                  name="data_lte"
                  defaultValue={formattedDataLte}
                  rules={{ required: "Salutation is required" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <DatePicker
                      format={dateFormat}
                      placeholder="Selecione a data final"
                      suffixIcon={false}
                      style={{
                        height: "auto",
                        width: "100%",
                        border: "none",
                        borderRadius: "0px",
                        cursor: "pointer",
                        fontSize: "17px",
                        margin: "0px",
                        marginLeft: "10px",
                        padding: "0px",
                      }}
                      ref={ref}
                      value={value ? moment(value, dateFormat) : null}
                      onChange={(date: unknown, dateString: string) => {
                        if (!dateString) {
                          onChange(null);
                          return;
                        }

                        onChange(dateString);
                      }}
                    />
                  )}
                />
              </InputContainer>
            </ColumnDrawer>
          </ContentDrawer>
        </ContainerFilterDrawer>

        <ContainerFilterDrawer>
          <ContentDrawer>
            <ColumnDrawer>
              <LabelFilter>Faixa de valor</LabelFilter>

              <InputContainer>
                <div>
                  <RiMoneyDollarCircleLine
                    size={18}
                    color={Colors.secondary40}
                  />
                </div>
                <input
                  type="number"
                  placeholder="Valor mínimo"
                  {...register("price_min")}
                  min={0}
                />
              </InputContainer>

              <InputContainer>
                <div>
                  <RiMoneyDollarCircleLine
                    size={18}
                    color={Colors.secondary40}
                  />
                </div>
                <input
                  type="number"
                  placeholder="Valor máximo"
                  {...register("price_max")}
                  min={0}
                />
              </InputContainer>
            </ColumnDrawer>
          </ContentDrawer>
        </ContainerFilterDrawer>

        <ContainerFilterDrawer>
          <ContentDrawer>
            <ColumnDrawer>
              <LabelFilter>Evento</LabelFilter>

              <Controller
                control={control}
                defaultValue={events}
                rules={{ required: "Salutation is required" }}
                name="events"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <BorderSelect>
                    <SelectStyled
                      size="middle"
                      labelInValue
                      showSearch
                      allowClear
                      bordered={false}
                      placeholder="Insira o nome ou selecione..."
                      ref={ref}
                      style={{
                        width: "100%",
                      }}
                      onChange={(e: any) => {
                        if (e === undefined) {
                          onChange(null);
                          return;
                        }

                        onChange(e.key);
                      }}
                    >
                      {dataEvents?.results?.map((event) => (
                        <Option value={event?._id} key={event?._id}>
                          {event.name}
                        </Option>
                      ))}
                    </SelectStyled>
                  </BorderSelect>
                )}
              />
            </ColumnDrawer>
          </ContentDrawer>
        </ContainerFilterDrawer>

        <ContainerFilterDrawer>
          <ContentDrawer>
            <ColumnDrawer>
              <LabelFilter>Tipo de ingresso</LabelFilter>

              <Controller
                control={control}
                name="tickets"
                rules={{ required: "Salutation is required" }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <BorderSelect>
                    <SelectStyled
                      size="middle"
                      labelInValue
                      showSearch
                      allowClear
                      bordered={false}
                      disabled={!eventWatch}
                      placeholder="Insira o nome ou selecione..."
                      value={value}
                      ref={ref}
                      style={{
                        width: "100%",
                      }}
                      onChange={(e: any) => {
                        if (e === undefined) {
                          onChange(null);
                          return;
                        }

                        onChange(e.key);
                      }}
                    >
                      {dataTickets?.map((ticket) => (
                        <Option value={ticket?._id} key={ticket._id}>
                          {ticket?.name}
                        </Option>
                      ))}
                    </SelectStyled>
                  </BorderSelect>
                )}
              />
            </ColumnDrawer>
          </ContentDrawer>
        </ContainerFilterDrawer>

        <ContainerFilterDrawer>
          <ContentDrawer>
            <ColumnDrawer>
              <LabelFilter>Forma de pagamento</LabelFilter>

              <Controller
                control={control}
                name="payment_type"
                rules={{ required: "Salutation is required" }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <BorderSelect>
                    <SelectStyled
                      size="middle"
                      labelInValue
                      showSearch
                      allowClear
                      bordered={false}
                      placeholder="Insira o nome ou selecione..."
                      value={value}
                      ref={ref}
                      style={{
                        width: "100%",
                      }}
                      onChange={(e: any) => {
                        onChange(e.key);
                      }}
                    >
                      {dataPayment.map((payment) => (
                        <Option value={payment.key} key={payment.key}>
                          {payment.name}
                        </Option>
                      ))}
                    </SelectStyled>
                  </BorderSelect>
                )}
              />
            </ColumnDrawer>
          </ContentDrawer>
        </ContainerFilterDrawer>

        <ContainerFilterDrawer>
          <ContentDrawer>
            <ColumnDrawer>
              <LabelFilter>Status pagamento</LabelFilter>

              <Controller
                control={control}
                name="payment_status"
                rules={{ required: "Salutation is required" }}
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <BorderSelect>
                    <SelectStyled
                      size="middle"
                      labelInValue
                      showSearch
                      allowClear
                      bordered={false}
                      placeholder="Insira o nome ou selecione..."
                      value={value}
                      ref={ref}
                      style={{
                        width: "100%",
                      }}
                      onChange={(e: any) => {
                        onChange(e.key);
                      }}
                    >
                      {dataStatus.map((payment) => (
                        <Option value={payment.key} key={payment.key}>
                          {payment.name}
                        </Option>
                      ))}
                    </SelectStyled>
                  </BorderSelect>
                )}
              />
            </ColumnDrawer>
          </ContentDrawer>
        </ContainerFilterDrawer>

        <ContainerDrawer>
          <div className="buttons-drawer">
            <button type="button" className="first" onClick={clear}>
              Limpar
            </button>

            <button type="submit" className="second">
              Aplicar
            </button>
          </div>
        </ContainerDrawer>
      </ContainerForm>
    </Drawer>
  );
}
