import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { IoCloseCircleSharp } from "react-icons/io5";

import { Colors } from "constants/colors";

import {
  BorderSelect,
  Container,
  DivIsSelected,
  SelectStyledWithoutDrop,
} from "./styles";
import { KeySort } from "constants/filters";
import { useFiltersContext } from "contexts/FiltersContext";
import { useAuthOrganizer } from "services/Hook/Organizer";

interface SelectFilterModalProps {
  title: string;
  sortKey: KeySort;
}

// pegar organizador selecionado por parametro no caso -> organizer_name
export function SelectOrganizerFilter({
  title,
  sortKey,
}: SelectFilterModalProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [name, setName] = useState("");

  const { data } = useAuthOrganizer();

  const { setIsOpenFilterModalOrganizer } = useFiltersContext();

  const filter = searchParams.get(sortKey) ?? "";

  const clearId = useCallback(() => {
    searchParams.delete(sortKey);

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [searchParams, setSearchParams, sortKey]);

  useEffect(() => {
    if (sortKey) {
      if (data) {
        const organizer = data?.find(
          (organizer: any) => String(organizer._id) === filter
        );

        if (organizer) {
          setName(organizer?.name);
        }
      }
    }
  }, [data, filter, sortKey]);

  const handleOpenModal = () => {
    setIsOpenFilterModalOrganizer(true);
  };

  return (
    <Container>
      {filter === "" ? (
        <BorderSelect>
          <SelectStyledWithoutDrop
            bordered={false}
            labelInValue
            size="large"
            defaultValue={{ value: 0, label: title }}
            open={false}
            onClick={handleOpenModal}
          />
        </BorderSelect>
      ) : (
        <DivIsSelected>
          <h3 className="selected">{name}</h3>
          <IoCloseCircleSharp
            onClick={clearId}
            style={{ cursor: "pointer" }}
            size={18}
            color={Colors.white}
          />
        </DivIsSelected>
      )}
    </Container>
  );
}
