import { DropDown, SearchItem } from "components/Header/styles";
import { Colors } from "constants/colors";
import {
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import { BiSearch } from "react-icons/bi";
import { FiMapPin } from "react-icons/fi";
import { VscChevronDown } from "react-icons/vsc";

interface SearchFormData {
  title: string;
}

interface MobileSearchFormProps {
  register: UseFormRegister<SearchFormData>;
  showModalSearch: () => void;
  handleSubmit: UseFormHandleSubmit<SearchFormData>;
  searchOrRouter: SubmitHandler<SearchFormData>;
}

function MobileSearchForm({
  register,
  handleSubmit,
  searchOrRouter,
  showModalSearch,
}: MobileSearchFormProps) {
  return (
    <DropDown>
      <SearchItem>
        <form>
          <div className="dropdown" onClick={showModalSearch}>
            <div>
              <FiMapPin size={14} color={Colors.primary100} />
              <h3>Qualquer lugar</h3>
            </div>
            <VscChevronDown size={16} color={Colors.primary100} />
          </div>
        </form>

        <form onSubmit={handleSubmit(searchOrRouter)}>
          <div className="searchInput">
            <input
              {...register("title")}
              placeholder="Pesquise eventos, locais, organizadores..."
            />
          </div>

          <button className="search" type="submit">
            <BiSearch size={20} color={Colors.primary100} />
          </button>
        </form>
      </SearchItem>
    </DropDown>
  );
}

export default MobileSearchForm;
