import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { RiCloseCircleLine, RiIncreaseDecreaseLine } from "react-icons/ri";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { useFiltersContext } from "contexts/FiltersContext";

import { Colors } from "constants/colors";

import {
  ButtonClear,
  ButtonOk,
  ContainerButtonsModal,
  ContainerCustomData,
  ContainerInputFull,
  ContainerModal,
  FormLabel,
  GridInputs,
  ModalStyled,
  SliderStyled,
} from "./styles";

type CreateDataEventFormData = {
  price_min: number;
  price_max: number;
};

const createDataEventFormSchema = yup.object().shape({
  price_min: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, "A Quantidade mínima é 1")
    .max(999999, "A Quantidade máxima é 999999")
    .required("Quantidade mínima obrigatória"),
  price_max: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, "A Quantidade mínima é 1")
    .max(999999, "A Quantidade máxima é 999999")
    .required("Quantidade máxima obrigatória"),
});

export function ModalFilterByPrice() {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<CreateDataEventFormData>({
    resolver: yupResolver(createDataEventFormSchema),
  });

  const { isOpenFilterModalPrice, setIsOpenFilterModalPrice } =
    useFiltersContext();

  const closeModal = () => {
    setIsOpenFilterModalPrice(false);
  };

  const handleChange = useCallback(
    (values: number[]) => {
      const [price_min, price_max] = values;

      setValue("price_min", price_min);
      setValue("price_max", price_max);
    },
    [setValue]
  );

  const handleAddFilterPrice: SubmitHandler<CreateDataEventFormData> = async ({
    price_min,
    price_max,
  }) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      price_min: String(price_min),
      price_max: String(price_max),
    });

    setIsOpenFilterModalPrice(false);
  };

  const clear = () => {
    searchParams.delete("price_min");
    searchParams.delete("price_max");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  };

  const price_min = watch("price_min");
  const price_max = watch("price_max");

  return (
    <ModalStyled
      width={857}
      visible={isOpenFilterModalPrice}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <h1>Filtrar por valor</h1>

        <h2>Defina uma faixa de valor para a visualização dos resultados.</h2>

        <ContainerCustomData onSubmit={handleSubmit(handleAddFilterPrice)}>
          <SliderStyled
            range={{ draggableTrack: true }}
            max={1000}
            min={0}
            value={[price_min, price_max]}
            onChange={handleChange}
            defaultValue={[20, 50]}
          />

          <GridInputs>
            <ContainerInputFull isFull error={!!errors.price_min}>
              <FormLabel>
                <RiIncreaseDecreaseLine size={22} color={Colors.secondary40} />
                <h2>Valor inicial</h2>
              </FormLabel>

              <input
                type="number"
                {...register("price_min")}
                min={0}
                max={1000}
              />

              {!!errors.price_min ? (
                <span className="error">{errors?.price_min?.message}</span>
              ) : null}
            </ContainerInputFull>

            <ContainerInputFull isFull error={!!errors.price_max}>
              <FormLabel>
                <RiIncreaseDecreaseLine size={22} color={Colors.secondary40} />
                <h2>Valor final</h2>
              </FormLabel>

              <input
                type="number"
                {...register("price_max")}
                min={0}
                max={1000}
              />

              {!!errors.price_max ? (
                <span className="error">{errors?.price_max?.message}</span>
              ) : null}
            </ContainerInputFull>
          </GridInputs>

          <ContainerButtonsModal>
            <ButtonClear type="button" onClick={clear}>
              <span>Limpar</span>
            </ButtonClear>

            <ButtonOk type="submit">
              <span>Aplicar filtros</span>
            </ButtonOk>
          </ContainerButtonsModal>
        </ContainerCustomData>
      </ContainerModal>
    </ModalStyled>
  );
}
