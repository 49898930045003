import * as React from "react";
import { SVGProps } from "react";

const Withdraw = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={17}
    fill="none"
    {...props}
  >
    <path
      fill="#D40050"
      d="M18.516 14.5v-12H2.484v12h16.032Zm0-14.016c.562 0 1.03.204 1.406.61.375.375.562.843.562 1.406v12c0 .563-.187 1.047-.562 1.453-.375.375-.844.563-1.406.563H2.484c-.562 0-1.03-.188-1.406-.563A2.069 2.069 0 0 1 .516 14.5v-12c0-.563.187-1.031.562-1.406.375-.407.844-.61 1.406-.61h16.032Zm-9 13.032v-1.032H7.5v-1.968h3.984V9.484h-3a.954.954 0 0 1-.703-.28A.954.954 0 0 1 7.5 8.5v-3c0-.281.094-.516.281-.703a.954.954 0 0 1 .703-.281h1.032V3.484h1.968v1.032H13.5v1.968H9.516v1.032h3c.28 0 .515.093.703.28a.954.954 0 0 1 .281.704v3a.954.954 0 0 1-.281.703.954.954 0 0 1-.703.281h-1.032v1.032H9.516Z"
    />
  </svg>
);

export default Withdraw;
