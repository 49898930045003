import { Colors } from "constants/colors";
import { sizeConversor } from "utils/conversors";
import styled from "styled-components";

export const Wrapper = styled.div`
  @media (max-width: 768px) {
    padding-bottom: 50px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  @media (max-width: 800px) {
    margin-bottom: 2.12rem;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1276px) {
    justify-content: center;
  }
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;
  margin-top: 9.14rem;
  width: 100%;
  padding: 0 38px;

  @media (max-width: 1024px) {
    margin-top: 70px;
    padding-left: 28px;
    padding-right: 28px;
  }

  div.header {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    @media (max-width: 800px) {
      padding-right: 28px;
      margin-bottom: 26px;
    }

    h2 {
      margin-bottom: 0px !important;

      @media (max-width: 800px) {
        font-weight: bold;
      }
    }

    nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      div.dots {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 21px;
      }
    }
  }

  div.main-header {
    display: flex;
    justify-content: space-between;
    margin-top: 2.53rem;

    h1 {
      font-family: "Cairo";
      font-weight: 700;
      font-size: 1.87rem;
      margin-bottom: 0px;
      color: ${Colors.black100};
      margin-top: 0 !important;
    }

    .filter-container {
      display: flex;
      align-items: center;
      column-gap: 12px;
    }

    @media (max-width: 768px) {
      align-items: center;
      margin-top: 24px;

      > h1 {
        font-size: ${sizeConversor(24)};
      }
    }
  }

  h2 {
    color: var(--preto-preto-100, #11142d);
    font-family: Cairo;
    font-size: ${sizeConversor(32)};
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }

  .rectangle {
    h3 {
      color: var(--preto-preto-30, #b8b9c0);
      font-family: Open Sans;
      font-size: ${sizeConversor(26)};
      font-style: normal;
      font-weight: 700;
    }
  }

  hr {
    border-top: ${sizeConversor(1)} solid rgba(233, 231, 238, 1);
    width: 100%;
  }

  .rectangle {
    hr {
      margin: ${sizeConversor(80)} 0;
    }
  }

  div.bread {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const SyntheticSelect = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: Poppins;
  font-size: ${sizeConversor(20)};
  font-weight: 400;

  width: ${sizeConversor(397)};
  height: ${sizeConversor(60)};
  border-radius: ${sizeConversor(8)};
  border: ${sizeConversor(2)} solid var(--secundaria-secundaria-20, #d3cfde);
  cursor: pointer;

  padding: ${sizeConversor(14)} ${sizeConversor(20)};
`;

export const ButtonFilter = styled.button`
  cursor: pointer;
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${Colors.white20};
  width: 25.735px;
  height: 25.735px;

  @media (min-width: 670px) {
    display: none;
  }
`;

export const ContainerFilters = styled.div`
  margin-top: 8px;
  @media (min-width: 670px) {
    display: none;
  }
`;
