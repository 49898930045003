import * as yup from "yup";

export const createTicketEventFormSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  quantity: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, "A Quantidade mínima é 1")
    .max(999999, "A Quantidade máxima é 999999")
    .required("Quantidade obrigatória"),
  price: yup.string().required("Preço obrigatório"),
  create_half_price: yup.boolean().notRequired(),
  half_quantity: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .notRequired()
    .when("create_half_price", {
      is: true,
      then: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .min(1, "A Quantidade mínima é 1")
        .max(999999, "A Quantidade máxima é 999999")
        .required("Quantidade obrigatória"),
    }),
  half_price: yup
    .string()
    .notRequired()
    .when("create_half_price", {
      is: true,
      then: yup.string().required("Preço obrigatório"),
    }),
  description: yup.string().notRequired(),
  type_sale: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required("Requerimento obrigatório"),
  discount: yup.string().notRequired(),
  sale_ticket: yup
    .string()
    .notRequired()
    .when("type_sale", {
      is: 2,
      then: yup.string().required("Horário de início obrigatório"),
    }),
  sale_begin_date: yup
    .string()
    .notRequired()
    .when("type_sale", {
      is: 1,
      then: yup.string().required("Data inicial obrigatória"),
    }),
  sale_begin_hour: yup
    .string()
    .notRequired()
    .when("type_sale", {
      is: 1,
      then: yup.string().required("Horário de início obrigatório"),
    }),
  sale_end_date: yup.string().required("Data final obrigatória"),
  sale_end_hour: yup.string().required("Horário de fim obrigatório"),
  transfer: yup.boolean().required("Requerimento obrigatório"),
  status: yup.boolean().notRequired(),
  absorb_rate: yup.boolean().required("Taxa obrigatória"),
  quantity_max: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, "A Quantidade mínima é 1")
    .max(999999, "A Quantidade máxima é 999999")
    .required("Quantidade máxima obrigatória"),
  quantity_min: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .min(1, "A Quantidade mínima é 1")
    .max(999999, "A Quantidade máxima é 999999")
    .required("Quantidade mínima obrigatória"),
  type_arquive: yup.number().notRequired(),
  archive: yup
    .string()
    .notRequired()
    .when(["checked_has_arquive", "type_arquive"], {
      is: (checked_has_arquive: any, type_arquive: any) =>
        checked_has_arquive && type_arquive === 1,
      then: yup.string().required("Link externo obrigatório"),
    }),
  guest: yup.boolean().notRequired(),
  guest_quantity: yup
    .number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .notRequired()
    .when("guest", {
      is: true,
      then: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .nullable()
        .min(1, "A Quantidade mínima é 1")
        .max(999999, "A Quantidade máxima é 999999")
        .required("Quantidade de acompanhantes obrigatórios"),
    }),
  checked_has_arquive: yup.boolean().notRequired(),
});
