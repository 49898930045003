import { StepContainer } from "./styles";

import { RiArrowRightSLine } from "react-icons/ri";
import { useSearchParams } from "react-router-dom";

interface SidebarProps {
  steps: Array<string>;
  activeStep: number;
}

function Sidebar({ steps, activeStep }: SidebarProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleNavigateForward = (index: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      activeStep: String(index),
    });
  };

  return (
    <aside>
      {steps.map((elem, index) => (
        <StepContainer
          onClick={() => handleNavigateForward(index + 1)}
          activeStep={index + 1 === activeStep}
        >
          <span>{`${index + 1}. ${elem}`}</span>
          <span className="chevron">
            {<RiArrowRightSLine className="icon" size={25} />}
          </span>
        </StepContainer>
      ))}
    </aside>
  );
}

export default Sidebar;
