import { useParams } from 'react-router-dom'
import { MdAdd, MdEdit, MdSyncAlt } from 'react-icons/md'
import { useAuth } from 'contexts/AuthContext'
import { ORGANIZER_PROFILE_NAV } from 'constants/navs'
import { authClient } from 'services/authClient'
import { SidebarContainer } from 'styles/pages/Sidebar'

import {
  Avatar,
  ProfileCard,
  Content,
  CardFirstSteps,
  FirstStepItem,
  Text,
  ContentStep,
  TextStep,
  Divider
} from './styles'

import { Link } from 'react-router-dom'

import { successNotification } from 'components/Notification'
import { SidebarOptions } from 'components/SidebarOptions'

import user_avatar from 'assets/user-avatar.svg'
import { RiCalendarCheckFill, RiCoupon2Line } from 'react-icons/ri'
import { FiChevronRight } from 'react-icons/fi'
import { Colors } from 'constants/colors'
import { useAuthOrganizerById } from 'services/Hook/Organizer'
import {
  ButtonAddNew,
  ButtonChangeOrganizer,
  FlexButtonResponsive
} from 'components/HeaderTitleOrganizerProfile/styles'
import { useFiltersContext } from 'contexts/FiltersContext'

interface SidebarOrganizerProfileProps {
  main?: boolean
}

interface OrganizerDetail {
  organizer: {
    _id: string
    address_city: string
    address_country: string
    address_number: string
    address_state: string
    address_street: string
    address_zipcode: string
    agent_birthdate: string
    business_email: string
    description: string
    document: string
    name: string
    nickname: string
    fee: number
    type_document: number
    user: string
    address_complement: string
    address_disctrict: string
    agent_document: string
    business_phone: string
    document_city: string
    agent_email: string
    document_name: string
    agent_name: string
    agent_phone: string
    manager_id: string
    created_at: string
    updated_at: string
    __v: 0
    events: Array<string>
    formmatedAgentBirthdate: string
  }
}

export function SidebarOrganizerProfile ({
  main = false
}: SidebarOrganizerProfileProps) {
  const { user, hydratationUser } = useAuth()

  const { setIsOpenFilterModalOrganizer } = useFiltersContext()
  const { hasOrganizer } = useAuth()

  const handleOpenModalChangeOrganizer = () => {
    setIsOpenFilterModalOrganizer(true)
  }

  const { id } = useParams()

  const { data: dataOrganizer } = useAuthOrganizerById<OrganizerDetail>(
    String(id)
  )

  const onSelectFile = async (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      return
    }

    try {
      let formData1 = new FormData()
      if (e.target.files[0]) {
        formData1.append('avatar', e.target.files[0])
      }

      await authClient.post(`/users/upload-photo/${user?.id}`, formData1, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })

      hydratationUser()

      successNotification(
        'Usuario atualizado',
        `Seu avatar foi atualizado com sucesso!`,
        1
      )
    } catch (err) {}
  }

  return (
    <SidebarContainer main={main}>
      <ProfileCard>
        <Avatar>
          <img
            src={user?.avatar ?? user_avatar}
            alt={dataOrganizer?.organizer?.name}
          />

          <input
            id='file'
            type='file'
            style={{ display: 'none' }}
            accept='image/png, image/gif, image/jpeg'
            onChange={onSelectFile}
          />

          <label className='badge' htmlFor='file'>
            <MdEdit size={18} />
          </label>
        </Avatar>

        <Content>
          <h2>{`${dataOrganizer?.organizer?.name ?? ''}`}</h2>
          <span>{dataOrganizer?.organizer?.agent_email}</span>
          <span>{dataOrganizer?.organizer?.agent_phone}</span>
        </Content>
      </ProfileCard>
      <Text>PRIMEIROS PASSOS</Text>
      <CardFirstSteps>
        {/* <FirstStepItem>
          <ContentStep>
            <RiAccountBoxLine width={18} height={18} color={Colors.success} />

            <TextStepThrough>Dados do organizador</TextStepThrough>
          </ContentStep>

          <RiCheckboxCircleFill width={18} height={18} color={Colors.success} />
        </FirstStepItem> */}

        {/* <FirstStepItem>
          <ContentStep>
            <RiFileUploadLine width={18} height={18} color={Colors.success} />

            <TextStepThrough>Envio de documentos</TextStepThrough>
          </ContentStep>

          <RiCheckboxCircleFill width={18} height={18} color={Colors.success} />
        </FirstStepItem> */}

        {/* <FirstStepItem>
          <ContentStep>
            <RiBarChartBoxLine width={18} height={18} color={Colors.success} />

            <TextStepThrough>Dados Financeiros</TextStepThrough>
          </ContentStep>

          <RiCheckboxCircleFill width={18} height={18} color={Colors.success} />
        </FirstStepItem>

        <Divider /> */}

        <FirstStepItem>
          <ContentStep>
            <RiCalendarCheckFill
              width={18}
              height={18}
              color={Colors.secondary20}
            />

            <TextStep>Cadastrar evento</TextStep>
          </ContentStep>

          <FiChevronRight width={16} height={16} color={Colors.secondary40} />
        </FirstStepItem>

        <FirstStepItem>
          <ContentStep>
            <RiCoupon2Line width={18} height={18} color={Colors.secondary20} />

            <TextStep>Criar ingressos</TextStep>
          </ContentStep>

          <FiChevronRight width={16} height={16} color={Colors.secondary40} />
        </FirstStepItem>
      </CardFirstSteps>
      <Text>OPÇÕES</Text>
      <SidebarOptions options={ORGANIZER_PROFILE_NAV({ id })} />
      <Divider />
      <FlexButtonResponsive>
        {hasOrganizer ? (
          <ButtonChangeOrganizer onClick={handleOpenModalChangeOrganizer}>
            <MdSyncAlt width={18} height={18} color={Colors.primary100} />

            <span>Alterar organizador</span>
          </ButtonChangeOrganizer>
        ) : null}

        <Link to={`/organizer/new`}>
          <ButtonAddNew>
            <MdAdd size={22} color={Colors.white} />

            <span>Adicionar novo</span>
          </ButtonAddNew>
        </Link>
      </FlexButtonResponsive>
    </SidebarContainer>
  )
}
