import { ButtonConfirm, ContainerModal, ModalStyled } from "./styles";

import img_reverse_success from "assets/img-reverse-sucess.svg";

interface ModalSuccessProps {
  title: string;
  subtitle?: string;
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
}

export function ModalSuccess({
  title,
  subtitle,
  handleCancel,
  handleOk,
  isModalVisible,
}: ModalSuccessProps) {
  return (
    <ModalStyled
      width={642}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      closeIcon={null}
      footer={null}
    >
      <ContainerModal>
        <img
          src={img_reverse_success}
          width={189}
          height={189}
          alt="confirm-cancel"
        />

        <div className="texts-confirm">
          <h3>{title}</h3>

          {subtitle ? <p>{subtitle}</p> : null}
        </div>

        <div className="buttons">
          <ButtonConfirm onClick={handleOk}>
            <span>Ok</span>
          </ButtonConfirm>
        </div>
      </ContainerModal>
    </ModalStyled>
  );
}
