import styled from "styled-components";

import { Colors } from "constants/colors";
import { Modal } from "antd";

export const ModalStyled = styled(Modal)`
  .ant-modal-content {
    border-radius: 20px;
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 44px 109px 64px 109px;

  @media (max-width: 800px) {
    padding: 24px 10px 24px 10px;
  }

  h1 {
    color: var(--secundaria-secundaria-100, #241058);
    text-align: center;

    font-size: 1.87rem;
    font-family: Poppins;
    font-weight: 600;
    line-height: 160%;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-size: 20px;
    }
  }
`;
