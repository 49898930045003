import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { useFinancialTransactionSearch } from "services/Hook/Financial";

import { paramsToString } from "utils/paramsToString";

import { Transaction } from "types";
import { useSWRConfig } from "swr";
import { authClient } from "services/authClient";
import { useGetDateAgo } from "./useGetDateAgo";
import useDebounce from "./useDebounce";

export interface TransactionType {
  count: number;
  results: Transaction[];
}

interface UseSearchFinancialGeneralParams {
  statusFinancial?: string;
}

export function useSearchFinancialTransaction({
  statusFinancial,
}: UseSearchFinancialGeneralParams) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate } = useSWRConfig();

  const { daysFilter } = useGetDateAgo();

  const pageCurrent = searchParams.get("page");
  const [page, setPage] = useState(
    Number(pageCurrent) === 0 ? 1 : Number(pageCurrent)
  );

  const [pageMobile, setPageMobile] = useState(1);

  const [loadingMoreItems, setLoadingMoreItems] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const organizers = searchParams.get("organizer") ?? "";
  const events = searchParams.get("events") ?? "";
  const tickets = searchParams.get("tickets") ?? "";
  const data_gte = searchParams.get("data_gte") ?? "";
  const data_lte = searchParams.get("data_lte") ?? "";
  const data_type = searchParams.get("data_type") ?? "";
  const payment_type = searchParams.get("payment_type") ?? "";
  const filterId = searchParams.get("filter_id") ?? "";
  const filterText = searchParams.get("filter_text") ?? "";
  const payment_status = searchParams.get("payment_status") ?? "";

  const [paramsString, setParamsString] = useState<URLSearchParams>();

  const debouncedSearch = useDebounce(paramsString, 1000);

  const { data, partialUrl } = useFinancialTransactionSearch<TransactionType>({
    page: String(page),
    paramsString: debouncedSearch,
    paramsObject: {
      ...(organizers && { organizers: [organizers] }),
      ...(events && { events: events.split("%") }),
      ...(tickets && { event_tickets: tickets.split("%") }),
      ...(data_gte && { date_end: data_lte }),
      ...(data_lte && { date_start: data_gte }),
      ...(data_type &&
        data_type !== "choose" && {
          date_end:
            daysFilter.find((day) => String(day.id) === data_type)?.date_end ??
            daysFilter[0].date_end,
          date_start:
            daysFilter.find((day) => String(day.id) === data_type)
              ?.date_start ?? daysFilter[0].date_start,
        }),
      ...(statusFinancial && { status: statusFinancial }),
      ...(payment_type && { payment_type }),
      ...(filterId && filterText && { [filterId]: filterText }),
      ...(payment_status && { status: payment_status }),
    },
  });

  const handleLoadMorePurchasingsMobile = () => {
    setPageMobile(pageMobile + 1);

    mutate(
      `${partialUrl}-${1}-${paramsString ?? ""}`,
      async () => {
        setLoadingMoreItems(true);

        const response = await authClient.post(
          `${partialUrl}?page=${pageMobile + 1}&limit=10`
        );

        const count = response.data.count;

        const last_page = Math.ceil(count / 10);

        if (pageMobile + 1 === last_page) {
          setHasMoreItems(false);
        }

        setLoadingMoreItems(false);

        const mapEvents = response?.data?.results?.map(
          (purchase: Transaction) => ({
            ...purchase,
            isOpen: false,
          })
        );

        let newMapEvents = data?.results;

        const mobile = data?.results;

        if (mobile) {
          newMapEvents = [...mobile, ...mapEvents];
        }

        return {
          count: response?.data?.count,
          results: newMapEvents,
        };
      },
      false
    );
  };

  useEffect(() => {
    const paramsString = new URLSearchParams(
      paramsToString({
        organizers,
        data_type,
        events,
        tickets,
        status: statusFinancial ?? "",
        payment_type,
        filter_id: filterId,
        filter_text: filterText,
        payment_status,
      })
    );

    setParamsString(paramsString);
  }, [
    data_type,
    events,
    filterId,
    filterText,
    organizers,
    payment_type,
    statusFinancial,
    tickets,
    payment_status,
  ]);

  const onChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);

      setSearchParams(
        {
          ...Object.fromEntries([...searchParams]),
          page: String(currentPage),
        },
        {
          replace: true,
          state: {
            scroll: false,
          },
        }
      );
    },
    [searchParams, setSearchParams]
  );

  const handleOpenDrop = (id: string) => {
    mutate(
      `${partialUrl}-${page}-${paramsString ?? ""}`,
      () => {
        const newMapPurchases = data?.results?.map((purchase) =>
          purchase._id === id
            ? { ...purchase, isOpen: !purchase?.isOpen }
            : purchase
        );

        return {
          results: newMapPurchases,
          count: data?.count,
        };
      },
      false
    );
  };

  return {
    data,
    page,
    loadingMoreItems,
    handleOpenDrop,
    hasMoreItems,
    onChange,
    handleLoadMorePurchasingsMobile,
  };
}
