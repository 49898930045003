import { Purchase } from 'types'

import { ReverseBoleto } from './components/ReverseBoleto'
import { ContainerModalReverse } from 'components/ModalConfirmReverse/styles'

interface ConfirmReverseProps {
  showModalConfirm(): void
  showModalError(): void
  purchase: Purchase | null
  closeShowReverse?(): void
}

export function ConfirmReverse ({
  purchase,
  showModalConfirm,
  showModalError,
  closeShowReverse
}: ConfirmReverseProps) {
  return (
    <>
      <ContainerModalReverse>
        {purchase && purchase?.payment_type === 'boleto' ? (
          <ReverseBoleto
            purchaseId={purchase?._id}
            showModalConfirm={showModalConfirm}
            showModalError={showModalError}
            closeShowReverse={closeShowReverse}
          />
        ) : null}
      </ContainerModalReverse>
    </>
  )
}
