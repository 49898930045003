import { Pagination } from "antd";
import { Colors } from "constants/colors";
import styled from "styled-components";

export const PaginationStyled = styled(Pagination)`
  .ant-pagination-item {
    display: inline-block;
    min-width: 60px;
    height: 60px;
    margin-right: 0;
    font-family: "Cairo";
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: ${Colors.white};
    border: none;
    border-radius: 14px;
    outline: 0;
    cursor: pointer;
    font-weight: 700;
  }

  .ant-pagination-item a {
    display: block;
    padding: 0 6px;
    color: ${Colors.primary100};
    transition: none;
    line-height: 60px;
    font-size: 18px;
  }

  .ant-pagination-item-active {
    background: ${Colors.primary100};
    font-weight: 700;
    color: ${Colors.white};
  }

  .ant-pagination-item-active a {
    color: ${Colors.white};
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;
    border: 1px solid ${Colors.primary100};
    border-radius: 6px;
    outline: none;
    transition: all 0.3s;

    color: ${Colors.primary100};
  }

  .ant-pagination-prev, .ant-pagination-next {
    height: 60px !important;
  }
`;

export const Container = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  margin: 0 auto;
`;

export const ContainerItems = styled.div`
  width: 100%;
  margin-top: 74px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  div.pages {
    h2 {
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;

      color: ${Colors.black70};
    }
  }

  div.buttons {
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;


interface ButtonProps {
  active: boolean;
}

export const ButtonPrev = styled.button<ButtonProps>`
  cursor: pointer;
  border: 0;
  width: 127px;
  height: 60px;
  margin-right: 18px;

  background: ${(props) => (props.active ? Colors.primary10 : Colors.black10)};
  border-radius: 14px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    margin-left: 10px;
    color: ${(props) => (props.active ? Colors.primary100 : Colors.black30)};
  }
`;

export const ButtonNext = styled.button<ButtonProps>`
  cursor: pointer;
  border: 0;
  width: 127px;
  height: 60px;
  margin-left: 18px;

  background: ${(props) => (props.active ? Colors.primary10 : Colors.black10)};
  border-radius: 14px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    margin-left: 10px;
    color: ${(props) => (props.active ? Colors.primary100 : Colors.black30)};
  }
`;

export const Numbers = styled.div`
  /* min-width: 169px; */
  height: 47px;

  border: 1px solid ${Colors.border20};
  box-sizing: border-box;
  border-radius: 14px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface NumberProps {
  active: boolean;
}

export const Number = styled.button<NumberProps>`
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 47px;

  background: ${(props) => (props.active ? Colors.primary100 : "transparent")};
  border-radius: 14px;

  h2.number-pagination {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    margin-top: 5px;

    color: ${(props) => (props.active ? Colors.white : Colors.black100)};
  }
`;

