import { useState } from "react";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { MdEdit } from "react-icons/md";

import { useAuth } from "contexts/AuthContext";

import { USER_PROFILE_NAV } from "constants/navs";

import { authClient } from "services/authClient";

import { SidebarContainer } from "styles/pages/Sidebar";

import { Avatar, ProfileCard, Content } from "./styles";

import { successNotification } from "components/Notification";
import { SidebarOptions } from "components/SidebarOptions";

import user_avatar from "assets/user-avatar.svg";
import { Colors } from "constants/colors";

export function SidebarProfile() {
  const { user, hydratationUser } = useAuth();

  const [loading, setLoading] = useState(false)

  const onSelectFile = async (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    setLoading(true)

    try {
      let formData1 = new FormData();
      if (e.target.files[0]) {
        formData1.append("image", e.target.files[0]);
      }

      await authClient.post(`/user/avatar/upload`, formData1, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setLoading(false)

      hydratationUser();

      successNotification(
        "Usuario atualizado",
        `Seu avatar foi atualizado com sucesso!`,
        1
      );
    } catch (err) {
      setLoading(false)
    }
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 12, color: Colors.primary100 }} spin />
  );


  return (
    <SidebarContainer>
      <ProfileCard>
        <Avatar>
          <img src={user?.avatar ?? user_avatar} alt={user?.name} />

          <input
            id="file"
            type="file"
            style={{ display: "none" }}
            accept="image/png, image/gif, image/jpeg"
            onChange={onSelectFile}
          />

          <label className="badge" htmlFor="file">
           {loading ? <Spin spinning indicator={antIcon} /> : <MdEdit size={18} />}
          </label>
        </Avatar>

        <Content>
          <h2>{`${user?.name ?? ""} ${user?.last_name ?? ""} `}</h2>
          <span>{user?.email}</span>
          <span>{user?.cell_phone}</span>
        </Content>
      </ProfileCard>

      <SidebarOptions options={USER_PROFILE_NAV} />
    </SidebarContainer>
  );
}
