/* eslint-disable @typescript-eslint/no-explicit-any */
import { Colors } from "constants/colors";
import styled from "styled-components";

export const CardParentalRating = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  margin-top: 1.87rem;
  border-radius: 0.93rem;

  @media (max-width: 1190px) {
    max-width: 100%;
    width: 100%;
    margin-bottom: 1rem;
  }
`;

export const TitleAsideSmall = styled.h3`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.21rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
`;

export const ParentalRatingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  text-align: left;
  margin-top: 0.93rem;

  color: ${Colors.black70};
  font-family: Open Sans;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 600;
  font-family: var(--font-open-sans);
  border-radius: 1rem;
  border: 1px solid ${Colors.black10};
`;

interface IParentalRatingProps {
  rating: any;
}

export const ParentalRatingIcon = styled.div<IParentalRatingProps>`
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  flex-shrink: 0;
  align-items: center;
  background: ${(props) => props.rating?.color};
  background: linear-gradient(
    0deg,
    ${(props) => props.rating?.color} 00%,
    rgba(255, 255, 255, 1) 200%
  );

  p {
    font-family: Poppins;
    font-size: 1.21rem;
    font-style: normal;
    font-weight: 700;
    color: ${Colors.white};
    margin-bottom: 0px;
  }
`;
