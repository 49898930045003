import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import { Transaction } from "types";

import {
  ButtonPrimary,
  Card,
  CardDetail,
  Column,
  Container,
  ContainerItems,
  ContainerTable,
  HeaderEvent,
  Row,
  SubTitleCard,
  SubTitleStatusCard,
  Title,
  TitleCard,
} from "./styles";

import Popup from "containers/Popup";

import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { Footer } from "components/Footer";

import { useFinancialTransactionById } from "services/Hook/Financial";
import { BadgePurchaseByStatus } from "components/BadgePurchaseByStatus";
import {
  returnStatusPurchase,
  returnTypePaymentPurchase,
} from "utils/purchase";
import { MdOutlineEmail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { Colors } from "constants/colors";
import { formatPrice } from "utils/formatPrice";

export default function TransactionDetail() {
  const location = useLocation();

  const navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const { transaction_id } = useParams();

  const { data } = useFinancialTransactionById<Transaction>(
    transaction_id ?? ""
  );

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  const metaTags = {
    title: `${transaction_id ?? ""} - ${
      process.env.REACT_APP_EVENTX_META_TITLE_NAME
    }`,
    noIndex: true,
  };

  return (
    <>
      <Header metaTags={metaTags} isProfile customBack={() => navigate(-1)} />

      <Container>
        <ContainerItems>
          <div className="bread">
            <ListBreadcrumb
              data={[
                { name: "Home", link: "/" },
                { name: "Financeiro", link: "/financial" },
                { name: `Transação ID: ${transaction_id}` ?? "", link: "/" },
              ]}
            />
          </div>

          <HeaderEvent>
            <h1>Detalhes da transação - ID: {data?._id}</h1>
          </HeaderEvent>

          <Card>
            <ContainerTable>
              <Title>Dados pessoais</Title>

              <table>
                <thead>
                  <tr>
                    <th>Nome</th>

                    <th>E-mail</th>
                    <th>Telefone</th>
                    <th>Contato</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <h6>{data?.user_id?.name}</h6>
                    </td>

                    <td>
                      <h6>{data?.user_id?.email}</h6>
                    </td>

                    <td>
                      <h6>{data?.user_id?.cell_phone}</h6>
                    </td>

                    <td>
                      <div className="row-buttons">
                        <ButtonPrimary
                          href={`mailto:${data?.user_id?.email}`}
                          rel="noreferrer"
                        >
                          <MdOutlineEmail size={18} color={Colors.primary100} />

                          <span>Enviar e-mail</span>
                        </ButtonPrimary>

                        <ButtonPrimary
                          href={`https://api.whatsapp.com/send?phone=${
                            data?.user_id?.cell_phone.includes("+")
                              ? data?.user_id?.cell_phone
                              : `+55 ${data?.user_id?.cell_phone}`
                          }&text=Oi,%20`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FaWhatsapp size={18} color={Colors.primary100} />

                          <span>Enviar mensagem</span>
                        </ButtonPrimary>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <CardDetail>
                <Row>
                  <Column>
                    <TitleCard>nome</TitleCard>

                    <SubTitleCard>{data?.user_id?.name}</SubTitleCard>
                  </Column>
                </Row>

                <Column>
                  <TitleCard>email</TitleCard>

                  <SubTitleCard>{data?.user_id?.email}</SubTitleCard>
                </Column>

                <Column>
                  <TitleCard>telefone</TitleCard>

                  <SubTitleCard>{data?.user_id?.cell_phone}</SubTitleCard>
                </Column>
              </CardDetail>
            </ContainerTable>

            <ContainerTable>
              <Title>Detalhes do evento</Title>

              <table>
                <thead>
                  <tr>
                    <th>Evento</th>
                    <th>Data</th>

                    <th></th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <h6>{data?.event_id?.name}</h6>
                    </td>

                    <td>
                      <h6>{data?.event_id?.formattedDate}</h6>
                    </td>

                    <td></td>

                    <td></td>

                    <td></td>
                  </tr>
                </tbody>
              </table>

              <CardDetail>
                <Column>
                  <TitleCard>evento</TitleCard>

                  <SubTitleCard>{data?.event_id?.name}</SubTitleCard>
                </Column>

                <Column>
                  <TitleCard>Data</TitleCard>

                  <SubTitleCard>{data?.formattedDate}</SubTitleCard>
                </Column>
              </CardDetail>
            </ContainerTable>

            <ContainerTable>
              <Title>Detalhes da compra</Title>

              <table>
                <thead>
                  <tr>
                    <th>Data - Horário</th>

                    <th>Valor da venda</th>
                    <th>Taxa da venda</th>
                    <th>Total</th>
                    <th>Status</th>
                    <th>Forma de pagamento</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td>
                      <h6>{data?.formattedDateAndTime}</h6>
                    </td>

                    <td>
                      <h6>
                        {formatPrice(
                          data?.total_wet ? data?.total_wet / 100 : 0
                        )}
                      </h6>
                    </td>

                    <td>
                      <h6>
                        {formatPrice(
                          data?.total_fee ? data?.total_fee / 100 : 0
                        )}
                      </h6>
                    </td>

                    <td>
                      <h6>
                        {formatPrice(data?.total ? data?.total / 100 : 0)}
                      </h6>
                    </td>

                    <td>
                      <BadgePurchaseByStatus
                        status={data?.payment_status ?? ""}
                      />
                    </td>

                    <td>
                      <h6>
                        {returnTypePaymentPurchase(data?.payment_type ?? "")}
                      </h6>
                    </td>
                  </tr>
                </tbody>
              </table>

              <CardDetail>
                <Column>
                  <TitleCard>data - horário</TitleCard>

                  <SubTitleCard>{data?.formattedDateAndTime}</SubTitleCard>
                </Column>

                <Column>
                  <TitleCard>valor da venda</TitleCard>

                  <SubTitleCard>
                    {formatPrice(data?.total_wet ? data?.total_wet / 100 : 0)}
                  </SubTitleCard>
                </Column>

                <Column>
                  <TitleCard>taxa da venda</TitleCard>

                  <SubTitleCard>
                    {formatPrice(data?.total_fee ? data?.total_fee / 100 : 0)}
                  </SubTitleCard>
                </Column>

                <Column>
                  <TitleCard>total</TitleCard>

                  <SubTitleCard>
                    {formatPrice(data?.total ? data?.total / 100 : 0)}
                  </SubTitleCard>
                </Column>
                <Column>
                  <TitleCard>Status</TitleCard>

                  <SubTitleStatusCard status={data?.payment_status}>
                    {returnStatusPurchase(data?.payment_status ?? "")}
                  </SubTitleStatusCard>
                </Column>

                <Column>
                  <TitleCard>forma de pagamento</TitleCard>

                  <SubTitleCard>
                    {returnTypePaymentPurchase(data?.payment_type ?? "")}
                  </SubTitleCard>
                </Column>
              </CardDetail>
            </ContainerTable>
          </Card>
        </ContainerItems>
      </Container>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
