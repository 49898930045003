import styled from "styled-components";

const EventBanner = styled.div<{img: string}>`
    background-image: url(${({img}) => img});
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
`;

export default EventBanner