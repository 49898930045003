import { ListBreadcrumb } from "components/ListBreadcrumb";
import { Container, ContainerItems, TitleCard } from "../styles";

import { CardProfile, ContainerSwitch, TitleSwitch } from "./styles";

import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { InputForm } from "components/Form/InputForm";

import { LoadingOutlined } from "@ant-design/icons";

import { useDisclosure } from "hooks/useDisclosure";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { createOrganizerBankDataFormSchema } from "lib/validations/createOrganizer";

import {
  ColumnContent,
  Divider,
  DividerNav,
  Hr,
  NavHeader,
  RowtTitle,
  SubTitle,
  SubtitleNav,
  TitleNav,
  BorderSelect,
  LabelSelect,
  ContainerSelect,
  StyledSelect,
  GridInputs,
  Title,
} from "pages/OrganizerCadaster/Steps/BankData/styles";

import { CreateBankDataOrganizerFormData } from "types";
import { IoMdInformationCircle } from "react-icons/io";
import { Colors } from "constants/colors";
import Datas from "constants/Datas";
import { Select, Spin, Switch } from "antd";
import { RiBankLine, RiContactsLine } from "react-icons/ri";
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { GridSidebar } from "styles/pages/Sidebar";
import { SidebarOrganizerProfile } from "components/SidebarOrganizerProfile";
import { useEffect, useState } from "react";

import { HeaderTitleOrganizerProfile } from "components/HeaderTitleOrganizerProfile";

import {
  ButtonCancel,
  ButtonEdit,
  ButtonSave,
  ContainerButtonsEdit,
  ContainerButtonsSave,
} from "pages/OrganizerProfile/Data/styles";

import { FaPen } from "react-icons/fa";
import { MdSave } from "react-icons/md";

const { Option } = Select;

const metaTags = {
  title: `Dados de acesso - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

export function EditOrganizerAccount() {
  const [isEditing, setIsEditing] = useState(false);

  const { register, control, handleSubmit, formState } =
    useForm<CreateBankDataOrganizerFormData>({
      resolver: yupResolver(createOrganizerBankDataFormSchema),
    });

  const { errors, isSubmitting } = formState;

  const { handleCancel, isModalVisible, showModal } = useDisclosure();

  const handleSubmitBankData: SubmitHandler<
    CreateBankDataOrganizerFormData
  > = async (data) => {
    const { account, agency, bank, digit, holder_name, type_account } = data;
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  return (
    <>
      <Header isProfile metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <div className="breadcrumb">
            <ListBreadcrumb
              data={[
                { name: "Home", link: "/" },
                { name: "Perfil", link: "/" },
                { name: "Nova conta", link: "/" },
              ]}
            />
          </div>

          <HeaderTitleOrganizerProfile />

          <GridSidebar>
            <SidebarOrganizerProfile />

            <form onSubmit={handleSubmit(handleSubmitBankData)}>
              <CardProfile>
                <TitleCard>Dados bancários</TitleCard>

                <SubTitle>
                  A conta bancária selecionada deve pertencer a você. Não serão
                  aceitas contas de terceiros.
                </SubTitle>

                <Divider />

                <ContainerSelect>
                  <LabelSelect>Banco</LabelSelect>

                  <Controller
                    control={control}
                    name="bank"
                    rules={{ required: "Salutation is required" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <BorderSelect>
                        <StyledSelect
                          size="large"
                          labelInValue
                          bordered={false}
                          placeholder="Selecione uma opção"
                          value={value}
                          style={{
                            width: "100%",
                          }}
                          onChange={(e: any) => {
                            onChange(e.value);
                          }}
                        >
                          {Datas?.Banks?.map((bank) => {
                            return (
                              <Option value={bank.value} key={bank.value}>
                                {bank.label}
                              </Option>
                            );
                          })}
                        </StyledSelect>
                      </BorderSelect>
                    )}
                  />

                  {!!errors.bank ? (
                    <span className="error">{errors?.bank?.message}</span>
                  ) : null}
                </ContainerSelect>

                <InputForm
                  isFull
                  {...register("agency")}
                  icon={<RiBankLine size={22} color={Colors.secondary40} />}
                  label="Agência (sem dígito)"
                  error={errors.agency}
                />

                <GridInputs>
                  <InputForm
                    isFull
                    {...register("account")}
                    icon={<RiBankLine size={22} color={Colors.secondary40} />}
                    label="Conta"
                    error={errors?.account}
                  />

                  <InputForm
                    isFull
                    {...register("digit")}
                    icon={<RiBankLine size={22} color={Colors.secondary40} />}
                    label="Dígito"
                    error={errors?.digit}
                  />
                </GridInputs>

                <ContainerSelect>
                  <LabelSelect>Tipo de conta</LabelSelect>

                  <Controller
                    control={control}
                    name="type_account"
                    rules={{ required: "Salutation is required" }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <BorderSelect>
                        <StyledSelect
                          size="large"
                          labelInValue
                          bordered={false}
                          placeholder="Selecione uma opção"
                          value={value}
                          style={{
                            width: "100%",
                          }}
                          onChange={(e: any) => {
                            onChange(e.value);
                          }}
                        >
                          <Option value={"1"} key={"1"}>
                            Corrente
                          </Option>

                          <Option value={"2"} key={"2"}>
                            Poupança
                          </Option>
                        </StyledSelect>
                      </BorderSelect>
                    )}
                  />

                  {!!errors?.type_account ? (
                    <span className="error">
                      {errors?.type_account?.message}
                    </span>
                  ) : null}
                </ContainerSelect>

                <ContainerSwitch>
                  <Controller
                    control={control}
                    name="type_account"
                    // defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <Switch
                        // checked={value}
                        onChange={(e) => {
                          onChange(e);

                          // setValue("price", "0");
                          // setValue("half_price", "0");
                        }}
                      />
                    )}
                  />

                  <TitleSwitch>Tornar esta conta padrão</TitleSwitch>
                </ContainerSwitch>

                <InputForm
                  isFull
                  name="name"
                  // {...register("agency")}
                  placeholder="Insira o CPF ou CNPJ vinculado á conta..."
                  icon={<RiContactsLine size={22} color={Colors.secondary40} />}
                  // error={errors.agency}
                  label="CPF/CNPJ"
                />

                <Divider />

                {!isEditing ? (
                  <ContainerButtonsEdit>
                    <ButtonEdit
                      type="button"
                      onClick={() => setIsEditing(true)}
                    >
                      <div className="icon">
                        <FaPen
                          width={18}
                          height={18}
                          color={Colors.primary100}
                        />
                      </div>

                      <span>Editar</span>
                    </ButtonEdit>
                  </ContainerButtonsEdit>
                ) : null}

                {isEditing ? (
                  <ContainerButtonsSave>
                    <ButtonCancel
                      type="button"
                      onClick={() => {
                        setIsEditing(false);
                      }}
                    >
                      <span>Cancelar</span>
                    </ButtonCancel>

                    <ButtonSave type="submit">
                      {!isSubmitting ? (
                        <MdSave
                          className="icon-d"
                          size={23}
                          color={Colors.white}
                        />
                      ) : (
                        <Spin spinning={isSubmitting} indicator={antIcon} />
                      )}
                      <span>Salvar</span>
                    </ButtonSave>
                  </ContainerButtonsSave>
                ) : null}
              </CardProfile>
            </form>
          </GridSidebar>
        </ContainerItems>
      </Container>

      <Footer />
    </>
  );
}
