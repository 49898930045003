import { useEffect, useState } from 'react'

import { Radio, Spin } from 'antd'

import { SubmitHandler, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

import { LoadingOutlined } from '@ant-design/icons'
import { RiCalendar2Line, RiUserLine } from 'react-icons/ri'
import { FaPen } from 'react-icons/fa'
import { MdSave } from 'react-icons/md'

import { useAuth } from 'contexts/AuthContext'

import { UserUpdate, useUser } from 'services/Hook/User'

import { Colors } from 'constants/colors'

import { UserType } from 'types'

import hasCookiesEnabled from 'utils/hasCookiesEnabled'
import { checkUserPlatform } from 'utils/plaftformDetect'

import {
  Container,
  ContainerItems,
  CardProfile,
  ButtonEdit,
  ButtonSave,
  ButtonCancel,
} from './styles'

import Popup from 'containers/Popup'
import { Footer } from 'components/Footer'
import { Header } from 'components/Header'
import { Title } from 'components/Title'
import { ListBreadcrumb } from 'components/ListBreadcrumb'
import { SidebarProfile } from 'components/SidebarProfile'
import { GridSidebar } from 'styles/pages/Sidebar'
import { GridInputs } from 'styles/components/Form/styles'
import { InputForm } from 'components/Form/InputForm'

type PersonalFormData = {
  name: string
  cell_phone: string
  birthdate: string
  gender: string
  document: string
}

const personalFormSchema = yup.object().shape({
  name: yup.string().required('E-mail obrigatório')
})

const metaTags = {
  title: `Dados pessoais - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true
}

export default function PersonalData () {
  const [visible, setVisible] = useState(false)
  const [userPlatform, setUserPlatform] = useState('')

  const { user, hydratationUser } = useAuth()

  const [isEditing, setIsEditing] = useState(false)

  const { register, handleSubmit, formState, reset } =
    useForm<PersonalFormData>({
      resolver: yupResolver(personalFormSchema)
    })

  const { data } = useUser<UserType>(user?.id ?? '')

  const { errors, isSubmitting } = formState

  useEffect(() => {
    let userObject = {
      name: data?.name,
      cell_phone: data?.cell_phone,
      birthdate: data?.formattedBirthdate,
      gender: data?.gender,
      document: data?.document
    }

    reset(userObject)
  }, [reset, data])

  const [value, setValue] = useState('1')

  useEffect(() => {
    if (user && user?.type_document !== null) {
      setValue(user?.type_document)
    }
  }, [user, user?.type_document])

  const onChange = (e: any) => {
    setValue(e.target.value)
  }

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  )

  const handleUpdateUser: SubmitHandler<PersonalFormData> = async ({
    name,
    birthdate,
    cell_phone,
  }) => {
    try {
      await UserUpdate({
        id: user?.id,
        name,
        birthdate: birthdate,
        cell_phone,
      })

      hydratationUser()
      setIsEditing(false)
      alert('Usuario atualizado com sucesso')
    } catch (err) {
      alert('os Dados do usuario são invalidos')
    }
  }

  const handleCookies = () => {
    setVisible(false)
  }

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true)
    setUserPlatform(checkUserPlatform())
  }, [setVisible, userPlatform])

  return (
    <>
      <Header isProfile metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <ListBreadcrumb
            data={[
              { name: 'Home', link: '/' },
              { name: 'Perfil', link: '/' }
            ]}
          />

          <Title />

          <GridSidebar>
            <SidebarProfile />

            <CardProfile onSubmit={handleSubmit(handleUpdateUser)}>
              <h2 className='title'>Dados pessoais</h2>

              <InputForm
                isFull
                {...register('name')}
                icon={<RiUserLine size={17} color={Colors.secondary40} />}
                error={errors.name}
                label={value === '1' ? 'Nome' : 'Razão social'}
                isEditing={isEditing}
              />

              <GridInputs>
                <InputForm
                  isFull
                  {...register('cell_phone')}
                  icon={<RiUserLine size={17} color={Colors.secondary40} />}
                  error={errors.cell_phone}
                  label='Telefone'
                  isEditing={isEditing}
                />

                <InputForm
                  isFull
                  {...register('birthdate')}
                  mask='birthdate'
                  icon={
                    <RiCalendar2Line size={17} color={Colors.secondary40} />
                  }
                  error={errors.birthdate}
                  label='Data de nascimento'
                  isEditing={isEditing}
                />
              </GridInputs>

              {user?.document === null ? (
                <div className='type-document'>
                  <h3>Escolha um tipo de identificação</h3>

                  <div className='checks'>
                    <Radio.Group onChange={onChange} value={value}>
                      <Radio value={'1'}>CPF</Radio>
                      <Radio value={'2'}>CNPJ</Radio>
                    </Radio.Group>
                  </div>
                </div>
              ) : null}

              <GridInputs>
                <InputForm
                  isFull
                  {...register('document')}
                  icon={<RiUserLine size={17} color={Colors.secondary40} />}
                  error={errors.document}
                  mask={value === '1' ? 'cpf' : 'cnpj'}
                  label={value === '1' ? 'CPF' : 'CNPJ'}
                  isEditing={false}
                  disabled
                />
              </GridInputs>

              {isEditing ? (
                <div className='full-button-save'>
                  <ButtonCancel
                    type='button'
                    onClick={() => setIsEditing(false)}
                  >
                    <span>Cancelar</span>
                  </ButtonCancel>

                  <ButtonSave type='submit'>
                    {!isSubmitting ? (
                      <MdSave
                        className='icon-d'
                        size={23}
                        color={Colors.white}
                      />
                    ) : (
                      <Spin spinning={isSubmitting} indicator={antIcon} />
                    )}
                    <span>Salvar</span>
                  </ButtonSave>
                </div>
              ) : (
                <div className='full-button'>
                  <ButtonEdit type='button' onClick={() => setIsEditing(true)}>
                    <div className='icon'>
                      <FaPen size={18} color={Colors.primary100} />
                    </div>

                    <span>Editar</span>
                  </ButtonEdit>
                </div>
              )}
            </CardProfile>
          </GridSidebar>
        </ContainerItems>
      </Container>

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  )
}
