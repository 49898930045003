import { ListBreadcrumb } from "components/ListBreadcrumb";
import { Container, ContainerItems } from "../styles";
import { Card, TitleNew } from "./styles";

import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { InputForm } from "components/Form/InputForm";

import { ModalSuccess } from "components/ModalSuccess";

import { useDisclosure } from "hooks/useDisclosure";

import { LoadingOutlined } from "@ant-design/icons";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { createOrganizerBankDataFormSchema } from "lib/validations/createOrganizer";

import {
  ColumnContent,
  Divider,
  DividerNav,
  Hr,
  NavHeader,
  RowtTitle,
  SubTitle,
  SubtitleNav,
  Title,
  TitleNav,
  BorderSelect,
  LabelSelect,
  ContainerSelect,
  StyledSelect,
  GridInputs,
  ButtonSelectBank,
} from "pages/OrganizerCadaster/Steps/BankData/styles";

import {
  ButtonNext,
  ButtonPrev,
  ContainerButtons,
} from "pages/OrganizerCadaster/styles";

import { CreateBankDataOrganizerFormData } from "types";
import { IoMdInformationCircle } from "react-icons/io";
import { Colors } from "constants/colors";
import Datas from "constants/Datas";
import { Select, Spin } from "antd";
import { RiBankLine } from "react-icons/ri";
import { Header } from "components/Header";
import { Footer } from "components/Footer";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AddBank } from "services/Hook/Organizer/Bank";
import { errorNotification } from "components/Notification";
import { ModalSelectBank } from "components/ModalSelectBank";
import { IoChevronDownOutline } from "react-icons/io5";

const { Option } = Select;

const metaTags = {
  title: `Dados de acesso - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

export function NewOrganizerAccount() {
  const { register, control, handleSubmit, formState, watch, setValue } =
    useForm<CreateBankDataOrganizerFormData>({
      resolver: yupResolver(createOrganizerBankDataFormSchema),
    });

  const navigate = useNavigate();

  const { id } = useParams();

  const { errors, isSubmitting } = formState;

  const { isModalVisible, showModal } = useDisclosure();

  const bankWatch = watch("bank");

  const {
    handleCancel: handleCancelBank,
    isModalVisible: isModalVisibleBank,
    showModal: showModalBank,
    handleOk: handleOkBank,
  } = useDisclosure();

  const handleSubmitBankData: SubmitHandler<
    CreateBankDataOrganizerFormData
  > = async (data) => {
    const { account, agency, bank, digit, type_account, holder_name } = data;

    const credentials = {
      organizer_id: id,
      holder_name,
      bank_id: String(bank),
      agency,
      account,
      digit,
      type_account: Number(type_account),
      default: true,
    };

    try {
      await AddBank({ credentials });

      showModal();
    } catch (err: any) {
      errorNotification(
        "Aconteceu um erro ao cadastrar seu banco.",
        JSON.stringify(err?.response?.data?.message ?? "Tente novamente.")
      );
    }
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const findBankById = bankWatch
    ? Datas.Banks.find((bank) => bank.value === bankWatch)
    : { value: "", label: "" };

  const handleSelectBank = (bankId: string) => {
    setValue("bank", bankId);

    handleOkBank();
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  return (
    <>
      <Header isProfile metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <div className="breadcrumb">
            <ListBreadcrumb
              data={[
                { name: "Home", link: "/" },
                {
                  name: "Perfil",
                  link: `/organizer/profile/main/${id}`,
                },
                { name: "Nova conta", link: "/" },
              ]}
            />
          </div>

          <TitleNew>Nova conta</TitleNew>

          <form onSubmit={handleSubmit(handleSubmitBankData)}>
            <Card>
              <Title>Dados bancários</Title>

              <SubTitle>
                A conta bancária selecionada deve pertencer a você. Não serão aceitas
                contas de terceiros.
              </SubTitle>

              <Divider />

              <NavHeader>
                <Hr />

                <ColumnContent>
                  <RowtTitle>
                    <IoMdInformationCircle color={Colors.primary100} size={28} />

                    <TitleNav>Avisos</TitleNav>
                  </RowtTitle>

                  <DividerNav />

                  <SubtitleNav>
                    . A conta bancária deve ser vinculada ao CPF ou CNPJ cadastrado na
                    Eventx.
                  </SubtitleNav>
                </ColumnContent>
              </NavHeader>

              <ContainerSelect>
                <LabelSelect>Banco</LabelSelect>

                <ButtonSelectBank type="button" onClick={showModalBank}>
                  {bankWatch ? (
                    <span>{findBankById?.label}</span>
                  ) : (
                    <span>Selecione uma opção</span>
                  )}

                  <IoChevronDownOutline color={Colors.secondary30} />
                </ButtonSelectBank>

                {!!errors.bank ? (
                  <span className="error">{errors?.bank?.message}</span>
                ) : null}
              </ContainerSelect>

              <InputForm
                isFull
                {...register("agency")}
                icon={<RiBankLine size={22} color={Colors.secondary40} />}
                error={errors.agency}
                label="Agência (sem dígito)"
              />

              <GridInputs>
                <InputForm
                  isFull
                  {...register("account")}
                  icon={<RiBankLine size={22} color={Colors.secondary40} />}
                  error={errors.account}
                  label="Conta"
                />

                <InputForm
                  isFull
                  {...register("digit")}
                  icon={<RiBankLine size={22} color={Colors.secondary40} />}
                  error={errors.digit}
                  label="Dígito"
                />
              </GridInputs>

              <InputForm
                isFull
                {...register("holder_name")}
                icon={<RiBankLine size={22} color={Colors.secondary40} />}
                error={errors.holder_name}
                label="Titular da conta"
              />

              <ContainerSelect>
                <LabelSelect>Tipo de conta</LabelSelect>

                <Controller
                  control={control}
                  name="type_account"
                  rules={{ required: "Salutation is required" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <BorderSelect>
                      <StyledSelect
                        size="large"
                        labelInValue
                        bordered={false}
                        placeholder="Selecione uma opção"
                        value={value}
                        style={{
                          width: "100%",
                        }}
                        onChange={(e: any) => {
                          onChange(e.value);
                        }}
                      >
                        <Option value={"1"} key={"1"}>
                          Corrente
                        </Option>

                        <Option value={"2"} key={"2"}>
                          Poupança
                        </Option>
                      </StyledSelect>
                    </BorderSelect>
                  )}
                />

                {!!errors.type_account ? (
                  <span className="error">{errors?.type_account?.message}</span>
                ) : null}
              </ContainerSelect>
            </Card>

            <ContainerButtons>
              <ButtonPrev type="button" onClick={() => navigate(-1)}>
                <span>Cancelar</span>
              </ButtonPrev>

              <ButtonNext type="submit" isLoading={isSubmitting}>
                <Spin spinning={isSubmitting} indicator={antIcon} />

                <span>Salvar</span>
              </ButtonNext>
            </ContainerButtons>
          </form>
        </ContainerItems>
      </Container>

      <ModalSuccess
        handleCancel={() => navigate(-1)}
        handleOk={() => navigate(-1)}
        isModalVisible={isModalVisible}
        title="Cadastro de organizador finalizado!"
        subtitle="Clique em OK para prosseguir."
      />

      <ModalSelectBank
        isModalVisible={isModalVisibleBank}
        handleCancel={handleCancelBank}
        handleOk={handleOkBank}
        handleSelectBank={handleSelectBank}
      />

      <Footer />
    </>
  );
}
