import { sizeConversor } from "utils/conversors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${sizeConversor(22)};

  width: 24.7rem;
  height: ${sizeConversor(118)};
  padding-left: ${sizeConversor(20)};

  border-radius: ${sizeConversor(14)};
  border: ${sizeConversor(1)} solid #F0F0F0;
  background: var(--sistema-branco, #FFF);
  box-shadow: 0px 10px 20px 0px rgba(108, 93, 211, 0.06);

  .name {
    display: flex;
    flex-direction: column;

    span {
      font-style: normal;
      font-weight: 700;
    }

    span:nth-child(1) {
      color: var(--preto-preto-100, #11142D);
      font-family: Cairo;
      font-size: ${sizeConversor(26)};
    }

    span:nth-child(2) {
      color: var(--preto-preto-30, #B8B9C0);
      font-family: Open Sans;
      font-size: ${sizeConversor(20)};
    }
  }

  @media (max-width: 768px) {
    height: auto;
    padding: 8px 12px;
    gap: 14px;

    .name {
      span:nth-child(1) {
        font-size: 12px;
      }

      span:nth-child(2) {
        font-size: 8px;
      }
    }
  }
`;

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: ${sizeConversor(35)};

  width: ${sizeConversor(60)};
  height: ${sizeConversor(60)};
  border-radius: 50%;

  @media (max-width: 768px) {
    height: 28px;
    width: 28px;
    font-size: 14px;
  }
`
