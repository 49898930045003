import { useCallback, useState } from "react";

import { Link, useLocation } from "react-router-dom";

import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { useAuth } from "contexts/AuthContext";
import { useSearchCityAndState } from "contexts/SearchCityAndState";

import { Container, ContainerItems } from "./styles";
import { ModalCity } from "../ModalCity";

import logo from "assets/logo.svg";
import { FRONTLINE_URL } from "constants/environmentVariables";
import MetaTagsWrapper from "./components/MetaTagsWrapper";
import SearchForm from "./components/SearchForm";
import { OrganizerDropdown } from "./components/OrganizerDropdown";
import CartDropdown from "./components/CartDropdown";
import AccountDropdown from "./components/AccountDropdown";
import Drawer from "./components/Drawer";
import MobileSearchForm from "./components/SearchForm/MobileSearchForm";
import MobileLinksContainer from "./components/MobileLinksContainer";

interface MetaTags {
  title?: string;
  description?: string;
  keywords?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogImage?: string;
  ogType?: string;
  ogUrl?: string;
  noIndex?: boolean;
}

interface HeaderProps {
  isProfile?: boolean;
  isSignin?: boolean;
  cart?: any;
  changeCart?: any;
  quantityTotal?: number;
  valueTotal?: number;
  openLogin?: any;
  openSteps?: any;
  rate?: number;
  customBack?(): void;
  metaTags?: MetaTags;
}

type SearchFormData = {
  title: string;
};

const searchFormSchema = yup.object().shape({
  title: yup.string(),
});

export function Header({
  isProfile,
  cart,
  changeCart,
  quantityTotal,
  valueTotal,
  openLogin,
  openSteps,
  rate,
  isSignin = false,
  customBack,
  metaTags,
}: HeaderProps) {
  const { user, signOut, hasOrganizer, organizerId } = useAuth();
  const { showModalSearch } = useSearchCityAndState();
  const location = useLocation();
  const [activeDrop, setActiveDrop] = useState(false);
  const [activeDropOrganizer, setActiveDropOrganizer] = useState(false);
  const [activeDropAccount, setActiveDropAccount] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const isSignedInAndNotOrganizer = !!user && !hasOrganizer && !isSignin;

  const { register, handleSubmit, setFocus } = useForm<SearchFormData>({
    resolver: yupResolver(searchFormSchema),
  });

  const onOpenDrawer = useCallback(() => {
    setVisible(true);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setVisible(false);
  }, []);

  const searchOrRouter: SubmitHandler<SearchFormData> = ({ title }) => {
    if (title === "") {
      alert("Digite o nome que deseja buscar");

      setFocus("title");
      return;
    }

    if (location.pathname === "/search") {
      window.location.href = `${FRONTLINE_URL}/search?name=${title}`;
    }

    window.location.href = `${FRONTLINE_URL}/search?name=${title}`;
  };

  const handleChangeDropOrganizer = useCallback(() => {
    setActiveDropOrganizer(!activeDropOrganizer);

    if (activeDropAccount) {
      setActiveDropAccount(false);
    }
  }, [activeDropOrganizer, activeDropAccount]);

  const handleChangeDropAccount = useCallback(() => {
    setActiveDropAccount(!activeDropAccount);

    if (activeDropOrganizer) {
      setActiveDropOrganizer(false);
    }
  }, [activeDropAccount, activeDropOrganizer]);

  return (
    <Container>
      <MetaTagsWrapper metaTags={metaTags} />

      <ContainerItems>
        <div className="content">
          <a href={FRONTLINE_URL} rel="noopener noreferrer">
            <div className="logo-main">
              <img src={logo} alt="logo" width={182.14} height={126.06} />
            </div>
          </a>

          <SearchForm
            register={register}
            handleSubmit={handleSubmit}
            searchOrRouter={searchOrRouter}
            showModalSearch={showModalSearch}
          />

          <div className="buttons">
            {isSignedInAndNotOrganizer ? (
              <Link to="/organize-seu-evento">Organize seu Evento</Link>
            ) : null}

            {isSignin ? <Link to="/faq">Precisa de Ajuda</Link> : null}

            {!!user && hasOrganizer && organizerId ? (
              <OrganizerDropdown
                activeDropOrganizer={activeDropOrganizer}
                handleChangeDropOrganizer={handleChangeDropOrganizer}
                organizerId={organizerId}
              />
            ) : null}

            {cart ? (
              <CartDropdown
                isDropdownVisible={isDropdownVisible}
                quantityTotal={quantityTotal ?? 0}
                setIsDropdownVisible={setIsDropdownVisible}
              />
            ) : null}

            {!!user ? (
              <AccountDropdown
                activeDropAccount={activeDropAccount}
                handleChangeDropAccount={handleChangeDropAccount}
                signOut={signOut}
                user={user}
              />
            ) : null}
          </div>
        </div>

        <MobileLinksContainer
          activeDrop={activeDrop}
          onCloseDrawer={onCloseDrawer}
          onOpenDrawer={onOpenDrawer}
          setActiveDrop={setActiveDrop}
          customBack={customBack}
          isProfile={isProfile}
          visible={visible}
        />
      </ContainerItems>

      <Drawer
        onCloseDrawer={onCloseDrawer}
        signOut={signOut}
        visible={visible}
        hasOrganizer={hasOrganizer}
        organizerId={organizerId}
        user={user}
      />

      {activeDrop ? (
        <MobileSearchForm
          handleSubmit={handleSubmit}
          register={register}
          searchOrRouter={searchOrRouter}
          showModalSearch={showModalSearch}
        />
      ) : null}

      <ModalCity />
    </Container>
  );
}
