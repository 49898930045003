import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { authClient } from "services/authClient";
import { useUserTicket } from "services/Hook/User";
import { useSWRConfig } from "swr";
import { TicketType } from "types";

interface Event {
  id: string;
  name: string;
  thumb: string;
  photo: string;
  address_name: string;
  address_street: string;
  begin_date: string;
  formattedDate: string;
  formattedHour: string;
}

interface TicketsInterface {
  results: TicketType[];
  count: number;
}

export function useSearchTicket() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { mutate } = useSWRConfig();

  const pageCurrent = searchParams.get("page");
  const [page, setPage] = useState(
    Number(pageCurrent) === 0 ? 1 : Number(pageCurrent)
  );
  const [pageMobile, setPageMobile] = useState(1);

  const [loadingMoreItems, setLoadingMoreItems] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(true);

  const { data, partialUrl } = useUserTicket<TicketsInterface>(page);

  const onChange = useCallback(
    (currentPage: number) => {
      setPage(currentPage);

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        page: String(currentPage),
      });
    },
    [searchParams, setSearchParams]
  );

  const handleOpenDropTicketEventMobile = (id: string) => {
    mutate(
      [partialUrl, 1],
      () => {
        const newMapTickets = data?.results.map((ticket) =>
          ticket._id === id ? { ...ticket, isOpen: !ticket.isOpen } : ticket
        );

        return {
          ...data,
          results: newMapTickets,
        };
      },
      false
    );
  };

  const handleLoadMoreTicketsMobile = () => {
    setPageMobile(pageMobile + 1);

    mutate(
      [partialUrl, 1],
      async () => {
        setLoadingMoreItems(true);

        const response = await authClient.get(
          `${partialUrl}?page=${pageMobile}&limit=20`
        );

        const count = response.data.count;

        const last_page = Math.ceil(count / 20);

        if (pageMobile + 1 === last_page) {
          setHasMoreItems(false);
        }

        setLoadingMoreItems(false);

        const mapTickets = response.data.results.map((event: Event) => ({
          ...event,
          isOpen: false,
        }));

        let newMapTickets = data?.results;

        const mobile = data?.results;

        if (mobile) {
          newMapTickets = [...mobile, ...mapTickets];
        }

        return {
          count: response.data.count,
          results: newMapTickets,
        };
      },
      false
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);

  return {
    data,
    page,
    loadingMoreItems,
    hasMoreItems,
    onChange,
    handleOpenDropTicketEventMobile,
    handleLoadMoreTicketsMobile,
  };
}
