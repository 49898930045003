import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
  height: 100%;
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.34rem 6.37rem 2.34rem 6.37rem;
  border: 1px solid ${Colors.black10};
  border-radius: 0.93rem;
  margin-top: 2.34rem;

  @media (max-width: 1024px) {
    padding: 0px 0px;
    border: 0;
  }
`;

export const Title = styled.h2`
  color: ${Colors.secondary100};
  font-size: 1.21rem;
  font-family: Poppins;
  font-weight: 600;
  line-height: 160%;

  @media (max-width: 670px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 700;
    line-height: 25px;
  }
`;

export const SubTitle = styled.h3`
  color: ${Colors.black70};
  font-size: 1rem;
  font-family: Open Sans;
  line-height: 160%;
  margin-top: 0.93rem;

  @media (max-width: 670px) {
    font-size: 12px;
    font-family: Open Sans;
    margin-top: 8px;
  }
`;

export const LabelTitle = styled.h4`
  color: ${Colors.secondary100};
  font-size: 1.03rem;
  font-family: Poppins;
  font-weight: 500;
  line-height: 160%;

  @media (max-width: 670px) {
    color: ${Colors.black100};
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
  }
`;

export const SubLabelTitle = styled.h4`
  color: ${Colors.black60};
  font-size: 0.75rem;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 160%;

  strong {
    font-size: 0.75rem;
    font-family: Open Sans;
    font-style: italic;
    color: ${Colors.black70};

    @media (max-width: 670px) {
      font-size: 14px;
      font-family: Cairo;
      font-weight: 600;
    }
  }

  @media (max-width: 670px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
  }
`;

export const Divider = styled.div`
  margin-top: 1.87rem;
  padding-bottom: 1.87rem;
  height: 1px;
  border-top: 1px solid #e9e7ee;
`;

export const GridInputs = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  @media (max-width: 670px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
  isFull?: boolean;
}

export const ContainerInputFull = styled.div<ContainerInputProps>`
  width: ${(props) => (props.isFull ? "100%" : "49%")};
  display: flex;
  flex-direction: column;

  margin-bottom: 25px;

  input {
    width: 100%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: ${true ? "14px" : "20px"};

    padding: 0px 0px 4px 23px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-left: 13px;
    margin-top: 10px;
    color: ${Colors.secondary40};
    font-weight: normal;
    font-size: ${true ? "14px" : "20px"};
    line-height: 160%;
  }
`;
