import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 2.53rem;
  margin-bottom: 1.4rem;

  @media (max-width: 1024px) {
    margin-top: 0;
  }
`;

export const Title = styled.h1`
  font-family: "Cairo";
  font-style: normal;
  font-weight: 800;
  font-size: 1.87rem;
  color: ${Colors.black100} #11142d;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ButtonChangeOrganizer = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 12rem;
  height: 2.81rem;

  border-radius: 0.65rem;
  background: ${Colors.primary10};

  span {
    color: ${Colors.primary100};
    font-family: Cairo;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 700;
    margin-left: 10px;
    margin-bottom: 0px;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const FlexButtonsWeb = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 1rem;

    > a {
      width: 100%;
    }

    width: 100%;
  }
`;

export const FlexButtonResponsive = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  margin: 24px auto;

  @media (min-width: 1024px) {
    display: none;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 1rem;

    > a {
      width: 100%;
    }

    width: 100%;
  }
`;

export const FlexButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;

  margin: 24px auto;

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 1rem;

    > a {
      width: 100%;
    }

    width: 100%;
  }
`;

export const ButtonAddNew = styled.button`
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 12.81rem;
  height: 2.81rem;

  background: ${Colors.primary100};
  border-radius: 0.65rem;

  @media (max-width: 1024px) {
    width: 100%;
  }

  span {
    font-family: "Cairo";
    font-weight: bold;
    font-size: 0.93rem;
    margin-bottom: 0px;

    color: ${Colors.white};
    margin-left: 10px;
  }
`;
