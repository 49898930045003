import { useEffect, useState } from "react";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import {
  Container,
  ContainerItems,
  ContainerResponsive,
  ContainerWeb,
} from "./styles";

import Popup from "containers/Popup";
import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { SidebarProfile } from "components/SidebarProfileMobile";
import PersonalData from "./PersonalData";

export default function Profile() {
  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  return (
    <>
      <ContainerWeb>
        <PersonalData />
      </ContainerWeb>

      <ContainerResponsive>
        <Header />

        <Container>
          <ContainerItems>
            <SidebarProfile />
          </ContainerItems>
        </Container>

        <Footer />
      </ContainerResponsive>

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
