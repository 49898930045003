import { useCallback } from "react";

import { useSearchParams } from "react-router-dom";

import { Checkbox } from "antd";

import { RiCloseCircleLine, RiMoneyDollarCircleLine } from "react-icons/ri";

import { useFiltersContext } from "contexts/FiltersContext";

import { Colors } from "constants/colors";

import {
  ContainerModal,
  ContainerSelect,
  ModalStyled,
  NormalCardBorder,
} from "./styles";

export const dataStatus = [
  { id: 1, name: "Pago", key: "PAID" },
  { id: 2, name: "Pendente", key: "PENDING" },
  { id: 3, name: "Estornado", key: "REFUND" },
  { id: 4, name: "Cancelado", key: "CHARGEBACK" },
];

export function ModalFilterByStatus() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { isOpenFilterModalStatus, setIsOpenFilterModalStatus } =
    useFiltersContext();

  const closeModal = useCallback(() => {
    setIsOpenFilterModalStatus(false);
  }, [setIsOpenFilterModalStatus]);

  const handleSelectedIdFilter = useCallback(
    (value: any) => {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        payment_status: value,
      });

      closeModal();
    },
    [closeModal, searchParams, setSearchParams]
  );

  return (
    <ModalStyled
      width={857}
      visible={isOpenFilterModalStatus}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <h1>Filtrar por Status Do pagamento</h1>

        <h2>
          Selecione um status de pagamento para a visualização dos resultados.
        </h2>

        <ContainerSelect>
          <Checkbox.Group style={{ width: "100%" }} defaultValue={["Pear"]}>
            {dataStatus?.map((type) => (
              <NormalCardBorder
                onClick={() => handleSelectedIdFilter(type.key)}
              >
                <div className="circle-icon">
                  <RiMoneyDollarCircleLine
                    size={16}
                    color={Colors.secondary100}
                  />
                </div>

                <h3 className="normal">{type?.name}</h3>
              </NormalCardBorder>
            ))}
          </Checkbox.Group>
        </ContainerSelect>
      </ContainerModal>
    </ModalStyled>
  );
}
