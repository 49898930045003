import useSWR from "swr";

import api from "services/api";
import { authClient } from "services/authClient";

interface useFetchBasicProps {
  method: string;
  url: string;
  key?: string | string[];
}

export function useFetchBasic<Data = any, Error = any>({
  method,
  url,
  key,
}: useFetchBasicProps) {
  const { data, error, isValidating, mutate } = useSWR<Data, Error>(
    key ?? url,
    async (url) => {
      switch (method) {
        case "get":
          const responseGet = await api.get(url);

          return responseGet.data;

        case "post":
          const responsePost = await api.post(url);

          return responsePost.data;

        case "put":
          const responsePut = await api.put(url);

          return responsePut.data;

        case "patch":
          const responsePatch = await api.patch(url);

          return responsePatch.data;

        case "delete":
          const responseDelete = await api.delete(url);

          return responseDelete.data;
      }
    }
  );

  return { data, error, isValidating, mutate };
}

interface useFetchAuthProps {
  method: string;
  url: string;
  key?: string | string[];
}

export function useFetchAuth<Data = any, Error = any>({
  method,
  url,
  key,
}: useFetchAuthProps) {
  const { data, error, isValidating, mutate } = useSWR<Data, Error>(
    key ?? url,
    async (url) => {
      switch (method) {
        case "get":
          const responseGet = await authClient.get(url);

          return responseGet.data;

        case "post":
          const responsePost = await authClient.post(url);

          return responsePost.data;

        case "put":
          const responsePut = await authClient.put(url);

          return responsePut.data;

        case "patch":
          const responsePatch = await authClient.patch(url);

          return responsePatch.data;

        case "delete":
          const responseDelete = await authClient.delete(url);

          return responseDelete.data;
      }
    }
  );

  return { data, error, isValidating, mutate };
}
