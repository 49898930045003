import { useFetchBasic } from "services/useFetch";

export function useCategories<Data = any, Error = any>() {
  const url: string = "/category?limit=99&page=1";

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  return { data: data, error, mutate };
}
