import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
  height: 100%;
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.34rem 6.37rem 2.34rem 6.37rem;
  border: 1px solid ${Colors.black10};
  border-radius: 0.93rem;
  margin-top: 2.34rem;

  @media (max-width: 1024px) {
    padding: 0px 0px;
    border: 0;
    margin-top: 39px;
  }
`;

export const Title = styled.h2`
  color: ${Colors.secondary100};
  font-family: Poppins;
  font-size: 1.03rem;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0px;

  @media (max-width: 1024px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 700;
    line-height: 25px;
  }
`;

export const TitleWithMargin = styled.h2`
  color: ${Colors.secondary100};
  font-family: Poppins;
  font-size: 1.03rem;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 1.68rem;

  @media (max-width: 1024px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 700;
    line-height: 25px;
  }
`;

export const TitleWithMarginTop = styled.h2`
  color: ${Colors.secondary100};
  font-family: Poppins;
  font-size: 1.03rem;
  font-style: normal;
  font-weight: 500;
  margin-top: 2.68rem;
  margin-bottom: 1.68rem;

  @media (max-width: 1024px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 700;
    line-height: 25px;
  }
`;

export const SubTitle = styled.p`
  color: ${Colors.black70};
  font-family: Open Sans;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  margin-top: 0.65rem;

  span {
    color: ${Colors.black70};
    font-family: Open Sans;
    font-size: 16px;
    font-style: italic;
    font-weight: 600;
  }
`;

export const ListBenefits = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin-top: 2.1rem;

  gap: 1.35rem;

  @media (max-width: 1024px) {
    margin-top: 20px;
    gap: 18px;
  }
`;

export const ContainerLink = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.68rem;

  h2 {
    color: ${Colors.secondary40};
    font-family: Poppins;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0px;

    @media (max-width: 670px) {
      font-size: 11px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const RowShared = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 4px;
  gap: 0.93rem;

  input.upload {
    border: none;
    outline: 0;
    width: 100%;
    height: 2.81rem;
    border-radius: 0.37rem;
    border: 2px solid ${Colors.secondary20};
    background: ${Colors.white};
    padding-left: 0.93rem;

    @media (max-width: 670px) {
      border: transparent;
    }
  }

  @media (max-width: 670px) {
    border-radius: 3px;
    padding-right: 12px;
    border-radius: 5px;
    border: 1.5px solid ${Colors.border20};
    background: ${Colors.white};
  }
`;

export const ButtonShare = styled.div`
  cursor: pointer;
  border: 0;
  outline: 0;
  display: flex;
  width: 4.68rem;
  height: 2.81rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px 0px rgba(212, 0, 80, 0.15);

  img {
    width: 1.12rem;
    height: 1.12rem;

    @media (max-width: 670px) {
      width: 14px;
      height: 14px;
    }
  }

  @media (max-width: 670px) {
    border-radius: 3px;
    width: 48px;
    height: 28px;
  }
`;

export const ButtonShareWeb = styled.button`
  cursor: pointer;
  border: 0;
  outline: 0;
  display: flex;
  width: 4.68rem;
  height: 2.81rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px 0px rgba(212, 0, 80, 0.15);

  @media (max-width: 1024px) {
    display: none;
  }

  img {
    width: 1.12rem;
    height: 1.12rem;

    @media (max-width: 670px) {
      width: 14px;
      height: 14px;
    }
  }

  @media (max-width: 670px) {
    border-radius: 3px;
    width: 48px;
    height: 28px;
  }
`;

export const ButtonShareResponsible = styled.button`
  cursor: pointer;
  border: 0;
  outline: 0;
  display: flex;
  width: 4.68rem;
  height: 2.81rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px 0px rgba(212, 0, 80, 0.15);

  @media (min-width: 1024px) {
    display: none;
  }

  img {
    width: 1.12rem;
    height: 1.12rem;

    @media (max-width: 670px) {
      width: 14px;
      height: 14px;
    }
  }

  @media (max-width: 670px) {
    border-radius: 3px;
    width: 48px;
    height: 28px;
  }
`;

export const Divider = styled.div`
  margin-top: 1.87rem;
  padding-bottom: 1.87rem;
  height: 1px;
  border-top: 1px solid ${Colors.secondary10};
`;

export const GridInputs = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  @media (max-width: 670px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
`;

export const ContainerBottom = styled.div`
  height: 90px;
  margin-top: 30px;
  padding-left: 28px;

  > div + div {
    margin-top: 19px;
  }

  div.row {
    display: flex;
    flex-direction: row;
    align-items: center;

    h3 {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: ${Colors.black50};
      margin-bottom: 0px;
      margin-left: 10px;
    }

    div.badge {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: ${Colors.secondary10};
    }
  }
`;

export const ContainerVerifyImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 28px 34px 28px;
`;

export const Image = styled.div`
  width: 100%;
  height: 357px;
  border-radius: 10px;
  background: ${Colors.gray30};
`;

export const Text = styled.p`
  color: ${Colors.gray10};

  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 30px;

  strong {
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const ButtonTakenAnotherPhoto = styled.button`
  width: 100%;
  height: 38px;
  border: 0;
  outline: 0;

  border-radius: 8px;
  background: ${Colors.primary10};
  margin-top: 46px;

  span {
    color: ${Colors.primary100};
    font-family: Cairo;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

export const ButtonSendThis = styled.button`
  width: 100%;
  height: 38px;
  border: 0;
  outline: 0;

  border-radius: 8px;
  background: ${Colors.primary100};
  box-shadow: 0px 5px 30px 0px rgba(212, 0, 80, 0.25);

  margin-top: 30px;

  span {
    color: ${Colors.white};
    font-family: Cairo;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;
