import React from "react";
import { Link } from "react-router-dom";
import { RiCalendar2Line } from "react-icons/ri";
import { IoChevronDownSharp, IoChevronUpSharp } from "react-icons/io5";
import { ButtonOrganizer, AccountDropDownOrganizer } from "../../styles";
import { Colors } from "constants/colors";

interface OrganizerDropdownProps {
  activeDropOrganizer: boolean;
  handleChangeDropOrganizer: () => void;
  organizerId: string;
}

export const OrganizerDropdown: React.FC<OrganizerDropdownProps> = ({
  activeDropOrganizer,
  handleChangeDropOrganizer,
  organizerId,
}) => (
  <div className="account-container">
    <ButtonOrganizer
      active={activeDropOrganizer}
      onClick={handleChangeDropOrganizer}
    >
      <RiCalendar2Line size={20} color={Colors.primary100} />
      Organizador
      {activeDropOrganizer ? (
        <IoChevronUpSharp size={20} />
      ) : (
        <IoChevronDownSharp size={20} />
      )}
    </ButtonOrganizer>
    {activeDropOrganizer && (
      <AccountDropDownOrganizer>
        <ul>
          <Link to={`/organizer/profile/main/${organizerId}`}>
            <li>
              <span>Perfil</span>
            </li>
          </Link>
          <Link to="/my-event">
            <li>
              <span>Meus eventos</span>
            </li>
          </Link>
          <Link to="/financial">
            <li>
              <span>Financeiro</span>
            </li>
          </Link>
          <Link to="/ticket-office">
            <li>
              <span>Bilheteria</span>
            </li>
          </Link>
          <Link to="/organizer/ticket-check">
            <li aria-label="button" className="last">
              <span>Ticket check-in</span>
            </li>
          </Link>
        </ul>
      </AccountDropDownOrganizer>
    )}
  </div>
);
