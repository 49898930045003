import { Colors } from "constants/colors";
import { sizeConversor } from "utils/conversors";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 45px;

  svg {
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (max-width: 800px) {
    display: none;
  }

  div.tabs-content {
    display: flex;
    width: 100%;
    gap: 40px;
    margin-top: 30px;

    div.all-index {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }


  hr {
    border-top: ${sizeConversor(2)} solid var(--preto-preto-10, #E7E7EA);
    width: 100%;
  }
`;

interface TabStyledProps {
  active: boolean;
}

export const TabStyled = styled.h3<TabStyledProps>`
  font-family: "Poppins";
  font-weight: ${(props) => (props.active ? "bold" : 600)};
  font-size: ${sizeConversor(22)};
  line-height: 160%;
  text-decoration: none;

  cursor: pointer;
  color: ${(props) => (props.active ? Colors.primary100 : Colors.black30)};
  border-bottom: ${(props) => (props.active ? `3px solid ${Colors.primary100}` : 'none')};
  margin-bottom: 0px;
`;

export const MobileContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  position: fixed;
  bottom: 0;
  left: 0;

  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.10);

  z-index: 1000;
`

export const MobileTabContainer = styled.button`
  padding: 11px 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) => props.theme.colors.white};
  outline: none;
  border: none;
  width: 100%;

  border-bottom: 2px solid ${(props) => props.theme.colors.white};

  > span {
    font-family: "Cairo";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #24105899;
  }

  &.activeTab {
    > span {
      color: ${(props) => props.theme.colors.pink};
      font-weight: 700;
    }

    border-bottom: 2px solid ${(props) => props.theme.colors.pink};
  }
`
