import { Colors } from "constants/colors";
import styled from "styled-components";

interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
  isFull?: boolean;
}

export const ContainerInputFull = styled.div<ContainerInputProps>`
  width: ${(props) => (props.isFull ? "100%" : "49%")};
  display: flex;
  flex-direction: column;

  margin-bottom: 25px;
  margin-top: 1.68rem;

  textarea {
    width: 100%;
    height: 200px;

    outline: 0;
    border: 0;

    border-radius: 10px;
    border: 2px solid ${Colors.secondary20};
    background: ${Colors.white};
    padding: 0.93rem 0.93rem;
    color: ${Colors.secondary40};
    font-size: 0.93rem;
    font-family: Open Sans;
    line-height: 160%;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;
