import "react-loading-skeleton/dist/skeleton.css";
import { AuthProvider } from "contexts/AuthContext";
import { IntegrationsProvider } from "contexts/IntegrationsContext";
import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import theme from "./styles/theme";
import RoutesPage from "./routes";

import { SearchCityAndStateProvider } from "contexts/SearchCityAndState";
import { TabsTicketsAndPurchasesProvider } from "contexts/TabsTicketsAndPurchasesContext";
import GlobalStyle from "styles/GlobalStyle";
import { CreateEventProvider } from "contexts/CreateEventContext";
import { CreateAndEditTicketProvider } from "contexts/CreateAndEditTicketContext";
import { FiltersContextProvider } from "contexts/FiltersContext";
import { GeneralContextProvider } from "contexts/GeneralContext";
import { CreateOrganizerProvider } from "contexts/CreateOrganizerContext";

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />

      <ThemeProvider theme={theme}>
        <AuthProvider>
          <FiltersContextProvider>
            <GeneralContextProvider>
              <CreateEventProvider>
                <CreateOrganizerProvider>
                  <CreateAndEditTicketProvider>
                    <SearchCityAndStateProvider>
                      <IntegrationsProvider>
                        <TabsTicketsAndPurchasesProvider>
                          <RoutesPage />
                        </TabsTicketsAndPurchasesProvider>
                      </IntegrationsProvider>
                    </SearchCityAndStateProvider>
                  </CreateAndEditTicketProvider>
                </CreateOrganizerProvider>
              </CreateEventProvider>
            </GeneralContextProvider>
          </FiltersContextProvider>
        </AuthProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
