import { useState } from "react";

import { FiChevronRight } from "react-icons/fi";

import { Colors } from "constants/colors";

import {
  Column,
  Container,
  ContainerItems,
  Divider,
  Row,
  Title,
  TitlePrice,
  TitleTotal,
} from "./styles";

import { DrawerOverlay } from "components/DrawerOverlay";

import { useGeneralContext } from "contexts/GeneralContext";
import { BiChevronDown, BiChevronLeft } from "react-icons/bi";
import { formatPrice } from "utils/formatPrice";
import { divideBy100 } from "utils/divideBy100";

interface DrawerGeneralBalanceReceivableProps {
  total: number;
}

export function DrawerGeneralBalanceReceivable({
  total,
}: DrawerGeneralBalanceReceivableProps) {
  const [selectedPurchase, setSelectedPurchase] = useState<
    "resume" | "detail" | null
  >(null);

  const {
    isOpenDrawerGeneralBalanceReceivable,
    setIsOpenDrawerGeneralBalanceReceivable,
  } = useGeneralContext();

  const handleSelectedPurchase = (value: "resume" | "detail" | null) => {
    setSelectedPurchase(value);
  };

  const title: { [key: string]: any } = {
    resume: "Resumo das vendas",
    detail: "Detalhes da venda",
    default: "Saldo a receber",
  };

  return (
    <DrawerOverlay
      title={title[selectedPurchase ?? "default"]}
      icon={
        !selectedPurchase ? (
          <BiChevronDown
            color={Colors.white}
            size={24}
            onClick={() => setIsOpenDrawerGeneralBalanceReceivable(false)}
          />
        ) : (
          <BiChevronLeft
            color={Colors.white}
            size={24}
            onClick={() => handleSelectedPurchase(null)}
          />
        )
      }
      isVisible={isOpenDrawerGeneralBalanceReceivable}
      handleOk={() => setIsOpenDrawerGeneralBalanceReceivable(false)}
    >
      {selectedPurchase === null ? (
        <Container>
          <ContainerItems>
            <Title>Saldo disponível para antecipação</Title>

            <Divider />

            <Row onClick={() => handleSelectedPurchase("resume")}>
              <Column>
                <TitleTotal>saldo a receber</TitleTotal>

                <TitlePrice>{formatPrice(divideBy100(total))}</TitlePrice>
              </Column>

              <FiChevronRight color={Colors.gray45} size={24} />
            </Row>
          </ContainerItems>
        </Container>
      ) : null}
    </DrawerOverlay>
  );
}
