import { GoChevronUp } from "react-icons/go";
import { MdEdit } from "react-icons/md";

import { useSWRConfig } from "swr";

import { useCreateAndEditTicket } from "contexts/CreateAndEditTicketContext";

import {
  useAuthTicketsByEventsId,
  useTicketEvent,
} from "services/Hook/Event/Ticket";

import { TicketEvent } from "types";
import { formatPrice } from "utils/formatPrice";

import { Colors } from "constants/colors";

import {
  ButtonEditTicket,
  ButtonEditTicketResponsive,
  CardAccordion,
  Container,
  ContainerBorder,
  ContainerBorderMobile,
  ContainerButtons,
  DataDropDown,
  RowMobile,
  RowMobileBorder,
  RowMobileFirst,
} from "./styles";

import ticket_profile from "assets/ticket-profile.svg";

interface TicketsEventInterface {
  tickets: TicketEvent[];
}

interface ListTicketByEventProps {
  event_id: string;
}

export function ListTicketByEvent({ event_id }: ListTicketByEventProps) {
  const { setSelectedTicket, setIsOpenModalTicket, setIsOpenDrawerTicket } =
    useCreateAndEditTicket();

  // const { data: dataTickets } = useTicketEvent<TicketsEventInterface>(
  //   event_id ?? ""
  // );

  const { data: dataTickets } = useAuthTicketsByEventsId<TicketEvent[]>([
    String(event_id),
  ]);

  const { mutate } = useSWRConfig();

  const handleOpenTicketEvent = (ticketId: number | string) => {
    mutate(
      `/event-ticket/search/${event_id}`,
      () => {
        const mapTickets = dataTickets?.map((ticket) =>
          ticket._id === ticketId
            ? { ...ticket, isOpen: !ticket.isOpen }
            : ticket
        );

        return mapTickets;

        // return {
        //   ...dataTickets,
        //   tickets: mapTickets,
        // };
      },
      false
    );
  };

  const handleSelectedTicketForModal = (ticket_event: TicketEvent) => {
    setSelectedTicket(ticket_event);

    setIsOpenModalTicket(true);
  };

  const handleSelectedTicketForDrawer = (ticket_event: TicketEvent) => {
    setSelectedTicket(ticket_event);

    setIsOpenDrawerTicket(true);
  };

  return (
    <Container>
      {dataTickets &&
        dataTickets?.map((ticket) => (
          <>
            <CardAccordion
              active={ticket.isOpen}
              onClick={() => handleOpenTicketEvent(ticket._id)}
              key={ticket?.id}
            >
              <div className="content">
                <div className="image">
                  <img
                    className="ticket"
                    src={ticket_profile}
                    alt="Logo"
                    width={25}
                    height={22.5}
                  />
                </div>

                <div className="texts">
                  <h2>{ticket?.name}</h2>
                  <p>{formatPrice(ticket?.price / 100)}</p>
                </div>
              </div>

              <GoChevronUp size={22} color={Colors.secondary50} />
            </CardAccordion>

            {ticket.isOpen ? (
              <DataDropDown>
                <ContainerBorder>
                  <div className="content">
                    <div className="column">
                      <h2>Data de criação</h2>

                      <p>{ticket.formattedDate}</p>
                    </div>
                    <div className="column">
                      <h2>Valor do ingresso</h2>

                      <p>{formatPrice(ticket.price / 100)}</p>
                    </div>

                    <div className="column">
                      <h2>Custo da venda</h2>

                      <p>-</p>
                    </div>
                    <div className="column">
                      <h2>Valor total</h2>

                      <p>{formatPrice(ticket.price / 100)}</p>
                    </div>

                    <div className="column">
                      <h2>Cupons aplicados</h2>

                      <p>-</p>
                    </div>
                  </div>
                </ContainerBorder>

                <ContainerBorderMobile>
                  <RowMobileFirst>
                    <h6 className="row">Data de criação:</h6>

                    <span className="row">{ticket.formattedDate}</span>
                  </RowMobileFirst>

                  <RowMobile>
                    <h6 className="row">Valor do ingresso:</h6>

                    <span className="row">
                      {formatPrice(ticket.price / 100)}
                    </span>
                  </RowMobile>

                  <RowMobile>
                    <h6 className="row">Custo da venda:</h6>

                    <span className="row">-</span>
                  </RowMobile>

                  <RowMobileBorder>
                    <h6 className="row">Valor total:</h6>

                    <span className="row">
                      {formatPrice(ticket.price / 100)}
                    </span>
                  </RowMobileBorder>

                  <RowMobile>
                    <h6 className="row">Cupons aplicados:</h6>

                    <span className="row">-</span>
                  </RowMobile>
                </ContainerBorderMobile>

                <ContainerButtons>
                  <ButtonEditTicket
                    type="button"
                    onClick={() => handleSelectedTicketForModal(ticket)}
                  >
                    <MdEdit size={20} color={Colors.green10} />

                    <div className="text">
                      <span>Editar Ingresso</span>
                    </div>
                  </ButtonEditTicket>

                  <ButtonEditTicketResponsive
                    type="button"
                    onClick={() => handleSelectedTicketForDrawer(ticket)}
                  >
                    <MdEdit size={20} color={Colors.green10} />

                    <div className="text">
                      <span>Editar Ingresso</span>
                    </div>
                  </ButtonEditTicketResponsive>
                </ContainerButtons>
              </DataDropDown>
            ) : null}
          </>
        ))}
    </Container>
  );
}
