import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;

  padding-right: 38px;
  padding-left: 38px;

  margin-top: 9.14rem;

  width: 100%;

  @media (max-width: 1024px) {
    margin-top: 70px;
    padding-left: 28px;
    padding-right: 28px;
  }

  div.main-header {
    @media (max-width: 900px) {
      display: none;
    }

    h1 {
      font-family: "Cairo";
      margin-top: 2.53rem;
      font-weight: 900;
      font-size: 1.87rem;
      margin-bottom: 0px;
      color: ${Colors.black100};
    }
  }

  div.header-filter-mobile {
    margin-top: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 1024px) {
      display: none;
    }

    h1 {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;

      color: ${Colors.black100};
    }

    div.box {
      width: 25.73px;
      height: 25.73px;
      border-radius: 50%;
      background: ${Colors.white20};

      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;
    }
  }

  div.bread {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const Title = styled.h1`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.87rem;
  font-style: normal;
  font-weight: 700;
  margin-top: 2.53rem;

  @media (max-width: 640px) {
    color: var(--Preto, #11142d);
    font-family: Cairo;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.68rem;
  margin-bottom: 2rem;
`;

export const ButtonPrevFirst = styled.button`
  border: 0;
  width: 18.23rem;
  height: 3.84rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 14px;

  @media (max-width: 670px) {
    width: 124px;
    height: 38px;
    border-radius: 8px;
  }

  span {
    color: ${Colors.black10};
    text-align: center;
    font-size: 1.21rem;
    font-family: Cairo;
    font-weight: 700;
    line-height: 160%;

    @media (max-width: 670px) {
      font-size: 12px;
    }
  }
`;

interface ButtonPrevProps {
  active?: boolean;
}

export const ButtonPrev = styled.button<ButtonPrevProps>`
  cursor: ${(props) => (props.active ? "pointer" : "normal")};
  border: 0;
  width: 18.23rem;
  height: 3.84rem;
  background: ${(props) => (props.active ? Colors.primary10 : Colors.black10)};
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 14px;

  @media (max-width: 670px) {
    width: 124px;
    height: 38px;
    border-radius: 8px;
  }

  span {
    color: ${(props) => (props.active ? Colors.primary100 : Colors.gray45)};
    text-align: center;
    font-size: 1.21rem;
    font-family: Cairo;
    font-weight: 700;
    line-height: 160%;

    @media (max-width: 670px) {
      font-size: 12px;
    }
  }
`;

interface ButtonNextProps {
  isLoading?: boolean;
}

export const ButtonNext = styled.button<ButtonNextProps>`
  cursor: pointer;
  border: 0;
  display: flex;
  width: 18.23rem;
  height: 3.84rem;
  padding: 20px 0px;
  justify-content: center;
  align-items: center;
  background-color: ${Colors.primary100};
  flex-shrink: 0;
  border-radius: 14px;

  @media (max-width: 670px) {
    width: 124px;
    height: 38px;
    border-radius: 8px;
  }

  span {
    color: ${Colors.white};
    text-align: center;
    font-size: 1.21rem;
    font-family: Cairo;
    font-weight: 700;
    line-height: 160%;
    margin-left: ${(props) => (props.isLoading ? "0.46rem" : 0)};

    @media (max-width: 670px) {
      font-size: 12px;
    }
  }
`;
