import { ReactNode } from "react";

import { RiCloseCircleLine } from "react-icons/ri";

import { Colors } from "constants/colors";

import { ContainerHeader, DrawerStyled } from "./styles";

interface DrawerOverlayProps {
  children: ReactNode;
  icon?: ReactNode;
  isVisible: boolean;
  handleOk(): void;
  title: string;
}

export function DrawerOverlay({
  children,
  title,
  icon,
  handleOk,
  isVisible,
}: DrawerOverlayProps) {
  return (
    <DrawerStyled
      title={
        <ContainerHeader>
          {icon ?? icon}
          <h1>{title}</h1>
        </ContainerHeader>
      }
      className="custom-drawer"
      width="100%"
      drawerStyle={{ color: Colors.secondary100 }}
      headerStyle={{
        // paddingLeft: "14px",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
        color: Colors.white,
        fontWeight: "bold",
        backgroundColor: Colors.secondary100,
      }}
      closable={false}
      bodyStyle={{ padding: 0 }}
      placement="right"
      onClose={handleOk}
      visible={isVisible}
      // extra={
      //   <RiCloseCircleLine size={24} color={Colors.white} onClick={handleOk} />
      // }
      // closeIcon
    >
      {children}
    </DrawerStyled>
  );
}
