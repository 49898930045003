import { useSecureLocalStorage } from "hooks/useSecureLocalStorage";

import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

import {
  resetStore,
  toBack,
  toNext,
  addIdentification,
  addFrontIdentify,
  addBackIdentify,
  addProofOfAddress,
  addSelfieHoldingDocument,
} from "reducers/create-organizer/actions";

import { organizerReducer } from "reducers/create-organizer/reducers";

import { IdentificationResponseOrganizer } from "types";

interface Step {
  title: string;
  current: boolean;
  completed: boolean;
}

type CreateOrganizerContextData = {
  steps: Step[];
  organizer: {
    step: number;
    identification: IdentificationResponseOrganizer | null;
    frontIdentify: string;
    backIdentify: string;
    proofOfAddress: string;
    selfieHoldingDocument: string;
    proofOfRegistrationStatus: string;
    goToNextStep(): void;
    goToBackStep(): void;
    createIdentification(identification: IdentificationResponseOrganizer): void;
    createFrontIdentify(document: string): void;
    createBackIdentify(document: string): void;
    createProofOfAddress(document: string): void;
    createSelfieHoldingDocument(document: string): void;
    resetTheStore(): void;
  };
};

const CreateOrganizerContext = createContext<CreateOrganizerContextData>(
  {} as CreateOrganizerContextData
);

export const CreateOrganizerProvider: React.FC = ({ children }) => {
  const [steps, setSteps] = useState([
    { title: "Introdução", current: false, completed: false },
    { title: "Dados de Identificação", current: false, completed: false },
    { title: "Documentos", current: false, completed: false },
    { title: "Dados Financeiros", current: false, completed: false },
  ]);

  const secureLocalStorage = useSecureLocalStorage();

  const [organizerState, dispatch] = useReducer(
    organizerReducer,
    {
      step: 0,
      identification: null,
      frontIdentify: "",
      backIdentify: "",
      proofOfRegistrationStatus: "",
      proofOfAddress: "",
      selfieHoldingDocument: "",
    },
    (initialState) => {
      const storedStateAsJSON = secureLocalStorage.getItem(
        "@EventX-create_organizer"
      );

      if (storedStateAsJSON) {
        return storedStateAsJSON;
      }

      return initialState;
    }
  );

  useEffect(() => {
    secureLocalStorage.setItem("@EventX-create_organizer", organizerState);
  }, [organizerState, secureLocalStorage]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    setSteps((prev) => {
      return prev.map((item, index) => {
        if (index === organizerState.step) {
          return { ...item, current: true, completed: false };
        }

        return {
          ...item,
          current: false,
          completed: organizerState.step > index ? true : false,
        };
      });
    });
  }, [organizerState.step]);

  const value = {
    ...organizerState,
    goToNextStep: () => {
      dispatch(toNext());
    },
    goToBackStep: () => {
      dispatch(toBack());
    },
    createIdentification: (identification: IdentificationResponseOrganizer) => {
      dispatch(addIdentification(identification));
    },
    createFrontIdentify(document: string) {
      dispatch(addFrontIdentify(document));
    },
    createBackIdentify(document: string) {
      dispatch(addBackIdentify(document));
    },
    createProofOfAddress(document: string) {
      dispatch(addProofOfAddress(document));
    },
    createSelfieHoldingDocument(document: string) {
      dispatch(addSelfieHoldingDocument(document));
    },
    resetTheStore: () => {
      dispatch(resetStore());
    },
  };

  return (
    <CreateOrganizerContext.Provider
      value={{
        steps,
        organizer: value,
      }}
    >
      {children}
    </CreateOrganizerContext.Provider>
  );
};

export function useCreateOrganizer(): CreateOrganizerContextData {
  const context = useContext(CreateOrganizerContext);

  if (!context) {
    throw new Error(`useCreateOrganizer it depends CreateOrganizerProvider`);
  }

  return context;
}
