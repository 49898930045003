import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Colors } from "constants/colors";

import { Transaction } from "types";

import {
  HiddenResponsive,
  CardAccordion,
  DataDropDown,
  ContainerBorderMobile,
  RowMobileFirst,
  RowMobile,
  ContainerButtons,
  ButtonDownload,
  ContainerTransactions,
  TitleTransactions,
  TitleTransactionsResponsive,
  TitleHistory,
} from "./styles";

import { ModalFilterByData } from "components/ModalFilterByData";
import { ModalFilterByEvent } from "components/ModalFilterByEvent";
import { ModalFilterByOrganizer } from "components/ModalFilterByOrganizer";
import { ModalFilterByTicket } from "components/ModalFilterByTicket";

import { TableTransactions } from "components/TableTransactions";

import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { AiOutlineEye } from "react-icons/ai";

import { sortingFilterFinancialTransactions } from "constants/filters";
import { HeaderFilter } from "components/HeaderFilter";
import { ModalFilterByFormPayment } from "components/ModalFilterByFormPayment";
import { ModalFilterByPrice } from "components/ModalFilterByPrice";
import { useSearchFinancialTransaction } from "hooks/useSearchFinancialTransaction";

import { Pagination } from "components/Pagination";
import { EmptySearchComponent } from "components/EmptySearchComponent";
import { Loading } from "components/Loading";
import { BadgePurchaseByStatus } from "components/BadgePurchaseByStatus";
import { returnTypePaymentPurchase } from "utils/purchase";
import { ModalFilterByStatus } from "components/ModalFilterByStatus";
import { formatPrice } from "utils/formatPrice";
import LoadMoreItems from "components/LoadMoreItems";
import { DropDownSearch } from "components/DropDownSearch";



export default function Transactions() {
  const [purchaseSelected, setPurchaseSelected] =
    useState<Transaction | null>();

  const {
    data,
    onChange,
    page,
    handleLoadMorePurchasingsMobile,
    hasMoreItems,
    handleOpenDrop,
    loadingMoreItems,
  } = useSearchFinancialTransaction({});

  const handleOpenDropCardEvent = (purchase: Transaction) => {
    if (purchase._id === purchaseSelected?._id) {
      setPurchaseSelected(null);

      return;
    }

    handleOpenDrop(purchase._id);

    setPurchaseSelected(purchase);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <HiddenResponsive>
        <HeaderFilter filters={sortingFilterFinancialTransactions} />
      </HiddenResponsive>

      <TitleTransactions>Histórico de transações</TitleTransactions>

      <TitleTransactionsResponsive>pesquisar por</TitleTransactionsResponsive>

      <DropDownSearch />

      <TableTransactions
        purchases={data?.results ?? null}
        handleOpenDropCardEvent={handleOpenDropCardEvent}
        eventSelected={purchaseSelected ?? null}
      />

      {!data ? <Loading /> : null}

      {data && data?.results?.length === 0 ? <EmptySearchComponent /> : null}

      {data && data?.results?.length > 0 ? (
        <div className="pagination">
          <Pagination
            title="registros"
            totalCountOfRegisters={data?.count}
            currentPage={Number(page)}
            onPageChange={onChange}
            registersPerPage={10}
          />
        </div>
      ) : null}

      {data && data?.results?.length > 0 ? (
        <TitleHistory>histórico de transações</TitleHistory>
      ) : null}

      <ContainerTransactions>
        {data &&
          data?.results.map((purchase) => (
            <div className="container-card" key={purchase?._id}>
              <CardAccordion onClick={() => handleOpenDrop(purchase?._id)}>
                <div className="content">
                  <div className="texts">
                    <h2>
                      {purchase?.user_id?.name.length > 38
                        ? `${purchase?.user_id?.name.substring(0, 38)}...`
                        : purchase?.user_id?.name}
                    </h2>

                    <p>ID: {purchase?.user_id?._id}</p>
                  </div>
                </div>

                {purchase?.isOpen ? (
                  <GoChevronDown size={22} color={Colors.secondary50} />
                ) : (
                  <GoChevronUp size={22} color={Colors.secondary50} />
                )}
              </CardAccordion>

              {purchase?.isOpen ? (
                <DataDropDown>
                  <ContainerBorderMobile>
                    <RowMobileFirst>
                      <h6 className="row">Evento:</h6>

                      <span className="row">{purchase?.event_id?.name}</span>
                    </RowMobileFirst>

                    <RowMobileFirst>
                      <h6 className="row">Data da venda:</h6>

                      <span className="row">{purchase?.formattedDate}</span>
                    </RowMobileFirst>

                    <RowMobile>
                      <h6 className="row">Valor da venda:</h6>

                      <span className="row">
                        {formatPrice(
                          purchase?.total_wet ? purchase?.total_wet / 100 : 0
                        )}
                      </span>
                    </RowMobile>

                    <RowMobile>
                      <h6 className="row-margin-right">Status da venda:</h6>

                      <BadgePurchaseByStatus
                        status={purchase?.payment_status ?? ""}
                      />
                    </RowMobile>

                    <RowMobile>
                      <h6 className="row">Forma de Pagamento:</h6>

                      <span className="row">
                        {returnTypePaymentPurchase(
                          purchase?.payment_type ?? ""
                        )}
                      </span>
                    </RowMobile>
                  </ContainerBorderMobile>

                  <ContainerButtons>
                    <Link to={`/financial/${purchase?._id}`}>
                      <ButtonDownload type="button">
                        <AiOutlineEye size={20} color={Colors.secondary100} />

                        <div className="text">
                          <span>Visualizar</span>
                        </div>
                      </ButtonDownload>
                    </Link>
                  </ContainerButtons>
                </DataDropDown>
              ) : null}
            </div>
          ))}
      </ContainerTransactions>

      <LoadMoreItems
        handleLoadMore={handleLoadMorePurchasingsMobile}
        fetching={loadingMoreItems}
        hasMoreItems={hasMoreItems}
        length={data?.results?.length}
        total={data?.count}
      />

      <ModalFilterByData />

      <ModalFilterByEvent />

      <ModalFilterByOrganizer />

      <ModalFilterByTicket />

      <ModalFilterByFormPayment />

      <ModalFilterByPrice />

      <ModalFilterByStatus />
    </>
  );
}
