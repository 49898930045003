import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 0.84rem;
  list-style: none;
`;

interface CardProps {
  active?: boolean;
}

export const Icon = styled.div<CardProps>`
  width: 2.1rem;
  height: 2.1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${(props) =>
    props.active ? Colors.primary10 : Colors.white};
  border-radius: 0.56rem;

  svg {
    color: ${(props) =>
      props.active ? Colors.primary100 : Colors.secondary40};
  }
`;

export const Item = styled.li<CardProps>`
  cursor: pointer;
  width: 100%;
  min-height: 4.73rem;
  max-height: 4.73rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.31rem 1.03rem 1.31rem 1.03rem;
  border: 1px solid
    ${(props) => (props.active ? Colors.primary40 : Colors.black10)};
  box-shadow: 0px 2px 6px rgba(18, 25, 34, 0.05);
  padding: 0px 5px 0px 1.4rem;
  border-radius: 0.65rem;

  svg.icon {
    color: ${(props) =>
      props.active ? Colors.primary100 : Colors.secondary40};

    @media (max-width: 800px) {
      width: 10px;
      height: 10px;
    }
  }

  @media (max-width: 800px) {
    min-height: 38px;
    max-height: 38px;
    border-radius: 8px;
    padding: 18px 10px;
  }
`;

export const Text = styled.h3<CardProps>`
  color: ${(props) => (props.active ? Colors.primary100 : Colors.black100)};
  font-family: Poppins;
  font-size: 1.03rem;
  font-weight: ${(props) => (props.active ? 600 : 400)};
  margin-bottom: 0;
  margin-left: 0.56rem;

  @media (max-width: 800px) {
    color: #a79fbc;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const Content = styled.div<CardProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
