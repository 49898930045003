import {
  Container,
  Step,
  Steps,
  Item,
  Circle,
  Tag,
  TagTransparent,
  ResponsiveSteps,
  TagTransparentFirst,
} from "./styles";
import { Colors } from "constants/colors";
import { BiCheck } from "react-icons/bi";

import { useCreateOrganizer } from "contexts/CreateOrganizerContext";

export function StepsOrganizerComponent() {
  const { steps } = useCreateOrganizer();

  return (
    <Container>
      <Steps>
        {steps.map((item, index) => {
          return (
            <Step active={item.completed || item.current} isFirst={index === 0}>
              <Item>
                {index === 0 ? (
                  <TagTransparentFirst />
                ) : (
                  <Tag completed={item.completed || item.current} />
                )}

                <Circle current={item.current} completed={item.completed}>
                  {item.completed ? (
                    <BiCheck color={Colors.white} size={22} />
                  ) : null}
                </Circle>

                <Tag completed={item.completed} />
              </Item>

              <h2>{item.title}</h2>
            </Step>
          );
        })}
      </Steps>

      <ResponsiveSteps>
        {steps.map((item, index) => {
          return (
            <Step active={item.completed || item.current} isFirst={index === 0}>
              <Item>
                {index === 0 ? (
                  <TagTransparent />
                ) : (
                  <Tag completed={item.completed || item.current} />
                )}

                <Circle current={item.current} completed={item.completed}>
                  {item.completed ? (
                    <BiCheck color={Colors.white} size={22} />
                  ) : null}
                </Circle>

                {index === 3 ? (
                  <TagTransparent />
                ) : (
                  <Tag completed={item.completed} />
                )}
              </Item>

              <h2>{item.title}</h2>
            </Step>
          );
        })}
      </ResponsiveSteps>
    </Container>
  );
}
