import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
  height: 100%;

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.34rem 6.37rem 2.34rem 6.37rem;
  border: 1px solid ${Colors.black10};
  border-radius: 0.93rem;
  margin-top: 2.34rem;

  @media (max-width: 1024px) {
    padding: 0px 0px;
    border: 0;
  }
`;

export const Title = styled.h2`
  color: ${Colors.secondary100};
  font-size: 1.21rem;
  font-family: Poppins;
  font-weight: 600;
  line-height: 160%;

  @media (max-width: 670px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 700;
    line-height: 25px;
  }
`;

export const SubTitle = styled.h3`
  color: ${Colors.black70};
  font-size: 1rem;
  font-family: Open Sans;
  line-height: 160%;
  margin-top: 0.93rem;

  @media (max-width: 670px) {
    font-size: 12px;
    font-family: Open Sans;
    margin-top: 8px;
  }
`;

export const LabelTitle = styled.h4`
  color: ${Colors.secondary100};
  font-size: 1.03rem;
  font-family: Poppins;
  font-weight: 500;
  line-height: 160%;

  @media (max-width: 670px) {
    color: ${Colors.black100};
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
  }
`;

export const SubLabelTitle = styled.h4`
  color: ${Colors.black60};
  font-size: 0.75rem;
  font-family: Open Sans;
  font-weight: 700;
  line-height: 160%;

  strong {
    font-size: 0.75rem;
    font-family: Open Sans;
    font-style: italic;
    color: ${Colors.black70};

    @media (max-width: 670px) {
      font-size: 14px;
      font-family: Cairo;
      font-weight: 600;
    }
  }

  @media (max-width: 670px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
  }
`;

export const Divider = styled.div`
  margin-top: 1.87rem;
  padding-bottom: 1.87rem;
  height: 1px;
  border-top: 1px solid ${Colors.secondary10};
`;

export const ButtonAddImage = styled.div`
  cursor: pointer;
  border: 0;
  display: flex;
  width: 24.98rem;
  height: 2.81rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background: ${Colors.primary10};
  margin-top: 1.68rem;

  span {
    color: ${Colors.primary100};
    font-size: 0.93rem;
    font-family: Cairo;
    font-weight: 700;
    line-height: 160%;
    margin-left: 0.46rem;

    @media (max-width: 670px) {
      font-size: 12px;
      font-weight: 600;
      margin-left: 7px;
    }
  }

  @media (max-width: 670px) {
    width: 100%;
  }
`;

export const ContainerImage = styled.div`
  border-radius: 0.65rem;
  background: ${Colors.purple5};
  width: 24.98rem;
  height: 9.98rem;
  flex-shrink: 0;
  margin-top: 0.93rem;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 4.12rem;
    height: 4.12rem;
  }

  @media (max-width: 670px) {
    width: 100%;
  }
`;

export const ContainerImagePreview = styled.div`
  border-radius: 0.65rem;
  background: ${Colors.purple5};
  width: 24.98rem;
  height: 9.98rem;
  flex-shrink: 0;
  margin-top: 0.93rem;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    width: 100%;
    border-radius: 0.65rem;
    object-fit: cover;
  }

  @media (max-width: 670px) {
    width: 100%;
  }
`;
