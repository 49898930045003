import { useCallback } from "react";
import { FilterByData, GridFilterByData } from "./styles";
import { useSearchParams } from "react-router-dom";

const dataFilter = [
  { id: 1, name: "Hoje" },
  { id: 2, name: "7 dias" },
  { id: 3, name: "15 dias" },
  { id: 4, name: "30 dias" },
];

export function FiltersByDataType() {
  const [searchParams, setSearchParams] = useSearchParams();

  const data_type = searchParams.get("data_type") ?? "";

  const handleChangeDataType = useCallback(
    (type_id) => {
      if (type_id === data_type) {
        searchParams.delete("data_type");

        setSearchParams({
          ...Object.fromEntries([...searchParams]),
        });

        return;
      }

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        data_type: type_id,
      });
    },
    [data_type, searchParams, setSearchParams]
  );

  return (
    <GridFilterByData>
      {dataFilter.map((filter) => (
        <FilterByData
          key={filter?.id}
          active={String(filter?.id) === data_type}
          onClick={() => handleChangeDataType(String(filter?.id))}
        >
          <span>{filter?.name}</span>
        </FilterByData>
      ))}
    </GridFilterByData>
  );
}
