import { Colors } from "constants/colors";
import { sizeConversor } from "utils/conversors";
import styled from "styled-components";

interface SidebarProps {
  activeStep: boolean;
}

export const StepContainer = styled.div<SidebarProps>`
  cursor: pointer;

  font-weight: normal;
  font-size: 1.03rem;

  font-weight: ${(props) => (props.activeStep ? "bold" : 400)};
  display: flex;
  justify-content: space-between;
  padding: 0 22px;
  align-items: center;
  color: ${(props) => (props.activeStep ? Colors.primary100 : Colors.black100)};
  border: 1px solid
    ${(props) => (props.activeStep ? Colors.primary40 : Colors.black10)};
  border-radius: 14px;
  min-height: ${sizeConversor(90)};
  width: ${sizeConversor(400)};
  padding: ${sizeConversor(28)};
  margin-top: 18px;
  box-shadow: 0px 2px 10px 0 rgba(18, 25, 34, 0.05);

  .chevron {
    color: ${(props) =>
      props.activeStep ? Colors.primary100 : Colors.secondary40};
    font-size: ${(props) => (props.activeStep ? "18px" : "16px")};
  }

  span {
    font-family: Poppins;
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;
