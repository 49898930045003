import { useCallback, useEffect, useState } from "react";

import { MdAdd, MdCheck } from "react-icons/md";

import { SubmitHandler, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { LoadingOutlined } from "@ant-design/icons";

import {
  ButtonShare,
  Card,
  Container,
  ContainerLink,
  Divider,
  RowShared,
  SubTitle,
  Title,
} from "../Identification/styles";

import { ContainerButtons, ButtonPrev, ButtonNext } from "../../styles";

import { DocumentCreateOrganizer } from "types";
import { createOrganizerDocumentFormSchema } from "lib/validations/createOrganizer";
import { Colors } from "constants/colors";
import { CreateDocumentOrganizer } from "services/Hook/Organizer/Document";
import { Spin } from "antd";
import { useCreateOrganizer } from "contexts/CreateOrganizerContext";
import {
  errorNotification,
  successNotification,
} from "components/Notification";

export function Document() {
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    reset,
  } = useForm<DocumentCreateOrganizer>({
    resolver: yupResolver(createOrganizerDocumentFormSchema),
  });

  const [loadingUploadFront, setLoadingUploadFront] = useState(false);
  const [loadingUploadBack, setLoadingUploadBack] = useState(false);
  const [loadingUploadProof, setLoadingUploadProof] = useState(false);
  const [loadingUploadHolding, setLoadingUploadHolding] = useState(false);

  const { organizer } = useCreateOrganizer();

  useEffect(() => {
    reset({
      has_uploaded_identify_front: organizer?.frontIdentify,
      has_uploaded_identify_back: organizer?.backIdentify,
      has_uploaded_proof_of_address: organizer?.proofOfAddress,
      has_uploaded_selfie_holding_document: organizer?.selfieHoldingDocument,
    });
  }, [
    organizer?.backIdentify,
    organizer?.frontIdentify,
    organizer?.proofOfAddress,
    organizer?.selfieHoldingDocument,
    reset,
  ]);

  const handleAddDocuments: SubmitHandler<
    DocumentCreateOrganizer
  > = async () => {
    organizer.goToNextStep();
  };

  const handleChangeAddFront = async (event: any) => {
    if (!organizer?.identification?.organizerId) return;

    const fileFrontIdentify = event.target.files[0];

    try {
      setLoadingUploadFront(true);

      const data = await CreateDocumentOrganizer({
        document: fileFrontIdentify,
        organizerId: organizer?.identification?.organizerId,
        type: 1,
      });

      setLoadingUploadFront(false);

      organizer.createFrontIdentify(data?.patch);

      successNotification("Documento adicionado com sucesso");
    } catch (err: any) {
      setLoadingUploadFront(false);

      errorNotification(
        "Erro ao cadastrar o documento",
        JSON.stringify(err?.response?.data?.message ?? "Tente novamente")
      );
    }

    event.target.value = "";
  };

  const handleChangeAddBack = async (event: any) => {
    const fileIdentifyBack = event.target.files[0];

    try {
      setLoadingUploadBack(true);

      const data = await CreateDocumentOrganizer({
        document: fileIdentifyBack,
        organizerId: organizer?.identification?.organizerId ?? "",
        type: 1,
      });

      setLoadingUploadBack(false);

      organizer.createBackIdentify(data?.patch);

      successNotification("Documento adicionado com sucesso");
    } catch (err: any) {
      setLoadingUploadBack(false);

      errorNotification(
        "Erro ao cadastrar o documento",
        JSON.stringify(err?.response?.data?.message ?? "Tente novamente")
      );
    }

    event.target.value = "";
  };

  const handleChangeAddProofOfAddress = async (event: any) => {
    if (!organizer?.identification?.organizerId) return;

    const fileProofOfAddress = event.target.files[0];

    try {
      setLoadingUploadProof(true);

      const data = await CreateDocumentOrganizer({
        document: fileProofOfAddress,
        organizerId: organizer?.identification?.organizerId,
        type: 3,
      });

      setLoadingUploadProof(false);

      organizer.createProofOfAddress(data?.patch);

      successNotification("Documento adicionado com sucesso");
    } catch (err: any) {
      setLoadingUploadProof(false);

      errorNotification(
        "Erro ao cadastrar o documento",
        JSON.stringify(err?.response?.data?.message ?? "Tente novamente")
      );
    }

    event.target.value = "";
  };

  const handleChangeAddSelfieHoldingDocument = async (event: any) => {
    if (!organizer?.identification?.organizerId) return;

    const fileSelfieHoldingDocument = event.target.files[0];

    try {
      setLoadingUploadHolding(true);

      const data = await CreateDocumentOrganizer({
        document: fileSelfieHoldingDocument,
        organizerId: organizer?.identification?.organizerId,
        type: 4,
      });

      setLoadingUploadHolding(false);

      organizer.createSelfieHoldingDocument(data?.patch);

      successNotification("Documento adicionado com sucesso");
    } catch (err: any) {
      setLoadingUploadHolding(false);

      errorNotification(
        "Erro ao cadastrar o documento",
        JSON.stringify(err?.response?.data?.message ?? "Tente novamente")
      );
    }

    event.target.value = "";
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  return (
    <Container onSubmit={handleSubmit(handleAddDocuments)}>
      <Card>
        <Title>Upload de documentos</Title>

        <SubTitle>
          Especificações: arquivo do tipo PNG ou JPEG, com tamanho máximo
          recomendado de 4MB.
        </SubTitle>

        <ContainerLink>
          <h2>Documento de identidade (frente)</h2>

          <RowShared>
            <input
              type="text"
              className="upload"
              style={{ width: "100%" }}
              value={organizer?.frontIdentify}
              placeholder="selecione uma Imagem"
              disabled
              readOnly
            />

            <input
              id="identify_front"
              name="identify_front"
              type="file"
              style={{ display: "none" }}
              onChange={handleChangeAddFront}
              // disabled={!!hasUploadedIdentifyFront}
              accept="image/png, image/jpg"
            />

            <label className="badge" htmlFor="identify_front">
              <ButtonShare>
                {loadingUploadFront ? (
                  <Spin spinning={loadingUploadFront} indicator={antIcon} />
                ) : null}

                {!loadingUploadFront ? (
                  <>
                    {organizer?.frontIdentify ? (
                      <MdCheck color={Colors.white} size={24} />
                    ) : (
                      <MdAdd color={Colors.white} size={24} />
                    )}
                  </>
                ) : null}
              </ButtonShare>
            </label>
          </RowShared>

          {!!errors.has_uploaded_identify_front ? (
            <span className="error">
              {errors?.has_uploaded_identify_front?.message}
            </span>
          ) : null}
        </ContainerLink>

        <ContainerLink>
          <h2>Documento de identidade (verso)</h2>

          <RowShared>
            <input
              type="text"
              className="upload"
              value={organizer?.backIdentify}
              placeholder="selecione uma Imagem"
              disabled
              readOnly
            />

            <input
              id="identify_back"
              type="file"
              style={{ display: "none" }}
              name="identify_back"
              onChange={handleChangeAddBack}
              // disabled={hasUploadedIdentifyBack === 1}
              accept="image/png, image/jpg"
            />

            <label className="badge" htmlFor="identify_back">
              <ButtonShare>
                {loadingUploadBack ? (
                  <Spin spinning={loadingUploadBack} indicator={antIcon} />
                ) : null}

                {!loadingUploadBack ? (
                  <>
                    {organizer?.backIdentify ? (
                      <MdCheck color={Colors.white} size={24} />
                    ) : (
                      <MdAdd color={Colors.white} size={24} />
                    )}
                  </>
                ) : null}
              </ButtonShare>
            </label>
          </RowShared>

          {!!errors.has_uploaded_identify_back ? (
            <span className="error">
              {errors?.has_uploaded_identify_back?.message}
            </span>
          ) : null}
        </ContainerLink>

        <ContainerLink>
          <h2>Comprovante de residência</h2>

          <RowShared>
            <input
              type="text"
              className="upload"
              value={organizer?.proofOfAddress}
              placeholder="selecione uma Imagem"
              disabled
              readOnly
            />

            <input
              id="proof_of_address"
              type="file"
              style={{ display: "none" }}
              name="proof_of_address"
              onChange={handleChangeAddProofOfAddress}
              // disabled={hasUploadedProofOfAddress === 1}
              accept="image/png, image/jpg"
            />

            <label className="badge" htmlFor="proof_of_address">
              <ButtonShare>
                {loadingUploadProof ? (
                  <Spin spinning={loadingUploadProof} indicator={antIcon} />
                ) : null}

                {!loadingUploadProof ? (
                  <>
                    {organizer?.proofOfAddress ? (
                      <MdCheck color={Colors.white} size={24} />
                    ) : (
                      <MdAdd color={Colors.white} size={24} />
                    )}
                  </>
                ) : null}
              </ButtonShare>
            </label>
          </RowShared>

          {!!errors.has_uploaded_proof_of_address ? (
            <span className="error">
              {errors?.has_uploaded_proof_of_address?.message}
            </span>
          ) : null}
        </ContainerLink>

        <Divider />

        <Title>Selfie segurando seu documento</Title>

        <SubTitle>
          Tire uma foto sua segurando seu documento de identidade (RG ou CNH).
          Seu rosto e seu documento precisam estar visíveis na foto.
        </SubTitle>

        <ContainerLink>
          <h2>Selfie segurando seu documento</h2>

          <RowShared>
            <input
              type="text"
              className="upload"
              value={organizer?.selfieHoldingDocument}
              placeholder="selecione uma Imagem"
              disabled
              readOnly
            />

            <input
              id="selfie_holding_document"
              type="file"
              style={{ display: "none" }}
              name="selfie_holding_document"
              onChange={handleChangeAddSelfieHoldingDocument}
              // disabled={hasUploadedSelfieHoldingDocument === 1}
              accept="image/png, image/jpg"
            />

            <label className="badge" htmlFor="selfie_holding_document">
              <ButtonShare>
                {loadingUploadHolding ? (
                  <Spin spinning={loadingUploadHolding} indicator={antIcon} />
                ) : null}

                {!loadingUploadHolding ? (
                  <>
                    {organizer?.selfieHoldingDocument ? (
                      <MdCheck color={Colors.white} size={24} />
                    ) : (
                      <MdAdd color={Colors.white} size={24} />
                    )}
                  </>
                ) : null}
              </ButtonShare>
            </label>
          </RowShared>

          {!!errors.has_uploaded_selfie_holding_document ? (
            <span className="error">
              {errors?.has_uploaded_selfie_holding_document?.message}
            </span>
          ) : null}
        </ContainerLink>

        <ContainerButtons>
          <ButtonPrev type="button">
            <span>Voltar</span>
          </ButtonPrev>

          <ButtonNext
            type="submit"
            isLoading={isSubmitting}
            disabled={isSubmitting}
          >
            <Spin spinning={isSubmitting} indicator={antIcon} />

            <span>Prosseguir</span>
          </ButtonNext>
        </ContainerButtons>
      </Card>
    </Container>
  );
}
