import { authClient } from "services/authClient";
import { useFetchBasic } from "services/useFetch";
import useSWR from "swr";
import { TicketEvent } from "types";

export function useTicketEvent<Data = any, Error = any>(
  id: number | string | undefined
) {
  const url: string = `/tickets/events/${id}`;

  const { data, error, mutate } = useSWR<Data, Error>(
    url,
    async (url) => {
      const response = await authClient.get(url);

      const dataResponse = response.data;

      const newData = dataResponse;

      const mapTickets = newData?.tickets?.map((ticket: TicketEvent) => ({
        ...ticket,
        isOpen: false,
      }));

      const newResponse = { ...newData, tickets: mapTickets };

      return newResponse;
    },
    {
      isPaused: () => {
        return !id || id === "undefined";
      },
    }
  );

  return { data, error, mutate };
}

interface useTicketCodeResponse {
  ticket_code: number;
  ticket_name: string;
}

const filterCode = (ticket: number) => {
  return ("0000000000000000" + Number(ticket)).slice(-16);
};

export function useTicketCode<Data = any, Error = any>(id: string) {
  const url: string = `/tickets/ticket-code/${id}`;

  const { data, error, mutate } = useFetchBasic<Data, Error>({
    method: "get",
    url,
  });

  const dataResponse = data as unknown;

  const newData = dataResponse as useTicketCodeResponse;

  const newResponse = {
    ticket_code: filterCode(newData?.ticket_code),
    ticket_name: newData?.ticket_name,
  };

  return { data: newResponse, error, mutate };
}

export function useAuthTicketsByEventsId<Data = any, Error = any>(
  events_ids: Array<string>
) {
  const url: string = `/event-ticket/search`;

  const event_id = events_ids[0];

  const { data, error, mutate } = useSWR<Data, Error>(
    `${url}/${event_id}`,
    async () => {
      const { data } = await authClient.post(url, {
        event_id: events_ids,
      });

      const response = data.results;

      return response;
    }
  );

  return { data, error, mutate };
}

interface AddTicketProps {
  credentials: any;
}

export async function AddTicket({ credentials }: AddTicketProps) {
  const url: string = "/event-ticket";

  const response = await authClient.post(url, credentials);

  return response;
}

interface EditTicketProps {
  id: string | number | undefined;
  credentials: any;
}

export async function EditTicket({ id, credentials }: EditTicketProps) {
  const url: string = `/event-ticket/${id}`;

  const response = await authClient.patch(url, credentials);

  return response.data;
}
