import {
  DataCreateEvent,
  DescriptionCreateEvent,
  EventResponse,
  InformationCreateEvent,
  LocalCreateEvent,
  OrganizerCreateEvent,
  TicketCreateEvent,
} from "types";

import { ActionTypes } from "./actions";

export interface EventState {
  step: number;
  information: InformationCreateEvent | null;
  data: DataCreateEvent | null;
  local: LocalCreateEvent | null;
  description: DescriptionCreateEvent | null;
  organizer: OrganizerCreateEvent | null;
  event_response: EventResponse | null;
  tickets: TicketCreateEvent[] | [];
}

export function eventReducer(state: EventState, action: any) {
  switch (action.type) {
    case ActionTypes.GO_TO_BACK_STEP:
      if (state.step === 0) {
        return { ...state };
      }

      return {
        ...state,
        step: state.step - 1,
      };

    case ActionTypes.GO_TO_NEXT_STEP:
      if (state.step >= 6) {
        return { ...state };
      }

      return {
        ...state,
        step: state.step + 1,
      };

    case ActionTypes.ADD_INFORMATION:
      return {
        ...state,
        information: action.payload.information,
      };

    case ActionTypes.ADD_DATA:
      return {
        ...state,
        data: action.payload.data,
      };

    case ActionTypes.ADD_LOCAL:
      return {
        ...state,
        local: action.payload.local,
      };

    case ActionTypes.ADD_DESCRIPTION:
      return {
        ...state,
        description: action.payload.description,
      };

    case ActionTypes.ADD_ORGANIZER:
      return {
        ...state,
        organizer: action.payload.organizer,
      };

    case ActionTypes.ADD_EVENT_RESPONSE:
      return {
        ...state,
        event_response: action.payload.event_response,
      };

    case ActionTypes.ADD_TICKET:
      return {
        ...state,
        tickets: [...state.tickets, action.payload.ticket],
      };

    case ActionTypes.RESET_STORE:
      return {
        ...state,
        step: 0,
        information: null,
        data: null,
        local: null,
        description: null,
        organizer: null,
        event_response: null,
        tickets: [],
      };

    default:
      return state;
  }
}
