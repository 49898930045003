import styled from "styled-components";

export const GridSidebar = styled.div`
  width: 100%;
  display: grid;
  gap: 1.87rem;
  grid-template-columns: 1fr 3fr;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

interface SidebarContainerProps {
  main?: boolean;
}

export const SidebarContainer = styled.div<SidebarContainerProps>`
  width: 100%;

  @media (max-width: 1024px) {
    display: ${(props) => (props.main ? "flex" : "none")};
    flex-direction: column;
  }
`;
