import { useEffect, useState } from "react";

import { useSearchParams } from "react-router-dom";

import { Checkbox } from "antd";

import { RiCloseCircleLine, RiCoupon2Line } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";

import type { CheckboxValueType } from "antd/es/checkbox/Group";

import { Colors } from "constants/colors";

import {
  ButtonClear,
  ButtonOk,
  ButtonSearch,
  CheckboxStyled,
  ContainerInputSearch,
  ContainerModal,
  ContainerSelect,
  ModalStyled,
  NormalCardBorder,
} from "./styles";

import { useFiltersContext } from "contexts/FiltersContext";
import { ContainerButtonsModal } from "components/ModalFilterByData/styles";
import { TicketEvent } from "types";
import { useAuthTicketsByEventsId } from "services/Hook/Event/Ticket";

const data = [
  { id: 1, name: "Ingresso padrão - 1º lote" },
  { id: 2, name: "Ingresso padrão - 2º lote" },
  { id: 3, name: "Ingresso padrão - 3º lote" },
  { id: 4, name: "Ingresso padrão - 4º lote" },
  { id: 5, name: "Ingresso padrão - 5º lote" },
  { id: 6, name: "Ingresso padrão - 6º lote" },
];

// pegar ingressos por parametros, fazer um merge de tickets de cada ingresso e colocar
// em um array
export function ModalFilterByTicket() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [textSearch, setTextSearch] = useState("");

  const { isOpenFilterModalModalTicket, setIsOpenFilterModalTicket } =
    useFiltersContext();

  const events = searchParams.get("events") ?? "";

  const eventsBy = events?.split(",");

  const { data: dataTickets } =
    useAuthTicketsByEventsId<TicketEvent[]>(eventsBy);

  const closeModal = () => {
    setIsOpenFilterModalTicket(false);
  };

  const [selectedItems, setSelectedItems] = useState<CheckboxValueType[]>([]);

  const tickets = searchParams.get("tickets") ?? "";

  useEffect(() => {
    if (tickets) {
      const ticketsBy = tickets?.split("%");

      setSelectedItems(ticketsBy);
    }
  }, [tickets]);

  const onChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedItems(checkedValues);
  };

  // useEffect(() => {
  //   setTicketsData(data ?? []);
  // }, []);

  useEffect(() => {
    let filterTimeout: NodeJS.Timeout;

    const doFilter = () => {
      // clearTimeout(filterTimeout);
      // if (!textSearch) return setTicketsData(data ?? []);
      // filterTimeout = setTimeout(async () => {
      //   if (data) {
      //     setTicketsData(
      //       data.filter((ticket) =>
      //         ticket.name.toLowerCase().includes(textSearch.toLowerCase())
      //       )
      //     );
      //   }
      // }, 500);
    };

    if (textSearch) {
      doFilter();
    }
  }, [textSearch]);

  const handleAddFilterTicket = () => {
    if (selectedItems.length === 0) {
      return;
    }

    const tickets = selectedItems.join("%");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      tickets,
    });

    closeModal();
  };

  const clearTicket = () => {};

  return (
    <ModalStyled
      width={857}
      visible={isOpenFilterModalModalTicket}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <h1>Filtrar por ingresso</h1>

        <h2>
          Selecione um ou mais ingressos para a visualização dos resultados.
        </h2>

        <ContainerInputSearch>
          <input type="text" placeholder="Insira o nome do ingresso..." />

          <ButtonSearch onClick={() => {}}>
            <FiSearch size={18} color={Colors.primary100} />
          </ButtonSearch>
        </ContainerInputSearch>

        <ContainerSelect>
          <Checkbox.Group
            style={{ width: "100%" }}
            defaultValue={["Pear"]}
            onChange={onChange}
          >
            {dataTickets?.map((ticket) => (
              <NormalCardBorder>
                <div className="check">
                  <CheckboxStyled value={ticket?._id} />
                </div>

                <div className="circle-icon">
                  <RiCoupon2Line size={16} color={Colors.secondary100} />
                </div>

                <h3 className="normal">{ticket?.name}</h3>
              </NormalCardBorder>
            ))}
          </Checkbox.Group>
        </ContainerSelect>

        <ContainerButtonsModal>
          <ButtonClear onClick={clearTicket}>
            <span>Limpar</span>
          </ButtonClear>

          <ButtonOk onClick={handleAddFilterTicket}>
            <span>Aplicar filtros</span>
          </ButtonOk>
        </ContainerButtonsModal>
      </ContainerModal>
    </ModalStyled>
  );
}
