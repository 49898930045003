import { Divider } from "antd";
import {
  Container,
  GridItems,
  Header,
  Item,
  SubTitle,
  TextTotal,
  TitleTotal,
  WithdrawButton,
} from "./styles";
import { RiAccountBoxLine, RiInformationLine } from "react-icons/ri";
import { Colors } from "constants/colors";
import { formatPrice } from "utils/formatPrice";
import { divideBy100 } from "utils/divideBy100";
import Withdraw from "assets/icons/Withdraw";
import { OrganizerBalance } from "types";
import { useAuth } from "contexts/AuthContext";
import { Card } from "components/ModalGeneralBalanceAvailable/styles";

export type BalanceProps = OrganizerBalance & {
  organizerName: string;
};

interface OrganizerBalanceAvailableProps {
  organizersBalances: Array<OrganizerBalance>;
  onWithdraw: (balance: BalanceProps) => void;
}

function OrganizerBalanceAvailable({
  organizersBalances,
  onWithdraw,
}: OrganizerBalanceAvailableProps) {
  const { organizers: organizersData } = useAuth();

  const organizers = organizersData ?? [];

  return (
    <Card>
      <Container>
        {organizersBalances?.map((item) => {
          const organizer = organizers?.find(
            (i) => i._id.toString() === item.id
          );

          if (!organizer) return null;

          return (
            <>
              <Header>
                <RiAccountBoxLine color={Colors.secondary40} size={25} />

                <span>{organizer.name}</span>
              </Header>

              <GridItems>
                <Item onClick={() => {}}>
                  <div className="column-content">
                    <TitleTotal>SALDO DISPONÍVEL</TitleTotal>

                    <TextTotal>
                      {formatPrice(divideBy100(item.availableAmount))}
                    </TextTotal>

                    <div>
                      <RiInformationLine color={Colors.black50} size={18} />

                      <SubTitle>Saldo disponível para saque</SubTitle>
                    </div>
                  </div>

                  {item.availableAmount > 0 && (
                    <WithdrawButton
                      onClick={() =>
                        onWithdraw({ ...item, organizerName: organizer.name })
                      }
                    >
                      <Withdraw />

                      <span>Fazer saque</span>
                    </WithdrawButton>
                  )}
                </Item>
              </GridItems>

              <Divider />
            </>
          );
        })}
      </Container>
    </Card>
  );
}

export default OrganizerBalanceAvailable;
