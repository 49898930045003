import { useState } from "react";

import { RiErrorWarningLine, RiInformationLine } from "react-icons/ri";

import { Colors } from "constants/colors";

import {
  CardReport,
  Column,
  ColumnContentReport,
  ColumnContentTotal,
  Container,
  ContainerItems,
  ContainerOverlay,
  ContainerTax,
  Divider,
  Row,
  RowItemTotal,
  Title,
  TitlePrice,
  TitlePriceSmall,
  TitleTax,
  TitleTotal,
  TitleTotalSmall,
} from "./styles";

import { DrawerOverlay } from "components/DrawerOverlay";

import { useGeneralContext } from "contexts/GeneralContext";
import { BiChevronDown, BiChevronLeft } from "react-icons/bi";
import { FiChevronRight } from "react-icons/fi";
import { formatPrice } from "utils/formatPrice";

interface DrawerGeneralTransferProps {
  total: number;
  fee: number;
}

export function DrawerGeneralTransfer({
  total,
  fee,
}: DrawerGeneralTransferProps) {
  const [selectedPurchase, setSelectedPurchase] = useState(false);

  const { isOpenDrawerGeneralTransfer, setIsOpenDrawerGeneralTransfer } =
    useGeneralContext();

  return (
    <DrawerOverlay
      title={
        !!selectedPurchase ? "Repasses da EventX" : "Detalhes dos repasses"
      }
      icon={
        !selectedPurchase ? (
          <BiChevronDown
            color={Colors.white}
            size={24}
            onClick={() => setIsOpenDrawerGeneralTransfer(false)}
          />
        ) : (
          <BiChevronLeft
            color={Colors.white}
            size={24}
            onClick={() => setSelectedPurchase(false)}
          />
        )
      }
      isVisible={isOpenDrawerGeneralTransfer}
      handleOk={() => setIsOpenDrawerGeneralTransfer(false)}
    >
      {!selectedPurchase ? (
        <Container>
          <ContainerItems>
            <Title>Taxas da EventX</Title>

            <Divider />

            <Row onClick={() => {}}>
              <Column>
                <TitleTotal>total de repasses</TitleTotal>

                <TitlePrice>{formatPrice(fee ?? 0)}</TitlePrice>
              </Column>

              <FiChevronRight color={Colors.gray45} size={24} />
            </Row>

            <ContainerTax>
              <RiInformationLine color={Colors.black50} size={12} />

              <TitleTax>Vendas online dos ingressos</TitleTax>
            </ContainerTax>

            <ContainerOverlay>
              <RowItemTotal>
                <ColumnContentTotal>
                  <TitleTotalSmall>vendas totais</TitleTotalSmall>

                  <TitlePriceSmall>{formatPrice(total ?? 0)}</TitlePriceSmall>
                </ColumnContentTotal>

                <FiChevronRight color={Colors.gray45} size={24} />
              </RowItemTotal>
            </ContainerOverlay>
          </ContainerItems>
        </Container>
      ) : null}

      {selectedPurchase ? (
        <Container>
          <ContainerItems>
            <CardReport>
              <Column>
                <ColumnContentReport>
                  <RiErrorWarningLine color={Colors.primary100} size={20} />

                  <h3>Reportar</h3>
                </ColumnContentReport>
              </Column>
            </CardReport>
          </ContainerItems>
        </Container>
      ) : null}
    </DrawerOverlay>
  );
}
