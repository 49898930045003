import { subDays, format } from "date-fns/esm";

const filtersDate = [
  { id: 1, name: "Hoje", interval: 0 },
  { id: 2, name: "Últimos 7 dias", interval: 7 },
  { id: 3, name: "Últimos 15 dias", interval: 15 },
  { id: 4, name: "Últimos 30 dias", interval: 30 },
  { id: 5, name: "Data personalizada", interval: 0 },
];

interface DayDistance {
  date_start: string;
  date_end: string;
}

export function useGetDateAgo() {
  function getDataFromDistance(interval: number): DayDistance {
    const today = new Date();

    const dayAgo = subDays(today, interval);

    const formatDateStart = format(dayAgo, "yyyy-MM-dd");
    const formatDateEnd = format(today, "yyyy-MM-dd");

    return { date_start: formatDateStart, date_end: formatDateEnd };
  }

  const daysFilter = filtersDate.map((item) => {
    const { date_start, date_end } = getDataFromDistance(item.interval);

    return {
      ...item,
      date_start,
      date_end,
    };
  });

  return { daysFilter };
}
