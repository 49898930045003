import { useCreateEvent } from "contexts/CreateEventContext";

import { Container, ContainerItems } from "./styles";

import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";
import { StepsComponent } from "components/Steps";
import { Footer } from "components/Footer";

import { BasicInformations } from "./Steps/BasicInformation";
import { Data } from "./Steps/Data";
import { Local } from "./Steps/Local";
import { Description } from "./Steps/Description";
import { AboutOrganizer } from "./Steps/AboutOrganizer";
import { Ticket } from "./Steps/Ticket";
import { ShareEvent } from "./Steps/ShareEvent";

export default function EventNew() {
  const { event } = useCreateEvent();

  const steps: { [key: string]: any } = {
    0: <BasicInformations />,
    1: <Data />,
    2: <Local />,
    3: <Description />,
    4: <AboutOrganizer />,
    5: <Ticket />,
    6: <ShareEvent />,
  };

  return (
    <>
      <Header />
      <Container>
        <ContainerItems>
          <>
            <div className="bread">
              <ListBreadcrumb
                data={[
                  { name: "Home", link: "/" },
                  { name: "Meus eventos", link: "/my-event" },
                  { name: "Criar evento", link: "/" },
                ]}
              />
            </div>

            <div className="main-header">
              <h1>Cadastre seu evento</h1>
            </div>

            <div className="header-filter-mobile">
              <h1>Cadastre seu evento</h1>
            </div>
          </>

          <StepsComponent />

          {steps[event.step ?? 0]}
        </ContainerItems>
      </Container>

      <Footer />
    </>
  );
}
