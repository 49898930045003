import React from "react";
import { FiMapPin } from "react-icons/fi";
import { VscChevronDown } from "react-icons/vsc";
import { Colors } from "constants/colors";
import Constants from "constants/Datas";

interface LocationSelectorProps {
  address_city?: string;
  address_state?: string;
  showModalSearch: () => void;
}

export const LocationSelector: React.FC<LocationSelectorProps> = ({
  address_city,
  address_state,
  showModalSearch,
}) => (
  <div className="dropdown" onClick={showModalSearch}>
    <FiMapPin size={14} color={Colors.primary100} />
    <div className="text">
      {!address_city && !address_state ? <h3>Qualquer lugar</h3> : null}
      {!!address_city ? <h3>{address_city}</h3> : null}
      {!!address_state ? <h3>{renderDistrict(address_state)}</h3> : null}
    </div>
    <div className="icon">
      <VscChevronDown className="down" size={16} color={Colors.primary100} />
    </div>
  </div>
);

const renderDistrict = (district: string) => {
  const findState = Constants.States.find(
    (value) => String(value.value) === district
  );
  return findState ? findState.label : district;
};
