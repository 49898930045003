import { PropsWithChildren } from "react";
import { Container, Icon } from "./styles";

export interface OverviewProps {
  name: string;
  quantity: number;
  color: string;
  bgColor: string;
}

export function OverviewItem(props: PropsWithChildren<OverviewProps>) {
  return (
    <Container className="overview-item">
      <Icon style={{color: props.color, backgroundColor: props.bgColor}}>{props.children}</Icon>
      <div className="name">
        <span>{props.quantity}</span>
        <span>{props.name}</span>
      </div>
    </Container>
  );
}
