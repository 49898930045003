import {
  Card,
  Container,
  ListBenefits,
  Retangle,
  RowBenefit,
  Title,
} from "./styles";

import { ContainerButtons, ButtonPrevFirst, ButtonNext } from "../../styles";

import ri_bar from "assets/ri_bar-chart-box-line-pink.svg";
import ri_file from "assets/ri_file-list-line-pink.svg";
import ri_wallet from "assets/ri_wallet-3-line-pink.svg";
import { useCreateOrganizer } from "contexts/CreateOrganizerContext";

const benefits = [
  {
    title: "Receba o valor de suas vendas",
    description:
      "Cadastrando seus dados de organizador, você poderá receber seus ganhos de forma simples e segura.",
    icon: ri_wallet,
  },
  {
    title: "Emita nota fiscal",
    description:
      "A nota fiscal referente aos valores repassados pela EventX será emitida utilizando os dados de identificação que você cadastrará aqui.",
    icon: ri_file,
  },
  {
    title: "Obtenha uma organização financeira",
    description:
      "Gerencie os valores de todas as suas vendas em uma dashboard simples e funcional.",
    icon: ri_bar,
  },
];

export function Introduction() {
  const { organizer } = useCreateOrganizer();

  return (
    <Container>
      <Card>
        <Title>Por que devo cadastrar meus dados de organizador?</Title>

        <ListBenefits>
          {benefits.map((benefit) => (
            <RowBenefit key={benefit.title}>
              <Retangle>
                <img src={benefit?.icon} alt="" />
              </Retangle>

              <div className="content">
                <h3>{benefit?.title}</h3>

                <h4>{benefit?.description}</h4>
              </div>
            </RowBenefit>
          ))}
        </ListBenefits>
      </Card>

      <ContainerButtons>
        <ButtonPrevFirst type="button" onClick={() => {}}>
          <span>Voltar</span>
        </ButtonPrevFirst>

        <ButtonNext type="button" onClick={() => organizer.goToNextStep()}>
          <span>Prosseguir</span>
        </ButtonNext>
      </ContainerButtons>
    </Container>
  );
}
