import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  max-width: 19.81rem;
  width: 100%;
`;

export const ProfileCard = styled.div`
  width: 100%;
  min-height: 6.56rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.7rem 1.92rem 0.7rem 1.4rem;
  border: 1px solid ${Colors.black10};
  box-shadow: 0px 2px 6px rgba(18, 25, 34, 0.05);
  border-radius: 0.65rem;
  margin-bottom: 0.84rem;

  @media (max-width: 800px) {
    padding: 0.7rem 1.92rem 0.7rem 0px;
    box-shadow: none;
    border: 0;
  }
`;

export const Avatar = styled.div`
  width: 4.5rem;
  height: 4.5rem;

  position: relative;
  background: ${Colors.white};

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
  }

  @media (max-width: 800px) {
    width: 89px;
    height: 89px;
    border-radius: 50%;
  }

  .badge {
    cursor: pointer;
    border: 0;
    outline: 0;
    position: absolute;
    width: 1.59rem;
    height: 1.59rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0;
    bottom: 0;
    background: ${Colors.white};
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    font-size: 12px;
    color: ${Colors.primary100};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 0.84rem;

  h2 {
    font-family: Cairo;
    color: ${Colors.secondary100};
    font-weight: bold;
    font-size: 1.03rem;
    margin-bottom: 0.46rem;
    line-height: 1.3rem;

    @media (max-width: 800px) {
      font-family: Cairo;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  span {
    font-family: Poppins;
    color: ${Colors.black50};
    font-weight: normal;
    font-size: 0.75rem;
    margin-bottom: 6px;

    @media (max-width: 800px) {
      color: #888996;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 4px;
    }
  }
`;

export const CardFirstSteps = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 13px;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.84rem;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${Colors.border60};
  background: ${Colors.white};
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 40px;
`;

export const FirstStepItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f1eefa;
`;

export const Text = styled.div`
  color: #adadad;
  font-family: "Open Sans";
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 0.84rem;
`;

export const TextStepThrough = styled.h2`
  color: var(--gray2, #a79fbc);
  font-family: Poppins;
  font-size: 0.84rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
  text-decoration: line-through;
  margin-left: 10px;
`;

export const TextStep = styled.h2`
  color: var(--Preto, #11142d);
  font-family: Poppins;
  font-size: 0.84rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
  margin-left: 10px;
`;

export const ContentStep = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
