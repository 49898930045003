import moment from "moment";

export function checkFormat(date: string) {
  if (date === "") return "";

  if (moment(date, "YYYY-MM-DD HH:mm", true).isValid()) {
    return moment(date, "YYYY-MM-DD HH:mm").format("hh:mm");
  }

  return moment(date, "HH:mm:ss").format("hh:mm");
}
