import { Colors } from "constants/colors";
import styled from "styled-components";

import { Select } from "antd";

export const LabelTitle = styled.h4`
  color: ${Colors.secondary100};
  font-size: 1.03rem;
  font-family: Poppins;
  font-weight: 500;
  line-height: 160%;

  @media (max-width: 670px) {
    color: ${Colors.black100};
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
  }
`;

export const Divider = styled.div`
  margin-top: 1.87rem;
  padding-bottom: 1.87rem;
  height: 1px;
  border-top: 1px solid ${Colors.secondary10};
`;

export const DividerWithoutBorder = styled.div`
  padding-bottom: 1.87rem;
  height: 1px;
`;

export const GridInputs = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  @media (max-width: 670px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
`;

export const CardContentModal = styled.div`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  margin-top: 2.62rem;
  border-radius: 0.93rem;
  border: 1px solid  ${Colors.black10};
  background: ${Colors.white};
  padding: 2.34rem 2.34rem;

  @media(max-width: 1024px){
    border: 0;
    padding:  0px 28px;
  }
`;

export const TitleModal = styled.h3`
  color: ${Colors.secondary100};
  font-size: 1.21rem;
  font-family: Poppins;
  font-weight: 600;
`;

export const ContainerButtonsModal = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 3.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media(max-width: 1024px){
    padding: 0px 28px;
    margin-bottom: 34px;
  }

  @media (max-width: 670px) {
    flex-direction: column;
  }
`;

export const ButtonClose = styled.button`
  cursor: pointer;
  display: flex;
  border: 0;
  width: 18rem;
  height: 3.84rem;
  padding: 0.93rem 0px;
  justify-content: center;
  align-items: center;

  border-radius: 0.65rem;
  background: ${Colors.primary10};

  @media (max-width: 670px) {
    height: 38px;
    width: 100%;
    border-radius: 8px;
  }

  span {
    color: ${Colors.primary100};
    text-align: center;

    font-size: 1.21rem;
    font-family: Cairo;
    font-weight: 700;
    line-height: 160%;

    @media (max-width: 670px) {
      font-family: Cairo;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
`;

export const ButtonOk = styled.button`
  cursor: pointer;
  display: flex;
  border: 0;
  width: 18rem;
  height: 3.84rem;
  padding: 0.93rem 0px;
  justify-content: center;
  align-items: center;

  border-radius: 0.65rem;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px 0px rgba(212, 0, 80, 0.15);

  @media (max-width: 670px) {
    height: 38px;
    margin-top: 30px;
    border-radius: 8px;
    width: 100%;
  }

  span {
    color: ${Colors.white};
    text-align: center;

    font-size: 1.21rem;
    font-family: Cairo;
    font-weight: 700;
    line-height: 160%;

    @media (max-width: 670px) {
      font-family: Cairo;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
`;

export const TitleLabelPrice = styled.h4`
  color: ${Colors.black30};
  font-family: Open Sans;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 700;
`;

export const TitlePrice = styled.h5`
  color: ${Colors.secondary100};
  font-family: Cairo;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
`;

export const ContainerSubLabelPrice = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SubLabelPrice = styled.h6`
  color: ${Colors.black50};
  font-family: Open Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
  margin-left: 6px;
`;

export const ContainerSwitch = styled.div`
  display: flex;

  .ant-switch-checked {
    background-color: ${Colors.primary100};
  }

  .column {
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
      color: ${Colors.black70};
      font-family: Open Sans;
      font-size: 0.75rem;
      font-style: italic;
      font-weight: 400;
      line-height: 160%;
      margin-left: 0.93rem;
      margin-top: 0.65rem;

      @media (max-width: 670px) {
        color: ${Colors.black70};
        font-family: Open Sans;
        font-size: 10px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        margin-top: 6px;
      }
    }
  }
`;

export const TitleSwitch = styled.h2`
  color: ${Colors.secondary100};
  font-family: Poppins;
  font-size: 1.03rem;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 0px;
  margin-left: 0.93rem;
`;

export const ContainerCheck = styled.div`
  margin-top: 1.68rem;

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: red;
    border-color: transparent;
    font-weight: normal;
    font-size: 0.56rem;
    color: ${Colors.primary100};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${Colors.primary100} !important;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${Colors.primary100};
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${Colors.primary100};
  }

  .ant-radio-wrapper {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 0.93rem;
    color: ${Colors.secondary70};

    @media (max-width: 720px) {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;

      color: ${Colors.secondary70};
    }
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;

  @media (max-width: 800px) {
    margin-top: 12px;
  }
`;

interface ContainerInputProps {
  error?: boolean;
  isEditing?: boolean;
  isFull?: boolean;
}

export const ContainerInputFull = styled.div<ContainerInputProps>`
  width: ${(props) => (props.isFull ? "100%" : "49%")};
  display: flex;
  flex-direction: column;

  margin-bottom: 25px;

  input {
    width: 100%;
    outline: 0;
    border: 0;
    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary70};
    font-size: ${true ? "14px" : "20px"};

    padding: 0px 0px 4px 23px;

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-left: 13px;
    margin-top: 10px;
    color: ${Colors.secondary40};
    font-weight: normal;
    font-size: ${true ? "14px" : "20px"};
    line-height: 160%;
  }
`;

export const ContainerLink = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    color: ${Colors.secondary40};
    font-family: Poppins;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 0px;

    @media (max-width: 670px) {
      font-size: 11px;
    }
  }
`;

export const RowShared = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 4px;
  gap: 0.93rem;

  .input {
    border: none;
    display: flex;
    align-items: center;
    outline: 0;
    width: 100%;
    height: 2.81rem;
    border-radius: 0.37rem;
    border: 2px solid ${Colors.secondary20};
    background: ${Colors.white};
    padding-left: 0.93rem;

    color: ${Colors.black30};

    font-family: Poppins;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 400;

    @media (max-width: 670px) {
      border: transparent;
    }
  }

  @media (max-width: 670px) {
    border-radius: 3px;
    padding-right: 12px;
    border-radius: 5px;
    border: 1.5px solid ${Colors.border20};
    background: ${Colors.white};
  }
`;

export const ButtonShare = styled.div`
  cursor: pointer;
  border: 0;
  outline: 0;
  display: flex;
  width: 4.68rem;
  height: 2.81rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.65rem;
  background: ${Colors.primary100};
  box-shadow: 0px 15px 30px 0px rgba(212, 0, 80, 0.15);

  img {
    width: 1.12rem;
    height: 1.12rem;

    @media (max-width: 670px) {
      width: 14px;
      height: 14px;
    }
  }

  @media (max-width: 670px) {
    border-radius: 3px;
    width: 48px;
    height: 28px;
  }
`;

export const LabelSelect = styled.h4`
  color: ${Colors.secondary40};
  font-size: 0.93rem;
  font-family: Poppins;
`;

export const ContainerSelect = styled.div`
  margin-top: 1.68rem;
  margin-bottom: 1.87rem;
  width: 100%;

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const SelectStyled = styled(Select)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  .ant-select-selection-item {
    font-family: "Poppins";
    font-size: 0.93rem;
    color: ${Colors.secondary40};
  }
`;

export const ContainerRadio = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 670px) {
    flex-direction: column;
    gap: 16px;
  }
`;

export const SubLabelTooltip = styled.p`
  color: ${Colors.black70};
  font-family: Open Sans;
  font-size: 0.75rem;
  font-style: italic;
  font-weight: 400;
  line-height: 160%;
  margin-top: 0.65rem;

  @media (max-width: 670px) {
    color: ${Colors.black70};
    font-family: Open Sans;
    font-size: 10px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
    margin-top: 6px;
  }
`;
