import axios from "axios";
import { useSecureCookieStorage } from "hooks/useSecureCookieStorage";

export function SetupAuthClient() {
  const secureCookieStorage = useSecureCookieStorage();

  const token = secureCookieStorage.getItem("@ventX-user_token");

  const auth = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/v1`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  });

  auth.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return auth;
}

export function SetupAuthPayment() {
  const secureCookieStorage = useSecureCookieStorage();

  const token = secureCookieStorage.getItem("@ventX-user_token");

  const auth = axios.create({
    baseURL: `${process.env.REACT_APP_PAYMENT_API_URL}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  });

  auth.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return auth;
}

