import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerItems = styled.div`
  margin-top: 7.96rem;
  max-width: 1280px;
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;

  @media (max-width: 1024px) {
    padding-left: 28px;
    padding-right: 28px;
    margin-top: 88px;
    margin-bottom: 2rem;
  }

  display: flex;
  flex-direction: column;

  .breadcrumb {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const TitleCard = styled.h1`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 1.87rem;
`;

export const CardProfile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;

  div.full-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  div.full-button-save {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  border: 1px solid ${Colors.black10};
  padding: 19px 41px 25px 42px;

  box-shadow: 0px 4px 10px rgba(18, 25, 34, 0.05);
  border-radius: 20px;

  @media (max-width: 800px) {
    margin-left: 0px;
    box-shadow: none;
    padding: 0;
    border: 0;
    height: 100%;
  }

  div.full-button {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const ContainerButtonsEdit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ContainerButtonsSave = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 640px) {
    flex-direction: column;

    gap: 30px;
  }
`;

export const ButtonEdit = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  display: flex;
  width: 12.28rem;
  height: 2.81rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 0.65rem;
  background: ${Colors.primary10};

  @media (max-width: 1140px) {
    width: 100%;
    height: 38px;
    border-radius: 8px;
    margin-bottom: 20px;

    svg {
      display: none;
    }
  }

  span {
    color: ${Colors.primary100};
    font-family: Cairo;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 700;
    margin-left: 10px;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 22px;
    }
  }
`;

export const ButtonCancel = styled.button`
  cursor: pointer;
  border: 0;
  width: 12.28rem;
  height: 2.81rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${Colors.primary10};
  border-radius: 14px;

  span {
    font-weight: bold;
    font-size: 0.93rem;
    text-align: center;
    color: ${Colors.primary100};

    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 22px;
    }
  }

  @media (max-width: 1140px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    height: 38px;
    border-radius: 8px;

    svg {
      display: none;
    }
  }
`;

export const ButtonSave = styled.button`
  cursor: pointer;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12.28rem;
  height: 2.81rem;
  background: ${Colors.primary100};
  border-radius: 0.65rem;

  span {
    font-weight: bold;
    font-size: 0.93rem;
    color: ${Colors.white};
    margin-left: 10px;
    margin-bottom: 0px;

    @media (max-width: 800px) {
      font-size: 12px;
      line-height: 22px;
    }
  }

  @media (max-width: 1140px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    height: 38px;
    border-radius: 8px;
    margin-bottom: 20px;

    svg {
      display: none;
    }
  }
`;
