import * as yup from "yup";

export const editOrganizerIdentificationFormSchema = yup.object().shape({
  type_document: yup.number().required("Tipo do documento obrigatório"),
  name: yup
    .string()
    .notRequired()
    .nullable()
    .when("type_document", {
      is: (type_document: any) => type_document === 1,
      then: yup.string().required("Nome obrigatório"),
    }),
  nickname: yup.string().required(),
  document: yup
    .string()
    .notRequired()
    .nullable()
    .when("type_document", {
      is: (type_document: any) => type_document === 1,
      then: yup.string().required("Documento obrigatório"),
    }),
  description: yup.string().notRequired(),

  agent_name: yup
    .string()
    .notRequired()
    .nullable()
    .when("type_document", {
      is: (type_document: any) => type_document === 2,
      then: yup.string().required("Nome obrigatório"),
    }),
  agent_document: yup
    .string()
    .notRequired()
    .nullable()
    .when("type_document", {
      is: (type_document: any) => type_document === 2,
      then: yup.string().required("Documento obrigatório"),
    }),
  agent_birthdate: yup.string().required("Data obrigatória"),
  agent_email: yup.string().required("Email obrigatória"),
  agent_phone: yup.string().required("Telefone obrigatória"),

  address_zipcode: yup.string().required("Cep obrigatório"),
  address_country: yup.string().notRequired(),
  address_state: yup.string().notRequired(),
  address_district: yup.string().notRequired(),
  address_city: yup.string().notRequired(),
  address_street: yup.string().notRequired(),
  address_number: yup.string().notRequired(),
  business_email: yup.string().notRequired(),
  business_phone: yup.string().notRequired(),
});

export const createOrganizerBankDataFormSchema = yup.object().shape({
  bank: yup.string().required("Banco obrigatório"),
  agency: yup.string().required("Agência obrigatória"),
  account: yup.string().required("Conta obrigatória"),
  digit: yup.string().required("Digito obrigatório"),
  type_account: yup.string().required("Tipo da Conta obrigatória"),
});
