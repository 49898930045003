import { Colors } from "constants/colors";
import styled from "styled-components";

type StatusProps = {
  status: 'PAID' | 'PENDING' | 'REFUND';
}

export const Container = styled.article`
  border-radius: 16px;
  border: 1px solid #F0F0F0;
  padding: 12px 16px;
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 13px;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export const ActionWrapper = styled.button`
  padding: 5px;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.purple};
  background-color: #F4F3F4;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 25px;
  min-height: 25px;

  cursor: pointer;
`;

export const ActionWrapperDownload = styled.button`
  padding: 5px;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.purple};
  background-color:${Colors.primary10};
  outline: none;
  border: none;

  width: 25px;
  height: 25px;

  cursor: pointer;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 290px) {
    flex-direction: column-reverse;
    row-gap: 13px;
  }
`;

export const Title = styled.strong`
  color: ${({ theme }) => theme.colors.black};
  font-family: 'Cairo';
  font-size: 14px;
  font-style: 26px;
  font-weight: 600;
  line-height: normal;
`;

export const Content = styled.p`
  color: #888996;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  word-wrap: break-word;
  margin-bottom: 0;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  row-gap: 2px;

  max-width: 70%;
  width: 100%;
`

export const EventContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`;

export const Status = styled.span<StatusProps>`
  color: ${(props) => (props.status === "PAID" ? "rgba(147, 199, 123, 1)" : props.status === "PENDING" ? "rgba(246, 177, 72, 1)" : props.status === "REFUND" ? "rgba(112, 114, 129, 1)" : "")};
  font-family: Cairo;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
