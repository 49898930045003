import styled from "styled-components";

const EventThumb = styled.div<{img: string}>`
    background-image: url(${({img}) => img});
    background-position: center;
    background-size: cover;
    width: 87px;
    height: 46px;
`;

export default EventThumb