import { useCallback } from "react";
import { useSearchParams } from "react-router-dom";

import { IoCloseCircleSharp } from "react-icons/io5";

import { useFiltersContext } from "contexts/FiltersContext";

import { Colors } from "constants/colors";
import { KeySort } from "constants/filters";

import {
  BorderSelect,
  Container,
  DivIsSelected,
  SelectStyledWithoutDrop,
} from "./styles";

interface SelectFilterModalProps {
  title: string;
  sortKey: KeySort;
}

export function SelectPriceFilter({ title }: SelectFilterModalProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { setIsOpenFilterModalPrice } = useFiltersContext();

  const price_min = searchParams.get("price_min") ?? "";
  const price_max = searchParams.get("price_max") ?? "";

  const clearId = useCallback(() => {
    searchParams.delete("price_min");
    searchParams.delete("price_max");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
    });
  }, [searchParams, setSearchParams]);

  const handleOpenModal = () => {
    setIsOpenFilterModalPrice(true);
  };

  return (
    <Container>
      {price_min === "" && price_max === "" ? (
        <BorderSelect>
          <SelectStyledWithoutDrop
            bordered={false}
            labelInValue
            size="large"
            defaultValue={{ value: 0, label: title }}
            open={false}
            onClick={handleOpenModal}
          />
        </BorderSelect>
      ) : (
        <DivIsSelected>
          <h3 className="selected">{`R$${price_min} - R$${price_max}`}</h3>

          <IoCloseCircleSharp
            onClick={clearId}
            style={{ cursor: "pointer" }}
            size={18}
            color={Colors.white}
          />
        </DivIsSelected>
      )}
    </Container>
  );
}
