import { useEffect } from "react";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Select, Checkbox, Space } from "antd";

import { MdOutlineMail } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa";
import { BiPlus } from "react-icons/bi";

import { Event } from "types";

import { Colors } from "constants/colors";

import { useDisclosure } from "hooks/useDisclosure";
import { ModalSuccess } from "components/ModalSuccess";
import { EditEventService } from "services/Hook/Event";

import {
  HorizontalLine,
  ButtonSecondary,
  ButtonPrimary,
  ContainerButtons,
  FormContent,
  TitleForm,
  SubTitleForm,
} from "../../styles";

import {
  BorderSelect,
  ButtonAddImage,
  ContainerCheck,
  ContainerCheckConditions,
  ContainerSelect,
  Divider,
  GridInputs,
  LabelSelect,
  LabelTitle,
  SubLabelTitle,
  TextTerms,
} from "pages/MyEvent/New/Steps/AboutOrganizer/styles";

import { StyledSelect } from "pages/MyEvent/New/Steps/Local/Form/styles";

import { InputForm } from "components/Form/InputForm";
import { Link } from "react-router-dom";
import { useAuthOrganizer } from "services/Hook/Organizer";

const { Option } = Select;

interface AboutOrganizerProps {
  activeStep: number;
  data: Event;
}

type CreateAboutOrganizerEventFormData = {
  checked_email: boolean;
  checked_whatsapp: boolean;
  support_email: string;
  support_whatsapp: string;
  organizer: string;
  accepted_terms: boolean;
};

const createAboutOrganizerEventFormSchema = yup.object().shape({
  checked_email: yup.boolean().notRequired(),
  checked_whatsapp: yup.boolean().notRequired(),
  organizer: yup.string().required("Organizador obrigatório"),
  support_email: yup
    .string()
    .notRequired()
    .nullable()
    .when("checked_email", {
      is: true,
      then: yup.string().required("Email obrigatório"),
    }),
  support_whatsapp: yup
    .string()
    .notRequired()
    .nullable()
    .when("checked_whatsapp", {
      is: true,
      then: yup.string().required("Whatsapp obrigatório"),
    }),
  accepted_terms: yup.boolean().required(),
});

export function AboutOrganizer({ activeStep, data }: AboutOrganizerProps) {
  const { data: dataOrganizer } = useAuthOrganizer();

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CreateAboutOrganizerEventFormData>({
    resolver: yupResolver(createAboutOrganizerEventFormSchema),
  });

  const { showModal, handleOk, handleCancel, isModalVisible } = useDisclosure();

  useEffect(() => {
    reset({
      checked_email: !!data?.support_email,
      checked_whatsapp: !!data?.support_whatsapp,
      support_email: data?.support_email ?? "",
      support_whatsapp: data?.support_whatsapp ?? "",
      organizer: data?.organizer?._id ?? "",
    });
  }, [data, reset]);

  const handleEditAboutOrganizer: SubmitHandler<
    CreateAboutOrganizerEventFormData
  > = async ({ organizer, support_email, support_whatsapp }) => {
    const dataEvent = {
      ...data,
      organizerId: organizer,
      support_email,
      support_whatsapp,
    };

    await EditEventService({
      id: data?._id,
      dataEvent,
    });

    showModal();
  };

  const checked_email = watch("checked_email");
  const checked_whatsapp = watch("checked_whatsapp");
  const watchAcceptedTerms = watch("accepted_terms");

  return (
    <FormContent onSubmit={handleSubmit(handleEditAboutOrganizer)}>
      <TitleForm>{activeStep}. Sobre o organizador</TitleForm>

      <SubTitleForm>
        Selecione o organizador responsável por este evento, ou adicione um
        novo.
      </SubTitleForm>

      <HorizontalLine />

      <LabelTitle>Quem o é organizador do seu evento?</LabelTitle>

      <ContainerSelect>
        <LabelSelect>Organizadores cadastrados</LabelSelect>

        <Controller
          control={control}
          name="organizer"
          rules={{ required: "Salutation is required" }}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <BorderSelect>
              <StyledSelect
                size="large"
                labelInValue
                bordered={false}
                placeholder="Selecione uma opção"
                value={value}
                ref={ref}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
                onChange={(e: any) => {
                  onChange(e.value);
                }}
              >
                {dataOrganizer?.map((organizer: any) => (
                  <Option key={organizer._id} value={organizer._id}>
                    {organizer.name}
                  </Option>
                ))}
              </StyledSelect>
            </BorderSelect>
          )}
        />

        {!!errors.organizer ? (
          <span className="error">{errors.organizer.message}</span>
        ) : null}
      </ContainerSelect>

      <SubLabelTitle>
        Não tem nenhum organizador cadastrado? Crie um agora!
      </SubLabelTitle>

      <Link to="/organizer/new">
        <ButtonAddImage type="button" onClick={() => {}}>
          <BiPlus size={18} color={Colors.primary100} />

          <span>Novo organizador</span>
        </ButtonAddImage>
      </Link>

      <Divider />

      <LabelTitle>Quais serão seus canais de suporte ao usuário?</LabelTitle>

      <SubLabelTitle>Escolha até duas opções.</SubLabelTitle>

      <ContainerCheck>
        <Space direction="horizontal">
          <Controller
            control={control}
            name="checked_whatsapp"
            render={({ field: { onChange, value, name, ref } }) => (
              <Checkbox
                name={name}
                onChange={(val) => {
                  onChange(val.target.checked);
                }}
                checked={!!value}
                ref={ref}
              >
                Whatsapp
              </Checkbox>
            )}
          />

          <Controller
            control={control}
            name="checked_email"
            render={({ field: { onChange, value, name, ref } }) => (
              <Checkbox
                name={name}
                onChange={(val) => {
                  onChange(val.target.checked);
                }}
                checked={!!value}
                ref={ref}
              >
                E-mail
              </Checkbox>
            )}
          />
        </Space>
      </ContainerCheck>

      <GridInputs>
        {checked_whatsapp ? (
          <InputForm
            {...register("support_whatsapp")}
            mask="phone"
            error={errors.support_whatsapp}
            isFull
            icon={<FaWhatsapp size={17} color={Colors.secondary40} />}
            label="Whatsapp"
          />
        ) : null}

        {checked_email ? (
          <InputForm
            {...register("support_email")}
            error={errors.support_email}
            isFull
            icon={<MdOutlineMail size={17} color={Colors.secondary40} />}
            label="E-mail"
          />
        ) : null}
      </GridInputs>

      <Divider />

      <LabelTitle>Termos e condições</LabelTitle>

      <ContainerCheckConditions>
        <Controller
          control={control}
          name="accepted_terms"
          defaultValue={false}
          render={({ field: { onChange, value, name, ref } }) => (
            <Checkbox
              name={name}
              onChange={(val) => {
                onChange(val.target.checked);
              }}
              checked={!!value}
              ref={ref}
            />
          )}
        />

        <TextTerms>
          Ao publicar este evento, estou de acordo com os{" "}
          <a href="a">Termos de Uso</a>, com as{" "}
          <a href="a">Diretrizes de Comunidade</a> e com o{" "}
          <a href="a">Acordo de Processamento de Dados</a>, bem como declaro
          estar ciente da <a href="a">Política de Privacidade</a>, da{" "}
          <a href="a">Política de Cookies</a> e das{" "}
          <a href="a">Obrigatoriedades Legais.</a>
        </TextTerms>
      </ContainerCheckConditions>

      <ContainerButtons>
        <ButtonSecondary type="button" onClick={() => {}}>
          <span>Cancelar</span>
        </ButtonSecondary>

        <ButtonPrimary
          htmlType="submit"
          disabled={!watchAcceptedTerms}
          loading={isSubmitting}
        >
          <span>Salvar</span>
        </ButtonPrimary>
      </ContainerButtons>

      <ModalSuccess
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        title="Edição de evento finalizada!"
        subtitle="Clique em OK para prosseguir."
      />
    </FormContent>
  );
}
