import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { Colors } from "constants/colors";

import { Center } from "./styles";

export function Loading() {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  );

  return (
    <Center>
      <Spin spinning indicator={antIcon} />
    </Center>
  );
}
