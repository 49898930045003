export const formatCpf = (value: string) => {
  let valueToFormat = value;

  if (!valueToFormat.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/)) {
    valueToFormat = valueToFormat.replace(/\D/g, "");
    valueToFormat = valueToFormat.replace(/(\d{3})(\d)/, "$1.$2");
    valueToFormat = valueToFormat.replace(/(\d{3})(\d)/, "$1.$2");
    valueToFormat = valueToFormat.replace(/(\d{3})(\d{2})$/, "$1-$2");

    return valueToFormat;
  }

  return valueToFormat;
};
