import { useState } from "react";

import { RiArrowLeftLine, RiCloseCircleLine } from "react-icons/ri";
import { FiChevronRight } from "react-icons/fi";

import { Colors } from "constants/colors";

import {
  ButtonLeft,
  Card,
  ContainerHeader,
  ContainerModal,
  GridItems,
  Item,
  ModalStyled,
  SubTitle,
  TextTotal,
  Title,
  TitleTotal,
} from "./styles";

import { useGeneralContext } from "contexts/GeneralContext";

import { formatPrice } from "utils/formatPrice";

interface ModalGeneralChargebackProps {
  total: number;
  fee: number;
}

export function ModalGeneralChargeback({
  total,
  fee,
}: ModalGeneralChargebackProps) {
  const [selectedPurchase, setSelectedPurchase] = useState<
    "resume" | "detail" | null
  >(null);

  const { isOpenModalGeneralChargeback, setIsOpenModalGeneralChargeback } =
    useGeneralContext();

  const closeSelectedPurchase = () => {
    setSelectedPurchase(null);
  };

  const closeModal = () => {
    setIsOpenModalGeneralChargeback(false);
  };

  const title: { [key: string]: any } = {
    resume: "Resumo das vendas",
    detail: "Detalhes da venda",
    default: "Estornos",
  };

  return (
    <ModalStyled
      width={1200}
      visible={isOpenModalGeneralChargeback}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <ContainerHeader>
          {selectedPurchase ? (
            <ButtonLeft onClick={closeSelectedPurchase}>
              <RiArrowLeftLine color={Colors.primary100} size={22} />
            </ButtonLeft>
          ) : null}

          <h1>{title[selectedPurchase ?? "default"]}</h1>
        </ContainerHeader>

        {selectedPurchase === null ? (
          <Card>
            <Title>Vendas canceladas</Title>

            <GridItems>
              <Item>
                <div className="column-content">
                  <TitleTotal>SALDO ESTORNADO</TitleTotal>

                  <TextTotal>{formatPrice(total)}</TextTotal>

                  <SubTitle>
                    Pedidos cancelados ou com solicitação de cancelamento
                  </SubTitle>
                </div>

                <FiChevronRight color={Colors.gray45} size={24} />
              </Item>
            </GridItems>
          </Card>
        ) : null}
      </ContainerModal>
    </ModalStyled>
  );
}
