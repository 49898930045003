import { Colors } from "constants/colors";
import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.93rem;
  border: 1px solid ${Colors.black10};
  background: ${Colors.white};
  margin-top: 1.4rem;
  padding: 50px 136px 58px 136px;

  @media (max-width: 800px) {
    padding: 0;
    border: 0;
  }
`;

export const TitleNew = styled.h1`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.87rem;
  font-style: normal;
  font-weight: 700;
  margin-top: 2.53rem;
  margin-bottom: 0px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ContainerSwitch = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.87rem;

  .ant-switch-checked {
    background-color: ${Colors.primary100};
  }
`;

export const TitleSwitch = styled.h2`
  color: ${Colors.secondary100};
  font-family: Poppins;
  font-size: 1.03rem;
  font-style: normal;
  font-weight: 500;
  margin-left: 0.93rem;
  margin-bottom: 0px;
`;
