import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  @media (max-width: 720px) {
    row-gap: 0;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  column-gap: 10px;

  > span {
    color: ${Colors.black100};
    font-family: "Cairo";
    font-weight: bold;
    font-size: 1.625rem;
    line-height: 2.6rem;
  }

  @media (max-width: 720px) {
    > span {
      font-size: 1rem;
      line-height: 2rem;
    }

    > svg {
      height: 24px;
    }
  }
`;

export const GridItems = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 1.4rem;
  grid-gap: 10px;
`;

export const Item = styled.div`
  width: 100%;
  display: flex;
  padding: 0.93rem 0.56rem 0.93rem 0.93rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.65rem;
  border: 1px solid #e7e7ea;
  background: ${Colors.white};

  div.column-content {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;
      column-gap: 6px;
    }
  }

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TitleTotal = styled.h4`
  color: ${Colors.black30};
  font-family: Open Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 700;
`;

export const TextTotal = styled.h5`
  color: ${Colors.secondary100};
  font-family: Cairo;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
`;

export const SubTitle = styled.h6`
  color: ${Colors.black50};
  font-family: Open Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  margin-top: 0.5rem;
`;

export const WithdrawButton = styled.button`
  cursor: pointer;
  background: ${Colors.primary10};
  height: 60px;
  width: 260px;
  outline: none;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  border-radius: 14px;

  > span {
    color: ${Colors.primary100};
    font-weight: bold;
    font-size: 1.1rem;
    line-height: 2rem;
  }

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 720px) {
    height: 45px;
    width: 100%;

    margin-top: 8px;

    > span {
      color: ${Colors.primary100};
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.4rem;
    }
  }
`;
