import { Colors } from "constants/colors";
import styled from "styled-components";
import { sizeConversor } from "utils/conversors";

export const Container = styled.div`
  @media (max-width: 768px) {
    .subtitle {
      font-size: ${sizeConversor(20)};
    }
  }
`;

export const FilterContainer = styled.div`
  hr {
    margin: ${sizeConversor(24)} 0;
  }
`;

export const ChartContainer = styled.div`
  display: flex;
  align-items: center;

  height: ${sizeConversor(281)};

  border-radius: ${sizeConversor(14)};
  border: ${sizeConversor(1)} solid #f0f0f0;
  background: var(--white, #fff);
  box-shadow: 0px 10px 20px 0px rgba(108, 93, 211, 0.06);

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const GridOverviewItems = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.51rem;
  margin-top: 1.1rem;

  .overview-item {
    width: 100%;
  }

  @media (max-width: 1230px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    margin-top: 0.56rem;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 0;

    .overview-item:nth-child(odd) {
      border-radius: 5px 0px 0px 5px;
      border: none;
      border-right: 1px solid #f0f0f0;
    }

    .overview-item:nth-child(even) {
      border-radius: 0px 5px 5px 0px;
      border: none;
    }
  }
`;

export const EventsContainer = styled.div`
  margin-top: 90px;

  .slider-container {
    width: 100%;

    display: flex;
    flex-direction: row;

    .slick-slider,
    .slick-track,
    .slick-list {
      width: 100%;
      min-width: 100%;
    }
  }
`;

export const OverviewContainer = styled.div`
  margin-top: 60px;
`;

export const DashboardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 11px;
  margin-top: 60px;
  row-gap: 2.81rem;

  @media (max-width: 1400px) {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 1.5rem;
  }
`;

export const CardArrowLeft = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 24px;
  height: 32px;
  width: 32px;
  border: 0;
  border-radius: 50%;
  background-color: ${Colors.secondary10};

  @media (max-width: 800px) {
    display: none;
  }
`;

export const CardArrowRight = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  height: 32px;
  width: 32px;
  border-radius: 50%;

  background-color: ${Colors.secondary10};
  border: 0;

  @media (max-width: 800px) {
    height: 24px;
    width: 24px;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

interface DotProps {
  active: boolean;
}

export const Dot = styled.div<DotProps>`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? Colors.secondary100 : Colors.secondary20};
  margin-right: 16px;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const SupportContainer = styled.div`
  margin-top: 24px;

  > h3 {
    margin-top: 24px;

    color: #b8b9c0;
    font-family: "Open Sans";
    font-size: ${sizeConversor(26)};
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }

  @media (max-width: 768px) {
    > h3 {
      font-size: ${sizeConversor(18)};
    }
  }
`;

export const OptionRectangle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: ${sizeConversor(120)};
  flex-shrink: 0;
  margin-top: ${sizeConversor(30)};
  padding: 0 ${sizeConversor(30)};
  color: rgba(167, 159, 188, 1);
  font-size: ${sizeConversor(26)};
  cursor: pointer;

  border-radius: 20px;
  border: ${sizeConversor(1)} solid var(--preto-preto-10, #e7e7ea);
  background: var(--sistema-branco, #fff);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);

  .option {
    display: flex;
    align-items: center;
    gap: ${sizeConversor(20)};
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: ${sizeConversor(60)};
    height: ${sizeConversor(60)};
    border-radius: ${sizeConversor(20)};
    background: var(--secundaria-secundaria-10, #e9e7ee);
  }

  p {
    margin: 0;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
  }

  .arrow {
    display: flex;
    font-size: ${sizeConversor(40)};
  }

  :hover {
    color: var(--primaria-primaria-100, #d40050);

    .icon {
      background: var(--primaria-primaria-10, #fbe5ed);
    }
  }

  @media (max-width: 768px) {
    height: auto;
    border-radius: 8px;
    padding: ${sizeConversor(10)} ${sizeConversor(20)};

    .option > p {
      font-size: ${sizeConversor(18)};
    }

    .icon {
      width: ${sizeConversor(18)};
      height: ${sizeConversor(18)};
      background-color: transparent;
    }
  }
`;
