import { authClient } from "services/authClient";
import { authPayment } from "services/authPayment";
import useSWR from "swr";

export function useBank<Data = any, Error = any>() {
  const url: string = "/organizer-bank";

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const { data } = response;

    const results = data?.data?.results;

    return results;
  });

  return { data, error, mutate };
}

export async function DeleteBank(id: string) {
  const url: string = `/organizer-bank/${id}`;

  const response = await authClient.delete(url);

  return response.data;
}

interface EditBankProps {
  id: string | undefined;
  credentials: any;
}

export async function EditBank({ id, credentials }: EditBankProps) {
  const url: string = `/organizer-bank/${id}`;

  const response = await authClient.patch(url, credentials);

  return response.data;
}

interface AddBankProps {
  credentials: any;
}

export async function AddBank({ credentials }: AddBankProps) {
  const url: string = "/organizer/bank";

  const response = await authPayment.post(url, credentials);

  return response.data;
}
