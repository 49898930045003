import { OrganizerBalanceResponse } from "services/Hook/Financial";

export interface ModalGeneralBalanceAvailableProps {
  balance?: OrganizerBalanceResponse;
  onWithdraw: () => void;
}

export enum ESteps {
  BALANCE_LIST = 0,
  WITHDRAW = 1,
  CONFIRM_PASSWORD = 2,
  TRANSACTION_DETAILS = 3,
}
