import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";

import { FRONTLINE_URL } from "constants/environmentVariables";
import { useSecureCookieStorage } from "hooks/useSecureCookieStorage";
import { GetOrganizers, GetUserById } from "services/Hook/User";
import { errorNotification } from "components/Notification";

type Error = {
  response: {
    status: number;
    data: {
      message: string;
    };
  };
};

export type Organizer = {
  id: number;
  _id: string;
  user_id: number;
  name: string;
  type_document: number;
  document: string;
  fee: string;
};

export type User = {
  id: string;
  email: string;
  name: string;
  last_name: string;
  avatar: string;
  cell_phone: string;
  birthdate: string;
  document: string;
  gender: string;
  type_document: string;
  organizer: Organizer[] | null;
};

type AuthContextData = {
  signOut(): void;
  hydratationUser(): void;
  user: User | undefined;
  isAuthenticated: boolean;
  hasOrganizer: boolean;
  organizerId: string | undefined;
  organizers: Array<Organizer>;
};

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | undefined>();
  const [organizers, setOrganizers] = useState<Array<Organizer>>([]);

  const secureCookieStorage = useSecureCookieStorage();

  const signOut = useCallback(() => {
    secureCookieStorage.removeItem("@ventX-user_token");
    secureCookieStorage.removeItem("userId");
    secureCookieStorage.removeItem("hasOrganizer");

    setUser(undefined);

    window.location.href = `${FRONTLINE_URL}/signin?page=signin&to=account`;
  }, [secureCookieStorage]);

  useEffect(() => {
    async function getUser() {
      const token = secureCookieStorage.getItem("@ventX-user_token");
      const organizers = secureCookieStorage.getItem("@ventX-organizers");

      setOrganizers(organizers || []);

      if (token) {
        try {
          const { data } = await GetUserById();

          const { data: dataOrganizers } = await GetOrganizers();

          if (data) {
            let organizer = null;

            if (dataOrganizers.results && dataOrganizers.results.length > 0) {
              organizer = dataOrganizers.results;

              secureCookieStorage.setItem("hasOrganizer", organizer[0]._id);
              secureCookieStorage.setItem("@ventX-organizers", organizer);

              setOrganizers(organizer);
            }

            setUser({
              id: data._id,
              name: data.name,
              last_name: data.last_name,
              avatar: data.avatar,
              cell_phone: data.cell_phone,
              email: data.email,
              birthdate: data.birthdate,
              document: data.document,
              gender: data.gender,
              type_document: data.type_document,
              organizer,
            });
          }
        } catch (err) {
          const error = err as Error;

          const { status, data } = error.response;

          if (status === 401) {
            signOut();

            return;
          }

          errorNotification("Erro", data?.message ?? "");
        }
      }
    }

    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function hydratationUser() {
    const token = secureCookieStorage.getItem("@ventX-user_token");

    if (token) {
      try {
        const { data } = await GetUserById();

        const { data: dataOrganizers } = await GetOrganizers();

        let organizer = null;

        if (dataOrganizers.results && dataOrganizers.results.length > 0) {
          organizer = dataOrganizers.results;

          secureCookieStorage.setItem("hasOrganizer", organizer[0]._id);
        }

        if (data) {
          setUser({
            id: data._id,
            name: data.name,
            last_name: data.last_name,
            avatar: data.avatar,
            cell_phone: data.cell_phone,
            email: data.email,
            birthdate: data.birthdate,
            document: data.document,
            gender: data.gender,
            type_document: data.type_document,
            organizer,
          });
        }
      } catch (err) {
        const error = err as Error;

        const { status, data } = error.response;

        if (status === 401) {
          signOut();

          return;
        }

        errorNotification("Erro", data?.message ?? "");
      }
    }
  }

  const userHasOrganizer = secureCookieStorage.getItem("hasOrganizer");

  return (
    <AuthContext.Provider
      value={{
        signOut,
        user,
        isAuthenticated: !!user,
        hydratationUser,
        hasOrganizer: !!userHasOrganizer,
        organizerId: userHasOrganizer,
        organizers,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
}
