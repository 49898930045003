import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { DatePicker } from "antd";

import {
  RiArrowLeftLine,
  RiCalendar2Line,
  RiCloseCircleLine,
} from "react-icons/ri";

import { AiOutlineUser } from "react-icons/ai";

import moment from "moment";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { useFiltersContext } from "contexts/FiltersContext";

import { Colors } from "constants/colors";

import {
  ButtonClear,
  ButtonLeft,
  ButtonOk,
  ContainerButtonsModal,
  ContainerCustomData,
  ContainerInputFull,
  ContainerModal,
  ContainerSelect,
  FormLabel,
  GridInputs,
  LabelCustom,
  ModalStyled,
  NormalCardBorder,
} from "./styles";
import { useGetDateAgo } from "hooks/useGetDateAgo";

type CreateFilterDataFormData = {
  data_gte: string;
  data_lte: string;
};

const createDataEventFormSchema = yup.object().shape({
  data_gte: yup.string().nullable().required("Data inicial obrigatória"),
  data_lte: yup.string().nullable().required("Data final obrigatória"),
});

const dateFormat = "DD/MM/YYYY";

interface DataType {
  id: number;
  name: string;
  date_start: string;
  date_end: string;
}

export function ModalFilterByData() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { daysFilter } = useGetDateAgo();

  const [isCustom, setIsCustom] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<CreateFilterDataFormData>({
    resolver: yupResolver(createDataEventFormSchema),
  });

  const { isOpenFilterModalData, setIsOpenFilterModalData } =
    useFiltersContext();

  const handleAddFilterData: SubmitHandler<CreateFilterDataFormData> = async ({
    data_gte,
    data_lte,
  }) => {
    searchParams.delete("data_type");

    const dateGte = moment(data_gte, "DD/MM/YYYY");
    const formatDateGte = dateGte.format("YYYY-MM-DD");

    const dateLte = moment(data_lte, "DD/MM/YYYY");
    const formatDateLte = dateLte.format("YYYY-MM-DD");

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      data_gte: formatDateGte,
      data_lte: formatDateLte,
    });

    setIsOpenFilterModalData(false);
  };

  const handleSelectedCustom = (data: DataType) => {
    if (data.name === "Data personalizada") {
      setIsCustom(true);

      return;
    }

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      data_type: String(data.id),
    });

    setIsOpenFilterModalData(false);
  };

  const closeModal = () => {
    setIsOpenFilterModalData(false);
  };

  const closeSelectedCustom = () => {
    setIsCustom(false);
  };

  return (
    <ModalStyled
      width={857}
      visible={isOpenFilterModalData}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <h1>Filtrar por data</h1>

        <h2>Selecione uma data para a visualização dos resultados.</h2>

        {!isCustom ? (
          <ContainerSelect>
            {daysFilter?.map((data) => (
              <NormalCardBorder onClick={() => handleSelectedCustom(data)}>
                <div className="circle-icon">
                  <AiOutlineUser size={13} color={Colors.secondary100} />
                </div>

                <h3 className="normal">{data.name}</h3>
              </NormalCardBorder>
            ))}
          </ContainerSelect>
        ) : null}

        {isCustom ? (
          <ContainerCustomData onSubmit={handleSubmit(handleAddFilterData)}>
            <ButtonLeft onClick={closeSelectedCustom}>
              <RiArrowLeftLine color={Colors.primary100} size={22} />
            </ButtonLeft>

            <LabelCustom>Data personalizada</LabelCustom>

            <GridInputs>
              <ContainerInputFull isFull error={!!errors.data_gte}>
                <FormLabel>
                  <RiCalendar2Line size={22} color={Colors.secondary40} />
                  <h2>Data inicial</h2>
                </FormLabel>

                <Controller
                  control={control}
                  name="data_gte"
                  rules={{ required: "Salutation is required" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <DatePicker
                      format={dateFormat}
                      placeholder="Selecione uma data inicial"
                      suffixIcon={false}
                      style={{
                        height: "auto",
                        width: "auto",
                        border: "none",
                        borderRadius: "0px",
                        cursor: "pointer",
                        fontSize: "17px",
                        margin: "0px",
                        padding: "0px",
                      }}
                      ref={ref}
                      value={value ? moment(value, dateFormat) : null}
                      onChange={(date: unknown, dateString: string) => {
                        if (!dateString) {
                          onChange(null);
                          return;
                        }

                        onChange(dateString);
                      }}
                    />
                  )}
                />

                {!!errors.data_gte ? (
                  <span className="error">{errors.data_gte.message}</span>
                ) : null}
              </ContainerInputFull>

              <ContainerInputFull isFull error={!!errors.data_lte}>
                <FormLabel>
                  <RiCalendar2Line size={22} color={Colors.secondary40} />
                  <h2>Data de término</h2>
                </FormLabel>

                <Controller
                  control={control}
                  name="data_lte"
                  rules={{ required: "Salutation is required" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <DatePicker
                      format={dateFormat}
                      placeholder="Selecione uma data final"
                      suffixIcon={false}
                      style={{
                        height: "auto",
                        width: "auto",
                        border: "none",
                        borderRadius: "0px",
                        cursor: "pointer",
                        fontSize: "17px",
                        margin: "0px",
                        padding: "0px",
                      }}
                      ref={ref}
                      value={value ? moment(value, dateFormat) : null}
                      onChange={(date: unknown, dateString: string) => {
                        if (!dateString) {
                          onChange(null);
                          return;
                        }

                        onChange(dateString);
                      }}
                    />
                  )}
                />

                {!!errors.data_lte ? (
                  <span className="error">{errors.data_lte.message}</span>
                ) : null}
              </ContainerInputFull>
            </GridInputs>

            <ContainerButtonsModal>
              <ButtonClear type="button" onClick={() => {}}>
                <span>Limpar</span>
              </ButtonClear>

              <ButtonOk type="submit">
                <span>Aplicar filtros</span>
              </ButtonOk>
            </ContainerButtonsModal>
          </ContainerCustomData>
        ) : null}
      </ContainerModal>
    </ModalStyled>
  );
}
