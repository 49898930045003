import { useEffect } from "react";

import { Radio, RadioChangeEvent } from "antd";

import {
  useForm,
  FormProvider,
  SubmitHandler,
  Controller,
} from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { EditEventService } from "services/Hook/Event";
import { CreateLocalEventFormData, Event } from "types";
import { createLocalEventFormSchema } from "lib/validations/createEvent";
import { useDisclosure } from "hooks/useDisclosure";

import {
  ButtonPrimary,
  ButtonSecondary,
  ContainerButtons,
  FormContent,
  HorizontalLine,
  SubTitleForm,
  TitleForm,
} from "../../styles";

import {
  ContainerEventLocal,
  RowTypeEvent,
} from "pages/MyEvent/New/Steps/Local/styles";

import { Form } from "./Form";
import { ModalSuccess } from "components/ModalSuccess";

interface PlaceInfoProps {
  activeStep: number;
  data: Event;
}

export function PlaceInfo({ activeStep, data }: PlaceInfoProps) {
  const methods = useForm<CreateLocalEventFormData>({
    resolver: yupResolver(createLocalEventFormSchema),
  });

  const {
    control,
    reset,
    formState: { isSubmitting },
    handleSubmit,
  } = methods;

  const { showModal, handleOk, handleCancel, isModalVisible } = useDisclosure();

  useEffect(() => {
    reset({
      type_event: data?.type_event,
      streaming_id: data?.streaming_id,
      streaming_url: data?.streaming_url,
      streaming_name: data?.streaming_name,
      address_name: data?.address_name,
      address_zipcode: data?.address_zipcode,
      address_street: data?.address_street,
      address_complement: data?.address_complement ?? "",
      address_country: data?.address_country,
      address_district: data?.address_district,
      address_city: data?.address_city,
      address_state: data?.address_state,
      address_number: data?.address_number,
    });
  }, [data, reset]);

  const handleEditLocal: SubmitHandler<CreateLocalEventFormData> = async ({
    type_event,
    streaming_id,
    streaming_name,
    streaming_url,
    address_name,
    address_zipcode,
    address_street,
    address_complement,
    address_country,
    address_district,
    address_city,
    address_state,
    address_number,
  }) => {
    try {
      const dataEvent = {
        ...data,
        type_event,
        address_map: true,
        streaming_id,
        streaming_name: streaming_name ?? null,
        streaming_url: streaming_url ?? null,
        address_complement,
        address_name,
        address_zipcode,
        address_street,
        address_country,
        address_district,
        address_city,
        address_state,
        address_number,
      };

      await EditEventService({
        id: data?._id,
        dataEvent,
      });

      showModal();
    } catch (err) {}
  };

  return (
    <FormProvider {...methods}>
      <FormContent onSubmit={handleSubmit(handleEditLocal)}>
        <TitleForm>{activeStep}. Local do evento</TitleForm>

        <SubTitleForm>
          Defina o local onde seu evento vai acontecer.
        </SubTitleForm>

        <ContainerEventLocal>
          <Controller
            control={control}
            name="type_event"
            defaultValue={1}
            render={({ field: { onChange, value } }) => (
              <Radio.Group
                onChange={(e: RadioChangeEvent) => {
                  onChange(e.target.value);
                }}
                value={value}
              >
                <RowTypeEvent>
                  <Radio className="radio" value={1}>
                    Evento presencial
                  </Radio>
                  <Radio className="radio" value={2}>
                    Evento online
                  </Radio>
                  <Radio className="radio" value={3}>
                    Evento híbrido (presencial + online)
                  </Radio>
                </RowTypeEvent>
              </Radio.Group>
            )}
          />
        </ContainerEventLocal>

        <HorizontalLine />

        <Form />

        <ContainerButtons>
          <ButtonSecondary type="button" onClick={() => {}}>
            <span>Cancelar</span>
          </ButtonSecondary>

          <ButtonPrimary htmlType="submit" loading={isSubmitting}>
            <span>Salvar</span>
          </ButtonPrimary>
        </ContainerButtons>
      </FormContent>

      <ModalSuccess
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        title="Edição de evento finalizada!"
        subtitle="Clique em OK para prosseguir."
      />
    </FormProvider>
  );
}
