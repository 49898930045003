import { useEffect } from "react";

import { RiCalendarCheckFill } from "react-icons/ri";
import { BiTime } from "react-icons/bi";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { DatePicker } from "antd";

import moment from "moment";

import { useCreateEvent } from "contexts/CreateEventContext";

import { Colors } from "constants/colors";

import { CreateDataEventFormData } from "types";

import {
  Card,
  Container,
  ContainerInputFull,
  Divider,
  FormLabel,
  GridInputs,
  LabelTitle,
  SubTitle,
  Title,
} from "./styles";

import { ButtonNext, ButtonPrev, ContainerButtons } from "../../styles";
import { InputForm } from "components/Form/InputForm";

const createDataEventFormSchema = yup.object().shape({
  begin_date: yup.string().required("Data inicial obrigatória"),
  begin_hour: yup.string().required("Hora inicial obrigatória"),
  end_date: yup.string().required("Data final obrigatória"),
  end_hour: yup.string().required("Hora final obrigatória"),
});

const dateFormat = "DD/MM/YYYY";

export function Data() {
  const { event } = useCreateEvent();

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<CreateDataEventFormData>({
    resolver: yupResolver(createDataEventFormSchema),
  });

  useEffect(() => {
    reset({
      begin_date: event.data?.begin_date,
      begin_hour: event.data?.begin_hour,
      end_date: event.data?.end_date,
      end_hour: event.data?.end_hour,
    });
  }, [event.data, reset]);

  const handleAddData: SubmitHandler<CreateDataEventFormData> = async ({
    begin_date,
    begin_hour,
    end_date,
    end_hour,
  }) => {
    event.createData({
      begin_date,
      begin_hour,
      end_date,
      end_hour,
    });

    event.goToNextStep();
  };

  return (
    <Container onSubmit={handleSubmit(handleAddData)}>
      <Card>
        <Title>2. Data do evento</Title>
        <SubTitle>
          Defina a data e o horário de quando seu evento irá acontecer.
        </SubTitle>

        <Divider />

        <LabelTitle>Quando seu evento irá acontecer?</LabelTitle>

        <GridInputs>
          <ContainerInputFull isFull error={!!errors.begin_date}>
            <FormLabel>
              <RiCalendarCheckFill size={17} color={Colors.secondary40} />
              <h2>Data inicial</h2>
            </FormLabel>

            <Controller
              control={control}
              name="begin_date"
              rules={{ required: "Salutation is required" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <DatePicker
                  format={dateFormat}
                  placeholder="Selecione uma data inicial"
                  suffixIcon={false}
                  style={{
                    height: "auto",
                    width: "auto",
                    border: "none",
                    borderRadius: "0px",
                    cursor: "pointer",
                    fontSize: "17px",
                    margin: "0px",
                    padding: "0px",
                  }}
                  ref={ref}
                  value={value ? moment(value, dateFormat) : null}
                  onChange={(date: unknown, dateString: string) => {
                    if (!dateString) {
                      onChange(null);
                      return;
                    }

                    onChange(dateString);
                  }}
                />
              )}
            />

            {!!errors.begin_date ? (
              <span className="error">{errors.begin_date.message}</span>
            ) : null}
          </ContainerInputFull>

          <InputForm
            label="Horário de início"
            isFull
            mask="hour"
            icon={<BiTime size={17} color={Colors.secondary40} />}
            {...register("begin_hour")}
            placeholder="Selecione um horário inicial"
            error={errors?.begin_hour}
          />
        </GridInputs>

        <GridInputs>
          <ContainerInputFull isFull error={!!errors.end_date}>
            <FormLabel>
              <RiCalendarCheckFill size={17} color={Colors.secondary40} />
              <h2>Data de término</h2>
            </FormLabel>

            <Controller
              control={control}
              name="end_date"
              rules={{ required: "Salutation is required" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <DatePicker
                  format={dateFormat}
                  placeholder="Selecione uma data final"
                  suffixIcon={false}
                  style={{
                    height: "auto",
                    width: "auto",
                    border: "none",
                    borderRadius: "0px",
                    cursor: "pointer",
                    fontSize: "17px",
                    margin: "0px",
                    padding: "0px",
                  }}
                  ref={ref}
                  value={value ? moment(value, dateFormat) : null}
                  onChange={(date: unknown, dateString: string) => {
                    if (!dateString) {
                      onChange(null);
                      return;
                    }

                    onChange(dateString);
                  }}
                />
              )}
            />

            {!!errors.end_date ? (
              <span className="error">{errors.end_date.message}</span>
            ) : null}
          </ContainerInputFull>

          <InputForm
            label="Horário de término"
            isFull
            mask="hour"
            icon={<BiTime size={17} color={Colors.secondary40} />}
            {...register("end_hour")}
            placeholder="Selecione um horário final"
            error={errors?.end_hour}
          />
        </GridInputs>
      </Card>

      <ContainerButtons>
        <ButtonPrev active type="button" onClick={event.goToBackStep}>
          <span>Voltar</span>
        </ButtonPrev>

        <ButtonNext type="submit">
          <p>Prosseguir</p>
        </ButtonNext>
      </ContainerButtons>
    </Container>
  );
}
