import {
  forwardRef,
  ForwardRefRenderFunction,
  InputHTMLAttributes,
} from "react";

import { FieldError } from "react-hook-form";

import { ContainerInputFull } from "./styles";

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  isEditing?: boolean;
  label?: string;
  error?: FieldError;
  isFull?: boolean;
}

export const TextAreaBase: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextAreaProps
> = ({ name, isEditing = true, label, error = null, isFull, ...rest }, ref) => {
  return (
    <ContainerInputFull isFull={isFull} error={!!error}>
      <textarea name={name} {...rest} ref={ref} disabled={!isEditing} />

      {!!error ? <span className="error">{error.message}</span> : null}
    </ContainerInputFull>
  );
};

export const TextAreaForm = forwardRef(TextAreaBase);
