import { useCallback } from "react";

import { CreateAddAndEditTicket } from "components/CreateAndEditTicket";
import { DrawerOverlay } from "components/DrawerOverlay";
import { Colors } from "constants/colors";
import { useCreateAndEditTicket } from "contexts/CreateAndEditTicketContext";

import { BiChevronDown } from "react-icons/bi";

interface DrawerCreateAndEditTicketProps {
  event_id: string;
}

export function DrawerCreateAndEditTicket({
  event_id,
}: DrawerCreateAndEditTicketProps) {
  const { isOpenDrawerTicket, setIsOpenDrawerTicket, setSelectedTicket } =
    useCreateAndEditTicket();

  const handleClose = useCallback(() => {
    setIsOpenDrawerTicket(false);
    setSelectedTicket(null);
  }, [setIsOpenDrawerTicket, setSelectedTicket]);

  return (
    <DrawerOverlay
      title="Criar ingresso"
      icon={
        <BiChevronDown color={Colors.white} size={24} onClick={handleClose} />
      }
      isVisible={isOpenDrawerTicket}
      handleOk={handleClose}
    >
      <CreateAddAndEditTicket event_id={event_id} />
    </DrawerOverlay>
  );
}
