import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";
import { VscChevronDown, VscChevronUp } from "react-icons/vsc";
import { ButtonAccount, AccountDropDown } from "../../styles";

interface AccountDropdownProps {
  user: any;
  activeDropAccount: boolean;
  handleChangeDropAccount: () => void;
  signOut: () => void;
}

function AccountDropdown({
  user,
  activeDropAccount,
  handleChangeDropAccount,
  signOut,
}: AccountDropdownProps) {
  return (
    <div className="account-container">
      <ButtonAccount
        active={activeDropAccount}
        onClick={handleChangeDropAccount}
      >
        <AiOutlineUser size={20} />
        Minha conta
        {activeDropAccount ? (
          <VscChevronUp size={20} />
        ) : (
          <VscChevronDown size={20} />
        )}
      </ButtonAccount>
      {activeDropAccount && (
        <AccountDropDown userLogged={!!user}>
          <ul>
            <Link to="/profile/personal-data">
              <li>
                <span>Meu Perfil</span>
              </li>
            </Link>
            <Link to="/profile/my-shopping">
              <li>
                <span>Compras</span>
              </li>
            </Link>
            <Link to="/profile/my-ticket">
              <li>
                <span>Ingressos</span>
              </li>
            </Link>
            <Link to="/faq">
              <li>
                <span>Suporte</span>
              </li>
            </Link>
            <li onClick={signOut} className="exit">
              <span>Sair</span>
            </li>
          </ul>
        </AccountDropDown>
      )}
    </div>
  );
}

export default AccountDropdown;
