import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { RiCloseCircleLine } from "react-icons/ri";
import { FiSearch } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";

import { useFiltersContext } from "contexts/FiltersContext";

import { Colors } from "constants/colors";

import {
  ButtonSearch,
  ContainerInputSearch,
  ContainerModal,
  ContainerSelect,
  ModalStyled,
  NormalCardBorder,
} from "./styles";

import { Organizer } from "types";
import { useAuthOrganizer } from "services/Hook/Organizer";

interface ModalFilterByOrganizerProps {
  type?: string;
}

export function ModalFilterByOrganizer({ type }: ModalFilterByOrganizerProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [textSearch, setTextSearch] = useState("");

  const [organizers, setOrganizers] = useState<Organizer[] | []>([]);

  const { data } = useAuthOrganizer();

  const { isOpenFilterModalOrganizer, setIsOpenFilterModalOrganizer } =
    useFiltersContext();

  const closeModal = () => {
    setIsOpenFilterModalOrganizer(false);
  };

  useEffect(() => {
    setOrganizers(data ?? []);
  }, [data]);

  useEffect(() => {
    let filterTimeout: NodeJS.Timeout;

    const doFilter = () => {
      clearTimeout(filterTimeout);
      if (!textSearch) return setOrganizers(data?.organizers ?? []);

      filterTimeout = setTimeout(async () => {
        if (data?.organizers) {
          setOrganizers(
            data?.filter((organizer: any) =>
              organizer.name.toLowerCase().includes(textSearch.toLowerCase())
            )
          );
        }
      }, 500);
    };

    if (textSearch) {
      doFilter();
    }
  }, [data, textSearch]);

  const handleSelectedIdFilter = useCallback(
    (value: any) => {
      if (type === "change_organizer") {
        navigate(`/organizer/profile/main/${value}`);

        setIsOpenFilterModalOrganizer(false);

        return;
      }

      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        organizer: value,
      });

      setIsOpenFilterModalOrganizer(false);
    },
    [
      navigate,
      searchParams,
      setIsOpenFilterModalOrganizer,
      setSearchParams,
      type,
    ]
  );

  return (
    <ModalStyled
      width={857}
      visible={isOpenFilterModalOrganizer}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <h1>Filtrar por organizador</h1>

        <h2>Selecione um organizador para a visualização dos resultados.</h2>

        <ContainerInputSearch>
          <input
            type="text"
            placeholder="Insira o nome do organizador..."
            onChange={(e) => setTextSearch(e.target.value)}
          />

          <ButtonSearch onClick={() => {}}>
            <FiSearch size={18} color={Colors.primary100} />
          </ButtonSearch>
        </ContainerInputSearch>

        <ContainerSelect>
          {organizers?.map((organizer: any) => (
            <NormalCardBorder
              onClick={() => handleSelectedIdFilter(organizer._id)}
            >
              <div className="circle-icon">
                <AiOutlineUser size={13} color={Colors.secondary100} />
              </div>

              <h3 className="normal">{organizer.name}</h3>
            </NormalCardBorder>
          ))}
        </ContainerSelect>
      </ContainerModal>
    </ModalStyled>
  );
}
