import { Colors } from "constants/colors";
import styled from "styled-components";

interface ContainerInputProps {
  error?: boolean;
  hasPrefix?: boolean;
  isEditing?: boolean;
  isFull?: boolean;
}

export const ContainerInputFull = styled.div<ContainerInputProps>`
  width: ${(props) => (props.isFull ? "100%" : "49%")};
  display: flex;
  flex-direction: column;

  margin-bottom: 25px;

  div.container {
    width: 100%;
    display: flex;
    flex-direction: row;

    border-bottom: 2px solid
      ${(props) => (props.error ? Colors.error20 : Colors.secondary20)};
    color: ${Colors.secondary30};

    h3 {
      color: ${Colors.secondary70};

      font-family: Poppins;
      font-size: 0.93rem;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0px;

      @media (max-width: 800px) {
        font-size: 12px;
      }
    }
  }

  input {
    width: 100%;
    outline: 0;
    border: 0;

    font-size: 0.93rem;
    font-family: Poppins;

    padding: 0px 0px 4px 23px;

    padding-left: ${(props) => (props.hasPrefix ? "5px" : "23px")};

    color: ${Colors.secondary70};

    ::placeholder {
      ${Colors.secondary70}
    }

    :-ms-input-placeholder {
      ${Colors.secondary70}
    }

    ::-ms-input-placeholder {
      ${Colors.secondary70}
    }

    @media (max-width: 800px) {
      width: 100%;
      font-size: 12px;
    }

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  span.error {
    font-weight: normal;
    font-size: 14px;
    line-height: 160%;
    color: ${Colors.error20};
    position: relative;
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  h2 {
    margin-left: 13px;
    margin-top: 10px;
    line-height: 160%;
    color: ${Colors.secondary40};
    font-size: 0.93rem;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
  }
`;
