import { RiMoneyDollarCircleLine, RiTimeLine } from "react-icons/ri";
import { BadgeStyle, TextStyle } from "./styles";
import { Colors } from "constants/colors";

const texts: { [key: string]: any } = {
  PENDING: "Aguardando pagamento",
  PAID: "Pagamento recebido",
  REFUND: "Pagamento estornado",
  CHARGEBACK: "Pagamento recusado",
  default: "Não encontrado",
};

const icons: { [key: string]: any } = {
  PENDING: <RiTimeLine size={8} color={Colors.orange100} />,
  PAID: <RiMoneyDollarCircleLine size={8} color={Colors.success} />,
  REFUND: <RiMoneyDollarCircleLine size={8} color={Colors.red10} />,
  CHARGEBACK: <RiMoneyDollarCircleLine size={8} color={Colors.red10} />,
  default: <RiMoneyDollarCircleLine size={8} color={Colors.success} />,
};

interface BadgePurchaseByStatusProps {
  status: string;
}

export function BadgePurchaseByStatus({ status }: BadgePurchaseByStatusProps) {
  return (
    <BadgeStyle status={status}>
      <span>{texts[status ?? "default"]}</span>
    </BadgeStyle>
  );
}

export function Text({ status }: BadgePurchaseByStatusProps) {
  return (
    <TextStyle>
      <span>{texts[status ?? "default"]}</span>
    </TextStyle>
  );
}

export function Icon({ status }: BadgePurchaseByStatusProps) {
  return icons[status ?? "default"];
}
