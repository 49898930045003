import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

function useDefaultFilterByToday() {
  const [searchParams, setSearchParams] = useSearchParams();

  const data_type = searchParams.get("data_type") ?? "";
  const data_gte = searchParams.get("data_gte") ?? "";
  const data_lte = searchParams.get("data_lte") ?? "";

  useEffect(() => {
    if (!data_type && data_gte === "" && data_lte === "") {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        data_type: "1",
      });
    }
  }, [data_gte, data_lte, data_type, searchParams, setSearchParams]);
}

export default useDefaultFilterByToday;
