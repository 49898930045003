export const META_TAGS = {
  title: `Bilheteria - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  noIndex: true,
};

export const HEADER_BREADCRUMB_LIST = [
  { name: "Home", link: "/" },
  { name: "Bilheteria", link: "/" },
];

export const TICKET_OFFICE_TABS_LIST = [
  { name: "Bilheteria", link: "/ticket-office" },

  {
    name: "Reenviar ingresso",
    link: "/resend-ticket",
  },
]
