import { Colors } from "constants/colors";
import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
  height: 100%;
`;

export const Card = styled.div`
  width: 100%;
  height: 100%;
  padding: 2.34rem 6.37rem 2.34rem 6.37rem;
  border: 1px solid ${Colors.black10};
  border-radius: 0.93rem;
  margin-top: 2.34rem;

  @media (max-width: 1024px) {
    padding: 0px 0px;
    border: 0;
  }
`;

export const Title = styled.h2`
  color: ${Colors.secondary100};
  font-size: 1.21rem;
  font-family: Poppins;
  font-weight: 600;
  line-height: 160%;

  @media (max-width: 670px) {
    font-size: 14px;
    font-family: Cairo;
    font-weight: 700;
    line-height: 25px;
  }
`;

export const SubTitle = styled.h3`
  color: ${Colors.black70};
  font-size: 1rem;
  font-family: Open Sans;
  line-height: 160%;
  margin-top: 0.93rem;

  @media (max-width: 670px) {
    font-size: 12px;
    font-family: Open Sans;
    margin-top: 8px;
  }
`;

export const LabelTitle = styled.h4`
  color: ${Colors.secondary100};
  font-size: 1.03rem;
  font-family: Poppins;
  font-weight: 500;
  line-height: 160%;

  @media (max-width: 670px) {
    color: ${Colors.black100};
    font-size: 14px;
    font-family: Cairo;
    font-weight: 600;
  }
`;

export const Divider = styled.div`
  margin-top: 1.87rem;
  padding-bottom: 1.87rem;
  height: 1px;
  border-top: 1px solid ${Colors.secondary10};
`;

export const ContainerEventLocal = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0px;
  background: ${Colors.white};
  margin-top: 1.21rem;

  @media (max-width: 720px) {
    border-radius: 0px;
    border: 0;
    padding: 0;
    padding-left: 10px;
    margin-top: 16px;
    /* padding-bottom: 30px; */

    .radio {
      margin-bottom: 16px;
    }

    .group {
      flex-direction: column;
      align-items: space-between;
      justify-content: flex-start;
    }
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: red;
    border-color: transparent;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: ${Colors.primary100};
  }

  .ant-radio-checked .ant-radio-inner {
    border-color: ${Colors.primary100} !important;
  }

  .ant-radio-checked .ant-radio-inner:after {
    background-color: ${Colors.primary100};
  }

  .ant-radio:hover .ant-radio-inner {
    border-color: ${Colors.primary100};
  }

  .ant-radio-wrapper {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 0.93rem;
    color: ${Colors.secondary70};

    @media (max-width: 720px) {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: ${Colors.secondary70};
    }
  }
`;

export const RowTypeEvent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
