import { Colors } from "constants/colors";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  > h1 {
    color: ${Colors.secondary100};
    font-family: "Poppins";
    font-size: 1.625rem;
    line-height: 2.6rem;
    margin: 0;
  }
`;

export const WrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;

  > span {
    color: ${Colors.black70};
    font-family: "Poppins";
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 2.2rem;
  }
`;

export const BankAccountContainer = styled.div`
  margin-top: 50px;
`;

export const BankAccountCard = styled.div`
  margin-top: 32px;

  background-color: ${Colors.white};
  border-radius: 14px;
  border: 1px solid ${Colors.border50};

  display: flex;
  flex-direction: column;
  row-gap: 6px;
  padding: 20px;

  color: #11142d;
  font-family: "Poppins";
  font-size: 1.1rem;
  line-height: 1.7rem;

  p {
    margin-bottom: 0;
    font-weight: 400;
    font-family: "Poppins";
  }

  > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    font-family: "Poppins";
  }
`;

export const Footer = styled.footer`
  margin-top: 80px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 720px) {
    flex-direction: column;
    row-gap: 8px;
    padding: 0 24px;
  }
`;

export const ButtonBase = css`
  cursor: pointer;

  max-width: 390px;
  width: 100%;
  padding: 20px 0;

  border-radius: 14px;

  font-family: "Cairo";
  font-size: 1.25rem;
  line-height: 2.6rem;
  font-weight: 700;
  border: none;
  outline: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:hover {
    opacity: 0.8;
  }

  @media (max-width: 720px) {
    height: 40px;
    padding: 0;
    border-radius: 8px;
    font-size: 1rem;
    line-height: 1.2;
  }
`;

export const ConfirmButton = styled.button`
  ${ButtonBase}

  color: ${Colors.white};
  box-shadow: 0px 15px 30px 0px #d4005026;
  background-color: ${Colors.primary100};
`;

export const CancelButton = styled.button`
  ${ButtonBase}

  color: ${Colors.primary100};
  background-color: ${Colors.primary10};
`;

export const EmptyBankAccount = styled.div`
  padding: 32px 220px;

  display: flex;
  flex-direction: column;
  row-gap: 40px;
  align-items: center;

  > .divider {
    border: 1px solid ${Colors.border40};
    width: 100%;
  }

  > img {
    height: 150px;
    width: 150px;
  }
`;

export const EmptyBankAccountHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 14px;

  h2 {
    font-family: "Open Sans";
    font-size: 18px;
    line-height: 2rem;
    font-weight: 700;
    color: ${Colors.gray10};
    margin: 0;
  }

  p {
    font-family: "Open Sans";
    font-size: 16px;
    line-height: 2rem;
    color: ${Colors.gray20};
    margin: 0;
  }
`;

export const AddBankAccount = styled(Link)`
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  row-gap: 14px;

  cursor: pointer;
  padding: 14px 22px;
  border-radius: 14px;

  background-color: ${Colors.primary10};

  color: ${Colors.primary100};
  font-size: 16px;
  font-weight: 700;

  &:hover {
    color: ${Colors.primary100};
  }
`;
