import { LoadingOutlined } from "@ant-design/icons";

import * as S from "./styles";
import { Colors } from "constants/colors";
import { Spin } from "antd";

interface LoadMoreItemsProps {
  hasMoreItems?: boolean;
  total?: number;
  length?: number;
  fetching?: boolean;
  handleLoadMore: () => void;
}

const antIcon = (
  <LoadingOutlined style={{ fontSize: 18, color: Colors.white }} spin />
);

function LoadMoreItems({
  hasMoreItems = false,
  length = 0,
  total = 0,
  fetching = false,
  handleLoadMore,
}: LoadMoreItemsProps) {
  const isDisabled = !hasMoreItems || fetching;

  if (length <= 0) return null;

  return (
    <S.Container>
      <h3>
        Mostrando {length} de {total}
      </h3>

      {hasMoreItems && length < total ? (
        <S.ButtonMoreItems disabled={isDisabled} onClick={handleLoadMore}>
          {fetching && <Spin spinning indicator={antIcon} />}

          {!fetching ? <span>Carregar mais</span> : null}
        </S.ButtonMoreItems>
      ) : null}
    </S.Container>
  );
}

export default LoadMoreItems;
