import { Colors } from "constants/colors";
import styled from "styled-components";
import { sizeConversor } from "utils/conversors";

export const Container = styled.button`
  display: flex;
  width: ${sizeConversor(262)};
  height: ${sizeConversor(60)};
  padding: ${sizeConversor(14)} ${sizeConversor(51)};
  justify-content: center;
  align-items: center;
  gap: ${sizeConversor(10)};
  cursor: pointer;

  border-radius: ${sizeConversor(14)};
  background: ${Colors.primary10};
  color: ${Colors.primary100};

  outline: none;
  border: none;

  font-family: Cairo;
  font-size: ${sizeConversor(20)};
  font-style: normal;
  font-weight: 700;
  line-height: 160%;

  @media (max-width: 670px) {
    cursor: pointer;
    border: 0;
    outline: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0px;
    width: 25.735px;
    height: 25.735px;

    span {
      display: none;
    }
  }
`;
