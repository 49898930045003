import { useState } from "react";

import { FiChevronRight } from "react-icons/fi";

import { Colors } from "constants/colors";
import { DrawerOverlay } from "components/DrawerOverlay";
import { useGeneralContext } from "contexts/GeneralContext";
import { BiChevronDown } from "react-icons/bi";
import AvailableBalance from "components/ModalGeneralBalanceAvailable/AvailableBalance";
import { ModalGeneralBalanceAvailableProps } from "components/ModalGeneralBalanceAvailable/types";

export function DrawerGeneralBalanceAvailable({
  balance,
  onWithdraw,
}: ModalGeneralBalanceAvailableProps) {
  const {
    isOpenDrawerGeneralBalanceAvailable,
    setIsOpenDrawerGeneralBalanceAvailable,
  } = useGeneralContext();

  const handleClose = () => {
    setIsOpenDrawerGeneralBalanceAvailable(false);
  };

  return (
    <DrawerOverlay
      title={"Saldo disponível"}
      icon={
        <BiChevronDown color={Colors.white} size={24} onClick={handleClose} />
      }
      isVisible={isOpenDrawerGeneralBalanceAvailable}
      handleOk={handleClose}
    >
      <AvailableBalance
        closeModal={handleClose}
        onWithdraw={onWithdraw}
        balance={balance}
      />
    </DrawerOverlay>
  );
}
