import moment from "moment";


export function transformerDateToBr(value: string) {
  const formatValue = "YYYY-MM-DD";
  const formatForReturn = "DD/MM/YYYY";

  let date = "";

  if(value){
    const checkIfDateIsAlreadyReturn = moment(value, formatForReturn, true).isValid();

    if(checkIfDateIsAlreadyReturn) return value

    const data = moment(value, formatValue) ;
    date =  data.format(formatForReturn);
  }

  return date;
}

export function transformerDateToSend(value: string) {
  const formatValue = "DD/MM/YYYY";
  const formatForReturn = "YYYY-MM-DD"

  let date = "";

  if(value){
    const data = moment(value, formatValue) ;
    date =  data.format(formatForReturn);
  }

  return date;
}
