import { Colors } from "constants/colors";
import styled from "styled-components";

export const GridFilterByData = styled.div`
  width: 100%;
  height: 28px;
  display: grid;
  padding: 4px;
  background-color: ${Colors.white};
  grid-template-columns: repeat(4, 1fr);
  filter: drop-shadow(0px 2px 10px rgba(108, 93, 211, 0.08));

  @media (min-width: 670px) {
    display: none;
  }
`;

interface FilterByDataProps {
  active: boolean;
}

export const FilterByData = styled.div<FilterByDataProps>`
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => (props.active ? Colors.primary100 : Colors.white)};
  border-radius: 3px;

  span {
    color: ${(props) => (props.active ? Colors.white : Colors.black100)};
    text-align: center;
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
  }
`;
