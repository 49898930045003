import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSecureLocalStorage } from "./useSecureLocalStorage";
import useDebounce from "./useDebounce";

const usePreserveScroll = () => {
  const location = useLocation();
  const { getItem, setItem } = useSecureLocalStorage();
  const [savedPosition, setSavedPosition] = useState(
    Number(getItem("@ventX-scroll-y")) ?? 0
  );
  const debouncedPosition = useDebounce(savedPosition, 200);

  useEffect(() => {
    const onScroll = () => {
      setItem("@ventX-scroll-y", window.scrollY);
      setSavedPosition(window.scrollY);
    };

    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });

    return () => window.removeEventListener("scroll", onScroll);
  }, [setItem]);

  useEffect(() => {
    window.scrollTo(0, debouncedPosition);
  }, [location, debouncedPosition]);
};

export default usePreserveScroll;
