import {
  Container,
  Step,
  Steps,
  Item,
  Circle,
  Tag,
  TagTransparent,
  ResponsiveSteps,
} from "./styles";
import { Colors } from "constants/colors";
import { BiCheck } from "react-icons/bi";
import { useCreateEvent } from "contexts/CreateEventContext";

export function StepsComponent() {
  const { steps, event } = useCreateEvent();

  return (
    <Container>
      <Steps>
        {steps.map((item, index) => {
          if (event.step > 4 && index === 0) {
            return null;
          }

          if (event.step <= 4 && index === steps.length - 1) {
            return null;
          }

          return (
            <Step active={item.completed || item.current} isFirst={index === 0}>
              <Item>
                {index === 0 ? (
                  <TagTransparent />
                ) : (
                  <Tag completed={item.completed || item.current} />
                )}

                <Circle current={item.current} completed={item.completed}>
                  {item.completed ? (
                    <BiCheck color={Colors.white} size={22} />
                  ) : null}
                </Circle>

                <Tag completed={item.completed} />
              </Item>

              <h2>{item.title}</h2>
            </Step>
          );
        })}
      </Steps>

      <ResponsiveSteps>
        {steps.map((item, index) => {
          if (event.step + 1 === 4 && index + 1 === steps.length) {
            return null;
          }

          if (event.step + 1 > 4 && index + 1 === 4) {
            return null;
          }

          if (event.step < 3 && index <= 2) {
            return (
              <Step
                active={item.completed || item.current}
                isFirst={index === 0}
              >
                <Item>
                  {index === 0 ? (
                    <TagTransparent />
                  ) : (
                    <Tag completed={item.completed || item.current} />
                  )}

                  <Circle current={item.current} completed={item.completed}>
                    {item.completed ? (
                      <BiCheck color={Colors.white} size={22} />
                    ) : null}
                  </Circle>

                  <Tag completed={item.completed} />
                </Item>

                <h2>{item.title}</h2>
              </Step>
            );
          }

          if (event.step >= 3 && index >= 3) {
            return (
              <Step
                active={item.completed || item.current}
                isFirst={index === 0}
              >
                <Item>
                  {index === 0 ? (
                    <TagTransparent />
                  ) : (
                    <Tag completed={item.completed || item.current} />
                  )}

                  <Circle current={item.current} completed={item.completed}>
                    {item.completed ? (
                      <BiCheck color={Colors.white} size={22} />
                    ) : null}
                  </Circle>

                  <Tag completed={item.completed} />
                </Item>

                <h2>{item.title}</h2>
              </Step>
            );
          }

          return null;
        })}
      </ResponsiveSteps>
    </Container>
  );
}
