import { Colors } from "constants/colors";
import { sizeConversor } from "utils/conversors";
import { Select as AntdSelect, Button } from "antd";
import styled from "styled-components";
import { Input } from "components/Form/Input";
import { SecondaryButton } from "components/Button";
import { Checkbox } from "components/Form/Checkbox";

interface ImagePreviewProps {
  photo?: string;
}

interface FlexibleContainerProps {
  justifyContent?: string;
  alignItems?: string;
  flexDirection?: string;
  flexWrap?: string;
  width?: string;
  marginY?: number;
  marginX?: number;
}

interface CapsuleProps {
  isActive: boolean;
}

interface GapProps {
  spaceX?: number;
  spaceY?: number;
  spaceTop?: number;
  spaceRight?: number;
  spaceBottom?: number;
  spaceLeft?: number;
}

interface HorizontalLineProps {
  marginY?: number;
  paddingX?: number;
}

interface PlatformBoxProps {
  platformSelected: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

export const ContentContainer = styled.div`
  display: flex;
  gap: 1.87rem;
  justify-content: space-between;

  @media (max-width: 1276px) {
    justify-content: center;
  }

  @media (max-width: 1024px) {
    gap: 0;
  }
`;

export const EditingBoard = styled.div`
  border-radius: 20px;
  border: 1px solid ${Colors.black10};
  box-shadow: 0px 4px 10px 0px rgba(18, 25, 34, 0.05);
  width: ${sizeConversor(1180)};
  min-height: 400px;
  margin: 20px 0;
  padding: 40px 60px;

  @media (max-width: 640px) {
    width: 100%;
    padding: 0px 0px;
    border: 0;
    background-color: transparent;
    box-shadow: none;
  }
`;

export const EditingBoardResponsive = styled.div`
  border-radius: 20px;
  border: 1px solid ${Colors.black10};
  box-shadow: 0px 4px 10px 0px rgba(18, 25, 34, 0.05);
  width: ${sizeConversor(1180)};
  min-height: 400px;
  margin: 20px 0;
  padding: 40px 60px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ContainerEditResponsive = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ListOptionsEditEvent = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  list-style: none;
  margin-top: 30px;
  margin-bottom: 40px;

  @media (min-width: 1024px) {
    display: none;
  }

  h3.title {
    color: #adadad;
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
`;

export const CardOptionEdit = styled.li`
  height: 38px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-radius: 8px;
  border: 1px solid ${Colors.secondary20};
  background: ${Colors.white};
  padding: 0px 13px 0px 18px;

  span {
    color: ${Colors.secondary40};

    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const HorizontalLine = styled.hr<HorizontalLineProps>`
  background-color: ${Colors.secondary10};
  height: 1px;
  border: none;
  margin: ${(props) =>
      props.marginY ? sizeConversor(props.marginY) : sizeConversor(40)}
    0;
  width: 100%;
`;

export const FormContent = styled.form`
  width: 100%;
`;

export const TitleForm = styled.h3`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;

  @media (max-width: 800px) {
    margin-bottom: 20px;
  }
`;

export const TitleTicket = styled.h3`
  color: ${Colors.black30};
  font-family: Open Sans;
  font-size: 0.93rem;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 0px;
`;

export const SubTitleForm = styled.h4`
  color: ${Colors.black70};
  font-family: Open Sans;
  font-size: 1.03rem;
  font-style: normal;
  font-weight: 400;
`;

export const LabelForm = styled.h5`
  color: ${Colors.secondary100};
  font-family: Poppins;
  font-size: 1.03rem;
  font-style: normal;
  font-weight: 500;
`;

export const EditingBoardContent = styled.form`
  h3 {
    font-size: ${sizeConversor(32)};
    font-family: Cairo, sans-serif;
    font-weight: 700;
    color: ${Colors.black100};

    @media (max-width: 640px) {
      font-family: Cairo;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
    }
  }
  h4 {
    font-size: ${sizeConversor(22)};
    color: ${Colors.secondary100};
    font-family: Poppins, sans-serif;
    font-weight: 600;

    @media (max-width: 640px) {
      font-family: Cairo;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 0;
    }
  }
  h5 {
    display: flex;
    font-size: ${sizeConversor(20)};
    color: ${Colors.secondary40};
    font-family: Poppins, sans-serif;
    align-items: center;
  }
  span {
    margin: 0 10px;
  }
  p {
    color: ${Colors.black70};
    font-family: Open sans, sans-serif;
  }
  small {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    color: ${Colors.black70};

    @media (max-width: 640px) {
      font-size: 10px;
      margin-top: 8px !important;
      line-height: 0px !important;
    }
  }
  .aligned-with-icon {
    margin-top: 10px;
    display: flex;
    align-items: center;
    align-content: center;
    span {
      display: flex;
      align-items: center;
    }
  }
  @media (max-width: 540px) {
    justify-content: center;
  }
`;

export const EditInput = styled(Input)`
  border-bottom: 2px solid ${Colors.secondary20};
  :focus,
  :hover {
    border-bottom: 2px solid ${Colors.primary20};
  }
`;

export const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1280px;

  padding-right: 38px;
  padding-left: 38px;

  margin-top: 9.14rem;
  width: 100%;

  @media (max-width: 1024px) {
    margin-top: 88px;
    padding-left: 28px;
    padding-right: 28px;
  }

  div.main-header {
    h1 {
      font-family: Cairo;
      font-weight: 900;
      font-size: 1.87rem;
      margin-bottom: 0px;
      color: ${Colors.black100};
      margin-top: 2.53rem;

      @media (max-width: 1024px) {
        margin-top: 0px;
      }

      @media (max-width: 640px) {
        font-family: Cairo;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
      }
    }

    h1.responsive {
      @media (max-width: 640px) {
        display: none;
      }
    }
  }

  div.header-filter-mobile {
    margin-top: 26px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (min-width: 1024px) {
      display: none;
    }

    h1 {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;
      color: ${Colors.black100};
    }

    div.box {
      width: 25.73px;
      height: 25.73px;
      border-radius: 50%;
      background: ${Colors.white20};
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;
    }
  }

  div.bread {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const PictureUploadButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: ${sizeConversor(533)};
  height: ${sizeConversor(60)};
  margin: 36px 0;
  background-color: ${Colors.primary10};
  border: none;
  border-radius: ${sizeConversor(14)};
  color: ${Colors.primary100};
  font-size: 20px;
  cursor: pointer;

  @media (max-width: 640px) {
    width: 100%;
  }

  span {
    font-family: Cairo;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 700;

    @media (max-width: 640px) {
      color: ${Colors.primary100};
      font-family: Cairo;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
`;

export const ImagePreview = styled.div<ImagePreviewProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.black50};
  width: ${sizeConversor(533)};
  height: ${sizeConversor(257)};
  font-size: ${sizeConversor(22)};
  background: ${(props) =>
    props.photo ? "url(" + props.photo + ")" : Colors.black10};
  background-size: cover;
  background-position: center;
  border: ${(props) => (props.photo ? null : `2px dashed ${Colors.black50}`)};

  @media (max-width: 640px) {
    width: 100%;
  }
`;

export const PhotoPreviewContainer = styled.div`
  width: ${sizeConversor(533)};
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 540px) {
    width: 100%;
  }
`;

export const FullWidthBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const DateTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputGroup = styled.div<HorizontalLineProps>`
  margin: ${(props) => (props.marginY ? sizeConversor(props.marginY) : "40px")}
    0px;
  padding: ${(props) =>
    props.paddingX ? sizeConversor(props.paddingX) : "10px"};
`;

export const EditEventLabel = styled.label`
  font-size: ${sizeConversor(20)};
  font-family: Poppins, sans-serif;
  color: ${Colors.secondary40};
  display: flex;
  align-items: center;
  * {
    margin: 0 10px;
  }
`;

export const InputFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  min-width: 48%;
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 540px) {
    flex-direction: column;
  }
`;

export const RadioGroup = styled.div`
  display: flex;
  justify-content: space-between;
  span {
    display: flex;
    align-items: center;
  }
  .ant-radio-group {
    @media (max-width: 540px) {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const EditSelect = styled(AntdSelect)`
  width: 100%;
  .ant-select-focused > .ant-select-selector {
    box-shadow: none;
  }
  & > .ant-select-selector {
    min-width: 81px;
    width: 100%;
    height: 36px !important;
    font: 400 1.2rem Poppins, sans-serif;
    color: ${Colors.gray30} !important;
    border-radius: 8px !important;
    border: 2px solid ${Colors.gray60} !important;
    align-items: center;

    :hover {
      border-color: ${Colors.primary100} !important;
    }

    :focus {
      box-shadow: none;
    }
  }

  &.ant-select-disabled > .ant-select-selector {
    cursor: not-allowed;
    color: ${Colors.gray10} !important;
    background-color: ${Colors.gray60} !important;
  }
  & > .ant-select-selector::placeholder {
    color: ${Colors.gray10} !important;
    font: 400 0.8rem Poppins, sans-serif !important;
  }
  &
    > .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none !important;
    border: 1px solid ${Colors.gray10} !important;
  }
  .ant-select-arrow {
    color: ${Colors.gray20} !important;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-active {
    background-color: ${Colors.gray40} !important;
  }
`;

export const FlexibleContainer = styled.div<FlexibleContainerProps>`
  display: flex;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : null};
  align-items: ${(props) => (props.alignItems ? props.alignItems : null)};
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : null)};
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : null};
  width: ${(props) => (props.width ? props.width : null)};
  margin: ${(props) => (props.marginY ? sizeConversor(props.marginY) : 0)},
    ${(props) => (props.marginX ? sizeConversor(props.marginX) : 0)};
`;

export const MapContainer = styled("div")`
  width: ${sizeConversor(533)};
  height: ${sizeConversor(282)};
  background-color: ${Colors.gray60};
`;

export const PlatformBox = styled.div<PlatformBoxProps>`
  width: 100%;
  height: 177px;
  border: 1px solid
    ${(props) => (props.platformSelected ? Colors.primary100 : Colors.black10)};
  display: flex;
  border-radius: ${sizeConversor(20)};
  padding: ${sizeConversor(20)};
  margin: 20px 0;
  h4,
  p,
  small {
    color: ${(props) =>
      props.platformSelected ? Colors.primary100 : Colors.secondary40};
    font-size: 12px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
  }
  .main-box {
    width: 100%;
  }
  hr {
    background-color: ${(props) =>
      props.platformSelected ? Colors.primary40 : Colors.secondary10};
    height: 1px;
    border: none;
    margin: ${sizeConversor(10)} 0;
  }
  :hover {
    border: 1px solid ${Colors.primary50};
    cursor: pointer;
  }
`;

export const IconBox = styled("div")`
  font-size: ${sizeConversor(35)};
  color: ${Colors.secondary40};
  margin-right: ${sizeConversor(20)};
  width: ${sizeConversor(35)};
`;

export const CustomInput = styled("div")`
  border: 1px solid ${Colors.secondary20};
  border-radius: 8px;
  width: ${sizeConversor(910)};
  height: ${sizeConversor(60)};
`;

export const CopyButton = styled("button")`
  height: ${sizeConversor(60)};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${sizeConversor(22)};
  width: ${sizeConversor(100)};
  background-color: ${Colors.primary100};
  border: none;
  border-radius: ${sizeConversor(18)};
  color: ${Colors.white};
`;

export const CharCounter = styled("small")`
  color: ${Colors.black40};
  font-size: ${sizeConversor(16)};
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
`;

export const Capsule = styled("span")<CapsuleProps>`
  color: ${(props) => (props.isActive ? Colors.white : Colors.secondary100)};
  background-color: ${(props) =>
    props.isActive ? Colors.primary100 : Colors.secondary10};
  padding: 2px 10px;
  font-size: 16px;
  font-family: Open Sans;
  width: auto;
  height: auto;
  border-radius: 100px;
  font-style: normal;
  font-weight: 600;
  && {
    margin: 10px;
  }
  :hover {
    cursor: pointer;
  }
`;
export const RadioList = styled.div`
  display: block;
  label {
    display: block;
  }
`;

export const RedirectDescription = styled.small`
  font-family: Open Sans, sans-serif;
  font-style: italic;
  font-weight: 400;
  &&& {
    font-size: ${sizeConversor(16)};
  }
`;

export const RedirectLinkButton = styled(SecondaryButton)`
  margin-top: ${sizeConversor(36)};
  width: ${sizeConversor(533)};
  font-size: ${sizeConversor(20)};
  font-weight: 700;
  @media (max-width: 540px) {
    max-width: ${sizeConversor(450)};
  }
`;

export const SavedLinksHeader = styled("h3")`
  font-style: normal;
  font-weight: 700;
  &&& {
    font-size: ${sizeConversor(20)};
    font-family: Open Sans;
    color: ${Colors.black30};
  }
`;

export const Gap = styled.div<GapProps>`
  margin: ${(props) =>
    props.spaceX && props.spaceY
      ? props.spaceY + "px " + props.spaceX + "px"
      : null};
  margin-top: ${(props) => (props.spaceTop ? props.spaceTop + "px" : null)};
  margin-right: ${(props) =>
    props.spaceRight ? props.spaceRight + "px" : null};
  margin-bottom: ${(props) =>
    props.spaceBottom ? props.spaceBottom + "px" : null};
  margin-left: ${(props) => (props.spaceLeft ? props.spaceLeft + "px" : null)};
  width: 1px;
  height: 1px;
  display: block;
`;

export const NewCheckbox = styled(Checkbox)`
  &&& > span {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: ${sizeConversor(20)};
  }
  & > span.ant-checkbox.ant-checkbox-checked + span {
    font-family: Poppins, sans-serif;
    font-weight: 400;
    font-size: ${sizeConversor(20)};
    color: ${Colors.secondary70};
  }
`;

export const TicketBox = styled("div")`
  width: 100%;
  height: ${sizeConversor(111)};
  border: 1px solid ${Colors.black10};
  border-radius: ${sizeConversor(20)};
  display: flex;
  justify-content: space-between;
  margin: ${sizeConversor(15)} 0;
  cursor: pointer;
  align-items: center;
  .left-side-info {
    display: flex;
    padding: ${sizeConversor(25)} ${sizeConversor(40)};
    .coupon-icon-container {
      padding-right: ${sizeConversor(40)};
      align-self: flex-start;
    }
    .info-container {
      display: flex;
      flex-direction: column;
      min-width: 40%;
      & > h4 {
        font-size: ${sizeConversor(20)};
        font-family: Poppins;
        margin-bottom: 0;
      }
      & > h3 {
        font-size: ${sizeConversor(20)};
        font-family: Cairo;
        color: ${Colors.gray20};
        margin-top: 0;
      }
    }
  }
  .dropdown-container {
    padding: ${sizeConversor(25)} ${sizeConversor(40)};
  }
`;

export const EditTicketOverlay = styled.div`
  width: 99vw;
  min-height: 100vh;
  padding: 40px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #24105844;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  .ticket-modal {
    background-color: ${Colors.white};
    width: ${sizeConversor(1619)};
    padding: ${sizeConversor(80)} ${sizeConversor(136)};
    min-height: 800px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      font-size: ${sizeConversor(40)};
      text-align: center;
      font-family: Poppins;
      font-weight: 600;
      color: ${Colors.secondary100};
    }
    .ticket-data-container {
      width: 100%;
      padding: 50px;
      min-height: 400px;
      border: 1px solid ${Colors.border50};
      border-radius: ${sizeConversor(20)};
    }
    .gray-subtitle {
      font-size: ${sizeConversor(20)};
      font-family: Open Sans;
      font-style: normal;
      font-weight: 700;
      color: ${Colors.gray20};
    }
    small {
      font-size: ${sizeConversor(16)};
      font-family: Open Sans;
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
    }
  }
  .platform-modal {
    background-color: ${Colors.white};
    width: ${sizeConversor(1619)};
    /* padding: ${sizeConversor(30)} ${sizeConversor(30)} ${sizeConversor(30)}
      ${sizeConversor(130)}; */
    min-height: 800px;
    display: flex;
    border-radius: ${sizeConversor(20)};
    align-items: center;
    justify-content: space-between;
    h2 {
      font-size: ${sizeConversor(40)};
      width: 100%;
      display: flex;
      font-family: Poppins;
      font-weight: 600;
      color: ${Colors.secondary100};
    }
    small {
      font-size: ${sizeConversor(22)};
      font-family: Open Sans;
      font-style: normal;
      font-weight: 400;
      display: flex;
      width: 100%;
    }
    .platform-options {
      width: 90%;
      margin-right: ${sizeConversor(40)};
    }
    .close-container {
      align-self: flex-start;
      color: #b8b9c0;
    }
    .platform-option {
      font-size: ${sizeConversor(22)};
      display: flex;
      justify-content: flex-start;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;
      align-content: center;
      color: #241058;
      :hover {
        cursor: pointer;
        font-size: ${sizeConversor(23)};
        color: #422a82;
      }
    }
    span {
      padding: 5px;
      background-color: #e9e7ee;
      border-radius: 50%;
      display: flex;
      align-items: center;
    }
    p {
      margin-bottom: 0;
      color: #241058;
      :hover {
        color: #422a82;
      }
    }
  }
`;

export const EditInputModal = styled(EditInput)`
  padding: 0;
`;

export const LinkContainer = styled.div`
  width: 100%;
  height: ${sizeConversor(111)};
  border: 1px solid ${Colors.black10};
  border-radius: ${sizeConversor(20)};
  padding: ${sizeConversor(20)} ${sizeConversor(24)};
  display: flex;
  .icon-container {
    display: flex;
    height: 100%;
    margin-right: 20px;
    margin-top: 5px;
  }
  .link-name {
    font-size: ${sizeConversor(22)};
    color: #241058;
    margin: 0;
  }
  .link {
    color: #a79fbc;
  }
`;

export const ContainerButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.81rem;

  @media (max-width: 680px) {
    flex-direction: column;
    row-gap: 30px;
  }
`;

export const ButtonSecondary = styled.button`
  cursor: pointer;
  outline: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13rem;
  height: 2.81rem;
  padding: 14px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 14px;
  background: ${Colors.primary10};

  @media (max-width: 680px) {
    width: 100%;
  }

  span {
    color: ${Colors.primary100};
    text-align: center;
    font-family: Cairo;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 700;
  }
`;

export const ButtonPrimary = styled(Button)`
  cursor: pointer;
  outline: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13rem;
  height: 2.81rem;
  padding: 14px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 14px;
  background: ${Colors.primary100};

  @media (max-width: 680px) {
    width: 100%;
  }

  &&& {
    background-color: ${Colors.primary100};
    border-color: ${Colors.primary100};
  }

  span {
    color: ${Colors.white};
    text-align: center;
    font-family: Cairo;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 700;
  }
`;
