import { useState } from "react";

import { RiArrowLeftLine, RiCloseCircleLine } from "react-icons/ri";
import { FiChevronRight } from "react-icons/fi";

import { Colors } from "constants/colors";

import {
  ButtonLeft,
  Card,
  ContainerHeader,
  ContainerModal,
  Divider,
  GridItems,
  Item,
  ModalStyled,
  SubTitle,
  TextTotal,
  Title,
  TitleTotal,
} from "./styles";

import { useGeneralContext } from "contexts/GeneralContext";
import { formatPrice } from "utils/formatPrice";
import { divideBy100 } from "utils/divideBy100";

interface ModalGeneralBalanceReceivableProps {
  total: number;
}

export function ModalGeneralBalanceReceivable({
  total,
}: ModalGeneralBalanceReceivableProps) {
  const [selectedPurchase, setSelectedPurchase] = useState<
    "resume" | "detail" | null
  >(null);

  const {
    isOpenModalGeneralBalanceReceivable,
    setIsOpenModalGeneralBalanceReceivable,
  } = useGeneralContext();

  const closeSelectedPurchase = () => {
    setSelectedPurchase(null);
  };

  const closeModal = () => {
    setIsOpenModalGeneralBalanceReceivable(false);
  };

  const title: { [key: string]: any } = {
    resume: "Resumo das vendas",
    detail: "Detalhes da venda",
    default: "Saldo a receber",
  };

  return (
    <ModalStyled
      width={1200}
      visible={isOpenModalGeneralBalanceReceivable}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: "20px" }}
      closeIcon={
        <RiCloseCircleLine
          style={{ marginTop: "14px", marginRight: "34px" }}
          size={43}
          color={Colors.black40}
        />
      }
      footer={null}
    >
      <ContainerModal>
        <ContainerHeader>
          {selectedPurchase ? (
            <ButtonLeft onClick={closeSelectedPurchase}>
              <RiArrowLeftLine color={Colors.primary100} size={22} />
            </ButtonLeft>
          ) : null}

          <h1>{title[selectedPurchase ?? "default"]}</h1>
        </ContainerHeader>

        {selectedPurchase === null ? (
          <Card>
            <Title>Saldo a receber sob as vendas realizadas</Title>

            <GridItems>
              <Item onClick={() => {}}>
                <div className="column-content">
                  <TitleTotal>SALDO A RECEBER</TitleTotal>

                  <TextTotal>{formatPrice(divideBy100(total))}</TextTotal>

                  <SubTitle>Venda dos ingressos online</SubTitle>
                </div>

                <FiChevronRight color={Colors.gray45} size={24} />
              </Item>
            </GridItems>

            <Divider />
          </Card>
        ) : null}
      </ContainerModal>
    </ModalStyled>
  );
}
