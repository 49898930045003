import { Link } from 'react-router-dom'
import {
  ButtonAddNew,
  ButtonChangeOrganizer,
  Container,
  FlexButtonsWeb,
  Title
} from './styles'

import { ModalFilterByOrganizer } from 'components/ModalFilterByOrganizer'
import { MdAdd, MdSyncAlt } from 'react-icons/md'
import { Colors } from 'constants/colors'
import { useAuth } from 'contexts/AuthContext'
import { useFiltersContext } from 'contexts/FiltersContext'

export function HeaderTitleOrganizerProfile () {
  const { setIsOpenFilterModalOrganizer } = useFiltersContext()

  const { hasOrganizer } = useAuth()

  const handleOpenModalChangeOrganizer = () => {
    setIsOpenFilterModalOrganizer(true)
  }

  return (
    <Container>
      <Title>Perfil do organizador</Title>

      <FlexButtonsWeb>
        {hasOrganizer ? (
          <ButtonChangeOrganizer onClick={handleOpenModalChangeOrganizer}>
            <MdSyncAlt width={18} height={18} color={Colors.primary100} />

            <span>Alterar organizador</span>
          </ButtonChangeOrganizer>
        ) : null}

        <Link to={`/organizer/new`}>
          <ButtonAddNew>
            <MdAdd size={22} color={Colors.white} />

            <span>Adicionar novo</span>
          </ButtonAddNew>
        </Link>
      </FlexButtonsWeb>

      <ModalFilterByOrganizer type='change_organizer' />
    </Container>
  )
}
