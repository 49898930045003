import { useSecureLocalStorage } from "hooks/useSecureLocalStorage";
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";

import {
  addData,
  addDescription,
  addEventResponse,
  addInformation,
  addLocal,
  addOrganizer,
  addTicket,
  resetStore,
  toBack,
  toNext,
} from "reducers/create-event/actions";

import { eventReducer } from "reducers/create-event/reducers";

import {
  DataCreateEvent,
  DescriptionCreateEvent,
  EventResponse,
  InformationCreateEvent,
  LocalCreateEvent,
  OrganizerCreateEvent,
  TicketCreateEvent,
} from "types";

interface Step {
  title: string;
  current: boolean;
  completed: boolean;
}

type CreateEventContextData = {
  steps: Step[];
  file: File | null;
  setFile(file: File): void;
  event: {
    step: number;
    goToNextStep(): void;
    goToBackStep(): void;
    information: InformationCreateEvent | null;
    data: DataCreateEvent | null;
    local: LocalCreateEvent | null;
    description: DescriptionCreateEvent | null;
    organizer: OrganizerCreateEvent | null;
    event_response: EventResponse | null;
    tickets: TicketCreateEvent[] | null | [];
    createInformation(information: InformationCreateEvent): void;
    createData(data: DataCreateEvent): void;
    createLocal(local: LocalCreateEvent): void;
    createDescription(description: DescriptionCreateEvent): void;
    createOrganizer(organizer: OrganizerCreateEvent): void;
    createEventResponse(event_response: EventResponse): void;
    createTicket(ticket: TicketCreateEvent): void;
    resetTheStore(): void;
  };
};

const CreateEventContext = createContext<CreateEventContextData>(
  {} as CreateEventContextData
);

export const CreateEventProvider: React.FC = ({ children }) => {
  const [file, setFile] = useState<File | null>(null);

  const [steps, setSteps] = useState([
    { title: "Informações Básicas", current: false, completed: false },
    { title: "Data do Evento", current: false, completed: false },
    { title: "Local do Evento", current: false, completed: false },
    { title: "Descrição e Definições", current: false, completed: false },
    { title: "Sobre o Organizador", current: false, completed: false },
    { title: "Ingressos", current: false, completed: false },
    { title: "Compartilhe seu evento", current: false, completed: false },
  ]);

  const secureLocalStorage = useSecureLocalStorage();

  const [eventState, dispatch] = useReducer(
    eventReducer,
    {
      step: 0,
      information: null,
      data: null,
      local: null,
      description: null,
      organizer: null,
      event_response: null,
      tickets: [],
    },
    (initialState) => {
      const storedStateAsJSON = secureLocalStorage.getItem(
        "@EventX-create_event"
      );

      if (storedStateAsJSON) {
        return storedStateAsJSON;
      }

      return initialState;
    }
  );

  useEffect(() => {
    secureLocalStorage.setItem("@EventX-create_event", eventState);
  }, [eventState, secureLocalStorage]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    setSteps((prev) => {
      return prev.map((item, index) => {
        if (index === eventState.step) {
          return { ...item, current: true, completed: false };
        }

        return {
          ...item,
          current: false,
          completed: eventState.step > index ? true : false,
        };
      });
    });
  }, [eventState.step]);

  const value = {
    ...eventState,
    goToNextStep: () => {
      dispatch(toNext());
    },
    goToBackStep: () => {
      dispatch(toBack());
    },
    createInformation: (information: InformationCreateEvent) => {
      dispatch(addInformation(information));
    },
    createData: (data: DataCreateEvent) => {
      dispatch(addData(data));
    },
    createLocal: (local: LocalCreateEvent) => {
      dispatch(addLocal(local));
    },
    createDescription: (description: DescriptionCreateEvent) => {
      dispatch(addDescription(description));
    },
    createOrganizer: (organizer: OrganizerCreateEvent) => {
      dispatch(addOrganizer(organizer));
    },
    createEventResponse: (event_response: EventResponse) => {
      dispatch(addEventResponse(event_response));
    },
    createTicket: (ticket: TicketCreateEvent) => {
      dispatch(addTicket(ticket));
    },
    resetTheStore: () => {
      dispatch(resetStore());
    },
  };

  return (
    <CreateEventContext.Provider
      value={{
        steps,
        event: value,
        file,
        setFile,
      }}
    >
      {children}
    </CreateEventContext.Provider>
  );
};

export function useCreateEvent(): CreateEventContextData {
  const context = useContext(CreateEventContext);

  if (!context) {
    throw new Error(`useCreateEvent it depends CreateEventProvider`);
  }

  return context;
}
