import { Link } from "react-router-dom";

import { BiChevronRight } from "react-icons/bi";

import { Colors } from "constants/colors";

import { Card, CircleIcon, Container, Title } from "./styles";

import customer_service from "assets/ri_customer-service-line.svg";

export function NeedHelpArticle() {
  return (
    <Container>
      <Title>PRECISA DE AJUDA?</Title>

      <Link to="/faq">
        <Card>
          <div className="content">
            <CircleIcon>
              <img src={customer_service} alt="" />
            </CircleIcon>

            <h5>Suporte</h5>
          </div>

          <BiChevronRight size={28} color={Colors.secondary40} />
        </Card>
      </Link>
    </Container>
  );
}
