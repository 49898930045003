import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useAddressById } from "services/Hook/User/Address";

import hasCookiesEnabled from "utils/hasCookiesEnabled";
import { checkUserPlatform } from "utils/plaftformDetect";

import { Form } from "./Form";

import Popup from "containers/Popup";
import { Footer } from "components/Footer";
import { Header } from "components/Header";

const metaTags = {
  title: `Editar endereço - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

export default function AddressEdit() {
  const [visible, setVisible] = useState(false);
  const [userPlatform, setUserPlatform] = useState("");

  let { id } = useParams();

  const { data } = useAddressById(String(id));

  const handleCookies = () => {
    setVisible(false);
  };

  useEffect(() => {
    !hasCookiesEnabled() && setVisible(true);
    setUserPlatform(checkUserPlatform());
  }, [setVisible, userPlatform]);

  return (
    <>
      <Header isProfile metaTags={metaTags} />

      <Form address={data} />

      <Footer />

      {visible && <Popup handleVisible={handleCookies} />}
    </>
  );
}
