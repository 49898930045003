import { Spin } from "antd";

import QRCode from "react-qr-code";

import { LoadingOutlined } from "@ant-design/icons";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { FiMapPin } from "react-icons/fi";
import { RiCloseCircleLine } from "react-icons/ri";

import { useTabsTicketsAndPurchases } from "contexts/TabsTicketsAndPurchasesContext";

import { Colors } from "constants/colors";

import { useDisclosure } from "hooks/useDisclosure";

import {
  ButtonVisualizationTicketMobile,
  CardAccordionMobile,
  ContainerButtonMobile,
  ContainerQrCodeMobile,
  ContainerTicketsMobile,
  DataDropDownMobile,
  ModalStyled,
  Center,
} from "./styles";

import logo from "assets/logo.svg";
import ticket_profile from "assets/ticket-profile.svg";
import { useSearchTicket } from "hooks/useSearchTicket";
import { DetailTicket } from "components/DetailTicket";
import LoadMoreItems from "components/LoadMoreItems";

export function TicketTabPane() {
  const { selectedTicketMobile, handleSelectedTicketMobile } =
    useTabsTicketsAndPurchases();

  const { handleOk, handleCancel, isModalVisible, showModal } = useDisclosure();

  const {
    data,
    handleLoadMoreTicketsMobile,
    handleOpenDropTicketEventMobile,
    hasMoreItems,
    loadingMoreItems,
  } = useSearchTicket();

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 38, color: Colors.primary100 }} spin />
  );

  return (
    <>
      {selectedTicketMobile === null ? (
        <ContainerTicketsMobile>
          <div className="header-filter">
            <h1>todos os ingressos</h1>
          </div>

          {!data ? (
            <Center>
              <Spin spinning indicator={antIcon} />
            </Center>
          ) : null}

          {data?.results?.map((ticket) => (
            <div key={ticket._id} className="accordion">
              <CardAccordionMobile
                active={ticket.isOpen}
                onClick={() => handleOpenDropTicketEventMobile(ticket._id)}
              >
                <div className="content">
                  <div className="image">
                    <img
                      className="ticket"
                      src={ticket_profile}
                      alt="Logo"
                      width={25}
                      height={22.5}
                    />
                  </div>

                  <div className="texts">
                    <div className="first">
                      <h2 className="text">
                        {ticket.event ? ticket?.event?.name : "Não Informado"}
                      </h2>
                    </div>

                    <span>
                      {ticket?.event_ticket_id
                        ? ticket?.event_ticket_id?.name.length > 27
                          ? `${ticket?.event_ticket_id?.name.substring(
                              0,
                              27
                            )}...`
                          : ticket?.event_ticket_id?.name
                        : "Não informado"}{" "}
                      {"\u00B7"} {ticket?.formattedDate}
                    </span>
                  </div>
                </div>

                {ticket.isOpen ? (
                  <GoChevronDown size={14} color={Colors.secondary50} />
                ) : (
                  <GoChevronUp size={14} color={Colors.secondary50} />
                )}
              </CardAccordionMobile>

              {ticket.isOpen ? (
                <DataDropDownMobile>
                  <div className="content-main">
                    <div className="texts-ticket">
                      <div className="data">
                        <h2 className="text-data">Email</h2>
                        <span>{ticket.holder_email}</span>
                      </div>

                      <div className="data">
                        <h2 className="text-data">Código da compra</h2>
                        <span>{ticket?.ticket_code}</span>
                      </div>
                    </div>

                    <div className="container-image">
                      <img
                        className="ticket"
                        src={ticket?.event?.thumb}
                        alt="Logo"
                        width="68px"
                        height="68px"
                      />
                    </div>
                  </div>

                  <ContainerButtonMobile>
                    <ButtonVisualizationTicketMobile
                      onClick={() => {
                        window.scrollTo(0, 0);
                        handleSelectedTicketMobile(ticket);
                      }}
                    >
                      <span>Ver ingresso completo</span>
                    </ButtonVisualizationTicketMobile>
                  </ContainerButtonMobile>
                </DataDropDownMobile>
              ) : null}
            </div>
          ))}

          <LoadMoreItems
            handleLoadMore={handleLoadMoreTicketsMobile}
            fetching={loadingMoreItems}
            hasMoreItems={hasMoreItems}
            length={data?.results?.length}
            total={data?.count}
          />
        </ContainerTicketsMobile>
      ) : null}

      {selectedTicketMobile !== null ? (
        <DetailTicket showModal={showModal} ticket={selectedTicketMobile} />
      ) : null}

      <ModalStyled
        width={1200}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={
          <RiCloseCircleLine
            style={{ marginTop: "14px", marginRight: "14px" }}
            size={28}
            color={Colors.gray30}
          />
        }
        footer={null}
      >
        <>
          <img
            style={{ marginTop: -18 }}
            src={logo}
            alt="logo"
            width={109}
            height={29}
          />

          <ContainerQrCodeMobile>
            <QRCode size={290} value={selectedTicketMobile?._id || ""} />
          </ContainerQrCodeMobile>

          <h5 className="code">{selectedTicketMobile?._id}</h5>
        </>
      </ModalStyled>
    </>
  );
}
