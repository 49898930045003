import { ButtonConfirm, ButtonLeft, ContainerModal, ModalStyled } from "./styles";

import img_help from "assets/help.png";

interface ModalHelpProps {
  title: string;
  subtitle?: string;
  isModalVisible: boolean;
  handleOk(): void;
  handleCancel(): void;
  leftButtonTitle: string;
  rightButtonTitle: string;
}

export function ModalHelp({
  title,
  subtitle,
  handleCancel,
  handleOk,
  isModalVisible,
  leftButtonTitle,
  rightButtonTitle
}: ModalHelpProps) {
  return (
    <ModalStyled
      width={642}
      visible={isModalVisible}
      onOk={handleCancel}
      onCancel={handleCancel}
      closeIcon={null}
      footer={null}
    >
      <ContainerModal>
        <img
          src={img_help}
          width={189}
          height={189}
          alt="confirm-cancel"
        />

        <div className="texts-confirm">
          <h3>{title}</h3>

          {subtitle ? <p>{subtitle}</p> : null}
        </div>

        <div className="buttons">
          <ButtonLeft onClick={handleCancel}>
           <span>{leftButtonTitle}</span>
          </ButtonLeft>

          <ButtonConfirm onClick={handleOk}>
            <span>{rightButtonTitle}</span>
          </ButtonConfirm>
        </div>
      </ContainerModal>
    </ModalStyled>
  );
}
