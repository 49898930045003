import useSWR from "swr";

import { authClient } from "services/authClient";

export function useAddress<Data = any, Error = any>() {
  const url: string = "/user-address";

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const { data } = response;

    const results = data?.data?.results;

    return results;
  });

  return { data, error, mutate };
}

export function useAddressById<Data = any, Error = any>(id: string) {
  const url: string = `/user-address/${id}`;

  const { data, error, mutate } = useSWR<Data, Error>(url, async (url) => {
    const response = await authClient.get(url);

    const data = response.data.data;

    return data;
  });

  return { data, error, mutate };
}

export async function DeleteAddress(id: string) {
  const url: string = `/user-address/${id}`;

  const response = await authClient.delete(url);

  return response.data;
}

interface EditAddressProps {
  id: string | undefined;
  credentials: any;
}

export async function EditAddress({ id, credentials }: EditAddressProps) {
  const url: string = `/user-address/${id}`;

  const response = await authClient.patch(url, credentials);

  return response.data;
}

interface AddAddressProps {
  credentials: any;
}

export async function AddAddress({ credentials }: AddAddressProps) {
  const url: string = "/user-address";

  const response = await authClient.post(url, credentials);

  return response.data;
}
