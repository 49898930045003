import { Colors } from "constants/colors";
import styled from "styled-components";

export const CardProfile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-self: flex-start;
  border: 1px solid ${Colors.black10};
  padding: 1.87rem 2.81rem;

  box-shadow: 0px 4px 10px rgba(18, 25, 34, 0.05);
  border-radius: 0.93rem;

  @media (max-width: 800px) {
    margin-left: 0px;
    box-shadow: none;
    padding: 0;
    border: 0;
    height: 100%;
  }
`;

export const TitleNew = styled.h1`
  color: ${Colors.black100};
  font-family: Cairo;
  font-size: 1.87rem;
  font-style: normal;
  font-weight: 700;
  margin-top: 2.53rem;
  margin-bottom: 0px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ContainerSwitch = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.87rem;

  .ant-switch-checked {
    background-color: ${Colors.primary100};
  }
`;

export const TitleSwitch = styled.h2`
  color: ${Colors.secondary100};
  font-family: Poppins;
  font-size: 1.03rem;
  font-style: normal;
  font-weight: 500;
  margin-left: 0.93rem;
  margin-bottom: 0px;
`;
