import { Container, ContainerItems, CardProfile, TitleCard } from "./styles";

import { Footer } from "components/Footer";
import { Header } from "components/Header";
import { ListBreadcrumb } from "components/ListBreadcrumb";

import { GridSidebar } from "styles/pages/Sidebar";
import { SidebarOrganizerProfile } from "components/SidebarOrganizerProfile";
import { TitleWithMargin } from "pages/OrganizerCadaster/Steps/Identification/styles";

import { HeaderTitleOrganizerProfile } from "components/HeaderTitleOrganizerProfile";

import { Form } from "./Data/Form";

const metaTags = {
  title: `Dados de acesso - ${process.env.REACT_APP_EVENTX_META_TITLE_NAME}`,
  description: process.env.REACT_APP_EVENTX_META_DESCRIPTION,
  noIndex: true,
};

export default function OrganizerProfile() {
  return (
    <>
      <Header isProfile metaTags={metaTags} />

      <Container>
        <ContainerItems>
          <div className="breadcrumb">
            <ListBreadcrumb
              data={[
                { name: "Home", link: "/" },
                { name: "Perfil", link: "/" },
              ]}
            />
          </div>

          <HeaderTitleOrganizerProfile />

          <GridSidebar>
            <SidebarOrganizerProfile main={true} />

            <CardProfile>
              <TitleCard>Dados do organizador</TitleCard>

              <TitleWithMargin>
                Dados do representante da empresa
              </TitleWithMargin>

              <Form />
            </CardProfile>
          </GridSidebar>
        </ContainerItems>
      </Container>

      <Footer />
    </>
  );
}
