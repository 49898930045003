import { useEffect } from "react";

import { RiCalendarCheckFill } from "react-icons/ri";
import { BiTime } from "react-icons/bi";

import { DatePicker } from "antd";
import moment from "moment";

import { CreateDataEventFormData, Event } from "types";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { Colors } from "constants/colors";
import { EditEventService } from "services/Hook/Event";
import { useDisclosure } from "hooks/useDisclosure";

import {
  DateTimeContainer,
  EditingBoardContent,
  HorizontalLine,
  ButtonSecondary,
  ButtonPrimary,
  ContainerButtons,
} from "../../styles";

import { InputForm } from "components/Form/InputForm";
import { ModalSuccess } from "components/ModalSuccess";

import {
  ContainerInputFull,
  FormLabel,
  GridInputs,
} from "pages/MyEvent/New/Steps/Data/styles";

interface EventDateTimeProps {
  activeStep: number;
  data: Event;
}

const createDataEventFormSchema = yup.object().shape({
  begin_date: yup.string().required("Data inicial obrigatória"),
  begin_hour: yup.string().required("Hora inicial obrigatória"),
  end_date: yup.string().required("Data final obrigatória"),
  end_hour: yup.string().required("Hora final obrigatória"),
});

const dateFormat = "DD/MM/YYYY";

export function EventDateTime({ activeStep, data }: EventDateTimeProps) {
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CreateDataEventFormData>({
    resolver: yupResolver(createDataEventFormSchema),
  });

  const { showModal, handleOk, handleCancel, isModalVisible } = useDisclosure();

  useEffect(() => {
    reset({
      begin_date: data?.formattedBeginDate,
      begin_hour: data?.formattedBeginHour,
      end_date: data?.formattedEndDate,
      end_hour: data?.formattedEndHour,
    });
  }, [data, reset]);

  const handleEditData: SubmitHandler<CreateDataEventFormData> = async ({
    begin_date,
    begin_hour,
    end_date,
    end_hour,
  }) => {
    try {
      const newDateBegin = begin_date.split("/") ?? "";

      const dateComponentBegin = `${newDateBegin[2]}-${newDateBegin[1]}-${newDateBegin[0]}`;

      const newDateEnd = end_date.split("/") ?? "";

      const dateComponentEnd = `${newDateEnd[2]}-${newDateEnd[1]}-${newDateEnd[0]}`;

      const dataEvent = {
        ...data,
        begin_date: dateComponentBegin,
        end_date: dateComponentEnd,
        begin_hour: `${dateComponentBegin} ${begin_hour}`,
        end_hour: `${dateComponentEnd} ${end_hour}`,
      };

      await EditEventService({
        id: data?._id,
        dataEvent,
      });

      showModal();
    } catch (err) {}
  };

  return (
    <EditingBoardContent onSubmit={handleSubmit(handleEditData)}>
      <h3>{activeStep}. Data do evento</h3>
      <p>Defina a data e o horário de quando seu evento irá acontecer.</p>
      <HorizontalLine />
      <h4>Quando seu evento irá acontecer?</h4>
      <DateTimeContainer>
        <GridInputs>
          <ContainerInputFull isFull error={!!errors.begin_date}>
            <FormLabel>
              <RiCalendarCheckFill size={17} color={Colors.secondary40} />
              <h2>Data inicial</h2>
            </FormLabel>

            <Controller
              control={control}
              name="begin_date"
              rules={{ required: "Salutation is required" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <DatePicker
                  format={dateFormat}
                  placeholder="Selecione uma data inicial"
                  suffixIcon={false}
                  style={{
                    height: "auto",
                    width: "auto",
                    border: "none",
                    borderRadius: "0px",
                    cursor: "pointer",
                    fontSize: "17px",
                    margin: "0px",
                    padding: "0px",
                  }}
                  ref={ref}
                  value={value ? moment(value, dateFormat) : null}
                  onChange={(date: unknown, dateString: string) => {
                    if (!dateString) {
                      onChange(null);
                      return;
                    }

                    onChange(dateString);
                  }}
                />
              )}
            />

            {!!errors.begin_date ? (
              <span className="error">{errors.begin_date.message}</span>
            ) : null}
          </ContainerInputFull>

          <InputForm
            label="Horário de início"
            isFull
            mask="hour"
            icon={<BiTime size={17} color={Colors.secondary40} />}
            {...register("begin_hour")}
            placeholder="Selecione um horário inicial"
            error={errors?.begin_hour}
          />
        </GridInputs>

        <GridInputs>
          <ContainerInputFull isFull error={!!errors.end_date}>
            <FormLabel>
              <RiCalendarCheckFill size={17} color={Colors.secondary40} />
              <h2>Data de término</h2>
            </FormLabel>

            <Controller
              control={control}
              name="end_date"
              rules={{ required: "Salutation is required" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <DatePicker
                  format={dateFormat}
                  placeholder="Selecione uma data final"
                  suffixIcon={false}
                  style={{
                    height: "auto",
                    width: "auto",
                    border: "none",
                    borderRadius: "0px",
                    cursor: "pointer",
                    fontSize: "17px",
                    margin: "0px",
                    padding: "0px",
                  }}
                  ref={ref}
                  value={value ? moment(value, dateFormat) : null}
                  onChange={(date: unknown, dateString: string) => {
                    if (!dateString) {
                      onChange(null);
                      return;
                    }

                    onChange(dateString);
                  }}
                />
              )}
            />

            {!!errors.end_date ? (
              <span className="error">{errors.end_date.message}</span>
            ) : null}
          </ContainerInputFull>

          <InputForm
            label="Horário de término"
            isFull
            mask="hour"
            icon={<BiTime size={17} color={Colors.secondary40} />}
            {...register("end_hour")}
            placeholder="Selecione um horário final"
            error={errors?.end_hour}
          />
        </GridInputs>
      </DateTimeContainer>

      <ContainerButtons>
        <ButtonSecondary type="button" onClick={() => {}}>
          <span>Cancelar</span>
        </ButtonSecondary>

        <ButtonPrimary htmlType="submit" loading={isSubmitting}>
          <span>Salvar</span>
        </ButtonPrimary>
      </ContainerButtons>

      <ModalSuccess
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalVisible={isModalVisible}
        title="Edição de evento finalizada!"
        subtitle="Clique em OK para prosseguir."
      />
    </EditingBoardContent>
  );
}